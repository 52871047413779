import foodPacksPhoto from '../../../images/landings/palestine/food-packs.png';
import hotMealsPhoto from '../../../images/landings/palestine/hot-meals.png';
import ricebags from '../../../images/eidFitr2024/ricebags.png';
import iftarMeals from '../../../images/eidFitr2024/iftar-meals.png';
import litres from '../../../images/eidFitr2024/litres.png';
import tonsOfSugar from '../../../images/eidFitr2024/tons-of-sugar.png';
import orphanIftar from '../../../images/eidFitr2024/orphan-iftar.png';
import blankets from '../../../images/eidFitr2024/blanket.png';
import water from '../../../images/eidFitr2024/water.png';
import flourBags from '../../../images/eidFitr2024/flour-bags.png';

// export const impactSliderData = [
//   {
//     img: { src: foodPacksPhoto, alt: 'Palestine Appeal provided food packs' },
//     value: '782,300',
//     title: 'Food Packs',
//   },
//   {
//     img: { src: hotMealsPhoto, alt: 'Palestine Charity, provided medical kits' },
//     value: '25,372',
//     title: 'Hot Meals',
//   },
//   {
//     img: { src: ricebags, alt: 'Donations to palestine, provided fuel' },
//     value: '1,460,000',
//     title: 'Rice Bags',
//   },
//   {
//     img: { src: iftarMeals, alt: 'Donate, Palestine. Mattresses provided' },
//     value: '1,210,357',
//     title: 'Iftar Meals',
//   },

//   {
//     img: { src: babyMilk, alt: 'Charity for Palestine provided medical relief' },
//     value: '20,300',
//     title: 'Baby Milk',
//   },
//   {
//     img: { src: litres, alt: 'Donate to Palestine with Hot meals' },
//     value: '42,500',
//     title: 'Litres of oil',
//   },
//   {
//     img: { src: tonsOfSugar, alt: 'Palestine Donation with medical beds and stretchers' },
//     value: '275,000',
//     title: 'Tons of sugar',
//   },
// ];
export const impactSliderData = [
  {
    img: { src: ricebags, alt: 'Donations to palestine, provided fuel' },
    value: '45 Tones',
    title: 'Rice',
  },
  {
    img: { src: iftarMeals, alt: 'Donate, Palestine. Mattresses provided' },
    value: '4,700',
    title: 'Masjid Iftar',
  },
  {
    img: { src: orphanIftar, alt: 'Donate, Palestine. Mattresses provided' },
    value: '14,000',
    title: 'Orphan Iftar',
  },
  {
    img: { src: blankets, alt: 'Donate, Palestine. Mattresses provided' },
    value: '1,200',
    title: 'blankets',
  },
  {
    img: { src: tonsOfSugar, alt: 'Palestine Donation with medical beds and stretchers' },
    value: '55 Tones',
    title: 'sugar',
  },
  {
    img: { src: water, alt: 'Palestine Appeal provided food packs' },
    value: '4,000000 L',
    title: 'Water',
  },
  {
    img: { src: flourBags, alt: 'Palestine Appeal provided food packs' },
    value: '6,500',
    title: 'Flour Bags',
  },
  {
    img: { src: litres, alt: 'Donate to Palestine with Hot meals' },
    value: '8,500',
    title: 'Litres of oil',
  },
  {
    img: { src: foodPacksPhoto, alt: 'Palestine Appeal provided food packs' },
    value: '26,000',
    title: 'Food Packs',
  },
  {
    img: { src: hotMealsPhoto, alt: 'Palestine Charity, provided medical kits' },
    value: '241,000',
    title: 'iftar Meals',
  },
];

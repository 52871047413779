import React from 'react'

const MaxWidthContainer = ({className,children}) => {
  return (
    <div className={`mx-auto px-6 sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1200px] 2xl:max-w-[1400px] ${className ? className : ""}`}>
        {children}
    </div>
  )
}

export default MaxWidthContainer
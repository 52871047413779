import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ShowCurrencies from '../../utils/showCurrencies';
import Skeleton from 'react-loading-skeleton';
import useHandleAddProduct from '../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import CustomButton from './button';
import IsGTMScriptLoaded from '../../utils/isGTMScriptLoaded';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';

const SingleProductBox = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const navigate = useNavigate();
  const [addProduct] = useHandleAddProduct();
  const {
    product,
    currency,
    isStaticProduct,
    isPalestineRefugee = false,
    arabic = false,
    staticLink,
    staticLinkText,
    buttonLable,
    stateVariableObj,
    scrollTop,
    alt,
    linkBtnClasses,
    titleClasses,
    descriptionStyle,
    _lang = 'en',
    removeDataLayer,
    isVisibleDescription = true,
    childClasses,
    defaultPrice = 100,
  } = props;
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isAnyAmount, setIsAnyAmount] = useState(false);
  const pixelID = localStorage.getItem('TikTokID');
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleCurrency = currency => {
    if (product.quantity) {
      return ShowCurrencies(currency, product[currency.toLowerCase()] * product.quantity, false);
    } else {
      return ShowCurrencies(currency, product[currency.toLowerCase()], false);
    }
  };
  const handleCustomProductAmount = product => {
    // console.log("product=",product)
    if (Number(process.env.REACT_APP_MAKE_DONATION_CATEGORY_ID) === Number(product.category_id) || product.id === 52 || product.id === 69) {
      setIsAnyAmount(true);
    } else {
      // if (Number(product[currency.toLowerCase()]) === 1) {
      let hasQuantity = product.quantity ? (product.quantity > 1 ? true : false) : false;
      // if (Number(product[currency.toLowerCase()]) < 6 && !hasQuantity) {
      if (Number(product.aud) <= 2 && !hasQuantity) {
        setIsAnyAmount(true);
      } else {
        setIsAnyAmount(false);
      }
    }
  };
  const handleHeight = () => {
    setShowMore(!showMore);
  };

  const handleViewItemDataLayer = (tempProduct, currency) => {
    // console.log("datalayer view item", tempProduct)
    let dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [
          {
            item_id: tempProduct.creator,
            item_name: tempProduct.name,
            item_brand: tempProduct.category || '',
            item_category: tempProduct.category || '',
            item_description: tempProduct.description || '',
            price: tempProduct[currency.toLowerCase()],
          },
        ],
      },
    };
    window.dataLayer = window.dataLayer || [];
    // console.log('datalayer view item=', IsGTMScriptLoaded(),tempProduct, dataLayerTemp, window.dataLayer);
    IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);

    let obj = {
      content_type: 'product',
      content_id: tempProduct.creator,
      currency: currency,
      value: tempProduct[currency.toLowerCase()],
      price: tempProduct[currency.toLowerCase()],
      quantity: 1,
      content_category: tempProduct.category ? tempProduct.category : 'donation',
      content_name: tempProduct.name,
      description: tempProduct.description,
    };
    window.ttq &&
      window.ttq.instance(pixelID).track('ViewContent', {
        ...obj,
      });
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (product) {
      handleCustomProductAmount(product);
      if (ref.current && ref.current.clientHeight > 60) {
        setIsOverflow(true);
      }
    }
    // Include dependencies that affect the rendering of the ref'd component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, ref.current]);
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`flex w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white ${childClasses ? childClasses : ''}`} style={{ height: '100%' }}>
      <div
        // className="overflow-hidden img-hover-zoom rounded-md max-h-[336px]- w-full sm:w-[197px]- sm:h-[169px] md:w-[210px] md:h-[210px] lg:w-[301px] lg:h-[301px] xl:w-[280px] xl:h-[280px] 2xl:w-[344px] 2xl:h-[344px] tooltip [&>span]:!block"
        className="overflow-hidden img-hover-zoom rounded-md w-full- sm:min-h-[169px] md:min-h-[210px] lg:min-h-[301px] xl:min-h-[280px] 2xl:min-h-[344px] tooltip [&>span]:!block !w-[40%] pr-2.5"
        // className="overflow-hidden img-hover-zoom rounded-md w-full tooltip [&>span]:!block"
        onClick={() => {
          isPalestineRefugee
            ? navigate(generateLinkWithLang(`/appeals/palestine/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang))
            : isStaticProduct
            ? navigate(generateLinkWithLang(`${staticLink ? staticLink : '/zakat-calculator'}`, lang))
            : navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang));
        }}
      >
        {product ? (
          product.image_link ? (
            <LazyLoadImage
              alt={alt ? alt : product.alt ? product.alt : product.name}
              effect="blur"
              width={`100%`}
              delayTime={500}
              afterLoad={() => {
                !removeDataLayer && handleViewItemDataLayer(product, currency);
              }}
              className="rounded cursor-pointer block"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/general/logo-replace.png';
              }}
              src={product.image_link} // use normal <img> attributes as props
            />
          ) : (
            <img className="w-full" src="/images/general/logo-replace.png" alt="" />
          )
        ) : (
          <Skeleton height={344} />
        )}
        {/* <span className="tooltiptext bg-[#00a3da] rounded-lg cursor-pointer mx-auto left-0 right-0 z-[1] absolute py-1 text-center text-white opacity-0 transition-all duration-300 w-32 top-[calc(50%_-_17px)]">Details</span> */}
      </div>
      <div className="w-[60%] flex flex-col items-start">
        <p
          className={`text-[#00a3da] cursor-pointer flex justify-center items-center text-sm sm:text-md md:text-md lg:text-lg xl:text-xl ${
            isVisibleDescription ? '' : ''
          }  mt-2 leading-4 md:leading-none uppercase ${arabic || _lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-medium'} ${
            titleClasses ? titleClasses : ''
          }`}
          onClick={() => {
            isPalestineRefugee
              ? navigate(generateLinkWithLang(`/appeals/palestine/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang))
              : isStaticProduct
              ? navigate(generateLinkWithLang(`${staticLink ? staticLink : '/zakat-calculator'}`, lang))
              : navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang));
          }}
        >
          {/* {product ? product.name : <Skeleton height={56} />} */}
          {product ? product.alternativeName || product.name : <Skeleton height={56} />}
        </p>
        <div className="relative">
          {product ? (
            <>
              {
                // Description block
                (isVisibleDescription || showMore) && (
                  <div
                    className={`transition-all duration-300 relative overflow-hidden ${showMore ? 'h-auto mb-4' : 'max-h-[65px] md:max-h-[78px]'} ${
                      (product.short_description || product.description).split(' ').length > 28 ? 'mb-0' : 'mb-0'
                    }`}
                  >
                    <p
                      ref={ref}
                      className={`text-stone-500 text-sm sm:text-sm md:text-base lg:text-base overflow-hidden transition-all duration-200 min-h-[65px] md:min-h-[79px] ${
                        descriptionStyle ? descriptionStyle : ''
                      } ${arabic || _lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}
                    >
                      {product.short_description || product.description}
                    </p>
                  </div>
                )
              }
              {
                // Chevron icon
                (isOverflow || !isVisibleDescription) && (
                  <ChevronDownIcon
                    onClick={handleHeight}
                    className={` md:block w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer ${
                      showMore ? 'transform rotate-180' : ''
                    }`}
                  />
                )
              }
            </>
          ) : (
            <Skeleton count={4} />
          )}
        </div>
        <div className="grid grid-cols-6 gap-1 2xl:gap-2">
          {product ? (
            <p className={`text-[#f60362] col-span-6 text-md md:text-xl font-bold ${arabic || _lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}>
              {isStaticProduct ? (
                ' '
              ) : isAnyAmount ? (
                arabic || _lang === 'ar' ? (
                  'أي مبلغ'
                ) : _lang === 'fr' ? (
                  `N'importe quel montant`
                ) : (
                  'Any Amount'
                )
              ) : (
                <div className='flex items-center'>
                  {handleCurrency(currency)} <small className={`uppercase ml-0.5`}>{currency}</small>
                </div>
              )}
            </p>
          ) : (
            <div className="col-span-6">
              <Skeleton />
            </div>
          )}

          <div className="flex justify-center col-span-6">
            {product ? (
              isStaticProduct ? (
                scrollTop >= 0 ? (
                  <CustomButton
                    onClick={() => {
                      window.scroll({ top: scrollTop, left: 0, behavior: 'smooth' });
                    }}
                    title={staticLinkText ? staticLinkText : 'Zakat Calculator'}
                    className={`${linkBtnClasses ? linkBtnClasses : ''}`}
                  />
                ) : (
                  // <button
                  //     className={`text-white text-sm md:text-md lg:text-xl col-span-6 xl:col-span-4 xl:col-start-2 font-medium flex rounded-md transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] cursor-pointer px-2 py-2 justify-center items-center ${linkBtnClasses ? linkBtnClasses : ''}`}
                  //     onClick={() => { window.scroll({ top: scrollTop, left: 0, behavior: 'smooth' }) }}
                  // >
                  //     {staticLinkText ? staticLinkText : 'Zakat Calculator'}
                  // </button>
                  <CustomButton
                    state={stateVariableObj}
                    link={`${staticLink ? staticLink : '/zakat-calculator'}`}
                    title={staticLinkText ? staticLinkText : 'Zakat Calculator'}
                    className={`${linkBtnClasses ? linkBtnClasses : ''}`}
                  />
                )
              ) : (
                //     <Link state={stateVariableObj} to={`${staticLink ? staticLink : '/zakat-calculator'}`}
                //         className={`font-bold text-white text-sm md:text-md lg:text-xl w-full sm:w-auto
                //  flex rounded-md transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] min-h-[44px] cursor-pointer min-w-[145px] sm:min-w-[155px] md:min-w-[185px]  px-2 py-2 justify-center items-center text-center ${linkBtnClasses ? linkBtnClasses : ''}`}
                //     >
                //         {staticLinkText ? staticLinkText : 'Zakat Calculator'}
                //     </Link>
                <CustomButton
                  onClick={() => {
                    addProduct({ product: product, currency: currency, defaultPrice: defaultPrice });
                  }}
                  title={
                    buttonLable ? buttonLable : arabic || _lang === 'ar' ? 'تبرع الان' : _lang === 'fr' ? 'Faites un don maintenant' : 'Donate Now'
                  }
                  className={`rounded-xl !h-[30px] ${linkBtnClasses ? linkBtnClasses : ''} ${arabic || _lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}
                />
              )
            ) : (
              <Skeleton height={43} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProductBox;

import { Link } from 'react-router-dom';
import Layout from '../../components/general/layout';
import banner from '../../images/landings/phishing/phishing-desktop-banner.jpg';
import bannerMobile from '../../images/landings/phishing/phishing-mobile-banner.jpg';
import phishingImg from '../../images/landings/phishing/phishing.jpg';

const PhishingLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    /* -------------------------------------------------------------------------- */
    return (
        <Layout className='bg-white'>
            <section className="flex justify-center">
                <img src={banner} alt="ramadan 2024" className="max-[500px]:hidden w-full" />
                <img src={bannerMobile} alt="ramadan 2024" className="min-[501px]:hidden w-full" />
            </section>
            <div className="md:container md:mx-auto mt-5 md:mt-10 px-4 md:px-0">
                <h2 className='text-[#23408f] text-[25px] md:text-[40px] my-4 w-full md:text-center font-bold'>Phishing scams & fraud</h2>
                <p className='w-full px-2 md:px-0 text-[#78716C] md:text-[18px]'>
                    The relationship with our donors is of the utmost importance to us here at MATW. Their unwavering support helps us in
                    driving forward our mission. We often appeal for donations to enable our life-saving projects and are incredibly grateful
                    for the donors who are quick to respond.
                    <br />
                    <br />
                    Regrettably, we must address a concerning issue that has come to our attention. It has come to light that there are some
                    individuals, with no affiliation to MATW (Muslims Around The World), who are exploiting our identity through use of our
                    name and our logo to fraudulently solicit donations for their own purpose. By pretending to be MATW, these individuals
                    are trying to unlawfully obtain financial and personal information.
                    <br />
                    <br />
                    While we strive to combat such deceptive practices, we acknowledge the challenges in entirely preventing them. Thus,
                    we urge you to remain vigilant and exercise caution when and if you receive a request that you feel may be suspicious.
                    To report any suspicious activity or unauthorized use of our identity, please utilize the following secure channel:
                    <Link to={'/contact'} className='ml-1 text-[#23408f]'>contact us</Link>.
                    <br />
                    <br />
                    In the interest of transparency and to illustrate the gravity of this issue, we would like to share some recent instances of
                    unauthorized use of our identity:
                </p>
                <div className="w-full bg-[#E1EFF2] rounded-lg flex justify-center items-center p-4 md:p-8 my-3 md:my-6">
                    <img src={phishingImg} alt="Phishing scams & fraud" className='w-full rounded-md' />
                </div>
                <p className='w-full px-2 md:px-0 text-[#78716C] md:text-[18px]'>
                    Your continued trust and support are invaluable to us, and we are committed to upholding the integrity of our organization.
                    Together, we can work towards combating fraudulent activities and ensuring the safety and security of our supporters.
                    <br />
                    <br />
                    Should you have any questions or concerns, please do not hesitate to reach out to us directly.
                </p>
            </div>
        </Layout>
    );
}

export default PhishingLandingPage;

import { useState } from 'react';

const useMutation = apiFunction => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const mutate = async data => {
    setIsSuccess(false);
    setError(null);
    setIsLoading(true);
    try {
      const response = await apiFunction(data);
      setIsLoading(false);
      setIsSuccess(true);
      return response;
    } catch (error) {
      setIsLoading(false);
      setError(error);
      setIsSuccess(false);
      throw error;
    }
  };

  return { mutate, isLoading, error, isSuccess };
};

export default useMutation;

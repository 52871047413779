import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/general/layout';
import services from '../../services';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { AsYouType } from 'libphonenumber-js';
import * as Yup from 'yup';
import { Formik } from 'formik';
// import { Formik } from 'formik';

const ParticipantPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const refPhone = useRef(null);
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [teacherInfo, setTeacherInfo] = useState({
        name: '',
        class: '',
        school: '',
        email: '',
        phone: '',
        target_goal: '',
    });
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    // eslint-disable-next-line no-useless-escape
    const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const TeacherInfoSchema = Yup.object().shape({
        name: Yup.string().min(3, 'Should be 3 character long').required('Enter name'),
        class: Yup.string().min(3, 'Should be 3 character long').required('Enter class'),
        target_goal: Yup.string().min(3, 'Should be 3 character long').required('Enter target goal'),
        school: Yup.string().min(3, 'Should be 3 character long').required('Enter school'),
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Enter phone number').min(10, 'Should be 10 digits'),
        email: Yup.string().matches(emailRegExp, 'Invalid email address').required('Enter your email address'),
    });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSubmit = () => {
        if (isPhoneValid) {
            setIsloading(true);
            submitForm();
        }
    }
    const submitForm = async () => {
        try {
            let tempUser = {};
            tempUser.name = teacherInfo.name;
            tempUser.class = teacherInfo.class;
            tempUser.school_name = teacherInfo.school;
            tempUser.email = teacherInfo.email;
            tempUser.goal = teacherInfo.target_goal;
            let phoneNumber = '';
            if (teacherInfo.phone.charAt(0) === '0') {
                phoneNumber = `0${teacherInfo.phone}`
            } else {
                phoneNumber = `00${teacherInfo.phone}`
            }
            tempUser.phone = phoneNumber;
            console.log('tempUser createUser', tempUser);
            const response = await services.postFormTeacherTraining(tempUser);
            console.log('response createUser', response);
            toast.success('Thank you for complete the form.');
            setIsloading(false);
            setTeacherInfo({
                ...teacherInfo,
                name: '',
                class: '',
                school: '',
                email: '',
                phone: '',
                target_goal: ''
            })
        } catch (error) {
            setIsloading(false);
            toast.error('Something went wrong. Please try again later.');
        }
    };
    const handlePhoneValidation = (value, country, type) => {
        let temp = new AsYouType(country.countryCode).input(`+${value}`)
        if (temp.split(' ')[1] && Number(temp.split(' ')[1].charAt(0)) === 0) {
            let temp3 = country.dialCode + value.substr(value.indexOf(country.dialCode) + country.dialCode.length + 1, value.length);
            console.log("temp3==", temp3);
            refPhone.current.value = temp3;
            setTeacherInfo({
                ...teacherInfo,
                phone: temp3,
            });
        } else {
            setTeacherInfo({
                ...teacherInfo,
                phone: value,
            });
        }
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' })
        window.scroll(0, 0);
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <Layout>
            <div className={`w-full bg-[url('../src/images/banners/banner-bg.png')] bg-cover text-white h-auto md:h-[340px] flex justify-center items-center`}>
                <div className="w-full h-full bg-[#093484]- bg-opacity-70 py-4">
                    <div className="md:container md:mx-auto px-2 sm:px-3 h-full flex flex-col justify-center items-center">
                        <h1 className='w-full text-white text-lg sm:text-2xl md:text-5xl text-center'>
                            Registration Form
                        </h1>
                        <p className="w-full text-center mt-4 text-lg">
                            A Race to Care for Orphans this Eid Al Adha
                        </p>
                    </div>
                </div>
            </div>
            <div className="lg:container mx-auto px-6 lg:px-0 pt-6">
                <div className="bg-white rounded-md shadow-md p-4 lg:p-6">
                    <h2 className="text-[#777] text-lg">
                        {/* Please complete your details to opt-out from our marketing. */}
                    </h2>
                    <Formik
                        initialValues={{
                            name: teacherInfo.name,
                            class: teacherInfo.class,
                            school: teacherInfo.school,
                            email: teacherInfo.email,
                            phone: teacherInfo.phone,
                            target_goal: teacherInfo.target_goal,
                        }}
                        validationSchema={TeacherInfoSchema}
                        onSubmit={(values) => {
                            console.log("values", values)
                            handleSubmit(values);
                        }}
                    >
                        {({ errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-cols-4 gap-4">
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777]'>Student or Teacher Name<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <input
                                                type="text"
                                                name='name'
                                                id='name'
                                                placeholder='Enter name'
                                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                                onBlur={handleBlur}
                                                value={teacherInfo.name}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setTeacherInfo({
                                                        ...teacherInfo,
                                                        name: e.target.value,
                                                    });
                                                }}
                                            />
                                            {(errors.name && touched.name) &&
                                                <small id='name-error' className="text-[#f60362] text-sm">
                                                    {errors.name}
                                                </small>}
                                        </label>
                                    </div>
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777]'>Class<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <input
                                                type="text"
                                                name='class'
                                                id='class'
                                                placeholder='Enter class'
                                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                                onBlur={handleBlur}
                                                value={teacherInfo.class}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setTeacherInfo({
                                                        ...teacherInfo,
                                                        class: e.target.value,
                                                    });
                                                }}
                                            />
                                            {(errors.class && touched.class) &&
                                                <small id='class-error' className="text-[#f60362] text-sm">
                                                    {errors.class}
                                                </small>}
                                        </label>
                                    </div>
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777]'>School<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <input
                                                type="text"
                                                name='school'
                                                id='school'
                                                placeholder='Enter school'
                                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                                onBlur={handleBlur}
                                                value={teacherInfo.school}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setTeacherInfo({
                                                        ...teacherInfo,
                                                        school: e.target.value,
                                                    });
                                                }}
                                            />
                                            {(errors.school && touched.school) &&
                                                <small id='school-error' className="text-[#f60362] text-sm">
                                                    {errors.school}
                                                </small>}
                                        </label>
                                    </div>
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777] w-full'>Phone<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <PhoneInput
                                                name="phone"
                                                ref={refPhone}
                                                className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md even:min-h-[48px]"
                                                id="phone"
                                                placeholder='Enter your phone number'
                                                onBlur={(val) => {
                                                    let e = { target: { value: val, name: 'phone', id: 'phone' } }
                                                    handleBlur(e)
                                                }}
                                                isValid={(value, country) => {
                                                    if (value.match(/12345/)) {
                                                        setIsPhoneValid(false)
                                                        return 'Invalid value: ' + value + ', ' + country.name;
                                                    } else if (value.match(/1234/)) {
                                                        return false;
                                                    } else {
                                                        setIsPhoneValid(true)
                                                        return true;
                                                    }
                                                }}
                                                country={'au'}
                                                inputClass={'!w-full'}
                                                value={teacherInfo.phone || ""}
                                                onChange={(val, country) => {
                                                    let e = { target: { value: val, name: 'phone', id: 'phone' } };
                                                    handlePhoneValidation(val, country, 'phone')
                                                    handleChange(e);
                                                }}
                                            />
                                            {(errors.phone && touched.phone) &&
                                                <small id='phone-error' className="text-[#f60362] text-sm">
                                                    {errors.phone}
                                                </small>}
                                        </label>
                                    </div>
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777]'>Email<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <input
                                                type="text"
                                                name='email'
                                                id='email'
                                                placeholder='Enter your email'
                                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                                onBlur={handleBlur}
                                                value={teacherInfo.email}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setTeacherInfo({
                                                        ...teacherInfo,
                                                        email: e.target.value,
                                                    });
                                                }}
                                            />
                                            {(errors.email && touched.email) &&
                                                <small id='email-error' className="text-[#f60362] text-sm">
                                                    {errors.email}
                                                </small>}
                                        </label>
                                    </div>
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777]'>Target Goal<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <input
                                                type="text"
                                                name='target_goal'
                                                id='target_goal'
                                                placeholder='Enter target goal'
                                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                                onBlur={handleBlur}
                                                value={teacherInfo.target_goal}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setTeacherInfo({
                                                        ...teacherInfo,
                                                        target_goal: e.target.value,
                                                    });
                                                }}
                                            />
                                            {(errors.target_goal && touched.target_goal) &&
                                                <small id='target_goal-error' className="text-[#f60362] text-sm">
                                                    {errors.target_goal}
                                                </small>}
                                        </label>
                                    </div>
                                </div>
                                <div className="grid grid-cols-6 mt-8 gap-2 md:gap-4">
                                    <div className="col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2">
                                        <button
                                            type="submit"
                                            disabled={isLoading}
                                            // className="group active:bg-blue-400 w-full flex items-center justify-center rounded bg-blue-600 text-white px-6 pt-2 pb-2 font-normal text-lg h-[48px] shadow-[0_4px_9px_-4px_#3b71ca]   hover:bg-blue-800 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-800 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                            className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]`}
                                        >
                                            {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            </>) : <><span>Submit</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                            </svg>
                                            </>}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </Layout>
    );
}

export default ParticipantPage;

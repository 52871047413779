const liveFundraiserProducts = [
  {
    id: 269,
    stripe_product_id: 'prod_OFXQqp1S174Tt1',
    paypal_product_id: 'PROD-5GD55498B4621951E',
    creator: 'FWD-DIS-AR-GN-002',
    name: 'Feed 10 Hungry People',
    description: 'The price you pay for one nice meal could answer the prayers of 10 people.',
    aud: 50,
    usd: 35,
    gbp: 27,
    cad: 46,
    eur: 32,
    sgd: 47,
    myr: 163,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-feed-10-hungry-people.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-07-12 22:38:25',
    updated_at: '2023-09-22 15:52:11',
    deleted_at: null,
    category_id: 23,
    aed: 183.5,
    idr: 769550,
  },
  {
    id: 272,
    stripe_product_id: 'prod_OFXQ6T5qmgYU9b',
    paypal_product_id: 'PROD-0B205461WJ995152F',
    creator: 'FWD-DIS-AR-GN-006',
    name: 'Water For 500 People',
    description: 'Give water to 500 people, bringing new life and energy to their existence.',
    aud: 50,
    usd: 35,
    gbp: 27,
    cad: 46,
    eur: 32,
    sgd: 47,
    myr: 163,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-for-500-people.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-07-12 22:38:26',
    updated_at: '2023-09-22 15:52:32',
    deleted_at: null,
    category_id: 24,
    aed: 183.5,
    idr: 769550,
  },
  {
    id: 55,
    stripe_product_id: 'prod_NYPzAtKvkDnwch',
    paypal_product_id: 'PROD-8CR03891LU471535H',
    creator: 'FWD-DIS-FPY-2023-01-045',
    name: 'Food Pack For Yemen',
    description:
      "Yemen is facing a devastating hunger crisis, with at least 17 million people struggling to find their next meal. MATW's Food Pack for Yemen initiative will provide essential dry items and food packages to those in need during these challenging times. You have helped us provide food packs to 394875 Yemenis. Keep donating and keep helping those going hungry.",
    aud: 60,
    usd: 45,
    gbp: 36,
    cad: 59,
    eur: 41,
    sgd: 58,
    myr: 191,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-food-pack-for-yemen.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:24',
    deleted_at: null,
    category_id: 23,
    aed: 150,
    idr: 685755,
  },
  {
    id: 56,
    stripe_product_id: 'prod_NYPzTgkHFQ5l81',
    paypal_product_id: 'PROD-8CR03891LU471535H',
    creator: 'FWD-DIS-FPP-2023-01-046',
    name: 'Food Pack For Pakistan',
    description:
      'Six out of ten Pakistanis are facing food insecurity. Help us alleviate the dire hunger crisis in Pakistan by supplying essential dry items and food packages to families facing extreme poverty. Your donation has helped us provide 9300 Pakistanis with essential food packs. Donate now to ensure that vulnerable communities receive vital sustenance, empowering them to overcome food insecurity and build a brighter future.',
    aud: 60,
    usd: 45,
    gbp: 36,
    cad: 59,
    eur: 41,
    sgd: 58,
    myr: 191,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-food-pack-for-pakistan.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:25',
    deleted_at: null,
    category_id: 23,
    aed: 150,
    idr: 685755,
  },
  {
    id: 54,
    stripe_product_id: 'prod_NYPzDmy83h5fO7',
    paypal_product_id: 'PROD-8CR03891LU471535H',
    creator: 'FWD-DIS-FPM-2023-01-044',
    name: 'Food Pack For Middle East',
    description:
      "141 million Middle Easterns are enduring a severe food shortage, leaving families in desperate need of assistance. MATW's Food Pack for the Middle East is a vital initiative that addresses this crisis by supplying crucial dry items and food packages. 25650 Middle Eastern people have been provided food packs until now. Donate now and become an agent of change, enabling us to reach those affected by hunger and provide them with the sustenance they require to survive.",
    aud: 60,
    usd: 45,
    gbp: 36,
    cad: 59,
    eur: 41,
    sgd: 58,
    myr: 191,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-food-pack-for-middle-east.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:23',
    deleted_at: null,
    category_id: 23,
    aed: 150,
    idr: 685755,
  },
  {
    id: 58,
    stripe_product_id: 'prod_NYPzZzHViWPBfu',
    paypal_product_id: 'PROD-92H982558H7722056',
    creator: 'FWD-DIS-BMW-2023-01-048',
    name: 'Baby Milk For 1 Week',
    description:
      "For families struggling to afford adequate nutrition, providing essential milk for their babies can be an immense challenge. Over 350 million children are going hungry every day. With MATW's Baby Milk for 1 Week initiative, you can help bridge this gap and ensure that infants receive the nourishment they require for healthy growth. Until now, you’ve helped us provide 14308 infants with baby milk. Donate more to impact the lives of vulnerable babies.",
    aud: 10,
    usd: 8,
    gbp: 6,
    cad: 10,
    eur: 7,
    sgd: 10,
    myr: 32,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-baby-milk-for-1-week.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:45',
    deleted_at: null,
    category_id: 23,
    aed: 32,
    idr: 121912,
  },
  {
    id: 62,
    stripe_product_id: 'prod_NYPzadgNZOrMSf',
    paypal_product_id: 'PROD-5EG88470PE463633Y',
    creator: 'FWD-DIS-RBG-2023-01-052',
    name: 'Rice Bags',
    description:
      "In regions plagued by food insecurity, rice serves as a staple that sustains families during times of hardship. MATW's Rice Bags program aims to address this critical need by supplying essential rice to vulnerable communities. Your support can make a lasting impact, ensuring that families have access to a vital source of nutrition. Join us in our mission to alleviate hunger and provide sustenance to those in need.",
    aud: 60,
    usd: 45,
    gbp: 36,
    cad: 60,
    eur: 41,
    sgd: 60,
    myr: 200,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-rice-bags.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:26',
    deleted_at: null,
    category_id: 23,
    aed: 150,
    idr: 685755,
  },
  {
    id: 61,
    stripe_product_id: 'prod_NYPzi2mEvIOYty',
    paypal_product_id: 'PROD-92H982558H7722056',
    creator: 'FWD-DIS-DBD-2023-01-051',
    name: 'Bread',
    description: 'Buy several loaves of bread to provide sustenance for a large family over the course of one week.',
    aud: 50,
    usd: 37,
    gbp: 30,
    cad: 50,
    eur: 35,
    sgd: 50,
    myr: 160,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-bread.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:44',
    deleted_at: null,
    category_id: 23,
    aed: 144,
    idr: 563843,
  },
  {
    id: 65,
    stripe_product_id: 'prod_NYPzJOZ1WHhvdF',
    paypal_product_id: 'PROD-5EG88470PE463633Y',
    creator: 'FWD-DIS-WYX-2023-01-055',
    name: 'Water Distribution In Yemen 10K Litres',
    description: 'Help provide 10,000L of water to those in need in Yemen.',
    aud: 100,
    usd: 75,
    gbp: 60,
    cad: 100,
    eur: 70,
    sgd: 100,
    myr: 320,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-distribution-in-yemen-10k-litres.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:47',
    deleted_at: null,
    category_id: 24,
    aed: 292,
    idr: 1142925,
  },
  {
    id: 64,
    stripe_product_id: 'prod_NYPzMLUjfxkeiI',
    paypal_product_id: 'PROD-5EG88470PE463633Y',
    creator: 'FWD-DIS-WPV-2023-01-054',
    name: 'Water Distribution In Palestine 5K Litres',
    description: 'Help provide 5,000L of water to those in need in Palestine.',
    aud: 100,
    usd: 75,
    gbp: 60,
    cad: 100,
    eur: 70,
    sgd: 100,
    myr: 320,
    custom_amount: 'Yes',
    image_link:
      'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-distribution-in-palestine-5k-litres.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:47',
    deleted_at: null,
    category_id: 24,
    aed: 292,
    idr: 1142925,
  },
  {
    id: 68,
    stripe_product_id: 'prod_NYPzGuMYlOBxGg',
    paypal_product_id: 'PROD-8UM4616492540181R',
    creator: 'FWD-DIS-WPX-2023-01-058',
    name: 'Water Distribution In Pakistan 10K Litres',
    description: 'Help provide 10,000L of water to those in need in Pakistan.',
    aud: 1000,
    usd: 735,
    gbp: 600,
    cad: 980,
    eur: 672,
    sgd: 966,
    myr: 3171,
    custom_amount: 'Yes',
    image_link:
      'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-distribution-in-pakistan-10k-litres.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:48',
    deleted_at: null,
    category_id: 24,
    aed: 2856,
    idr: 11200665,
  },
  {
    id: 63,
    stripe_product_id: 'prod_NYPzWt7trI3TZd',
    paypal_product_id: 'PROD-5EG88470PE463633Y',
    creator: 'FWD-DIS-WDL-2023-01-053',
    name: 'Water Distribution In Lebanon',
    description: 'Help provide clean and safe drinking water for everyday use.',
    aud: 50,
    usd: 37,
    gbp: 30,
    cad: 50,
    eur: 35,
    sgd: 50,
    myr: 160,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-distribution-in-lebanon.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:48',
    deleted_at: null,
    category_id: 24,
    aed: 144,
    idr: 563843,
  },
  {
    id: 185,
    stripe_product_id: 'prod_NifkXuCSVcRCX5',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-L1W-2023-01-026',
    name: 'L1 Africa Water Well',
    description: 'Provide a L1 hand-pump water well for those in need, lasting for over 10 years.',
    aud: 10000,
    usd: 7350,
    gbp: 5985,
    cad: 9765,
    eur: 6720,
    sgd: 9660,
    myr: 31710,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-l1-africa-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:43',
    updated_at: '2023-09-15 18:55:54',
    deleted_at: null,
    category_id: 24,
    aed: 28555,
    idr: 112006650,
  },
  {
    id: 183,
    stripe_product_id: 'prod_NifkqNm7ZuvCx3',
    paypal_product_id: 'PROD-5JP61826AH732013L',
    creator: 'WWC-CON-H1W-2023-01-024',
    name: 'H1 Hand Pump Water Well',
    description: 'Provide a H1 hand pump water well for communities in need, lasting for over 10 years.',
    aud: 3000,
    usd: 2205,
    gbp: 1800,
    cad: 2930,
    eur: 2016,
    sgd: 2898,
    myr: 9513,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-h1-hand-pump-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:41',
    updated_at: '2023-09-15 18:55:51',
    deleted_at: null,
    category_id: 24,
    aed: 8567,
    idr: 33601995,
  },
  {
    id: 188,
    stripe_product_id: 'prod_Nifkt7ZkeYU2EI',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-BBW-2023-01-029',
    name: 'Bangladesh Basic Water Well',
    description: 'Provide a hand-operated water well pump for a rural community in Bangladesh.',
    aud: 500,
    usd: 368,
    gbp: 300,
    cad: 489,
    eur: 336,
    sgd: 483,
    myr: 1586,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-bangladesh-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:45',
    updated_at: '2023-09-15 18:55:52',
    deleted_at: null,
    category_id: 24,
    aed: 1430,
    idr: 5607952,
  },
  {
    id: 184,
    stripe_product_id: 'prod_Nifkk2TtTElL6E',
    paypal_product_id: 'PROD-5JP61826AH732013L',
    creator: 'WWC-CON-H2W-2023-01-025',
    name: 'H2 Hand Pump Water Well',
    description: 'Provide a H2 hand pump water well for communities in need, lasting for over 10 years.',
    aud: 5000,
    usd: 3675,
    gbp: 3000,
    cad: 4883,
    eur: 3360,
    sgd: 4830,
    myr: 15855,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-h2-hand-pump-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:42',
    updated_at: '2023-09-15 18:55:53',
    deleted_at: null,
    category_id: 24,
    aed: 14278,
    idr: 56003325,
  },
  {
    id: 48,
    stripe_product_id: 'prod_NYPzK3tnA5TqNM',
    paypal_product_id: 'PROD-15Y09391Y1978500U',
    creator: 'RM23-011',
    name: 'Feed 1 Orphan For 30 Days',
    description: 'Give one orphan the care they deserve by feeding them for 30 days. 60 meals max. Africa / Lebanon / Bangladesh',
    aud: 100,
    usd: 73,
    gbp: 60,
    cad: 97,
    eur: 68,
    sgd: 97,
    myr: 318,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/ramadan-orphan-iftar-feed-1-orphan-for-30-days.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:31',
    deleted_at: null,
    category_id: 11,
    aed: 284,
    idr: 1112447,
  },
];
const indonesiaProducts = [
  {
    id: 130,
    stripe_product_id: 'prod_NfWvk5xYONkyv4',
    paypal_product_id: 'PROD-9H963994MU0170209',
    creator: 'EM-SYR-EQ-02-2023',
    name: 'Caravan',
    description: "Donate to MATW's Caravan program and provide displaced families with shelter",
    aud: 5500,
    usd: 4045,
    gbp: 3300,
    cad: 5313,
    eur: 3755,
    sgd: 5335,
    myr: 17490,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/ramadan-caravan-caravan.jpg',
    recurring: 'Allowed',
    status: 'NEW',
    created_at: '2023-04-07 19:43:15',
    updated_at: '2023-09-15 18:53:51',
    deleted_at: null,
    category_id: 36,
    aed: 15715,
    idr: 61641755,
  },
  {
    id: 118,
    stripe_product_id: 'prod_NYQ02zrUDkKTFd',
    paypal_product_id: 'PROD-4B053997N8197683S',
    creator: 'RM24-ZM-013',
    name: 'Zakat Al Mal',
    description: 'Obligatory charity donation given to the needy according to Shariah',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/donate-now-make-a-donation-zakat-al-mal.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:49',
    updated_at: '2023-09-22 15:57:37',
    deleted_at: null,
    category_id: 33,
    aed: 1,
    idr: 1,
  },
  {
    id: 106,
    stripe_product_id: 'prod_NYPzBmgc7SDRmx',
    paypal_product_id: 'PROD-80E28400TP321263B',
    creator: 'MKD-GEN-MSD-2023-01-121',
    name: 'Sadaqah Jariyah',
    description:
      'Donating to Sadaqah brings hope to those in need and transforms communities for the better. Your generosity has the power to brighten lives and bring about positive change. Investing in Sadaqah is not just a selfless act, it also brings spiritual benefits and increases faith. Be a light of hope today through your Sadaqah donation',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/donate-now-make-a-donation-sadaqah.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-22 15:56:09',
    deleted_at: null,
    category_id: 33,
    aed: 1,
    idr: 1,
  },
  {
    id: 1,
    stripe_product_id: 'prod_NYPz1DjEglQ0E1',
    paypal_product_id: 'PROD-5W3176846P1691438',
    creator: 'FST-DEL-F25-2023-01-008',
    name: 'Orphan Feast For 25',
    description: 'Make a difference in the lives of 25 Orphans by sponsoring (Wallima) a nourishing feast.',
    aud: 100,
    usd: 74,
    gbp: 60,
    cad: 98,
    eur: 68,
    sgd: 97,
    myr: 318,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-orphan-feast-orphan-feast-for-25.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:29',
    deleted_at: null,
    category_id: 11,
    aed: 288,
    idr: 1127686,
  },
  {
    id: 292,
    stripe_product_id: 'prod_OT4iN0TQ2ZoQrK',
    paypal_product_id: 'PROD-08W111077S9572802',
    creator: 'FWD-ODM-001',
    name: '1 Billion Legacy – Food Aid - Yearly',
    description:
      'Our nutritious plant-based meals combine health and impact. Your support widens our reach, reflecting our commitment to inclusivity and consistent access to nourishing food. Accommodating diverse needs and preferences, we ensure every recipient benefits. Join us in creating change.to reach more beneficiaries, reflecting our commitment to inclusivity and the belief that everyone should have\nregular access to nutritious and hot meals. We also cater to dietary needs and cultural preferences, making\nsure every recipient benefits fully. Join us in making a difference. Donate now',
    aud: 2,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 5,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-1-billion-legacy-food-aid.jpg',
    recurring: 'year',
    status: '1899-12-30 00:00:00',
    created_at: '2023-08-18 01:55:44',
    updated_at: '2023-09-15 18:56:35',
    deleted_at: null,
    category_id: 23,
    quantity: 365,
    aed: 4,
    idr: 15239,
  },
  {
    id: 292,
    stripe_product_id: 'prod_OT4iN0TQ2ZoQrK',
    paypal_product_id: 'PROD-08W111077S9572802',
    creator: 'FWD-ODM-001',
    name: '1 Billion Legacy – Food Aid - Monthly',
    description:
      'Our nutritious plant-based meals combine health and impact. Your support widens our reach, reflecting our commitment to inclusivity and consistent access to nourishing food. Accommodating diverse needs and preferences, we ensure every recipient benefits. Join us in creating change.to reach more beneficiaries, reflecting our commitment to inclusivity and the belief that everyone should have\nregular access to nutritious and hot meals. We also cater to dietary needs and cultural preferences, making\nsure every recipient benefits fully. Join us in making a difference. Donate now',
    aud: 2,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 5,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-1-billion-legacy-food-aid.jpg',
    recurring: 'month',
    status: '1899-12-30 00:00:00',
    created_at: '2023-08-18 01:55:44',
    updated_at: '2023-09-15 18:56:35',
    deleted_at: null,
    category_id: 23,
    quantity: 30,
    aed: 4,
    idr: 15239,
  },
  {
    id: 59,
    stripe_product_id: 'prod_NYPz9l1Z6a0LiE',
    paypal_product_id: 'PROD-92H982558H7722056',
    creator: 'FWD-DIS-BMM-2023-01-049',
    name: 'Baby Milk For 4 Weeks',
    description:
      "More than 350 million children face extreme poverty leading to hunger. Give vulnerable infants the best start in life with MATW's Baby Milk for 4 Weeks appeal. Many families struggle to provide proper nutrition for their little ones. 18270 infants have been given baby milk, all thanks to your generosity. Donate now to help us supply essential baby milk to those in need.",
    aud: 40,
    usd: 30,
    gbp: 25,
    cad: 40,
    eur: 30,
    sgd: 40,
    myr: 127,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-baby-milk-for-4-weeks.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:45',
    deleted_at: null,
    category_id: 23,
    aed: 117,
    idr: 457170,
  },
  {
    id: 187,
    stripe_product_id: 'prod_NifkhoxYK9a6LS',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-ABW-2023-01-028',
    name: 'Africa Basic Water Well',
    description: 'A manually operated water well that is hand-pulled and can last for 12-21 years.',
    aud: 1000,
    usd: 735,
    gbp: 600,
    cad: 977,
    eur: 672,
    sgd: 966,
    myr: 3171,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-africa-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:44',
    updated_at: '2023-09-15 18:55:50',
    deleted_at: null,
    category_id: 24,
    aed: 2856,
    idr: 11200665,
  },
  {
    id: 272,
    stripe_product_id: 'prod_OFXQ6T5qmgYU9b',
    paypal_product_id: 'PROD-0B205461WJ995152F',
    creator: 'FWD-DIS-AR-GN-006',
    name: 'Water For 500 People',
    description: 'Give water to 500 people, bringing new life and energy to their existence.',
    aud: 50,
    usd: 35,
    gbp: 27,
    cad: 46,
    eur: 32,
    sgd: 47,
    myr: 163,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-for-500-people.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-07-12 22:38:26',
    updated_at: '2023-09-22 17:59:41',
    deleted_at: null,
    category_id: 24,
    aed: 184,
    idr: 769550,
  },
];
const malaysiaProducts = [
  {
    id: 130,
    stripe_product_id: 'prod_NfWvk5xYONkyv4',
    paypal_product_id: 'PROD-9H963994MU0170209',
    creator: 'EM-SYR-EQ-02-2023',
    name: 'Caravan',
    description: "Donate to MATW's Caravan program and provide displaced families with shelter",
    aud: 5500,
    usd: 4045,
    gbp: 3300,
    cad: 5313,
    eur: 3755,
    sgd: 5335,
    myr: 17490,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/ramadan-caravan-caravan.jpg',
    recurring: 'Allowed',
    status: 'NEW',
    created_at: '2023-04-07 19:43:15',
    updated_at: '2023-09-15 18:53:51',
    deleted_at: null,
    category_id: 36,
    aed: 15715,
    idr: 61641755,
  },
  {
    id: 1,
    stripe_product_id: 'prod_NYPz1DjEglQ0E1',
    paypal_product_id: 'PROD-5W3176846P1691438',
    creator: 'FST-DEL-F25-2023-01-008',
    name: 'Orphan Feast For 25',
    description: 'Make a difference in the lives of 25 Orphans by sponsoring (Wallima) a nourishing feast.',
    aud: 100,
    usd: 74,
    gbp: 60,
    cad: 98,
    eur: 68,
    sgd: 97,
    myr: 318,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-orphan-feast-orphan-feast-for-25.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:29',
    deleted_at: null,
    category_id: 11,
    aed: 288,
    idr: 1127686,
  },
  {
    id: 118,
    stripe_product_id: 'prod_NYQ02zrUDkKTFd',
    paypal_product_id: 'PROD-4B053997N8197683S',
    creator: 'RM24-ZM-013',
    name: 'Zakat Al Mal',
    description: 'Obligatory charity donation given to the needy according to Shariah',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/donate-now-make-a-donation-zakat-al-mal.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:49',
    updated_at: '2023-09-22 15:57:37',
    deleted_at: null,
    category_id: 33,
    aed: 1,
    idr: 1,
  },
  {
    id: 292,
    stripe_product_id: 'prod_OT4iN0TQ2ZoQrK',
    paypal_product_id: 'PROD-08W111077S9572802',
    creator: 'FWD-ODM-001',
    name: '1 Billion Legacy – Food Aid - Yearly',
    description:
      'Our nutritious plant-based meals combine health and impact. Your support widens our reach, reflecting our commitment to inclusivity and consistent access to nourishing food. Accommodating diverse needs and preferences, we ensure every recipient benefits. Join us in creating change.to reach more beneficiaries, reflecting our commitment to inclusivity and the belief that everyone should have\nregular access to nutritious and hot meals. We also cater to dietary needs and cultural preferences, making\nsure every recipient benefits fully. Join us in making a difference. Donate now',
    aud: 2,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 5,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-1-billion-legacy-food-aid.jpg',
    recurring: 'year',
    status: '1899-12-30 00:00:00',
    created_at: '2023-08-18 01:55:44',
    updated_at: '2023-09-15 18:56:35',
    deleted_at: null,
    category_id: 23,
    quantity: 365,
    aed: 4,
    idr: 15239,
  },
  {
    id: 292,
    stripe_product_id: 'prod_OT4iN0TQ2ZoQrK',
    paypal_product_id: 'PROD-08W111077S9572802',
    creator: 'FWD-ODM-001',
    name: '1 Billion Legacy – Food Aid - Monthly',
    description:
      'Our nutritious plant-based meals combine health and impact. Your support widens our reach, reflecting our commitment to inclusivity and consistent access to nourishing food. Accommodating diverse needs and preferences, we ensure every recipient benefits. Join us in creating change.to reach more beneficiaries, reflecting our commitment to inclusivity and the belief that everyone should have\nregular access to nutritious and hot meals. We also cater to dietary needs and cultural preferences, making\nsure every recipient benefits fully. Join us in making a difference. Donate now',
    aud: 2,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 5,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-1-billion-legacy-food-aid.jpg',
    recurring: 'month',
    status: '1899-12-30 00:00:00',
    created_at: '2023-08-18 01:55:44',
    updated_at: '2023-09-15 18:56:35',
    deleted_at: null,
    category_id: 23,
    quantity: 30,
    aed: 4,
    idr: 15239,
  },
  {
    id: 106,
    stripe_product_id: 'prod_NYPzBmgc7SDRmx',
    paypal_product_id: 'PROD-80E28400TP321263B',
    creator: 'MKD-GEN-MSD-2023-01-121',
    name: 'Sadaqah Jariyah',
    description:
      'Donating to Sadaqah brings hope to those in need and transforms communities for the better. Your generosity has the power to brighten lives and bring about positive change. Investing in Sadaqah is not just a selfless act, it also brings spiritual benefits and increases faith. Be a light of hope today through your Sadaqah donation',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/donate-now-make-a-donation-sadaqah.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-22 15:56:09',
    deleted_at: null,
    category_id: 33,
    aed: 1,
    idr: 1,
  },
  {
    id: 272,
    stripe_product_id: 'prod_OFXQ6T5qmgYU9b',
    paypal_product_id: 'PROD-0B205461WJ995152F',
    creator: 'FWD-DIS-AR-GN-006',
    name: 'Water For 500 People',
    description: 'Give water to 500 people, bringing new life and energy to their existence.',
    aud: 50,
    usd: 35,
    gbp: 27,
    cad: 46,
    eur: 32,
    sgd: 47,
    myr: 163,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-for-500-people.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-07-12 22:38:26',
    updated_at: '2023-09-22 17:59:41',
    deleted_at: null,
    category_id: 24,
    aed: 184,
    idr: 769550,
  },
];
const singaporeProducts = [
  {
    id: 22,
    stripe_product_id: 'prod_NYPzy5rezy19Qx',
    paypal_product_id: 'PROD-3PR52435B9462552X',
    creator: 'ORP-GEN-TOC-2023-01-130',
    name: 'Togo Orphan Centre',
    description: 'Support the expansion of our Togo Orphan Centre and provide countless orphans with a safe living environment.',
    aud: 100,
    usd: 74,
    gbp: 60,
    cad: 98,
    eur: 68,
    sgd: 97,
    myr: 318,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-construction-togo-orphan-centre.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:42',
    deleted_at: null,
    category_id: 14,
    aed: 288,
    idr: 1127686,
  },
  {
    id: 1,
    stripe_product_id: 'prod_NYPz1DjEglQ0E1',
    paypal_product_id: 'PROD-5W3176846P1691438',
    creator: 'FST-DEL-F25-2023-01-008',
    name: 'Orphan Feast For 25',
    description: 'Make a difference in the lives of 25 Orphans by sponsoring (Wallima) a nourishing feast.',
    aud: 100,
    usd: 74,
    gbp: 60,
    cad: 98,
    eur: 68,
    sgd: 97,
    myr: 318,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-orphan-feast-orphan-feast-for-25.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:29',
    deleted_at: null,
    category_id: 11,
    aed: 288,
    idr: 1127686,
  },
  {
    id: 106,
    stripe_product_id: 'prod_NYPzBmgc7SDRmx',
    paypal_product_id: 'PROD-80E28400TP321263B',
    creator: 'MKD-GEN-MSD-2023-01-121',
    name: 'Sadaqah Jariyah',
    description:
      'Donating to Sadaqah brings hope to those in need and transforms communities for the better. Your generosity has the power to brighten lives and bring about positive change. Investing in Sadaqah is not just a selfless act, it also brings spiritual benefits and increases faith. Be a light of hope today through your Sadaqah donation',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/donate-now-make-a-donation-sadaqah.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-22 15:56:09',
    deleted_at: null,
    category_id: 33,
    aed: 1,
    idr: 1,
  },
  {
    id: 118,
    stripe_product_id: 'prod_NYQ02zrUDkKTFd',
    paypal_product_id: 'PROD-4B053997N8197683S',
    creator: 'RM24-ZM-013',
    name: 'Zakat Al Mal',
    description: 'Obligatory charity donation given to the needy according to Shariah',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/donate-now-make-a-donation-zakat-al-mal.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:49',
    updated_at: '2023-09-22 15:57:37',
    deleted_at: null,
    category_id: 33,
    aed: 1,
    idr: 1,
  },
  {
    id: 187,
    stripe_product_id: 'prod_NifkhoxYK9a6LS',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-ABW-2023-01-028',
    name: 'Africa Basic Water Well',
    description: 'A manually operated water well that is hand-pulled and can last for 12-21 years.',
    aud: 1000,
    usd: 735,
    gbp: 600,
    cad: 977,
    eur: 672,
    sgd: 966,
    myr: 3171,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-africa-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:44',
    updated_at: '2023-09-15 18:55:50',
    deleted_at: null,
    category_id: 24,
    aed: 2856,
    idr: 11200665,
  },
  {
    id: 292,
    stripe_product_id: 'prod_OT4iN0TQ2ZoQrK',
    paypal_product_id: 'PROD-08W111077S9572802',
    creator: 'FWD-ODM-001',
    name: '1 Billion Legacy – Food Aid - Yearly',
    description:
      'Our nutritious plant-based meals combine health and impact. Your support widens our reach, reflecting our commitment to inclusivity and consistent access to nourishing food. Accommodating diverse needs and preferences, we ensure every recipient benefits. Join us in creating change.to reach more beneficiaries, reflecting our commitment to inclusivity and the belief that everyone should have\nregular access to nutritious and hot meals. We also cater to dietary needs and cultural preferences, making\nsure every recipient benefits fully. Join us in making a difference. Donate now',
    aud: 2,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 5,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-1-billion-legacy-food-aid.jpg',
    recurring: 'year',
    status: '1899-12-30 00:00:00',
    created_at: '2023-08-18 01:55:44',
    updated_at: '2023-09-15 18:56:35',
    deleted_at: null,
    category_id: 23,
    quantity: 365,
    aed: 4,
    idr: 15239,
  },
  {
    id: 292,
    stripe_product_id: 'prod_OT4iN0TQ2ZoQrK',
    paypal_product_id: 'PROD-08W111077S9572802',
    creator: 'FWD-ODM-001',
    name: '1 Billion Legacy – Food Aid - Monthly',
    description:
      'Our nutritious plant-based meals combine health and impact. Your support widens our reach, reflecting our commitment to inclusivity and consistent access to nourishing food. Accommodating diverse needs and preferences, we ensure every recipient benefits. Join us in creating change.to reach more beneficiaries, reflecting our commitment to inclusivity and the belief that everyone should have\nregular access to nutritious and hot meals. We also cater to dietary needs and cultural preferences, making\nsure every recipient benefits fully. Join us in making a difference. Donate now',
    aud: 2,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 5,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-1-billion-legacy-food-aid.jpg',
    recurring: 'month',
    status: '1899-12-30 00:00:00',
    created_at: '2023-08-18 01:55:44',
    updated_at: '2023-09-15 18:56:35',
    deleted_at: null,
    category_id: 23,
    quantity: 30,
    aed: 4,
    idr: 15239,
  },
  {
    id: 130,
    stripe_product_id: 'prod_NfWvk5xYONkyv4',
    paypal_product_id: 'PROD-9H963994MU0170209',
    creator: 'EM-SYR-EQ-02-2023',
    name: 'Caravan',
    description: "Donate to MATW's Caravan program and provide displaced families with shelter",
    aud: 5500,
    usd: 4045,
    gbp: 3300,
    cad: 5313,
    eur: 3755,
    sgd: 5335,
    myr: 17490,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/ramadan-caravan-caravan.jpg',
    recurring: 'Allowed',
    status: 'NEW',
    created_at: '2023-04-07 19:43:15',
    updated_at: '2023-09-15 18:53:51',
    deleted_at: null,
    category_id: 36,
    aed: 15715,
    idr: 61641755,
  },
  {
    id: 81,
    stripe_product_id: 'prod_NYPzsrQeWfeGvB',
    paypal_product_id: 'PROD-7YW18999D3829780M',
    creator: 'MSJ-CON-SMJ-2023-01-011',
    name: 'Small Masjid',
    description: 'Help construct a main masjid block for 80-90 people and a small masjid for a community, each with a praying area of 100 sqm.',
    aud: 10000,
    usd: 7350,
    gbp: 5985,
    cad: 9765,
    eur: 6720,
    sgd: 9660,
    myr: 31710,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-masjid-small-masjid.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-22 16:44:12',
    deleted_at: null,
    category_id: 29,
    aed: 26975,
    idr: 113123850,
  },
];
const yasirProducts = [
  {
    id: 122,
    stripe_product_id: 'prod_NZYKQBceTRhsk1',
    paypal_product_id: 'PROD-15U431202D489773T',
    creator: 'MKD-MN-001',
    name: 'Where Most Needed',
    description: "Donate to where it's needed the most, we'll distribute your donation to the people that most need it",
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/logo-replace.png',
    recurring: 'Allowed',
    status: '1',
    created_at: '2022-03-22 10:49:11',
    updated_at: '2023-09-22 15:54:51',
    deleted_at: null,
    category_id: 33,
    aed: 1,
    idr: 1,
  },
  {
    id: 299,
    stripe_product_id: 'prod_OexjnZqtQjc7XJ',
    paypal_product_id: 'PROD-7L934005P0102581M',
    creator: 'EDU-GEN-BTS-20230-002',
    name: 'Orphan Education Supply kit',
    description:
      "Our product directly supports orphans' education by providing essential items like uniforms, shoes, bags, books, and pencils. Your purchase empowers them to embark on their educational journey confidently. Impact vulnerable children in Yemen, Togo, Lebanon, Palestine, Jordan, Syria, Pakistan, and Bangladesh.",
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 1,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-education-orphan-education-supply-kit.jpg',
    recurring: 'Allowed',
    status: 'Yes',
    created_at: '2023-09-18 19:29:38',
    updated_at: '2023-09-18 19:36:32',
    deleted_at: null,
    category_id: 13,
    aed: 1,
    idr: 1,
  },
  {
    id: 2,
    stripe_product_id: 'prod_NYPzzhDazjzIPE',
    paypal_product_id: 'PROD-1BL69444AG845241F',
    creator: 'FST-DEL-F50-2023-01-009',
    name: 'Orphan Feast For 50',
    description: 'Make a difference in the lives of 50 orphans by sponsoring (Wallima) a nourishing feast.',
    aud: 200,
    usd: 147,
    gbp: 120,
    cad: 196,
    eur: 135,
    sgd: 194,
    myr: 635,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-orphan-feast-orphan-feast-for-50.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:30',
    deleted_at: null,
    category_id: 11,
    aed: 572,
    idr: 2240133,
  },
  {
    id: 49,
    stripe_product_id: 'prod_NYPzp6QuMX9NW0',
    paypal_product_id: 'PROD-15Y09391Y1978500U',
    creator: 'RM24-OP-004',
    name: 'Feed 5 Orphans For 30 Days',
    description: 'Provide vital support and sustenance by feeding five orphans over 30 days. 60 meals max. Africa / Lebanon / Bangladesh',
    aud: 500,
    usd: 363,
    gbp: 300,
    cad: 483,
    eur: 336,
    sgd: 483,
    myr: 1586,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/ramadan-orphan-iftar-feed-5-orphans-for-30-days.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:31',
    deleted_at: null,
    category_id: 11,
    aed: 1411,
    idr: 5531757,
  },
  {
    id: 303,
    stripe_product_id: 'prod_OexjWgKc4hvYyh',
    paypal_product_id: 'PROD-9J744749GN383031C',
    creator: 'OPR-FUND-LB-2023-01-01',
    name: 'Orphan Centre Lebanon',
    description:
      "Support our mission for the Tripoli Lebanon Orphan Centre – a home for 140 orphans. Donate for shelter, education, meals, and hope. Make a lasting difference in these children's lives.",
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 1,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-support-an-orphan-orphan-centre-lebanon.jpg',
    recurring: 'Allowed',
    status: 'Yes',
    created_at: '2023-09-18 19:29:41',
    updated_at: '2023-09-18 19:36:35',
    deleted_at: null,
    category_id: 12,
    aed: 1,
    idr: 1,
  },
  {
    id: 269,
    stripe_product_id: 'prod_OFXQqp1S174Tt1',
    paypal_product_id: 'PROD-5GD55498B4621951E',
    creator: 'FWD-DIS-AR-GN-002',
    name: 'Feed 10 Hungry People',
    description: 'The price you pay for one nice meal could answer the prayers of 10 people.',
    aud: 50,
    usd: 35,
    gbp: 27,
    cad: 46,
    eur: 32,
    sgd: 47,
    myr: 163,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-feed-10-hungry-people.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-07-12 22:38:25',
    updated_at: '2023-09-22 15:52:11',
    deleted_at: null,
    category_id: 23,
    aed: 183.5,
    idr: 769550,
  },
  {
    id: 272,
    stripe_product_id: 'prod_OFXQ6T5qmgYU9b',
    paypal_product_id: 'PROD-0B205461WJ995152F',
    creator: 'FWD-DIS-AR-GN-006',
    name: 'Water For 500 People',
    description: 'Give water to 500 people, bringing new life and energy to their existence.',
    aud: 50,
    usd: 35,
    gbp: 27,
    cad: 46,
    eur: 32,
    sgd: 47,
    myr: 163,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-for-500-people.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-07-12 22:38:26',
    updated_at: '2023-09-22 15:52:32',
    deleted_at: null,
    category_id: 24,
    aed: 183.5,
    idr: 769550,
  },
  {
    id: 60,
    stripe_product_id: 'prod_NYPzmslRXQEABq',
    paypal_product_id: 'PROD-92H982558H7722056',
    creator: 'FWD-DIS-BMY-2023-01-050',
    name: 'Baby Milk For 52 Weeks',
    description:
      "Every child deserves access to proper nutrition, yet over 350 million children are suffering from hunger. MATW's Baby Milk for 52 Weeks appeal addresses this critical need by supplying baby milk to families in need. By contributing to this initiative, you can make a direct impact on the well-being of vulnerable infants, supporting their growth, health, and overall development. 7665 infants have benefitted from your donation of baby milk until now. Donate more to help even more babies in need.",
    aud: 520,
    usd: 385,
    gbp: 315,
    cad: 510,
    eur: 350,
    sgd: 505,
    myr: 1700,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-baby-milk-for-52-weeks.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:43',
    deleted_at: null,
    category_id: 23,
    aed: 1496,
    idr: 5867015,
  },
  {
    id: 187,
    stripe_product_id: 'prod_NifkhoxYK9a6LS',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-ABW-2023-01-028',
    name: 'Africa Basic Water Well',
    description: 'A manually operated water well that is hand-pulled and can last for 12-21 years.',
    aud: 1000,
    usd: 735,
    gbp: 600,
    cad: 977,
    eur: 672,
    sgd: 966,
    myr: 3171,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-africa-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:44',
    updated_at: '2023-09-15 18:55:50',
    deleted_at: null,
    category_id: 24,
    aed: 2856,
    idr: 11200665,
  },
  {
    id: 188,
    stripe_product_id: 'prod_Nifkt7ZkeYU2EI',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-BBW-2023-01-029',
    name: 'Bangladesh Basic Water Well',
    description: 'Provide a hand-operated water well pump for a rural community in Bangladesh.',
    aud: 500,
    usd: 368,
    gbp: 300,
    cad: 489,
    eur: 336,
    sgd: 483,
    myr: 1586,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-bangladesh-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:45',
    updated_at: '2023-09-15 18:55:52',
    deleted_at: null,
    category_id: 24,
    aed: 1430,
    idr: 5607952,
  },
  {
    id: 189,
    stripe_product_id: 'prod_Nifkp5ELktgaBB',
    paypal_product_id: 'PROD-4B9419827B839590X',
    creator: 'WWC-CON-PBW-2023-01-030',
    name: 'Pakistan Basic Water Well',
    description: 'Provide a hand-operated water well pump for a rural community in Pakistan.',
    aud: 500,
    usd: 368,
    gbp: 300,
    cad: 489,
    eur: 336,
    sgd: 483,
    myr: 1586,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-pakistan-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:46',
    updated_at: '2023-09-15 18:55:56',
    deleted_at: null,
    category_id: 24,
    aed: 1430,
    idr: 5607952,
  },
  {
    id: 190,
    stripe_product_id: 'prod_NifkUQG7Wel68B',
    paypal_product_id: 'PROD-4B9419827B839590X',
    creator: 'WWC-CON-IBW-2023-01-031',
    name: 'India Basic Water Well',
    description: 'Provide a hand-operated water well pump for a rural community in India.',
    aud: 500,
    usd: 368,
    gbp: 300,
    cad: 489,
    eur: 336,
    sgd: 483,
    myr: 1586,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-india-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:47',
    updated_at: '2023-09-15 18:55:57',
    deleted_at: null,
    category_id: 24,
    aed: 1430,
    idr: 5607952,
  },
  {
    id: 95,
    stripe_product_id: 'prod_NYPzyGg2doecQD',
    paypal_product_id: 'PROD-0CV39642N8662544H',
    creator: 'WWC-CON-H2W-2023-01-025',
    name: 'H2 Hand Pump Water Well',
    description:
      'Your support can provide a community in Uganda with a H2 hand pump water well, a reliable solution for communities in need of clean water, with a 3-year warranty and a lifespan of over 10 years.',
    aud: 5000,
    usd: 3675,
    gbp: 3000,
    cad: 4883,
    eur: 3360,
    sgd: 4830,
    myr: 15855,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-water-well-h2-hand-pump-water-well.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:53',
    deleted_at: null,
    category_id: 32,
    aed: 14278,
    idr: 56003325,
  },
  {
    id: 316,
    stripe_product_id: 'prod_OikZzVL1siZ75a',
    paypal_product_id: 'PROD-9GK37689YY0632733',
    creator: 'MSJ-WA-SMJ-2023-01',
    name: 'Africa Small Masjid',
    description:
      '100 sq. m. masjid with Wudu facilities and two bathrooms. These masjids will be installed in the West African region in areas where they are most needed. Running water is not included in the masjid.',
    aud: 15000,
    usd: 9750,
    gbp: 7800,
    cad: 13050,
    eur: 9150,
    sgd: 13200,
    myr: 45600,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-masjid-africa-small-masjid.jpg',
    recurring: 'Allowed',
    status: 'Active',
    created_at: '2023-09-28 22:09:31',
    updated_at: '2023-09-28 22:19:38',
    deleted_at: null,
    category_id: 29,
    aed: 35700,
    idr: 149424000,
  },
  {
    id: 92,
    stripe_product_id: 'prod_NYPzW69oISOsLZ',
    paypal_product_id: 'PROD-55J29194X8280435P',
    creator: 'MSJ-CON-IMJ-2023-01-014',
    name: 'Indonesia Masjid',
    description:
      'Your generosity can aid in the construction of a Main Masjid block and a small Mosque, providing a serene and devout space for the small community to connect with Allah through prayer.',
    aud: 30000,
    usd: 22050,
    gbp: 17955,
    cad: 29295,
    eur: 20160,
    sgd: 28980,
    myr: 95130,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-indonesia-masjid-indonesia-masjid.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:56:11',
    deleted_at: null,
    category_id: 29,
    aed: 85665,
    idr: 336019950,
  },
  {
    id: 293,
    stripe_product_id: 'prod_Oik7CQJl2GgLFl',
    paypal_product_id: 'PROD-56765614HK243104S',
    creator: 'EMR-MR-WMN-001-2023',
    name: 'Morocco Appeal',
    description:
      'Provide essential aid, including food, water, medical care and shelter to the survivors of the 6.8 magnitude Earthquake in Morocco. Make a difference in the lives of those who lost everything over night.',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-Earthquake-appeal-morocco-appeal.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-09-09 00:00:00',
    updated_at: '2023-09-28 21:44:00',
    deleted_at: null,
    category_id: 26,
    aed: 1,
    idr: 1,
  },
  {
    id: 295,
    stripe_product_id: 'prod_Odr9APNosVRE7T',
    paypal_product_id: 'PROD-5UH663536W393321E',
    creator: 'EMR-LBY-WMN-002-2023',
    name: 'Libya Floods',
    description: 'Donate today and help the thousands that have been displaced due to floods',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 1,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-emergency-support-libya-emergency.jpeg',
    recurring: 'Allowed',
    status: '1899-12-30 00:00:00',
    created_at: '2023-09-15 20:38:11',
    updated_at: '2023-09-15 20:40:00',
    deleted_at: null,
    category_id: 52,
    aed: 1,
    idr: 1,
  },
  {
    id: 327,
    stripe_product_id: 'prod_OikZV3mFw9EPen',
    paypal_product_id: 'PROD-9V037087TD4500314',
    creator: 'EM-SDQ-EM-02-2023',
    name: 'Emergency Caravan Homes',
    description:
      'A caravan is a long-term shelter to house a family and provide safe living with protection from the cold, and it contains a kitchen sink and toilet. This structure is 5 x 3 m.',
    aud: 5500,
    usd: 3575,
    gbp: 2860,
    cad: 4785,
    eur: 3355,
    sgd: 4840,
    myr: 16720,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-caravan-emergency-caravan-homes.jpg',
    recurring: 'Allowed',
    status: 'Active',
    created_at: '2023-09-28 22:09:40',
    updated_at: '2023-09-28 22:19:46',
    deleted_at: null,
    category_id: 36,
    aed: 13090,
    idr: 54788800,
  },
];
const noumanalikhanProducts = [
  {
    id: 255,
    stripe_product_id: 'prod_ODOmMJZwZjCKzJ',
    paypal_product_id: 'PROD-5RX549982D069874T',
    creator: 'EMR-DIS-PSE-2023-01',
    name: 'Palestine Most Needed',
    description:
      "Provide essential relief, including food, water, medical care and shelter, where it's needed most. Make a difference in the lives of those affected by the emergency in Palestine.",
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: '/images/landings/crisis/palestine/Palestine Appeal.jpg',
    recurring: 'Allowed',
    status: '0',
    created_at: '2023-07-07 05:32:26',
    updated_at: '2023-09-15 18:56:03',
    deleted_at: null,
    category_id: 27,
    aed: 4,
    idr: 15239,
  },
  {
    id: 252,
    stripe_product_id: 'prod_ODOmzCmBoZJd8b',
    paypal_product_id: 'PROD-9H660513WE9229831',
    creator: 'EMR-DIS-PSE-2023-04',
    name: 'Survival Pack',
    description:
      'Offer comfort and sustenance to families in Palestine who have endured immense hardship. Donate to provide essential necessities including food, water, blankets and pillows.',
    aud: 200,
    usd: 141,
    gbp: 111,
    cad: 128,
    eur: 185,
    sgd: 189,
    myr: 651,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-palestine-survival-pack.jpg',
    recurring: 'Allowed',
    status: '0',
    created_at: '2023-07-07 05:32:24',
    updated_at: '2023-09-15 18:56:01',
    deleted_at: null,
    category_id: 27,
    aed: 547,
    idr: 2144127,
  },
  {
    id: 339,
    stripe_product_id: 'prod_OxiDLPOzInnYbE',
    paypal_product_id: 'PROD-4DJ99407H73136731',
    creator: 'EMR-DIS-MDPL-2023-01-102',
    name: 'Palestine Emergency Medical Kit',
    description:
      'Sterile medical cotton, sterile gauze, large wound dressing, medical gloves for personal hygiene, wound plaster, painkillers, manual pressure gauge, blood sugar meter, bruise reliever, wide medical tape, bandage, medical scissors.',
    aud: 150,
    usd: 100,
    gbp: 80,
    cad: 130,
    eur: 90,
    sgd: 130,
    myr: 460,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-palestine-emergency-medical-kit-palestine.jpg',
    recurring: 'Allowed',
    status: 'Active',
    created_at: '2023-10-25 04:46:37',
    updated_at: '2023-11-07 21:45:35',
    deleted_at: null,
    category_id: 27,
    aed: 350,
    idr: 1503030,
  },
  {
    id: 329,
    stripe_product_id: 'prod_OkZT97dFkiDtMo',
    paypal_product_id: 'PROD-9HN83399D3650410N',
    creator: 'EM-MRC-SHL-01-2023',
    name: 'Tent for Morocco',
    description:
      'Tents provide critical shelter in emergency-affected areas, offering immediate relief to families facing displacement and adversity. These portable sanctuaries shield individuals from harsh weather conditions, restore a sense of security, and serve as the first step towards rebuilding lives. In times of crisis, tents become beacons of hope, offering a safe haven amidst chaos.',
    aud: 400,
    usd: 260,
    gbp: 208,
    cad: 244,
    eur: 348,
    sgd: 352,
    myr: 1216,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-earthquake-appeal-tent-for-morocco.jpg',
    recurring: 'Allowed',
    status: 'Active',
    created_at: '2023-10-03 19:50:00',
    updated_at: '2023-10-03 19:54:02',
    deleted_at: null,
    category_id: 26,
    aed: 3984640,
    idr: 952,
  },
  /* -------------------------------------------------------------------------- */
  {
    id: 55,
    stripe_product_id: 'prod_NYPzAtKvkDnwch',
    paypal_product_id: 'PROD-8CR03891LU471535H',
    creator: 'FWD-DIS-FPY-2023-01-045',
    name: 'Food Pack For Yemen',
    description:
      "Yemen is facing a devastating hunger crisis, with at least 17 million people struggling to find their next meal. MATW's Food Pack for Yemen initiative will provide essential dry items and food packages to those in need during these challenging times. You have helped us provide food packs to 394875 Yemenis. Keep donating and keep helping those going hungry.",
    aud: 60,
    usd: 45,
    gbp: 36,
    cad: 59,
    eur: 41,
    sgd: 58,
    myr: 191,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-food-pack-for-yemen.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:24',
    deleted_at: null,
    category_id: 23,
    aed: 150,
    idr: 685755,
  },
  {
    id: 295,
    stripe_product_id: 'prod_Odr9APNosVRE7T',
    paypal_product_id: 'PROD-5UH663536W393321E',
    creator: 'EMR-LBY-WMN-002-2023',
    name: 'Libya Floods',
    description: 'Donate today and help the thousands that have been displaced due to floods',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 1,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-emergency-support-libya-emergency.jpeg',
    recurring: 'Allowed',
    status: '1899-12-30 00:00:00',
    created_at: '2023-09-15 20:38:11',
    updated_at: '2023-09-15 20:40:00',
    deleted_at: null,
    category_id: 52,
    aed: 1,
    idr: 1,
  },
  /* -------------------------------------------------------------------------- */
  {
    id: 332,
    stripe_product_id: 'prod_OmuEoLcmoglQBr',
    paypal_product_id: 'PROD-6C8022626M763840T',
    creator: 'EMR-DIS-AFG-2023-02',
    name: 'Afghanistan Earthquake Appeal',
    description:
      'Provide essential aid like food, water, shelter and medical care to those affected by the 6.3-magnitude in Afghanistan. Make a difference in the lives of those struggling.',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 1,
    custom_amount: 'Yes',
    image_link:
      'https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-earthquake-appeal-afghanistan-earthquake-appeal.png',
    recurring: 'Allowed',
    status: 'Active',
    created_at: '2023-10-10 01:25:15',
    updated_at: '2023-10-10 01:27:23',
    deleted_at: null,
    category_id: 26,
    aed: 2,
    idr: 2,
  },
  {
    id: 299,
    stripe_product_id: 'prod_OexjnZqtQjc7XJ',
    paypal_product_id: 'PROD-7L934005P0102581M',
    creator: 'EDU-GEN-BTS-20230-002',
    name: 'Orphan Education Supply kit',
    description:
      "Our product directly supports orphans' education by providing essential items like uniforms, shoes, bags, books, and pencils. Your purchase empowers them to embark on their educational journey confidently. Impact vulnerable children in Yemen, Togo, Lebanon, Palestine, Jordan, Syria, Pakistan, and Bangladesh.",
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 1,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-education-orphan-education-supply-kit.jpg',
    recurring: 'Allowed',
    status: 'Yes',
    created_at: '2023-09-18 19:29:38',
    updated_at: '2023-09-18 19:36:32',
    deleted_at: null,
    category_id: 13,
    aed: 1,
    idr: 1,
  },
  {
    id: 2,
    stripe_product_id: 'prod_NYPzzhDazjzIPE',
    paypal_product_id: 'PROD-1BL69444AG845241F',
    creator: 'FST-DEL-F50-2023-01-009',
    name: 'Orphan Feast For 50',
    description: 'Make a difference in the lives of 50 orphans by sponsoring (Wallima) a nourishing feast.',
    aud: 200,
    usd: 147,
    gbp: 120,
    cad: 196,
    eur: 135,
    sgd: 194,
    myr: 635,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-orphan-feast-orphan-feast-for-50.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:53:30',
    deleted_at: null,
    category_id: 11,
    aed: 572,
    idr: 2240133,
  },
  /* -------------------------------------------------------------------------- */
  {
    id: 272,
    stripe_product_id: 'prod_OFXQ6T5qmgYU9b',
    paypal_product_id: 'PROD-0B205461WJ995152F',
    creator: 'FWD-DIS-AR-GN-006',
    name: 'Water For 500 People',
    description: 'Give water to 500 people, bringing new life and energy to their existence.',
    aud: 50,
    usd: 35,
    gbp: 27,
    cad: 46,
    eur: 32,
    sgd: 47,
    myr: 163,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-for-500-people.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-07-12 22:38:26',
    updated_at: '2023-09-22 17:59:41',
    deleted_at: null,
    category_id: 24,
    aed: 184,
    idr: 769550,
  },
  {
    id: 60,
    stripe_product_id: 'prod_NYPzmslRXQEABq',
    paypal_product_id: 'PROD-92H982558H7722056',
    creator: 'FWD-DIS-BMY-2023-01-050',
    name: 'Baby Milk For 52 Weeks',
    description:
      "Every child deserves access to proper nutrition, yet over 350 million children are suffering from hunger. MATW's Baby Milk for 52 Weeks appeal addresses this critical need by supplying baby milk to families in need. By contributing to this initiative, you can make a direct impact on the well-being of vulnerable infants, supporting their growth, health, and overall development. 7665 infants have benefitted from your donation of baby milk until now. Donate more to help even more babies in need.",
    aud: 520,
    usd: 385,
    gbp: 315,
    cad: 510,
    eur: 350,
    sgd: 505,
    myr: 1700,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-baby-milk-for-52-weeks.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:43',
    deleted_at: null,
    category_id: 23,
    aed: 1496,
    idr: 5867015,
  },
  /* -------------------------------------------------------------------------- */
  {
    id: 188,
    stripe_product_id: 'prod_Nifkt7ZkeYU2EI',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-BBW-2023-01-029',
    name: 'Bangladesh Basic Water Well',
    description: 'Provide a hand-operated water well pump for a rural community in Bangladesh.',
    aud: 500,
    usd: 368,
    gbp: 300,
    cad: 489,
    eur: 336,
    sgd: 483,
    myr: 1586,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-bangladesh-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:45',
    updated_at: '2023-09-15 18:55:52',
    deleted_at: null,
    category_id: 24,
    aed: 1430,
    idr: 5607952,
  },
  {
    id: 187,
    stripe_product_id: 'prod_NifkhoxYK9a6LS',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-ABW-2023-01-028',
    name: 'Africa Basic Water Well',
    description: 'A manually operated water well that is hand-pulled and can last for 12-21 years.',
    aud: 1000,
    usd: 735,
    gbp: 600,
    cad: 977,
    eur: 672,
    sgd: 966,
    myr: 3171,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-africa-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:44',
    updated_at: '2023-09-15 18:55:50',
    deleted_at: null,
    category_id: 24,
    aed: 2856,
    idr: 11200665,
  },
  {
    id: 95,
    stripe_product_id: 'prod_NYPzyGg2doecQD',
    paypal_product_id: 'PROD-0CV39642N8662544H',
    creator: 'WWC-CON-H2W-2023-01-025',
    name: 'Heavy Duty Hand Pump Uganda',
    description:
      'Your support can provide a community in Uganda with a H2 hand pump water well, a reliable solution for communities in need of clean water, with a 3-year warranty and a lifespan of over 10 years.',
    aud: 5000,
    usd: 3675,
    gbp: 3000,
    cad: 4883,
    eur: 3360,
    sgd: 4830,
    myr: 15855,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-water-well-h2-hand-pump-water-well.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:55:53',
    deleted_at: null,
    category_id: 32,
    aed: 14278,
    idr: 56003325,
  },
  {
    id: 316,
    stripe_product_id: 'prod_OikZzVL1siZ75a',
    paypal_product_id: 'PROD-9GK37689YY0632733',
    creator: 'MSJ-WA-SMJ-2023-01',
    name: 'Africa Small Masjid',
    description:
      '100 sq. m. masjid with Wudu facilities and two bathrooms. These masjids will be installed in the West African region in areas where they are most needed. Running water is not included in the masjid.',
    aud: 15000,
    usd: 9750,
    gbp: 7800,
    cad: 13050,
    eur: 9150,
    sgd: 13200,
    myr: 45600,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-masjid-africa-small-masjid.jpg',
    recurring: 'Allowed',
    status: 'Active',
    created_at: '2023-09-28 22:09:31',
    updated_at: '2023-09-28 22:19:38',
    deleted_at: null,
    category_id: 29,
    aed: 35700,
    idr: 149424000,
  },
  {
    id: 317,
    stripe_product_id: 'prod_OikZ6aITGE6Lv8',
    paypal_product_id: 'PROD-977897608X247964W',
    creator: 'MSJ-WA-LRG-2023-02',
    name: 'Africa Large Masjid',
    description:
      '150 sq. m. masjid with two classrooms for teaching Quran; it also includes Wudu facilities and two bathrooms. These masjids will be installed in the West African region in areas where they are most needed. Running water is not included in the masjid.',
    aud: 20000,
    usd: 13000,
    gbp: 10400,
    cad: 17400,
    eur: 12200,
    sgd: 17600,
    myr: 60800,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-masjid-africa-large-masjid.jpg',
    recurring: 'Allowed',
    status: 'Active',
    created_at: '2023-09-28 22:09:32',
    updated_at: '2023-09-28 22:19:39',
    deleted_at: null,
    category_id: 29,
    aed: 47600,
    idr: 199232000,
  },
  {
    id: 92,
    stripe_product_id: 'prod_NYPzW69oISOsLZ',
    paypal_product_id: 'PROD-55J29194X8280435P',
    creator: 'MSJ-CON-IMJ-2023-01-014',
    name: 'Indonesia Masjid',
    description:
      'Your generosity can aid in the construction of a Main Masjid block and a small Mosque, providing a serene and devout space for the small community to connect with Allah through prayer.',
    aud: 30000,
    usd: 22050,
    gbp: 17955,
    cad: 29295,
    eur: 20160,
    sgd: 28980,
    myr: 95130,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-indonesia-masjid-indonesia-masjid.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-09-15 18:56:11',
    deleted_at: null,
    category_id: 29,
    aed: 85665,
    idr: 336019950,
  },
  {
    id: 122,
    stripe_product_id: 'prod_NZYKQBceTRhsk1',
    paypal_product_id: 'PROD-15U431202D489773T',
    creator: 'MKD-MN-001',
    name: 'Where Most Needed',
    description: "Donate to where it's needed the most, we'll distribute your donation to the people that most need it",
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/logo-replace.png',
    recurring: 'Allowed',
    status: '1',
    created_at: '2022-03-22 10:49:11',
    updated_at: '2023-09-22 15:54:51',
    deleted_at: null,
    category_id: 33,
    aed: 1,
    idr: 1,
  },
];
const asmaaProducts = [
  {
    id: 18,
    stripe_product_id: 'prod_NYPzLN1kFAPknd',
    paypal_product_id: 'PROD-4A367278517348539',
    creator: 'EDU-GEN-DAQ-2023-01-061',
    name: 'Donate 10 Qurans',
    description: 'Give our brothers and sisters access to the Holy Quran, by supporting our "Donate 10 Qurans" program.\'',
    aud: 10,
    usd: 8,
    gbp: 6,
    cad: 10,
    eur: 7,
    sgd: 10,
    myr: 32,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-education-donate-a-quran.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-03-19 10:35:06',
    deleted_at: null,
    category_id: 13,
    quantity: 10,
  },
  {
    id: 269,
    stripe_product_id: 'prod_OFXQqp1S174Tt1',
    paypal_product_id: 'PROD-5GD55498B4621951E',
    creator: 'FWD-DIS-AR-GN-002',
    name: 'Feed 10 Hungry People',
    description: 'The price you pay for one nice meal could answer the prayers of 10 people.',
    aud: 50,
    usd: 35,
    gbp: 27,
    cad: 46,
    eur: 32,
    sgd: 47,
    myr: 163,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-feed-10-hungry-people.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-07-12 22:38:25',
    updated_at: '2023-07-12 22:42:00',
    deleted_at: null,
    category_id: 23,
  },
  {
    id: 272,
    stripe_product_id: 'prod_OFXQ6T5qmgYU9b',
    paypal_product_id: 'PROD-0B205461WJ995152F',
    creator: 'FWD-DIS-AR-GN-006',
    name: 'Water For 500 People',
    description: 'Give water to 500 people, bringing new life and energy to their existence.',
    aud: 50,
    usd: 35,
    gbp: 27,
    cad: 46,
    eur: 32,
    sgd: 47,
    myr: 163,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-for-500-people.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-07-12 22:38:26',
    updated_at: '2023-07-12 22:42:01',
    deleted_at: null,
    category_id: 24,
  },
  {
    id: 188,
    stripe_product_id: 'prod_Nifkt7ZkeYU2EI',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-BBW-2023-01-029',
    name: 'Bangladesh Basic Water Well',
    description: 'Provide a hand-operated water well pump for a rural community in Bangladesh.',
    aud: 500,
    usd: 368,
    gbp: 300,
    cad: 489,
    eur: 336,
    sgd: 483,
    myr: 1586,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-bangladesh-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:45',
    updated_at: '2023-04-16 05:06:33',
    deleted_at: null,
    category_id: 24,
  },
  {
    id: 71,
    stripe_product_id: 'prod_NYPzSYBkDheglm',
    paypal_product_id: 'PROD-3MD78906F99775436',
    creator: 'EM-SYR-EQ-02-2023',
    name: 'Caravan',
    description:
      "MATW's Caravan is a long term shelter to house a family and provide a safe living zone with protection from wilderness. It also contains a kitchen sink alongside a proper toilet. This structure is measured at 5x3m",
    aud: 5500,
    usd: 4045,
    gbp: 3300,
    cad: 5313,
    eur: 3755,
    sgd: 5335,
    myr: 17490,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/ramadan-caravan-caravan.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:48',
    updated_at: '2023-03-19 10:35:19',
    deleted_at: null,
    category_id: 26,
  },
  {
    id: 187,
    stripe_product_id: 'prod_NifkhoxYK9a6LS',
    paypal_product_id: 'PROD-40129125B2611963T',
    creator: 'WWC-CON-ABW-2023-01-028',
    name: 'Africa Basic Water Well',
    description: 'A manually operated water well that is hand-pulled and can last for 12-21 years.',
    aud: 1000,
    usd: 735,
    gbp: 600,
    cad: 977,
    eur: 672,
    sgd: 966,
    myr: 3171,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-africa-basic-water-well.jpg',
    recurring: 'Allowed',
    status: 'Duplicate',
    created_at: '2023-04-16 04:54:44',
    updated_at: '2023-04-16 05:06:32',
    deleted_at: null,
    category_id: 24,
  },
  {
    id: 123,
    stripe_product_id: 'prod_NfWvRvdaGsPUvG',
    paypal_product_id: 'PROD-5PM43583L6502403J',
    creator: 'MKD-GEN-MED-2023-01-122',
    name: 'General Orphan Support',
    description: 'Donate whatever you can to support these orphans in any way possible.',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/orphans-support-an-orphan-general-support.jpg',
    recurring: 'Allowed',
    status: 'NEW',
    created_at: '2023-04-07 19:43:15',
    updated_at: '2023-04-07 20:03:32',
    deleted_at: null,
    category_id: 12,
  },
  {
    id: 118,
    stripe_product_id: 'prod_NYQ02zrUDkKTFd',
    paypal_product_id: 'PROD-4B053997N8197683S',
    creator: 'RM24-ZM-013',
    name: 'Zakat Al Mal',
    description: 'Obligatory charity donation given to the needy according to Shariah',
    aud: 1,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 2,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/donate-now-make-a-donation-zakat-al-mal.jpg',
    recurring: 'Allowed',
    status: '1',
    created_at: '2023-03-19 10:07:49',
    updated_at: '2023-03-23 12:26:54',
    deleted_at: null,
    category_id: 33,
  },
  {
    id: 292,
    stripe_product_id: 'prod_OT4iN0TQ2ZoQrK',
    paypal_product_id: 'PROD-08W111077S9572802',
    creator: 'FWD-ODM-001',
    name: '1 Billion Legacy – Food Aid ',
    description:
      'Our nutritious plant-based meals combine health and impact. Your support widens our reach, reflecting our commitment to inclusivity and consistent access to nourishing food. Accommodating diverse needs and preferences, we ensure every recipient benefits. Join us in creating change.to reach more beneficiaries, reflecting our commitment to inclusivity and the belief that everyone should have\nregular access to nutritious and hot meals. We also cater to dietary needs and cultural preferences, making\nsure every recipient benefits fully. Join us in making a difference. Donate now',
    aud: 2,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 5,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-1-billion-legacy-food-aid.jpg',
    recurring: 'year',
    status: '1899-12-30 00:00:00',
    created_at: '2023-08-18 01:55:44',
    updated_at: '2023-08-18 01:57:52',
    deleted_at: null,
    category_id: 23,
    quantity: 365,
  },
  {
    id: 292,
    stripe_product_id: 'prod_OT4iN0TQ2ZoQrK',
    paypal_product_id: 'PROD-08W111077S9572802',
    creator: 'FWD-ODM-001',
    name: '1 Billion Legacy – Food Aid - Monthly',
    description:
      'Our nutritious plant-based meals combine health and impact. Your support widens our reach, reflecting our commitment to inclusivity and consistent access to nourishing food. Accommodating diverse needs and preferences, we ensure every recipient benefits. Join us in creating change.to reach more beneficiaries, reflecting our commitment to inclusivity and the belief that everyone should have\nregular access to nutritious and hot meals. We also cater to dietary needs and cultural preferences, making\nsure every recipient benefits fully. Join us in making a difference. Donate now',
    aud: 2,
    usd: 1,
    gbp: 1,
    cad: 1,
    eur: 1,
    sgd: 1,
    myr: 5,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-1-billion-legacy-food-aid.jpg',
    recurring: 'month',
    status: '1899-12-30 00:00:00',
    created_at: '2023-08-18 01:55:44',
    updated_at: '2023-08-18 01:57:52',
    deleted_at: null,
    category_id: 23,
    quantity: 30,
  },
];

const muftimentProducts = [
  {
    "id": 340,
    "stripe_product_id": "prod_P2WbtbnfhsEMdz",
    "new_stripe_product_id": null,
    "paypal_product_id": "PROD-58G78888MN986211D",
    "creator": "EMR-WNT-2023-01",
    "name": "Keep an Orphan Warm This Winter",
    "description": "Provide an Orphan with clothes to keep them warm this winter.",
    "aud": 50,
    "usd": 35,
    "gbp": 30,
    "cad": 45,
    "eur": 30,
    "sgd": 45,
    "myr": 160,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/winter-winter-campaign-keep-a-child-warm-this-winter.jpg",
    "recurring": "Allowed",
    "status": "Active",
    "created_at": "2023-11-20 18:04:15",
    "updated_at": "2023-11-20 18:09:41",
    "deleted_at": null,
    "category_id": 55,
    "aed": 125,
    "idr": 533365,
  },
  {
    "id": 342,
    "stripe_product_id": "prod_P2WbSXq6kXiaJS",
    "paypal_product_id": "PROD-2DA32401WD7463253",
    "creator": "EMR-WNT-2023-03",
    "name": "Shelter a Family and Keep Them Warm",
    "description": "Provide shelter to a family to protect them from cold and keep them warm this winter.",
    "aud": 500,
    "usd": 341,
    "gbp": 270,
    "cad": 465,
    "eur": 315,
    "sgd": 465,
    "myr": 1600,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/winter-winter-campaign-shelter-a-family-and-keep-them-warm.jpg",
    "recurring": "Allowed",
    "status": "Active",
    "created_at": "2023-11-20 18:04:16",
    "updated_at": "2023-11-20 18:09:42",
    "deleted_at": null,
    "category_id": 55,
    "aed": 1250,
    "idr": 5196499,

  },
  {
    "id": 255,
    "stripe_product_id": "prod_ODOmMJZwZjCKzJ",
    "paypal_product_id": "PROD-5RX549982D069874T",
    "creator": "EMR-DIS-PSE-2023-01",
    "name": "Palestine Most Needed",
    "description": "Provide essential relief, including food, water, medical care and shelter, where it's needed most. Make a difference in the lives of those affected by the emergency in Palestine.",
    "aud": 1,
    "usd": 1,
    "gbp": 1,
    "cad": 1,
    "eur": 1,
    "sgd": 1,
    "myr": 2,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-palestine-jenin-appeal.jpeg",
    "recurring": "Allowed",
    "status": "0",
    "created_at": "2023-07-07 05:32:26",
    "updated_at": "2023-09-15 18:56:03",
    "deleted_at": null,
    "category_id": 27,
    "aed": 4,
    "idr": 15239,

  },
  {
    "id": 293,
    "stripe_product_id": "prod_Oik7CQJl2GgLFl",
    "paypal_product_id": "PROD-56765614HK243104S",
    "creator": "EMR-MR-WMN-001-2023",
    "name": "Morocco Appeal",
    "description": "Provide essential aid, including food, water, medical care and shelter to the survivors of the 6.8 magnitude Earthquake in Morocco. Make a difference in the lives of those who lost everything over night.",
    "aud": 1,
    "usd": 1,
    "gbp": 1,
    "cad": 1,
    "eur": 1,
    "sgd": 1,
    "myr": 2,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-Earthquake-appeal-morocco-appeal.jpg",
    "recurring": "Allowed",
    "status": "1",
    "created_at": "2023-09-09 00:00:00",
    "updated_at": "2023-09-28 21:44:00",
    "deleted_at": null,
    "category_id": 26,
    "aed": 1,
    "idr": 1,

  },
  {
    "id": 295,
    "stripe_product_id": "prod_Odr9APNosVRE7T",
    "paypal_product_id": "PROD-5UH663536W393321E",
    "creator": "EMR-LBY-WMN-002-2023",
    "name": "Libya Floods",
    "description": "Donate today and help the thousands that have been displaced due to floods",
    "aud": 1,
    "usd": 1,
    "gbp": 1,
    "cad": 1,
    "eur": 1,
    "sgd": 1,
    "myr": 1,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-emergency-support-libya-emergency.jpeg",
    "recurring": "Allowed",
    "status": "1899-12-30 00:00:00",
    "created_at": "2023-09-15 20:38:11",
    "updated_at": "2023-09-15 20:40:00",
    "deleted_at": null,
    "category_id": 52,
    "aed": 1,
    "idr": 1,

  },
  {
    "id": 332,
    "stripe_product_id": "prod_OoFe6pBaRmxmQS",
    "paypal_product_id": "PROD-6C8022626M763840T",
    "creator": "EMR-DIS-AFG-2023-02",
    "name": "Afghanistan Earthquake Appeal",
    "description": "Provide essential aid like food, water, shelter and medical care to those affected by the 6.3-magnitude in Afghanistan. Make a difference in the lives of those struggling.",
    "aud": 1,
    "usd": 1,
    "gbp": 1,
    "cad": 1,
    "eur": 1,
    "sgd": 1,
    "myr": 1,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-earthquake-appeal-afghanistan-earthquake-appeal.png",
    "recurring": "Allowed",
    "status": "Active",
    "created_at": "2023-10-10 01:25:15",
    "updated_at": "2023-10-13 15:39:06",
    "deleted_at": null,
    "category_id": 26,
    "aed": 2,
    "idr": 2,

  },
  {
    "id": 299,
    "stripe_product_id": "prod_OexjnZqtQjc7XJ",
    "paypal_product_id": "PROD-7L934005P0102581M",
    "creator": "EDU-GEN-BTS-20230-002",
    "name": "Orphan Education Supply kit",
    "description": "Our product directly supports orphans' education by providing essential items like uniforms, shoes, bags, books, and pencils. Your purchase empowers them to embark on their educational journey confidently. Impact vulnerable children in Yemen, Togo, Lebanon, Palestine, Jordan, Syria, Pakistan, and Bangladesh.",
    "aud": 1,
    "usd": 1,
    "gbp": 1,
    "cad": 1,
    "eur": 1,
    "sgd": 1,
    "myr": 1,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/orphans-education-orphan-education-supply-kit.jpg",
    "recurring": "Allowed",
    "status": "Yes",
    "created_at": "2023-09-18 19:29:38",
    "updated_at": "2023-09-18 19:36:32",
    "deleted_at": null,
    "category_id": 13,
    "aed": 1,
    "idr": 1,

  },
  {
    "id": 2,
    "stripe_product_id": "prod_NYPzzhDazjzIPE",
    "paypal_product_id": "PROD-1BL69444AG845241F",
    "creator": "FST-DEL-F50-2023-01-009",
    "name": "Orphan Feast For 50",
    "description": "Make a difference in the lives of 50 orphans by sponsoring (Wallima) a nourishing feast.",
    "aud": 200,
    "usd": 147,
    "gbp": 120,
    "cad": 196,
    "eur": 135,
    "sgd": 194,
    "myr": 635,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/orphans-orphan-feast-orphan-feast-for-50.jpg",
    "recurring": "Allowed",
    "status": "1",
    "created_at": "2023-03-19 10:07:48",
    "updated_at": "2023-09-15 18:53:30",
    "deleted_at": null,
    "category_id": 11,
    "aed": 572,
    "idr": 2240133,

  },
  {
    "id": 272,
    "stripe_product_id": "prod_OFXQ6T5qmgYU9b",
    "paypal_product_id": "PROD-0B205461WJ995152F",
    "creator": "FWD-DIS-AR-GN-006",
    "name": "Water For 500 People",
    "description": "Give water to 500 people, bringing new life and energy to their existence.",
    "aud": 50,
    "usd": 35,
    "gbp": 27,
    "cad": 46,
    "eur": 32,
    "sgd": 47,
    "myr": 163,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-water-for-500-people.jpg",
    "recurring": "Allowed",
    "status": "1",
    "created_at": "2023-07-12 22:38:26",
    "updated_at": "2023-09-22 17:59:41",
    "deleted_at": null,
    "category_id": 24,
    "aed": 184,
    "idr": 769550,

  },
  {
    "id": 60,
    "stripe_product_id": "prod_NYPzmslRXQEABq",
    "paypal_product_id": "PROD-92H982558H7722056",
    "creator": "FWD-DIS-BMY-2023-01-050",
    "name": "Baby Milk For 52 Weeks",
    "description": "Every child deserves access to proper nutrition, yet over 350 million children are suffering from hunger. MATW's Baby Milk for 52 Weeks appeal addresses this critical need by supplying baby milk to families in need. By contributing to this initiative, you can make a direct impact on the well-being of vulnerable infants, supporting their growth, health, and overall development. 7665 infants have benefitted from your donation of baby milk until now. Donate more to help even more babies in need.",
    "aud": 520,
    "usd": 385,
    "gbp": 315,
    "cad": 510,
    "eur": 350,
    "sgd": 505,
    "myr": 1700,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-food-aid-baby-milk-for-52-weeks.jpg",
    "recurring": "Allowed",
    "status": "1",
    "created_at": "2023-03-19 10:07:48",
    "updated_at": "2023-09-15 18:55:43",
    "deleted_at": null,
    "category_id": 23,
    "aed": 1496,
    "idr": 5867015,

  },
  {
    "id": 188,
    "stripe_product_id": "prod_Nifkt7ZkeYU2EI",
    "paypal_product_id": "PROD-40129125B2611963T",
    "creator": "WWC-CON-BBW-2023-01-029",
    "name": "Bangladesh Basic Water Well",
    "description": "Provide a hand-operated water well pump for a rural community in Bangladesh.",
    "aud": 500,
    "usd": 368,
    "gbp": 300,
    "cad": 489,
    "eur": 336,
    "sgd": 483,
    "myr": 1586,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-bangladesh-basic-water-well.jpg",
    "recurring": "Allowed",
    "status": "Duplicate",
    "created_at": "2023-04-16 04:54:45",
    "updated_at": "2023-09-15 18:55:52",
    "deleted_at": null,
    "category_id": 24,
    "aed": 1430,
    "idr": 5607952,

  },
  {
    "id": 187,
    "stripe_product_id": "prod_NifkhoxYK9a6LS",
    "paypal_product_id": "PROD-40129125B2611963T",
    "creator": "WWC-CON-ABW-2023-01-028",
    "name": "Africa Basic Water Well",
    "description": "A manually operated water well that is hand-pulled and can last for 12-21 years.",
    "aud": 1000,
    "usd": 735,
    "gbp": 600,
    "cad": 977,
    "eur": 672,
    "sgd": 966,
    "myr": 3171,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/food-and-water-aid-water-aid-africa-basic-water-well.jpg",
    "recurring": "Allowed",
    "status": "Duplicate",
    "created_at": "2023-04-16 04:54:44",
    "updated_at": "2023-09-15 18:55:50",
    "deleted_at": null,
    "category_id": 24,
    "aed": 2856,
    "idr": 11200665,

  },
  {
    "id": 316,
    "stripe_product_id": "prod_OikZzVL1siZ75a",
    "paypal_product_id": "PROD-9GK37689YY0632733",
    "creator": "MSJ-WA-SMJ-2023-01",
    "name": "Africa Small Masjid",
    "description": "100 sq. m. masjid with Wudu facilities and two bathrooms. These masjids will be installed in the West African region in areas where they are most needed. Running water is not included in the masjid.",
    "aud": 15000,
    "usd": 9750,
    "gbp": 7800,
    "cad": 13050,
    "eur": 9150,
    "sgd": 13200,
    "myr": 45600,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-masjid-africa-small-masjid.jpg",
    "recurring": "Allowed",
    "status": "Active",
    "created_at": "2023-09-28 22:09:31",
    "updated_at": "2023-09-28 22:19:38",
    "deleted_at": null,
    "category_id": 29,
    "aed": 35700,
    "idr": 149424000,

  },
  {
    "id": 317,
    "stripe_product_id": "prod_OikZ6aITGE6Lv8",
    "paypal_product_id": "PROD-977897608X247964W",
    "creator": "MSJ-WA-LRG-2023-02",
    "name": "Africa Large Masjid",
    "description": "150 sq. m. masjid with two classrooms for teaching Quran; it also includes Wudu facilities and two bathrooms. These masjids will be installed in the West African region in areas where they are most needed. Running water is not included in the masjid.",
    "aud": 20000,
    "usd": 13000,
    "gbp": 10400,
    "cad": 17400,
    "eur": 12200,
    "sgd": 17600,
    "myr": 60800,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-masjid-africa-large-masjid.jpg",
    "recurring": "Allowed",
    "status": "Active",
    "created_at": "2023-09-28 22:09:32",
    "updated_at": "2023-09-28 22:19:39",
    "deleted_at": null,
    "category_id": 29,
    "aed": 47600,
    "idr": 199232000,

  },
  {
    "id": 92,
    "stripe_product_id": "prod_NYPzW69oISOsLZ",
    "paypal_product_id": "PROD-55J29194X8280435P",
    "creator": "MSJ-CON-IMJ-2023-01-014",
    "name": "Indonesia Masjid",
    "description": "Your generosity can aid in the construction of a Main Masjid block and a small Mosque, providing a serene and devout space for the small community to connect with Allah through prayer.",
    "aud": 30000,
    "usd": 22050,
    "gbp": 17955,
    "cad": 29295,
    "eur": 20160,
    "sgd": 28980,
    "myr": 95130,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-indonesia-masjid-indonesia-masjid.jpg",
    "recurring": "Allowed",
    "status": "1",
    "created_at": "2023-03-19 10:07:48",
    "updated_at": "2023-09-15 18:56:11",
    "deleted_at": null,
    "category_id": 29,
    "aed": 85665,
    "idr": 336019950,

  },
  {
    "id": 122,
    "stripe_product_id": "prod_NZYKQBceTRhsk1",
    "paypal_product_id": "PROD-15U431202D489773T",
    "creator": "MKD-MN-001",
    "name": "Where Most Needed",
    "description": "Donate to where it's needed the most, we'll distribute your donation to the people that most need it",
    "aud": 1,
    "usd": 1,
    "gbp": 1,
    "cad": 1,
    "eur": 1,
    "sgd": 1,
    "myr": 2,
    "custom_amount": "Yes",
    "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/logo-replace.png",
    "recurring": "Allowed",
    "status": "1",
    "created_at": "2022-03-22 10:49:11",
    "updated_at": "2023-09-22 15:54:51",
    "deleted_at": null,
    "category_id": 33,
    "aed": 1,
    "idr": 1,

  }
];

const events = [
  {
    id: 1,
    due: "Upcoming Events",
    title: "Winter Hope",
    by: "Mufti Menk",
    date: "<p><b>9th to 10th</b> December, 2023 </br>\n        in Malaysia, Kuala Lumpur</p>",
    shortDesc: "In a serene and welcoming venue in Malaysia, bathed in the warm glow of unity, a profound event is set to unfold between the 9th and 10th of December 2023, as renowned Islamic scholar Mufti Menk takes center stage to address a captivated audience.",
    btns: true,
    image: "/images/events/muftimenk.png",
    banner:"muftiBanner",
    bannerMobile:"muftiBannerMobile",
    longDescription: `<p>In a serene and welcoming venue in Malaysia, bathed in the warm glow of unity, a profound event is set to unfold between the 9th and 10th of December 2023, as renowned Islamic scholar Mufti Menk takes center stage to address a captivated audience. The occasion, aptly named "Winter Hope," aims to shed light on the importance of solidarity and compassion within the Muslim community, particularly during the unforgiving winter season when vulnerable members, especially orphans, will face increased challenges.</br></br>
      As attendees will file into the venue, a palpable sense of anticipation and community spirit will fill the air. The event will begin with a recitation from the Holy Quran, setting a serene and spiritually charged atmosphere. Mufti Menk, a beacon of wisdom and compassion, will then step onto the stage to deliver a thought-provoking lecture.</br></br>
      With eloquence and humility, Mufti Menk will articulate the essence of Islam and the crucial role each individual plays in uplifting the Ummah. He will underscore the significance of reaching out to our Muslim brothers and sisters, emphasizing that true faith is reflected in the actions taken to alleviate the struggles of those in need.</br></br>
      The focus of Mufti Menk's talk will gravitate towards the harsh winter season and its impact on vulnerable communities, particularly orphans. He will speak passionately about the responsibility each member of the Ummah holds in ensuring that no one is left without warmth, shelter, and sustenance during these challenging times.</br></br>
      Mufti Menk will share heartwarming stories of resilience and community support from around the world, illustrating the transformative power of collective action. His words will resonate deeply with the audience, inspiring a renewed commitment to charitable deeds and fostering a sense of kinship that transcends geographical boundaries.</br></br>
      The event will also feature testimonials from individuals and organizations actively involved in humanitarian efforts, showcasing tangible examples of positive change initiated by the compassionate hearts within the community. Attendees will be encouraged to contribute to ongoing initiatives and support organizations dedicated to providing relief to those in need.</br></br>
      In the spirit of solidarity, the event will conclude with a collective prayer for the well-being of the Ummah, especially during the challenging winter months. Attendees will leave with hearts aflame with compassion, determined to make a positive impact in the lives of their fellow Muslims, embodying the true essence of Islamic brotherhood and sisterhood. "Winter Hope" will serve not only as an informative gathering but also as a catalyst for positive change, instilling a sense of duty and empathy that will echo long after the event's conclusion.
      </p>`,
      donateLink:'https://matwcheckout.org/events/muftimenk',
      pledgeLink:'https://i5z70shhcis.typeform.com/to/SmyXI3DP?typeform-source=events.matwcheckout.org'
  },
  {
    id: 2,
    due: "Past Events",
    title: "Season by Compassion",
    by: "Nauman Ali Khan",
    date: "<p><b>19th to 29th</b> November, 2023 <br/>\n        in Australia</p>",
    shortDesc: "In a tranquil and inclusive setting in Australia, bathed in the warm glow of unity, a momentous event unfolded between the 19th of October and the 29th of November 2023, featuring esteemed Islamic scholar Nauman Ali Khan as the central speaker.",
    btns: false,
    image: "/images/events/nomankhan.png",
    banner:"nomanBanner",
    bannerMobile:"nomanBannerMobile",
    longDescription: `<p>In a tranquil and inclusive setting in Australia, bathed in the warm glow of unity, a momentous event unfolded between the 19th of October and the 29th of November 2023, featuring esteemed Islamic scholar Nauman Ali Khan as the central speaker. The event, aptly titled "Season of Compassion," aimed to illuminate the significance of solidarity and empathy within the Muslim community, especially during the challenging winter season when vulnerable members, particularly orphans, confronted heightened difficulties.</br></br>
      As attendees streamed into the venue, a palpable sense of anticipation and community spirit permeated the air. The gathering commenced with a recitation from the Holy Quran, setting a serene and spiritually charged atmosphere. Nauman Ali Khan, a luminary in Islamic wisdom, then took center stage to deliver a compelling lecture.</br></br>
      With eloquence and humility, Nauman Ali Khan expounded on the core principles of Islam and the pivotal role each individual plays in uplifting the Ummah. He underscored the importance of extending a helping hand to fellow Muslim brothers and sisters, emphasizing that genuine faith is demonstrated through actions taken to alleviate the struggles of those in need.</br></br>
      The crux of Nauman Ali Khan's discourse revolved around the harsh winter season and its impact on vulnerable communities, particularly orphans. He spoke passionately about the responsibility each member of the Ummah carries in ensuring that no one is left without warmth, shelter, and sustenance during these challenging times.</br></br>
      Nauman Ali Khan shared poignant stories of resilience and community support from various corners of the globe, illustrating the transformative power of collective action. His words resonated deeply with the audience, kindling a renewed commitment to charitable deeds and fostering a sense of kinship that transcends geographical boundaries.</br></br>
      The event also showcased testimonials from individuals and organizations actively engaged in humanitarian efforts, spotlighting tangible examples of positive change initiated by compassionate hearts within the community. Attendees were encouraged to contribute to ongoing initiatives and support organizations dedicated to providing relief to those in need.</br></br>
      In the spirit of solidarity, the event concluded with a collective prayer for the well-being of the Ummah, particularly during the challenging winter months. Attendees left with hearts ignited with compassion, determined to make a positive impact in the lives of their fellow Muslims, embodying the true essence of Islamic brotherhood and sisterhood. "Season of Compassion" served not only as an enlightening assembly but also as a catalyst for positive change, instilling a sense of duty and empathy that will 
      resonate long after the event's conclusion.</p>`,
      donateLink:'',
      pledgeLink:''
  }
]
export { liveFundraiserProducts, indonesiaProducts, malaysiaProducts, singaporeProducts, yasirProducts, noumanalikhanProducts, asmaaProducts, muftimentProducts ,events};

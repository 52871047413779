import React from 'react';
import { Link } from 'react-router-dom';
import { generateLinkWithLang } from '../../utils/linkGenerator'; // Import the link generator utility
import { useTranslation } from 'react-i18next';

const CustomButton = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { disabled, isLoading, title, icon, link, state, roundedFull, target, onClick, className, type = 'submit' } = props;
    const { i18n } = useTranslation();
    const lang = i18n.language;

    // Generate link with the language code
    const langLink = link ? generateLinkWithLang(link, lang) : null;
    /* -------------------------------------------------------------------------- */

    return (
        <>
            {langLink
                ? <Link state={state ? state : ''} to={langLink} target={target} className={`flex items-center justify-center text-center font-['Montserrat'] font-bold ${roundedFull ? 'rounded-full' : 'rounded-md'} px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase ${className ? className : ''} ${!disabled ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]' : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'}`}>
                    {title}
                </Link>
                : <button
                    type={type}
                    disabled={disabled}
                    className={`flex items-center justify-center text-center font-['Montserrat'] font-bold ${roundedFull ? 'rounded-full' : 'rounded-md'} px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase ${className ? className : ''} ${!disabled ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]' : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'}`}
                    onClick={() => onClick()}
                >
                    {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    </>) : <><span>{title}</span>
                        {icon}
                    </>}
                </button>
            }
        </>
    );
}

export default CustomButton;

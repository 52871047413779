import React, {
  // useEffect,
  useRef, useState
} from 'react';
// import { useSelector } from 'react-redux';
// import { selectSelectedCurrency } from '../../../store/user';
import DonateWidget from './donateWidget';
import CustomButton from '../../../components/general/button';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import ShowCurrencies from '../../../utils/showCurrencies';

const OrphansAIATabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { studentSponsorShipProduct, familySponsorShipProduct } = props;
  const contentRef = useRef(null);
  const [addProduct] = useHandleAddProduct();
  // const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  // const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    {
      title: 'Class Sponsorship',
      description: `As a student, you have the chance to make a direct impact on the life of an orphan. By contributing just $50 per year, you’ll be part of a collective class effort that supports these vulnerable children. It’s not only an opportunity for students but also a great way for parents to instill empathy and kindness in their children.`,
    },
    {
      title: 'Student / Individual Sponsorship',
      description: `Consider the collective impact of individual sponsorships. Each sponsorship contributes to supporting an orphan for an entire year. By pooling resources, individuals can create lasting change and cultivate a sense of community.`,
      products: studentSponsorShipProduct,
    },
    {
      title: 'Family Sponsorship',
      description: `Beyond student sponsorships, we invite individuals and families to participate. For $1,200 per year, you can provide essential support to an orphan, ensuring they receive education, healthcare, and emotional well-being.`,
      products: familySponsorShipProduct,
    },
  ];
  const donateProducts = [
    "EMR-AIA-24-01", "EMR-AIA-24-02", "EMR-AIA-24-03", "EMR-AIA-24-04", "EMR-AIA-24-05",
    "EMR-AIA-24-06", "EMR-AIA-24-07", "EMR-AIA-24-08", "EMR-AIA-24-09", "EMR-AIA-24-10",
    "EMR-AIA-24-11", "EMR-AIA-24-12", "EMR-AIA-24-13", "EMR-AIA-24-14", "EMR-AIA-24-15",
    "EMR-AIA-24-16", "EMR-AIA-24-17", "EMR-AIA-24-18", "EMR-AIA-24-19", "EMR-AIA-24-20",
    "EMR-AIA-24-21", "EMR-AIA-24-22", "EMR-AIA-24-23", "EMR-AIA-24-24",
  ]
  const handleAddNewProduct = (prd) => {
    let tempProduct = prd;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: 1,
      recurring: 'one-off',
    })
    addProduct({product: tempProduct, currency: "AUD"})
  }
  /* ------------------------------- Start hooks ------------------------------ */
  // useEffect(() => {
  //   selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  // }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <h1 className="max-w-[1080px] mx-auto font-brandingBold text-white text-[25px] md:text-[40px] leading-7 md:leading-[48px] text-center font-bold mb-2 md:mb-4">
          Joint Orphan Sponsorship Project by Australian International Academy of Education (AIAE) and Muslims Around the World (MATW)
        </h1>
        <p className="max-w-[1080px] mx-auto  text-white text-center mb-4 md:mb-8">
          We are excited to introduce a remarkable initiative that brings together compassion, education, and global citizenship. The Australian International Academy (AIA) and Muslims Around the World (MATW) have partnered to create a unique opportunity for students, families, and individuals to sponsor orphans similar to their age.
        </p>
        <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {tabButtonsProducts.map(({ title }, index) => (
            <button
              key={`btn_${index}`}
              onClick={() => {
                window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 })
                setActiveTabProducts(index);
              }}
              className={`${index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'} text-[14px] rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-['Montserrat'] font-bold`}>
              {title}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center" ref={contentRef}>
            {/* {tabButtonsProducts.map((item, index) =>
              index === activeTabProducts && <React.Fragment key={`description_${index}`}>
                <div className="flex w-full justify-center mb-6">
                  <p className='text-center text-white text-[18px] md:text-3xl md:w-2/3'>
                    {item.description}
                  </p>
                </div>
                <div ref={contentRef}>
                  <TabContent
                    products={item.products}
                    currency={selectedCurrency}
                    productsOnMobile={item.productsOnMobile ? item.productsOnMobile : null}
                    showDots={item.productsOnMobile ? false : true}
                    showArrows={item.productsOnMobile ? false : true}
                    isPalestineRefugee
                  />
                </div>
              </React.Fragment>
            )} */}
            {activeTabProducts === 0 && <div className='flex flex-wrap justify-center'>
              <div className="flex bg-[#E2EFF3] rounded-lg flex-col basis-full md:basis-1/3 lg:basis-1/4 pt-3">
                <div className="p-2 pb-0 md:p-4 md:pb-0">
                  <h3 className="text-[#253B7E] text-lg md:text-2xl mb-2 text-center font-bold">
                    {tabButtonsProducts[0].title}
                  </h3>
                  <p className="text-[#797979] text-sm md:text-base text-center">
                    {tabButtonsProducts[0].description}
                  </p>
                </div>
                <DonateWidget
                  hasSuggestPrice
                  donateProducts={donateProducts}
                  defaultProduct={{
                    creator: 'EMR-AIA-24-01',
                    label: 'Year Prep A',
                    value: 554,
                  }}
                />
              </div>
            </div>}
            {activeTabProducts === 1 && <div className='flex flex-wrap justify-center'>
              <div className="flex bg-[#E2EFF3] rounded-lg flex-col basis-full md:basis-1/3 lg:basis-1/4 pt-3">
                <div className="p-2 pb-0 md:p-4 md:pb-0">
                  <h3 className="text-[#253B7E] text-lg md:text-2xl mb-2 text-center font-bold">
                    {tabButtonsProducts[1].title}
                  </h3>
                  <p className="text-[#797979] text-sm md:text-base text-center">
                    {tabButtonsProducts[1].description}
                  </p>
                </div>
                <DonateWidget
                  hasSuggestPrice
                  isSingleProduct
                  customSuggestedPrices={[50, 100, 1200]}
                  donateProducts={tabButtonsProducts[1].products}
                  defaultProduct={{
                    creator: 'EMR-AIA-24-25',
                    label: 'Student / Individual Sponsorship',
                    value: 554,
                  }}
                />
              </div>
            </div>}
            {activeTabProducts === 2 && <div className='flex flex-wrap justify-center'>
              <div className="flex bg-[#E2EFF3] rounded-lg flex-col basis-full md:basis-1/3 lg:basis-1/4 pt-3 pb-4 md:pb-8 px-4 md:px-8">
                <div className="py-2 md:py-4">
                  <h3 className="text-[#253B7E] text-lg md:text-2xl mb-2 text-center font-bold">
                    {tabButtonsProducts[2].title}
                  </h3>
                  <p className="text-[#797979] text-sm md:text-base text-center">
                    {tabButtonsProducts[2].description}
                  </p>
                </div>
                <div className="flex flex-col">
                  {console.log("tabButtonsProducts[2].products[0]", tabButtonsProducts[2])}
                  {tabButtonsProducts[2].products[0] && <>
                    <h2 className="text-[#F60362] text-2xl font-bold text-center mb-2">
                      {/* {ShowCurrencies(selectedCurrency, tabButtonsProducts[2].products[0][selectedCurrency.toLowerCase()], true)}<span className={`ml-1 text-sm`}>per year</span> */}
                      {ShowCurrencies("AUD", tabButtonsProducts[2].products[0]['aud'], true)}<span className={`ml-1 text-sm`}>per year</span>
                    </h2>
                    <CustomButton
                      className='min-w-[140px]'
                      title={'Donate Now'}
                      disabled={!tabButtonsProducts[2].products[0].id}
                      onClick={() => handleAddNewProduct(tabButtonsProducts[2].products[0])}
                    />
                  </>}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </section>
  );
};
export default OrphansAIATabs;
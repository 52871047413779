import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

const ErrorPage = () => {
    return (
        <div className="container mx-auto">
            <div className="grid grid-cols-12 py-8 px-3 md:px-0">
                <div className="bg-white flex flex-col col-start-1 lg:col-start-2 xl:col-start-3 col-span-12 lg:col-span-10 xl:col-span-8 rounded-md px-6 py-8 shadow-md mt-10 items-center">
                    <img src='/images/general/error.png' alt='checkout' className={`w-[100px] mx-auto mb-5`} />
                    <h1 className='text-stone-700 text-2xl font-medium'>
                        Try Again!
                    </h1>
                    {/* </h1> */}
                    <div className='grid grid-cols-5 border-b border-stone-200 p-2 sm:p-4 w-full mt-6 gap-2 text-center'>
                        <p className='col-span-5 text-stone-500 mb-4'>
                            Your donation is failed. Please try again or contact the support team.
                        </p>
                        <div className='col-span-2 sm:col-start-3 sm:col-span-1 my-2'>
                            <Link to="/" className="w-full flex items-center justify-center rounded bg-[#00a3da] text-white px-6 pt-2 pb-2 font-normal text-lg h-[48px] shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#00a3da] hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#00a3da] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#00a3da] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                                Try Again
                            </Link>
                        </div>
                    </div>
                    <div className='p-2 sm:p-4 w-full mt-6 gap-2 flex flex-col'>
                        <p className='w-full text-stone-600 text-center text-xl mb-6'>
                            Share with Friends
                        </p>
                        <div className='flex justify-center items-center gap-6'>
                            <a href="#facebook" className='rounded-full border border-stone-200 h-[48px] w-[48px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400'>
                                <img src="/images/social-media/facebook.png" alt="facebook" className='w-auto h-[60%]'/>
                            </a>
                            <a href="#instagram" className='rounded-full border border-stone-200 h-[48px] w-[48px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400'>
                                <img src="/images/social-media/instagram.png" alt="instagram" className='w-auto h-[60%]'/>
                            </a>
                            <a href="#whatsapp" className='rounded-full border border-stone-200 h-[48px] w-[48px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400'>
                                <img src="/images/social-media/whatsapp.png" alt="whatsapp" className='w-auto h-[60%]'/>
                            </a>
                            <a href="#twitter" className='rounded-full border border-stone-200 h-[48px] w-[48px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400'>
                                <img src="/images/social-media/twitter.png" alt="twitter" className='w-auto h-[50%]'/>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ErrorPage;

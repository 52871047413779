import React from 'react';

export default function InfoSection() {
  const InfoItem = ({ title, icon, children }) => (
    <div className="flex w-full">
      <div className=" mr-4">{icon}</div>
      <div className="flex flex-col">
        <h2
          style={{
            fontSize: '1.75rem',
          }}
          className="text-primary mb-8 text-2xl"
        >
          {title}
        </h2>
        <div>{children}</div>
      </div>
    </div>
  );
  return (
    <div className="w-full flex flex-col gap-8 md:flex-row">
      <div className="w-full md:w-1/2">
        <InfoItem title={'Get in Touch'} icon={<img className=" w-10" src="/paperplane.svg" alt="marker" />}>
          <div className="text-base text-textPrimary">
            <p className="mb-5">
              {/* Telephone: 1300 628 977
              <br /> */}
              {/* <strong>24/7 Donation Line:</strong>
              <br />
              UK Local Rate:{' '}
              <a href="tel:0345 004 0534" target="_self" className="text-primary" rel="noopener noreferrer">
              0345 004 0534
              </a>
              <br />
              UK:{' '}
              <a href="tel:+44 204 577 1786" target="_self" className="text-primary" rel="noopener noreferrer">
              +44 204 577 1786
              </a>
              <br />
              USA:{' '}
              <a href="tel:+1 9292245611" target="_self" className="text-primary" rel="noopener noreferrer">
              +1 (929)224-5611
              </a>
              <br />
              Australia:{' '}
              <a href="tel:+61 26188 5623" target="_self" className="text-primary" rel="noopener noreferrer">
              +61 2 6188 5623
              </a>
              <br />
              Email:{' '}
              <a className="text-primary" href="mailto:call@matwproject.org">
                call@matwproject.org
              </a>
              <br />
              <br /> */}

              {window.location.hostname === 'matwproject.org' ? (
                <>
                  <strong>Australia Donor Care Team 9-5PM</strong>
                  <br />
                  Mon-Fri:
                  <br />
                  Australia Local Rate:{' '}
                  <a href="tel:1300 628 977" target="_self" className="text-primary" rel="noopener noreferrer">
                    1300 628 977
                  </a>
                  <br />
                  Australia:{' '}
                  <a href="tel:+61 2 9758 9037" target="_self" className="text-primary" rel="noopener noreferrer">
                    +61 2 9758 9037
                  </a>
                  <br />
                  Email:{' '}
                  <a className="text-primary" href="mailto:info@matwproject.org">
                    info@matwproject.org
                  </a>
                  <br />
                  WhatsApp:{' '}
                  <a className="text-primary" href="https://api.whatsapp.com/send?phone=61452375738">
                    +61 452 375 738
                  </a>
                  <br />
                  <br />
                </>
              ) : (
                ''
              )}
              {window.location.hostname === 'matwprojectusa.org' ? (
                <>
                  <strong>USA Call Centre</strong>
                  <br />
                  Our USA donor care line is open 9am to 6pm USA time.
                  <br />
                  <a href="tel:+1 7035403446" target="_self" className="text-primary" rel="noopener noreferrer">
                    +1 (703) 540-3446
                    
                  </a>
                  <br />
                  Email{' '}
                  <a href="mailto:info@matwprojectusa.org" className="text-primary">
                    info@matwprojectusa.org
                  </a>
                </>
              ) : (
                <>
                  <strong>UK Call Centre</strong>
                  <br />
                  Our UK donor care line is open 9am to 6pm UK time.
                  <br />
                  <a href="tel:+44 2080895843" target="_self" className="text-primary" rel="noopener noreferrer">
                    +44 (0)208 089 5843
                  </a>
                  <br />
                </>
              )}
              {/* Line 2:{' '}
              <a href="tel:+44 2045877830" target="_self" className="text-primary" rel="noopener noreferrer">
              +44 (0)2045877830
              </a> */}
            </p>
            <a href="/cancel" className="text-red-600 font-bold">
              Cancel Subscription
            </a>
            <p className="my-5">
              <strong>Instagram Pages</strong>
              <br />
              Please note our official Instagram pages are:
              <br />
              <br />
              {window.location.hostname === 'matwprojectusa.org' ? (
                <>
                  <a className="text-primary" href="https://www.instagram.com/matw_projectusa" target="_self" rel="noopener noreferrer">
                    @matw_projectusa (USA)
                  </a>
                  <br />
                </>
              ) : (
                <>
                  <a className="text-primary" href="https://www.instagram.com/matwproject_global" target="_self" rel="noopener noreferrer">
                    @matwproject_global (Global)
                  </a>
                  <br />
                  <a className="text-primary" href="https://www.instagram.com/matw_project" target="_self" rel="noopener noreferrer">
                    @matw_project (Australia)
                  </a>
                  <br />
                  <a className="text-primary" href="https://www.instagram.com/matw_projectuk" target="_self" rel="noopener noreferrer">
                    @matw_projectuk (UK)
                  </a>
                  <br />

                  <a className="text-primary" href="https://www.instagram.com/matw_projectcanada" target="_self" rel="noopener noreferrer">
                    @matw_projectcanada (Canada)
                  </a>
                  <br />
                  <a className="text-primary" href="https://www.instagram.com/matw_projectmiddleeast" target="_self" rel="noopener noreferrer">
                    @matw_projectmiddleeast (Middle East)
                  </a>
                  <br />
                  <a className="text-primary" href="https://www.instagram.com/matw_projectfrance" target="_self" rel="noopener noreferrer">
                    @matw_projectfrance (France)
                  </a>
                  <br />
                  <a className="text-primary" href="https://www.instagram.com/matw_projectsingapore" target="_self" rel="noopener noreferrer">
                    @matw_projectsingapore (Singapore)
                  </a>
                  <br />
                  <a className="text-primary" href="https://www.instagram.com/matw_projectmalaysia" target="_self" rel="noopener noreferrer">
                    @matw_projectmalaysia (Malaysia)
                  </a>
                  <br />
                  <a className="text-primary" href="https://www.instagram.com/matw_projectindonesia" target="_self" rel="noopener noreferrer">
                    @matw_projectindonesia (Indonesia)
                  </a>
                  <br />
                  <a className="text-primary" href="https://www.instagram.com/matw_projectnz" target="_self" rel="noopener noreferrer">
                    @matw_projectnz (New Zealand)
                  </a>
                </>
              )}
            </p>
            {/* <p className="mb-5">
              UK Contact Centre:{' '}
              <a className="text-primary" href="tel:+44 345 004 0534" target="_self" rel="noopener noreferrer">
                +44 345 004 0534
              </a>
            </p> */}
            {/* <p>
              <a className="text-primary" href="mailto:info@matwproject.org">
                info@matwproject.org
              </a>
            </p> */}
          </div>
        </InfoItem>
      </div>
      <div className="w-full md:w-1/2">
        <InfoItem title={'Visit Us'} icon={<img className=" w-10" src="/map-marker-line.svg" alt="marker" />}>
          <div className="text-textPrimary text-base">
            {window.location.hostname === 'matwprojectusa.org' ? (
              <div className="mb-5">2461 Eisenhower Ave 2nd Floor, #035 Alexandria, VA 22314</div>
            ) : (
              <div className="mb-5">Level 2 47 Rickard Road, Bankstown, NSW 2200 Australia</div>
            )}
            <p>Monday to Friday: 9am to 5pm</p>
          </div>
        </InfoItem>
      </div>
    </div>
  );
}

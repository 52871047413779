import axios from 'axios';

const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_API_HEADER;

let config = {
  headers: {
    'X-user-matw': Header,
    Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
  },
};

function getAllCategories() {
  return axios.get(`${Base}/categories`, config);
}
function createCategory(data,file) {
  let config = {
    headers: {
      accept: 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  return axios.post(`${Base}/admin-dashboard/categories`, data, config);
}
function updateCategory(id, data, file) {
  let config = {
    headers: {
      accept: 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  return axios.patch(`${Base}/admin-dashboard/categories/${id}`, data, config);
}
function updateCategoryWithoutImage(id, data) {
  return axios.patch(`${Base}/admin-dashboard/categories/${id}`, data, config);
}
function deleteCategory(id) {
  return axios.delete(`${Base}/admin-dashboard/categories/${id}`, config);
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllCategories,
  createCategory,
  updateCategory,
  updateCategoryWithoutImage,
  deleteCategory
};
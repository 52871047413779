const profanityList=[
    'arse',
    'arsehole',
    'ass',
    'asshat',
    'asshole',
    'bastard',
    'bellend',
    'bitch',
    'blowjob',
    'bugger',
    'bull',
    'cock',
    'coon',
    'cracker',
    'cunt',
    'dick',
    'faggot',
    'fuck',
    'git',
    'gook',
    'minger',
    'nigga',
    'nigger',
    'penis',
    'poof',
    'poofter',
    'prick',
    'pussy',
    'retard',
    'shit',
    'slag',
    'slut',
    'sod',
    'twat',
    'vagina',
    'wanker',
    'whore'

];
export default profanityList;
import React from 'react';
import Accordion from './Accordion';
import styles from './styles.module.css';
import createScn from '../../../utils/scn';
import { childComponentGenerator as ccg } from './utils';

const scn = createScn(styles);

const CustomAccordion = ({ children }) => {
  return <section className={`${scn('customAccordion__container')}`}>{children}</section>;
};

ccg(CustomAccordion, 'h1', 'customAccordion__heading', 'Title', scn);
ccg(CustomAccordion, 'div', 'customAccordion__grid', 'Header', scn);
ccg(CustomAccordion, 'div', 'customAccordion__gridItem', 'HeaderContent', scn);
ccg(CustomAccordion, 'div', 'customAccordion__question', 'HeaderQuestion', scn);
ccg(CustomAccordion, 'div', 'customAccordion__answer', 'HeaderAnswer', scn);

CustomAccordion.Accordion = ({ items }) => <Accordion items={items} />;

export default CustomAccordion;
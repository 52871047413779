import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
import ReactSelect from "react-select";
// import services from "../../services";
import ZakatSelectCurrency from "./zakatSelectCurrency";
import ShowCurrencies from "../../utils/showCurrencies";
import useHandleAddProduct from "../../utils/handleAddProduct";
import { selectProducts } from "../../store/products";
const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const ZakatWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { defaultProduct, hasSuggestPrice, className, arabic } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => selectProducts(state));
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedRecurringMode, setSelectedRecurringMode] = useState('one-off');
    const [selectedPrice, setSelectedPrice] = useState(100);
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
    const [currentProduct, setCurrentProduct] = useState(defaultProduct);
    const [addProduct] = useHandleAddProduct();
    const suggestedPrices = [
        50, 100, 150
    ]
    const suggestedRecurringModes = [
        { lable: arabic ? 'فردي' : 'Single', value: 'one-off' },
        { lable: arabic ? 'أسبوعي' : 'Weekly', value: 'week' },
        { lable: arabic ? 'شهري' : 'Monthly', value: 'month' }
    ]
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempMakeDonationProducts = [];
        let tempProductsList = [];
        let tempDefaultProduct;
        let tempAlternativeProduct;
        if (arabic) {
            products.map(item => {
                item.sub_categories.map(inner_item => {
                    inner_item.products.map(inner_item_product => {
                        if (inner_item_product.creator === defaultProduct.creator) {
                            tempDefaultProduct = inner_item_product;
                        }
                        if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') { // Morocco Appeal
                            tempMakeDonationProducts.push(inner_item_product);
                            console.log(inner_item_product.id)
                            tempProductsList.push({ value: inner_item_product.id, label: 'فلسطين أكثر ما يحتاج', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') { // Afghanistan Earthquake Appeal
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطبية في فلسطين', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GD-MP001') { // Libya Floods
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'حيث يحتاج الأكثر', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'نداء المغرب', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'فيضانات ليبيا', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-GEN-MER-2023-01-117') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطارئة', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة غذائية', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة المياه', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الأيتام حول العالم', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MSD-2023-01-121') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الصدقة الجارية', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MED-2023-01-122') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'التعليم', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'مشروع النور', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MPB-2023-01-125') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'امشروع البناء', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'SST-GEN-IGN-2023-01-069') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'توليد الدخل', creator: inner_item_product.creator })
                        }
                        // if (inner_item_product.creator === 'MKD-EM-TUR-EQ-01-2023') { // Keep Orphans Warm Where Most Needed
                        //     tempMakeDonationProducts.push(inner_item_product);
                        //     tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في تركيا', creator: inner_item_product.creator })
                        // }
                        if (inner_item_product.creator === 'MKD-EM-SYR-EQ-01-2023') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في سوريا', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'RM24-ZM-013') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'زكاة المال', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'INT-001') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'تنقية الربا', creator: inner_item_product.creator })
                        }
                        if (inner_item.name === 'Make A Donation') {
                            tempAlternativeProduct = inner_item.products[0];
                        }
                        return inner_item_product;
                    });
                    return inner_item;
                })
                return item;
            });

            tempProductsList = tempProductsList.filter(function ({ creator }) {
                return !this.has(creator) && this.add(creator);
            }, new Set())

            console.log(tempProductsList)
        }
        else {
            products.map(item => {
                item.sub_categories.map(inner_item => {
                    inner_item.products.map(inner_item_product => {
                        if (inner_item_product.creator === defaultProduct.creator) {
                            tempDefaultProduct = inner_item_product;
                        }
                        if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') { // Morocco Appeal
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-DIS-AFG-2023-02') { // Afghanistan Earthquake Appeal
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') { // Libya Floods
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                        if (inner_item.name === 'Make A Donation') {
                            if(inner_item_product.creator !== 'MKD-EM-TUR-EQ-01-2023') {
                                tempMakeDonationProducts.push(inner_item_product);
                                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                            }
                            tempAlternativeProduct = inner_item.products[0];
                        }
                        return inner_item_product;
                    });
                    return inner_item;
                })
                return item;
            });
        }
        if (tempDefaultProduct) {
            tempProductsList.unshift({ value: tempDefaultProduct.id, label: tempDefaultProduct.name, creator: tempDefaultProduct.creator });
            setSelectedProduct(tempDefaultProduct);
            setCurrentProduct({ value: tempDefaultProduct.id, label: tempDefaultProduct.name, creator: tempDefaultProduct.creator })
        } else {
            if (tempAlternativeProduct) {
                setSelectedProduct(tempAlternativeProduct);
                setCurrentProduct({ value: tempAlternativeProduct.id, label: tempAlternativeProduct.name, creator: tempAlternativeProduct.creator })
            }
        }
        const uniqueItems = tempProductsList.filter((item, index, self) =>
            index === self.findIndex((t) => t.creator === item.creator)
        );
        setProductsList(uniqueItems);
        setAllProducts(tempMakeDonationProducts)
    };
    const handleCurrency = (currency) => {
        setCurrentCurrency(currency.value)
        // selectedCurrency(currency);
    }
    const handleProduct = (value) => {
        // console.log("product", value, selectedProduct,allProducts);
        allProducts.map(item => item.creator === value.creator && setSelectedProduct(item))
    }
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedRecurringMode,
        })
        addProduct({ product: tempProduct, currency: currentCurrency })
    }

    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD')
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`max-w-[380px] bg-[#E2EFF3] rounded-lg p-3 md:p-5 flex items-center ${className}`}>
            <div className="w-full grid grid-cols-12 gap-4">
                <div className="col-span-12 grid grid-cols-6 gap-2 h-12">
                    {/* <div className="absolute -rotate-12 right-4 -top-8">

                        <span className="font-gotcha text-[18px] text-[#253B7E] rotate-180">Greater Impact</span>
                    </div>
                    <div className="absolute  right-[100px] -top-2">
                        <span><img src={Arrow}/></span>

                    </div> */}
                    {suggestedRecurringModes.map((mode, index) => {
                        return <div key={`modes${index}`} className="col-span-2" style={styles.shadow}>
                            <div
                                className={`h-12 rounded-md text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedRecurringMode === mode.value ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    setSelectedRecurringMode(mode.value);
                                    setProductAmount('');
                                }}
                            >
                                {mode.lable}
                            </div>
                        </div>
                    })}
                </div>
                <div className="col-span-12">
                    <ReactSelect
                        options={productsList}
                        className='max-h-[48px]'
                        defaultValue={currentProduct}
                        isSearchable={false}
                        styles={styles}
                        onChange={(e) => handleProduct(e)}
                    />
                </div>
                {hasSuggestPrice && <div className="col-span-12 grid grid-cols-6 gap-2 h-12">
                    {suggestedPrices.map((price, index) => {
                        return <div key={`prices${index}`} className="col-span-2" style={styles.shadow}>
                            <div
                                className={`h-12 rounded-md text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    setSelectedPrice(price);
                                    setProductAmount('');
                                }}
                            >
                                {ShowCurrencies(currentCurrency, price, false)}<span className={`uppercase ml-1 text-base`}>{currentCurrency}</span>
                            </div>
                        </div>
                    })}
                </div>}
                <div className="col-span-12 flex flex-wrap">
                    <div className="flex justify-center items-center mr-2 bg-white text-[#F60362] rounded-md w-12 h-12" style={styles.shadow}>
                        {currentCurrency}
                    </div>
                    <div className="flex justify-center items-center mr-2 bg-white text-[#F60362] rounded-md w-12 h-12" style={styles.shadow}>
                        <ZakatSelectCurrency onChangeCurrency={(e) => handleCurrency(e)} />
                    </div>
                    <div className="flex justify-center items-center bg-white text-[#F60362] rounded-md w-[calc(100%_-_112px)] h-12 px-2" style={styles.shadow}>
                        <input
                            value={productAmount}
                            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                            type="number"
                            onChange={(e) => {
                                setProductAmount(e.target.value);
                                setSelectedPrice(0);
                            }}
                            placeholder="Enter Amount" />
                    </div>

                </div>
                {/* {show &&
                <div className="col-span-12 flex h-[63px] p-3  bg-white rounded">

                    <div className=" text-[12px] bold"><div>Want to make an even bigger impact?</div>
                        <div className="font-brandingMedium leading-3">Would you like  to make this a monthly donation?</div>
                    </div>
                    <div className="flex justify-between items-center basis-1/4">
                        <button onClick={()=> {setSelectedRecurringMode('month');setProductAmount('');setShow(false)}} className="bg-[#F60362] w-[48px] mr-1 h-[27px] text-white rounded">Yes</button>
                        <button onClick={()=> setShow(false)} className="bg-[#253B7E] w-[48px] h-[27px] text-white rounded">No</button>
                    </div>
                </div>
                }*/}
                <div className="col-span-12">
                    <button
                        className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-[38px] px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                        onClick={(e) => { handleAddNewProduct() }}
                        disabled={!selectedProduct.id}
                    >{arabic ? 'تبرع الان' : 'Donate Now'}</button>
                </div>
                <div className="col-span-12 text-[#6C6B6A] text-center">
                    {arabic ? 'نحن نقبل جميع البطاقات الرئيسية ونظام PayPal ' : 'We accept all major cards and PayPal'}
                </div>
            </div>
        </div>
    );
}

export default ZakatWidget;

const HandleShowRecurring = (text) => {
    switch (text) {
        case 'one-off':
            return 'Single';
        case 'week':
            return 'Weekly';
        case 'month':
            return 'Monthly';
        case 'year':
            return 'Yearly';
        default:
            return 'Single';
    }
}
export default HandleShowRecurring;
import React, { useState, useEffect } from 'react';
import Card from '../../components/card';
import ProductsTable from './list';
import services from '../../../../services';
import HandleProduct from './handleProduct';
import { toast } from 'react-toastify';

const Products = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [allProducts, setAllProducts] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productCategories, setProductCategories] = useState();
  const [productSubCategories, setProductSubCategories] = useState();
  // const [searchedItems, setSearchedItems] = useState();
  const [currentSearchedItems, setCurrentSearchedItems] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchItems, setSearchItems] = useState({
    category: '',
    subCategory: '',
    name: '',
    creator: '',
  });
  const columnsDataProducts = [
    {
      Header: 'Image',
      accessor: 'image_link',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Creator',
      accessor: 'creator',
    },
    {
      Header: 'Category',
      accessor: 'category_name',
    },
    {
      Header: 'Created Date',
      accessor: 'created_at',
    },
    {
      Header: 'Updated Date',
      accessor: 'updated_at',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Actions',
      accessor: 'id',
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [categoryInfo, setCategoryInfo] = useState({
    category_id: 0,
    category_name: '',
    sub_category_id: 0,
    sub_category_name: '',
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getProducts = async () => {
    setIsLoading(true);
    try {
      const response = await services.allProducts();
      // console.log('🚀 ~ file: allAppeals.jsx:13 ~ get-Products ~ response:', response);
      let tempProducts = [];
      // let all_products = [];
      let tempSelectedProducts = [];
      let tempProductCategories = [];
      let tempProductSubCategories = [];
      response.data.map((item, index) => {
        // if (item.name !== 'Ramadan' && item.name !== 'Part Payments') {
        if (item.name !== 'Part Payments') {
          tempProducts.push(item);
        }
        return null;
      });
      // if (selectedProducts) {
      // if (selectedProducts.length) {
      //     tempProducts.map(item => {
      //         if (item.name === currentSearchedItems.category) {
      //             item.sub_categories.map(innerItem => {
      //                 if (innerItem.name === currentSearchedItems.subCategory) {
      //                     tempSelectedProducts = innerItem.products;
      //                 } else {
      //                     tempSelectedProducts = item.sub_categories[0].products;
      //                 }
      //                 return null
      //             })
      //         }
      //         return null
      //     })
      // } else {
      //     tempProducts.map((item, index) => {
      //         tempProductCategories.push({ value: item.name, lable: item.name });
      //         index === 0 && item.sub_categories.map((innerItem, innerIndex) => {
      //             tempProductSubCategories.push({ value: innerItem.name, lable: innerItem.name });
      //             if (innerIndex === 0) tempSelectedProducts = innerItem.products;
      //             return null
      //         });
      //         return null
      //     })
      //     setProductCategories(tempProductCategories);
      //     setProductSubCategories(tempProductSubCategories);
      //     setCurrentSearchedItems({
      //         category: tempProducts[0].name,
      //         subCategory: tempProducts[0].sub_categories[0].name
      //         // category: tempProductCategories[0].name,
      //         // subCategory: tempProductSubCategories[0].name
      //     })
      //     console.log("on get products=", tempProducts, tempProductCategories, tempProductCategories[0].name, tempProductSubCategories[0].name)
      //     setCategoryInfo({
      //         ...categoryInfo,
      //         category_id: tempProducts[0].sub_categories[0].category_id,
      //         sub_category_id: tempProducts[0].sub_categories[0].id,
      //         name: tempProducts[0].sub_categories[0].name
      //     })
      // }
      tempProducts.map((item, index) => {
        tempProductCategories.push({ value: item.name, lable: item.name, id: item.id });
        index === 0 &&
          item.sub_categories.map((innerItem, innerIndex) => {
            tempProductSubCategories.push({ value: innerItem.name, lable: innerItem.name, id: innerItem.id, category_id: innerItem.category_id });
            if (innerIndex === 0) {
              tempSelectedProducts = innerItem.products.map(_item => {
                _item = Object.assign({}, _item, {
                  category_name: innerItem.name,
                });
                return _item;
              });
            }
            return null;
          });
        return null;
      });
      setProductCategories(tempProductCategories);
      setProductSubCategories(tempProductSubCategories);
      // setCurrentSearchedItems({
      //     ...currentSearchedItems,
      //     category: tempProducts[0].name,
      //     subCategory: tempProducts[0].sub_categories[0].name
      //     // category: tempProductCategories[0].name,
      //     // subCategory: tempProductSubCategories[0].name
      // })
      setCurrentSearchedItems({ ...currentSearchedItems, category: tempProducts[0].name, subCategory: tempProducts[0].sub_categories[0].name });
      // console.log(
      //   'on get products=',
      //   currentSearchedItems,
      //   tempProducts,
      //   tempProductCategories,
      //   tempProductSubCategories,
      //   tempProductCategories[0].lable,
      //   tempProductSubCategories[0].lable,
      // );
      setCategoryInfo({
        ...categoryInfo,
        category_id: tempProducts[0].id,
        category_name: tempProducts[0].name,
        sub_category_id: tempProducts[0].sub_categories[0].id,
        sub_category_name: tempProducts[0].sub_categories[0].name,
      });
      // tempProducts.map(item => {
      //     item.sub_categories.map(inner_item => {
      //         inner_item.products.map(inner_item_product => {
      //             all_products.push(inner_item_product);
      //             return inner_item_product;
      //         });
      //         return inner_item;
      //     })
      //     return item;
      // });
      // setAllProducts(tempProducts);
      // console.log("tempSelectedProducts", tempSelectedProducts)
      setSelectedProducts(tempSelectedProducts);
      setAllProducts(tempProducts);
      // setSelectedProducts(all_products);
      // console.log("set-ProductSubCategories=", productSubCategories, tempProductSubCategories, selectedProducts, tempSelectedProducts)
      // console.log("response.data[0]", currentSearchedItems, productCategories, productSubCategories, categoryInfo, response.data[0])
      setIsLoading(false);
      // console.log("setAllProducts", tempProducts);
      // console.log("set-SelectedProducts", tempSelectedProducts);
      // console.log("setProductCategories", tempProductCategories);
      // console.log("set-ProductSubCategories", tempProductSubCategories);
    } catch (error) {
      setIsLoading(false);
      console.error('error========', error);
    }
  };
  const handleSearchedCategory = async searchs => {
    let tempProductSubCategories = [];
    let tempSelectedProducts = [];
    // console.log("allProducts in search", allProducts, currentSearchedItems, searchs);
    if (searchs.name || searchs.creator) {
      handleSearchProduct(searchs);
    } else {
      let _searches = searchs;
      if (!searchs.category && !searchs.subCategory) {
        _searches = currentSearchedItems;
      }
      if (_searches.subCategory) {
        // console.log("sub category has searched in search", currentSearchedItems, _searches.subCategory, allProducts)
        allProducts.map(item => {
          item.name === currentSearchedItems.category &&
            item.sub_categories.map(innerItem => {
              if (innerItem.name === _searches.subCategory) {
                tempSelectedProducts = innerItem.products.map(_item => {
                  _item = Object.assign({}, _item, {
                    category_name: innerItem.name,
                  });
                  return _item;
                });
                setCategoryInfo({
                  ...categoryInfo,
                  category_id: innerItem.category_id,
                  category_name: item.name,
                  sub_category_id: innerItem.id,
                  sub_category_name: innerItem.name,
                });
              }
              return null;
            });
          return null;
        });
        // console.log("sub category has searched in search", tempSelectedProducts)
        // set-ProductSubCategories(tempProductSubCategories);
        setCurrentSearchedItems({ ...currentSearchedItems, subCategory: _searches.subCategory });
      } else {
        console.log('category has searched in search', searchs.category);
        allProducts.map(item => {
          item.name === searchs.category &&
            item.sub_categories.length &&
            item.sub_categories.map((innerItem, innerIndex) => {
              console.log('category has searched in search=', searchs.category);
              tempProductSubCategories.push({ value: innerItem.name, lable: innerItem.name });
              if (innerIndex === 0 && innerItem.products.length) {
                // tempSelectedProducts = innerItem.products
                tempSelectedProducts = innerItem.products.map(_item => {
                  _item = Object.assign({}, _item, {
                    category_name: innerItem.name,
                  });
                  return _item;
                });
                setCategoryInfo({
                  ...categoryInfo,
                  category_id: innerItem.category_id,
                  category_name: item.name,
                  sub_category_id: innerItem.id,
                  sub_category_name: innerItem.name,
                });
              }
              return null;
            });
          return null;
        });
        // console.log("category has searched in search-setProductSubCategories=", tempSelectedProducts, tempProductSubCategories)
        // tempProductSubCategories.length && setProductSubCategories(tempProductSubCategories);
        setProductSubCategories(tempProductSubCategories);
        setCurrentSearchedItems({
          ...currentSearchedItems,
          category: searchs.category,
          subCategory: tempProductSubCategories[0] ? tempProductSubCategories[0].lable : '',
        });
        // setCurrentSearchedItems({ ...currentSearchedItems, category: searchs.category, subCategory: tempProductSubCategories[0].name });

        // setCategoryInfo({...categoryInfo,
        //     category_id: tempProductSubCategories[0].category_id,
        //     sub_category_id: tempProductSubCategories[0].id,
        //     name: tempProductSubCategories[0].name
        // })
        // console.log("tempProductSubCategories", tempProductSubCategories)
      }
      // console.log("handleSearchedCategory tempSelectedProducts", tempSelectedProducts)
      // tempSelectedProducts.length ? setSelectedProducts(tempSelectedProducts) : setSelectedProducts([]);
      setSelectedProducts(tempSelectedProducts);
    }
    // console.log("selected products in search", tempSelectedProducts)
  };
  const handleSearchProduct = searches => {
    // console.log("on search name=", searches, selectedProducts, categoryInfo, allProducts)
    let searchResult = [];
    if (searches.name && searches.name.length > 1) {
      allProducts.map(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (inner_item_product.name.toLowerCase().includes(searches.name.toLowerCase())) {
              // let _tempPrd = inner_item_product;
              inner_item_product = Object.assign({}, inner_item_product, {
                category_name: inner_item.name,
              });
              searchResult.push(inner_item_product);
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
      // selectedProducts.length
      //     ? setSelectedProducts(searchResult)
      //     : allProducts.map(item => {
      //         item.sub_categories.map(inner_item => {
      //             if (inner_item.name === categoryInfo.name) {
      //                 // searchResult = inner_item.products
      //                 setSelectedProducts(inner_item.products)
      //             }
      //             return inner_item;
      //         })
      //         return item;
      //     })
    } else if (searches.creator && searches.creator.length > 1) {
      allProducts.map(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (inner_item_product.creator.toLowerCase().includes(searches.creator.toLowerCase())) {
              inner_item_product = Object.assign({}, inner_item_product, {
                category_name: inner_item.name,
              });
              searchResult.push(inner_item_product);
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
    } else {
      allProducts.map(item => {
        item.sub_categories.map(inner_item => {
          if (inner_item.name === categoryInfo.sub_category_name) {
            searchResult = inner_item.products;
          }
          return inner_item;
        });
        return item;
      });
    }
    searchResult.length ? setSelectedProducts(searchResult) : toast.error(`Didn't find ${searches.name ? searches.name : searches.creator}!`);
  };
  const handleUpdateProduct = productId => {
    // console.log("product id", productId, selectedProducts);
    selectedProducts.map(item => item.id === productId && setSelectedProduct(item));
    // allProducts.map(item => item.id === productId && setSelectedProduct(item));
    setShowModal(true);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    getProducts();
    const handleContextmenu = e => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExportCSV = async () => {
    try {
      const response = await services.exportToCSV();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'products.csv'); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Failed to export CSV', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Card
        title="Products List"
        className={'w-full h-full sm:overflow-auto px-2 md:px-4 2xl:px-6'}
        btnClassName='flex flex-col md:flex-row'
        btn={
          <div className="flex">
            <button
              className="bg-[#0f9621] hover:bg-[#0a7418] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200"
              onClick={() => setShowModal(true)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
              </svg>
              Add
            </button>
            <button
              className="bg-[#0f9621] hover:bg-[#0a7418] text-white ml-2 px-8 h-10 flex items-center justify-center rounded transition-all duration-200"
              onClick={handleExportCSV}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v12m0 0l-4-4m4 4l4-4m-4 4V4m-8 16h16" />
              </svg>
              Export CSV
            </button>
          </div>
        }
      >
        {allProducts && !isLoading ? (
          <ProductsTable
            columnsData={columnsDataProducts}
            // tableData={selectedProducts}
            tableData={selectedProducts}
            pageSize={selectedProducts.length}
            categories={productCategories}
            subCategories={productSubCategories}
            isLoading={isLoading}
            currentSearches={currentSearchedItems}
            searchItems={searchItems}
            onSearchItems={e => {
              // setSearchedItems(e);
              handleSearchedCategory(e);
            }}
            onUpdateProduct={e => handleUpdateProduct(e)}
          />
        ) : (
          <></>
        )}
      </Card>
      {showModal && (
        <HandleProduct
          show={showModal}
          currentCategoryInfo={categoryInfo}
          selectedProduct={selectedProduct}
          categories={productCategories}
          currentSubCategories={productSubCategories}
          allProducts={allProducts}
          searchItems={searchItems}
          onSearchItems={e => {
            // setSearchedItems(e);
          }}
          onClose={() => {
            setShowModal(false);
            setSelectedProduct(null);
          }}
          onSaved={() => {
            getProducts();
            setSearchItems({ ...searchItems, category: '', subCategory: '', name: '', creator: '' });
            // setSearchItems({ ...searchItems, category: '', subCategory: '', name: '' })
          }}
        ></HandleProduct>
      )}
    </>
  );
};
export default Products;

import axios from "axios";

const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_API_HEADER;
let config = {
  headers: {
    "X-user-matw": Header
  },
};
function createCryptoOrder(data) {
  return axios.post(`${Base}/crypto/create-order`, data,config);
}
function getCryptoReceipt(request) {
  return axios.get(`${Base}/crypto/get-order?${request}`,config);
}
function saveWalletAddress(data) {
  return axios.post(`${Base}/crypto/save-wallet-address`, data, config);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createCryptoOrder,
  getCryptoReceipt,
  saveWalletAddress
};

import axios from 'axios';

const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_API_HEADER;

let config = {
  headers: {
    'X-user-matw': Header,
    Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
  },
};

function getAllMenus() {
  console.log(Base);

  return axios.get(`${Base}/menus`, config);
}

function getMegaMenuSearchSection(){
  return axios.get(`${Base}/menus?section=search&domain=matwproject.org`, config)
}
function getAdminAllMenus() {
  return axios.get(`${Base}/admin-dashboard/menus`, config);
}
function createMenu(data) {
  let config = {
    headers: {
      'accept': 'application/json',
      'Authorization': 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      "X-user-matw": Header
    },
  };
  return axios.post(`${Base}/admin-dashboard/menus`, data, config);
}
function createHighlightMenu(data, file) {
  let config = {
    headers: {
      accept: 'application/json',
      'content-type': file.type,
      'content-length': `${file.size}`,
      'X-user-matw': Header,
    },
  };
  return axios.post(`${Base}/admin-dashboard/menus`, data, config);
}
function updateHighlightMenu(id, data, file) {
  let config = {
    headers: {
      accept: 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      'X-user-matw': Header,
    },
  };
  return axios.post(`${Base}/admin-dashboard/menus/${id}?_method=patch`, data, config);
}
function updateMenu(id, data) {
  let config = {
    headers: {
      'accept': 'application/json',
      'Authorization': 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      "X-user-matw": Header
    },
  };
  return axios.put(`${Base}/admin-dashboard/menus/${id}`, data, config);
}
function deleteMenu(id) {
  let config = {
    headers: {
      'accept': 'application/json',
      'Authorization': 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      "X-user-matw": Header
    },
  };
  return axios.delete(`${Base}/admin-dashboard/menus/${id}`, config);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllMenus,
  getAdminAllMenus,
  updateMenu,
  createMenu,
  deleteMenu,
  createHighlightMenu,
  updateHighlightMenu,
  getMegaMenuSearchSection
};

import { useEffect, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import CustomButton from '../../../components/general/button';
import getSymbolFromCurrency from 'currency-symbol-map';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const AppealGivingProductBox = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const { product, currency, onAddQty, onReduceQty, onAddToGiving } = props;
  const [showMore, setShowMore] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(product);
  const [isAnyAmount, setIsAnyAmount] = useState(false);
  const descriptionLimit = 110;
  const description = currentProduct.short_description || currentProduct.description || '';
  const isLongDescription = description.length > descriptionLimit;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleCustomProductAmount = product => {
    product.creator === 'MA-PAL-GL-1-006' && console.log("product=",product)
    if (Number(product['aud']) < 2 && (!product.quantity || product.quantity < 2)) {
      setIsAnyAmount(true);
    } else {
      setIsAnyAmount(false);
    }
  };
  const shortenString = str => {
    if (str.length > descriptionLimit) {
      const trimmedStr = str.slice(0, descriptionLimit);
      return `${trimmedStr.trim()} ...`;
    }
    return str;
  };
  const ShowCurrencies2 = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    product && handleCustomProductAmount(product);
    product && setCurrentProduct(product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full rounded-xl shadow-lg bg-white overflow-hidden mx-auto p-2 md:p-3 md:min-w-[350px]- border border-stone-300">
      <div className="w-full flex justify-between items-center">
        <div className="text-lg md:text-2xl text-LightBlue font-bold">
          {isAnyAmount ? 'Any Amount' : product[currency.toLowerCase()] && ShowCurrencies2(currency, product[currency.toLowerCase()] * (product.quantity || 1), true)}
          {!isAnyAmount && <small className={`uppercase text-xs ml-0.5`}>{currency}</small>}
          {/* {isAnyAmount ? 'Any Amount' : <div>{product[currency.toLowerCase()]} <small className={`uppercase`}>{currency}</small></div>} */}
        </div>
        {/* {isAnyAmount
                ? <div className='text-lg md:text-2xl text-LightBlue font-bold'>Any Amount</div>
                : <div className='text-lg md:text-2xl text-LightBlue font-bold'>{ShowCurrencies(currency, product[currency.toLowerCase()], true)}</div>
                } */}
        <span className="text-[#AAAAAA] text-sm">{currentProduct ? `${currentProduct.total_count} Claimed` : <Skeleton height={48} />}</span>
      </div>
      <h3 className="w-full text-DarkerBlue lg:text-lg font-medium h-[34px] sm:h-[32px] lg:h-[48px] my-2 flex items-center">
        {currentProduct ? currentProduct.name : <Skeleton height={48} />}
      </h3>
      <div className="w-full relative">
        {currentProduct ? (
          <>
            <div className={`relative overflow-hidden`}>
              <p
                ref={ref}
                onClick={() => setShowMore(!showMore)}
                className={`text-[#78716C] text-xs sm:text-sm overflow-hidden transition-all duration-200 ${
                  isLongDescription ? 'min-h-[48px]' : 'min-h-[72px] sm:min-h-[84px]'
                }`}
              >
                {showMore ? description : shortenString(description)}
              </p>
            </div>
            {isLongDescription && (
              <ChevronDownIcon
                onClick={() => setShowMore(!showMore)}
                className={`w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer mx-auto
                  ${showMore ? 'transform rotate-180' : ''}
                    `}
              />
            )}
          </>
        ) : (
          <Skeleton count={4} />
        )}
      </div>
      <div className="w-full flex items-center mt-1">
        {currentProduct ? (
          currentProduct.isAdded ? (
            <div className="flex h-10 items-center justify-between w-full">
              <span className="text-green-700 italic text-xs">Item Added...</span>
              <div className="flex items-center">
                <span className="mr-5 text-[22px] ">{currentProduct.quantity}</span>
                <div className="flex flex-col w-5 mr-3">
                  <div className="w-8 flex cursor-pointer text-[#00a3da] pr-1" onClick={() => onAddQty()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                      <title>ctrl-up</title>
                      <g fill="#78c8ff">
                        <path
                          d="M31.408,20.225,16.305,8.6a.5.5,0,0,0-.61,0L.592,20.225a.5.5,0,0,0-.092.7L2.329,23.3a.5.5,0,0,0,.7.092L16,13.419,28.97,23.4a.5.5,0,0,0,.3.1.473.473,0,0,0,.065,0,.5.5,0,0,0,.332-.191L31.5,20.926A.5.5,0,0,0,31.408,20.225Z"
                          fill="#00a3da"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className="w-8 flex cursor-pointer text-[#00a3da] pr-1" onClick={() => onReduceQty()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                      <title>ctrl-down</title>
                      <g fill="#78c8ff">
                        <path
                          d="M29.671,8.7a.5.5,0,0,0-.332-.191.51.51,0,0,0-.369.1L16,18.581,3.03,8.6a.5.5,0,0,0-.7.092L.5,11.074a.5.5,0,0,0,.092.7L15.7,23.4a.5.5,0,0,0,.61,0l15.1-11.621a.5.5,0,0,0,.092-.7Z"
                          fill="#00a3da"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CustomButton
              onClick={() => onAddToGiving(isAnyAmount ? 50 : product.quantity || 1)}
              type={'button'}
              title={'Add to Giving'}
              className={`rounded-full !h-10`}
            />
          )
        ) : (
          <Skeleton height={40} />
        )}
      </div>
    </div>
  );
};

export default AppealGivingProductBox;

import { useEffect, useState } from 'react';
import { sustainableFaqs } from './faqs';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Loader } from '../../components/general';
import services from '../../services';
import Accordionn from '../../components/general/accordion';
import SadaqahWidget from './sadaqahWidget';
import FundraiserProductBox from '../../components/general/fundraiserProductBox';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
const SustainableLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [sustainalbeProducts, setSustainableProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [futureProducts, setFutureProducts] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getFundraiserProducts = async () => {
    let tempFutureProducts = [];
    try {
      const response = await services.fundraiserProducts();
      response.data.map(item => {
        item.raised = 0;
        if (item.name === 'Yemen Water Plant' || item.name === 'Palestine Water Plant') {
          item.goal = 200000;
          tempFutureProducts.push(item);
        }
        return item;
      });
      getProductsReports(tempFutureProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const getProductsReports = async tempFutureProducts => {
    try {
      const response = await services.orphanProductTransactions();
      response.data.length &&
        response.data.map((item, i) => {
          tempFutureProducts.map(future_item => {
            if (future_item.name === item.product_name) {
              future_item.raised = item.total_sales;
            }
            return future_item;
          });
          return null;
        });
      // Object.keys(response.data.data).length && Object.keys(response.data.data).map((keyName, i) => {
      //     tempFutureProducts.map(future_item => {
      //         if (future_item.name === keyName) {
      //             future_item.raised = response.data.data[keyName]
      //         }
      //         return future_item
      //     })
      //     return null;
      // })
      setFutureProducts(tempFutureProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const getProductsByName = async name => {
    let tempProducts = [];
    try {
      const response = await services.categoryProductsByName(name);
      response.data.products.map(item => {
        if (item.name === 'Olive Tree' && Number(item.status) === 1) {
          tempProducts[0] = item;
        }
        return item;
      });
      setSustainableProducts(tempProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // window.scroll(0, 0);
    getProductsByName('sustainable');
    getFundraiserProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <NewHelmetLayout className="!bg-white">
      {seo && (
        <Helmet>
          <title>{`${seo['sadaqahJariyah']['sustainable']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo['sadaqahJariyah']['sustainable']['description']}`} />
        </Helmet>
      )}
      <div className="w-full flex flex-wrap max-[380px]:min-h-[135px] min-[381px]:min-h-[165px] min-[501px]:min-h-[205px] sm:min-h-[260px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] px-4 md:px-0">
        <div className="w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
          <iframe
            className="video"
            width={'100%'}
            height={'100%'}
            title={'youtube'}
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://youtube.com/embed/jzAZ03Qgf7k?autoplay=0`}
          ></iframe>
        </div>
      </div>

      {/* /* ------------------------------- Widget ----------------------------------- */}
      <div className="w-full lg:container lg:mx-auto px-4 xl:px-2 py-4 md:py-6 flex justify-center items-center">
        <SadaqahWidget
          extraProduct={sustainalbeProducts[0]}
          defaultProduct={{
            creator: 'MKD-GEN-MIG-2023-01-126',
            label: 'Income Generation',
            value: 111,
          }}
          // defaultProduct={{
          //     creator: sustainalbeProducts.creator,
          //     label: sustainalbeProducts.name,
          //     value: sustainalbeProducts.id
          // }}
        />
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ------------------- Section One - Texts ---------------------------------- */}
      <div className="w-full flex flex-wrap justify-center md:container md:mx-auto px-4 py-4 md:py-8">
        <div className="basis-full flex flex-col justify-center mb-8 md:mb-16">
          <div className="w-full md:container flex justify-center">
            <div className="flex items-start mr-2 md:mr-4 w-[48px]">
              <svg className="rotate-180" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 186.15">
                <path
                  d="M242.5,87.53c-1.52,7.1-2.55,14.35-4.63,21.27-10.7,35.68-35.15,57.79-69.71,69.59-5,1.71-10.16,2.82-15.56,4.28l-9.89-21.3c24.63-11.76,37.24-31.54,40.19-58.79H142.83V2.5H242.5Z"
                  transform="translate(0 0)"
                  fill="none"
                  stroke="#00a3da"
                  strokeWidth={4}
                />
                <path
                  d="M2.5,2.5h99.14c.39.75.67,1,.66,1.31-.22,28.16,1,56.43-1,84.46-3,41-25.34,69.46-62.62,86.09C30.52,178,21.75,180.14,13,183.08L2.5,161.56v-1a16.11,16.11,0,0,0,2.8-.76c18.55-8.7,29.5-23.71,35.12-42.94,1.31-4.48,1.63-9.25,2.48-14.32H2.5Z"
                  transform="translate(0 0)"
                  fill="none"
                  stroke="#00a3da"
                  strokeWidth={4}
                />
              </svg>
            </div>
            <div className="flex w-[80%] md:w-[60%] xl:w-[40%]">
              <p className="text-center text-lg md:text-4xl text-[#00a3da]">
                <span className="font-['Gotcha'] font-medium text-[#253b7e] mx-2 md:mx-4">Sadaqah Jariyah</span>
                is a means of building sustainable communities and leaving a positive legacy for future generations.
                <span className="font-['Abeezee'] text-[#253b7e] mx-2 md:mx-4 text-base md:text-lg">~ Mufti Ismail Menk</span>
              </p>
            </div>
            <div className="flex items-end ml-2 md:ml-4 w-12">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 186.15">
                <path
                  d="M242.5,87.53c-1.52,7.1-2.55,14.35-4.63,21.27-10.7,35.68-35.15,57.79-69.71,69.59-5,1.71-10.16,2.82-15.56,4.28l-9.89-21.3c24.63-11.76,37.24-31.54,40.19-58.79H142.83V2.5H242.5Z"
                  transform="translate(0 0)"
                  fill="none"
                  stroke="#00a3da"
                  strokeWidth={4}
                />
                <path
                  d="M2.5,2.5h99.14c.39.75.67,1,.66,1.31-.22,28.16,1,56.43-1,84.46-3,41-25.34,69.46-62.62,86.09C30.52,178,21.75,180.14,13,183.08L2.5,161.56v-1a16.11,16.11,0,0,0,2.8-.76c18.55-8.7,29.5-23.71,35.12-42.94,1.31-4.48,1.63-9.25,2.48-14.32H2.5Z"
                  transform="translate(0 0)"
                  fill="none"
                  stroke="#00a3da"
                  strokeWidth={4}
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ------------------- Section Two - Second Widget -------------------------- */}
      <div className="w-full pt-4 bg-[#e2f0f3] flex flex-wrap  bg-center sm:bg-center bg-cover max-[500px]:min-h-[50px] min-[501px]:min-h-[80px] md:min-h-[320px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] px-4 md:px-0">
        <div className="w-full flex flex-wrap justify-center md:container md:mx-auto">
          <div className="flex flex-col basis-full items-center text-center bg-[#f60362] text-white mt-12 p-4 md:p-8">
            <h1 className="text-2xl md:text-5xl text-center font-medium mb-2 md:mb-4">MATW’s commitment to Sustainability</h1>
            <p className="w-full md:w-2/3 text-center my-3 md:my-6 text-lg md:text-2xl">
              MATW is dedicated to ensuring that those in need are given the chance to break free of cyclical poverty through income generation and
              livelihood programs for themselves and for generations to come.
              <br />
              Each of our initiatives is designed to provide the best possible solution within the regions we work, teaching people the necessary
              skills that can help them earn an income or setting up businesses that can support them and others.
              <br />
              Help us create a foundation for sustainability for those who need it most and break the cycle of poverty. Donate Now.
            </p>
          </div>
          <div className="basis-full flex justify-center bg-white p-4 md:p-8">
            <SadaqahWidget
              defaultProduct={{
                creator: 'MKD-GEN-MIG-2023-01-126',
                label: 'Income Generation',
                value: 111,
              }}
            />
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ------------------- Section Two - Products ------------------------------- */}
      <div className="w-full px-4 py-4 md:py-8 bg-[#e2f0f3]">
        <div className="w-full flex flex-wrap justify-center md:container md:mx-auto">
          <div className="flex w-full justify-center">
            <h1 className="text-2xl md:text-5xl w-full md:w-9/12 text-[#253B7E] text-center font-medium mb-2 md:mb-4">
              <strong className="text-[#f60362]">648 million</strong> people in the world live in extreme poverty with no stable source of income.
              They suffer hunger, lack of <strong className="text-[#f60362]">healthcare</strong> and lack of{' '}
              <strong className="text-[#f60362]">education.</strong>
            </h1>
          </div>
          {futureProducts ? (
            <div className="flex flex-wrap justify-center mt-4 md:mt-6">
              {futureProducts.map((item, index) => {
                return (
                  <div key={`fundrising${index}`} className="basis-full min-[400px]:basis-1/2 md:basis-1/3 xl:basis-1/4 p-2 overflow-hidden">
                    <FundraiserProductBox
                      goal={item.goal}
                      subTitle="Orphan Education"
                      product={item}
                      currency={selectedCurrency ? selectedCurrency : 'AUD'}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <Loader />
          )}
          <div className="basis-full flex justify-center">
            <p className="w-full md:w-2/3 text-center my-3 md:my-6 text-lg md:text-xl">
              Wouldn't it be better if, through our charity, we didn't just provide one-time help but left behind an establishment that had a lasting
              effect? Sadaqah Jariyah is all about an impact that lasts, and we can create just that by providing sustainability to the people in
              need. MATW aims to provide opportunities for employment and improved livelihoods to these underprivileged communities. You can
              contribute to these sustainable projects and earn sustained rewards for them.
            </p>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
      <div className="w-full h-auto flex flex-col justify-center items-center bg-[#E2EFF3]- py-16">
        <h2 className="text-2xl md:text-4xl font-medium text-center mt-8 mb-4 text-[#253B7E]">
          Sustainable donations <strong className="text-[#f60362]">FAQs</strong>
        </h2>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-2 md:px-0 mt-6">
          {sustainableFaqs.map((item, index) => {
            return (
              <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                <Accordionn key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                  {item.answer}
                </Accordionn>
              </div>
            );
          })}
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};

export default SustainableLandingPage;
import React from 'react';

// Admin Imports
// import Reports from "views/admin/reports";
// import QurbanReports from "views/admin/reportsQurban";
// import ZohoReports from "views/admin/zoho";

// Auth Imports
// import SignIn from "views/auth/SignIn";

// Icon Imports
import { MdHome, MdLogout } from 'react-icons/md';
// import BannersPage from './pages/banners';
// import ProductsPage from './pages/products';
import SubscriptionsPage from './pages/subscriptions';
// import Receipts from './pages/receipts';

const routes = [
  {
    name: 'Donor Dashboard',
    layout: '/',
    path: '/donor',
    icon: <MdHome className="h-6 w-6" />,
    component: <SubscriptionsPage />,
  },
  {
    name: 'Subscriptions',
    layout: '/',
    path: '/donor/subscriptions',
    icon: <MdHome className="h-6 w-6" />,
    component: <SubscriptionsPage />,
    secondary: true,
  },
  /*{
    name: 'Products',
    layout: '/',
    path: '/dashboard/products',
    icon: <MdBarChart className="h-6 w-6" />,
    component: <ProductsPage />,
    secondary: true,
  },*/
  // {
  //   name: 'Receipts',
  //   layout: '/',
  //   path: '/donor/receipts',
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   component: <Receipts />,
  //   secondary: true,
  // },
  {
    name: 'Log Out',
    layout: '/',
    path: '/',
    icon: <MdLogout className="h-6 w-6" />,
    component: '',
  },
  // {
  //   name: "Qurban",
  //   layout: "/",
  //   path: "qurban",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   component: <QurbanReports />,
  //   secondary: true,
  // },
  // {
  //   name: "Qurban List",
  //   layout: "/",
  //   path: "list",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   component: <QurbanReports />,
  //   secondary: true,
  // },
  // {
  //   name: "Zoho",
  //   layout: "/",
  //   path: "zoho",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   component: <ZohoReports />,
  //   secondary: true,
  // },
];
export default routes;

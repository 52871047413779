const winterFaqs = [
  {
    id: 0,
    question: 'Why is providing relief during winter important?',
    answer: `Winter relief is crucial because extreme cold weather poses serious health risks, especially to vulnerable populations. Providing adequate relief helps protect individuals from cold-related illnesses, offers warmth, and ensures their well-being.`,
  },
  {
    id: 1,
    question: 'What is provided as part of winter relief?',
    answer: `Winter relief typically includes food, shelter, warm clothing, blankets, heating materials, winter kits for emergency locations, and other essential supplies to help people endure cold temperatures.`,
  },
  {
    id: 2,
    question: 'Will relief be reaching those affected by recent earthquakes?',
    answer: `Yes, our efforts extend to areas affected by recent earthquakes, ensuring that winter relief reaches those facing additional challenges.`,
  },
  {
    id: 3,
    question: 'Is the relief the same in each country?',
    answer: `While the core components remain consistent, aid packages may be adapted based on specific regional needs and climate conditions.`,
  },
  {
    id: 4,
    question: 'Can I donate my zakat to the winter program? (Specify which products are eligible for zakat)',
    answer: `Yes, you can donate your zakat to the winter program. Zakat-eligible products may include warm clothing, blankets, and other essentials that meet the criteria for zakat.`,
  },
  {
    id: 5,
    question: 'Who will be receiving the relief? (beneficiaries targeted)',
    answer: `The relief is targeted towards vulnerable populations, including refugees, displaced persons, and communities facing economic hardships, ensuring it reaches those most in need.`,
  },
  {
    id: 6,
    question: 'What are the living conditions of those requiring winter relief?',
    answer: `Those requiring winter relief often live in precarious conditions, lacking proper shelter and the resources needed to cope with the harsh winter weather.`,
  },
];
const faqs = [
  {
    question: "Why is providing relief during winter important?",
    answers: "Winter relief is crucial because extreme cold weather poses serious health risks, especially for vulnerable populations. Adequate aid helps protect individuals from cold-related illnesses, provides warmth, and ensures their well-being."
  },
  {
    question: "What is provided as part of winter aid?",
    answers: "Winter aid typically includes food, shelter, warm clothing, blankets, heating materials, winter kit for emergency location and other essential supplies to help people withstand cold temperatures."
  },
  {
    question: "Where are you distributing winter relief?",
    answers: `We are distributing winter relief in areas with harsh winter conditions, focusing on regions where vulnerable communities are at risk.
    • Middle East (Palestine/Gaza, Lebanon, Jordon, Yemen) • Africa (Libya, Morocco) • South East Asia (Afghanistan, Pakistan, Bangladesh)`
  },
  {
    question: `Will you be distributing winter relief in Palestine?`,
    answers: `Yes, we are actively working to provide winter relief in Palestine to support those in need during the colder months.`
  },
  {
    question: `Will aid be reaching those affected by recent earthquakes?`,
    answers: `Yes, our efforts extend to areas affected by recent earthquakes, ensuring that winter aid reaches those facing additional challenges.`
  },
  {
    question: `Is the aid the same in each country?`,
    answers: `While the core components remain consistent, aid packages may be adapted based on specific regional needs and climate conditions.`
  },
  {
    question: `Can I donate my zakat towards the winter program? (specify products which are zakat eligible)`,
    answers: `Yes, you can donate your zakat towards the winter program. Zakat-eligible products may include warm clothing, blankets, and other essentials that fulfill the criteria of zakat.`
  },
  {
    question: `Who will be receiving the aid? (beneficiaries targeted)`,
    answers: `The aid is targeted towards vulnerable populations, including refugees, displaced persons, and communities facing economic hardships, ensuring it reaches those most in need.`
  },
  {
    question: `What is the living condition of those requiring winter relief?`,
    answers: `Those requiring winter relief often live in precarious conditions, lacking proper shelter and resources to cope with the harsh winter weather.`
  },
  {
    question: `How can I help more?`,
    answers: `You can help by making a donation, spreading awareness (follow and share MATW social media page), or volunteering. Your support contributes to providing essential relief to those in need.`
  },
  {
    question: `Can I volunteer in the field?`,
    answers: `Yes, MATW welcome volunteers. You can register yourself as a volunteer from MATW website, please check the below mentioned link for more details
    https://matwproject.org/Volunteer/`
  },

];
export { winterFaqs, faqs };

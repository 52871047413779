import React from 'react';
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const AccordionLayoutt = ({ title, children, index, activeIndex, level, noBorder, setActiveIndex ,className, lang = 'en'}) => {
    const handleSetIndex = (index) => {
        (activeIndex !== index) ? setActiveIndex(index) : setActiveIndex(-1);
    }
    // console.log("active, index", activeIndex, index, children)
    const RawHTML = ({children, className = ""}) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '')}} />
    return (
        <>
            <div onClick={() => handleSetIndex(index)} className={`flex w-full justify-between rounded ${level === 1 ? 'p-2 mt-2' : 'px-4 py-2'} cursor-pointer ${noBorder ? '' : 'border-b border-stone-200'}`}>
                <div className='flex'>
                    <div className={`text-xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} ${level === 1 ? activeIndex === index ? 'text-[#00a3da]' : 'text-[#777]' : activeIndex === index ? 'text-[#00a3da] font-normal' : 'text-[#888] font-normal'} ${className && className}`}>{noBorder ? '' : activeIndex === index ? '-' : '+'} {noBorder ? '' : `${index + 1}.`} {title}</div>
                </div>
                <div className="flex items-center justify-center">
                    <ChevronDownIcon className={`w-4 h-4 text-stone-600 transition-all duration-300 ${activeIndex === index ? ' transform rotate-180' : ''}`} />
                </div>
            </div>
            <div className={`shadow-3xl w-full rounded-md shadow-cyan-500/50 bg-stone-50 transition-all duration-200 ease-in-out px-3 sm:px-6 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} ${activeIndex === index ? ' h-auto py-4 mb-6' : 'h-0 overflow-hidden'}`}>
                {/* {children} */}
                <RawHTML>{children}</RawHTML>
            </div>
        </>
    );
};


// const AccordionLayoutt = ({ title, children, index, activeIndex, level, setActiveIndex }) => {
//     // const handleSetIndex = (index) => {
//     //     (activeIndex !== index) ? setActiveIndex(index) : setActiveIndex(-1);
//     // }
//     // console.log("active, index", activeIndex, index)
//     const handleLink = (link) => {
//         return link.replace(/\s/g, '_')
//     }
//     return (
//         <>
//             <div className={`flex w-full justify-between rounded  cursor-pointer border-b border-stone-200`}>
//                 <div className='flex'>
//                     {level > 1
//                     ? <h2 className={`text-[#00a3da] font-medium h-10`}>{title}</h2>
//                     : <h1 id={handleLink(title)} className={`text-[#00a3da] font-bold h-10`}>{title}</h1>
//                 }
//                 </div>
//             </div>
//             <div className={`shadow-3xl w-full rounded-md shadow-cyan-500/50 bg-stone-50 transition-all duration-200 ease-in-out pt-4`}>
//                 {children}
//             </div>
//         </>
//     );
// };

export default AccordionLayoutt;
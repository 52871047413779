import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import services from '../../../../services';
import CustomModal from '../../components/modal';
import { Formik } from 'formik';
import * as Yup from 'yup';

const HandleCategory = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { show, onClose, onSaved, selectedCategoryItem, currentParentId } = props;
    const fileRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    // const [status, setStatus] = useState(false);
    const [file, setFile] = useState();
    const [categoryItem, setCategoryItem] = useState(
        {
            category_id: '',
            name: '',
            page_title: '',
            page_subtitle: '',
            page_description: '',
            tile_image: '',
            thumbnail_image: '',
            id: 0,
        }
    )
    // const categoryItemSchema = Yup.object().shape({
    //     // name: Yup.string().min(3, 'Should be 3 character long').required('Enter name'),
    //     name: Yup.string(),
    //     page_title: Yup.string(),
    //     page_subtitle: Yup.string(),
    //     page_description: Yup.string(),
    //     // tile_image: Yup.mixed(),
    //     // thumbnail_image: Yup.mixed(),
    //     // status: Yup.boolean()
    // });
    const categoryItemSchema = Yup.object().shape({});
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleAddCategory = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        currentParentId && formData.append('category_id', currentParentId);
        formData.append('name', values.name);
        formData.append('tile_image', file);
        formData.append('thumbnail_image', file);
        formData.append('page_title', values.page_title);
        formData.append('page_subtitle', values.page_subtitle);
        formData.append('page_description', values.page_description);
        try {
            await services.createCategory(formData);
            toast.success('The category has been created successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response.data.message || 'Something went wrong!');
            setIsLoading(false);
        }
    };
    const handleUpdateCategoryByImage = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('category_id', selectedCategoryItem.category_id);
        formData.append('name', values.name);
        file && formData.append('tile_image', file);
        file && formData.append('thumbnail_image', file);
        formData.append('page_title', values.page_title);
        formData.append('page_subtitle', values.page_subtitle);
        formData.append('page_description', values.page_description);
        try {
            await services.updateCategory(selectedCategoryItem.id,formData, file);
            toast.success('The category has been updated successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response.data.message || 'Something went wrong!');
            setIsLoading(false);
        }
    };
    const handleUpdateCategory = async (values) => {
        setIsLoading(true);
        let req = {
            category_id: selectedCategoryItem.category_id,
            name: values.name,
            page_title: values.page_title,
            page_subtitle: values.page_subtitle,
            page_description: values.page_description,
        }
        try {
            await services.updateCategoryWithoutImage(selectedCategoryItem.id,req,);
            toast.success('The category has been updated successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response.data.message || 'Something went wrong!');
            setIsLoading(false);
        }
    };
    const closeModal = () => {
        setCategoryItem({
            ...categoryItem,
            category_id: '',
            name: '',
            page_title: '',
            page_subtitle: '',
            page_description: '',
            tile_image: '',
            thumbnail_image: '',
            id: 0,
        });
        onClose();
    }
    const handleSubmit = (values) => {
        selectedCategoryItem
        ? file ? handleUpdateCategoryByImage(values)  : handleUpdateCategory(values)
        : handleAddCategory(values)
    }
    const handleIconChange = (e) => {
        if (e.target.files) {
            let tempFile = e.target.files[0];
            if (!(tempFile.type === 'image/jpeg' || tempFile.type === 'image/png')) {
                toast.warning('Please upload a jpg/png file');
                fileRef.current.value = '';
                return false;
            }
            if (tempFile.size > 500000) {
                toast.warning('Please upload a file smaller than 500 KB');
                fileRef.current.value = '';
                return false;

            }
            setFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(tempFile);
            setCategoryItem({ ...categoryItem, icon: objectUrl });
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (selectedCategoryItem && show) {
            setCategoryItem(selectedCategoryItem);
            // setStatus(selectedCategoryItem.status)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    /* -------------------------------------------------------------------------- */
    return (
        <CustomModal
            title={selectedCategoryItem && show ? 'Edit Category' : 'Add New Category'}
            show={show}
            className='pb-0'
            onClose={() => closeModal()}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    name: selectedCategoryItem ? selectedCategoryItem.name : '',
                    page_title: selectedCategoryItem ? selectedCategoryItem.page_title : '',
                    page_subtitle: selectedCategoryItem ? selectedCategoryItem.page_subtitle : '',
                    page_description: selectedCategoryItem ? selectedCategoryItem.page_description : '',
                }}
                validationSchema={categoryItemSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, values, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={(values) => {
                        handleSubmit(values)
                    }}>
                        <div className="grid grid-cols-12 items-end w-full md:pl-3 mt-3 md:mt-0 gap-2 md:gap-3 max-h-[400px] md:max-h-[590px] overflow-y-auto">
                            <div className="flex col-span-12 flex-col min-h-[76px] relative">
                                <div className="absolute bottom-1 right-1 rounded h-6 flex flex-col items-center md:flex-row overflow-hidden">
                                    <span className="h-6 w-6 flex items-center justify-center rounded-full cursor-pointer text-blue-700 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M1 8a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 018.07 3h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0016.07 6H17a2 2 0 012 2v7a2 2 0 01-2 2H3a2 2 0 01-2-2V8zm13.5 3a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM10 14a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <input ref={fileRef} type="file" accept="image/jpeg, image/png" className='opacity-0 absolute w-full cursor-pointer' onChange={(e) => handleIconChange(e)} />
                                </div>
                                <div className="flex items-center w-full md:max-h-[100px] overflow-hidden border border-stone-200 rounded-md">
                                    <img src={
                                        categoryItem.thumbnail_image
                                            ? categoryItem.thumbnail_image
                                            : file
                                                ? URL.createObjectURL(file)
                                                : `/images/general/logo-replace.png`
                                    } alt="slider" className='w-full- w-auto h-auto rounded md:max-h-[100px] mx-auto' />
                                </div>
                            </div>
                            <div className="flex col-span-12 md:col-span-6 flex-col min-h-[76px]">
                                <label className="text-sm text-stone-500" htmlFor="name">Name</label>
                                <input
                                    name='name'
                                    type="text"
                                    value={values.name}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        // setCategoryItem({ ...categoryItem, name: e.target.value });
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.name && touched.name) &&
                                    <small id='name-error' className="text-[#f60362] text-xs">
                                        {errors.name}
                                    </small>
                                }
                            </div>
                            <div className="flex col-span-12 sm:col-span-6 flex-col min-h-[76px]">
                                <label className="text-sm text-stone-500" htmlFor="page_title">Page Title</label>
                                <input
                                    name='page_title'
                                    type="text"
                                    value={values.page_title}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        // setCategoryItem({ ...categoryItem, page_title: e.target.value });
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                            </div>
                            <div className="flex col-span-12 sm:col-span-6 flex-col min-h-[76px]">
                                <label className="text-sm text-stone-500" htmlFor="page_subtitle">Page Subtitle</label>
                                <input
                                    name='page_subtitle'
                                    type="text"
                                    value={values.page_subtitle}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        // setCategoryItem({ ...categoryItem, page_subtitle: e.target.value });
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                            </div>
                            {/* <div className="flex col-span-12 sm:col-span-6 flex-col min-h-[76px]">
                                <label className="text-sm text-stone-500" htmlFor="status">Status</label>
                                <select
                                    name="status"
                                    value={values.status}
                                    className='cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full'
                                    onChange={(e) => {
                                        handleChange(e)
                                        // setCategoryItem({ ...categoryItem, status: e.target.value });
                                    }}
                                >
                                    <option value={true} className='cursor-pointer'>
                                        Active
                                    </option>
                                    <option value={false} className='cursor-pointer'>
                                        Deactive
                                    </option>
                                </select>
                            </div> */}
                            <div className="flex col-span-12 flex-col min-h-[76px]">
                                <label className="text-sm text-stone-500" htmlFor="page_description">Page Description</label>
                                <input
                                    name='page_description'
                                    type="text"
                                    value={values.page_description}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        // setCategoryItem({ ...categoryItem, page_description: e.target.value });
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                            </div>
                        </div>
                        <div className="flex w-full justify-end pt-4">
                            <button className='border border-red-500 hover:bg-red-500 text-red-500 hover:text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 mr-2' onClick={() => closeModal()}>Cancel</button>
                            <button
                                disabled={isLoading}
                                type="submit"
                                className='bg-[#0f960f] hover:bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200'
                            // onClick={() => selectedCategoryItem ? handleUpdate() : handleSave()}
                            >
                                {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                </>) : (
                                    selectedCategoryItem && show ? 'Update' : 'Save'
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
export default HandleCategory;
import { useEffect } from 'react';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import banner from '../../images/landings/admin-fee/admin-fee-desktop-banner.jpg';
import bannerMobile from '../../images/landings/admin-fee/admin-fee-mobile-banner.jpg';
import CustomButton from '../../components/general/button';
import { QuoteWrapper } from '../../components/general/quoteWrapper';
import services from '../../services';
import { useState } from 'react';
import useHandleAddProduct from '../../utils/handleAddProduct';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const AdminFeesPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const [adminProduct, setAdminProduct] = useState();
    const [currentCurrency, setCurrentCurrency] = useState('AUD');
    const [addProduct] = useHandleAddProduct();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start Methods ----------------------------- */
    const getProduct = async () => {
        const temp = { product: 'INT-ADM-OVH-2023', currency: selectedCurrencyStore }
        try {
            const response = await services.getProduct(temp);
            setAdminProduct(response.data)

        } catch (error) {
            console.error('error========', error);
        }
    };
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (selectedCurrencyStore) {
            setCurrentCurrency(selectedCurrencyStore)
            getProduct()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])
    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <NewHelmetLayout>
            {seo &&
                <Helmet>
                    <title>{`${seo['aboutUs']['subscriptionsPortal']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo['aboutUs']['subscriptionsPortal']['description']}`} />
                </Helmet>
            }
            <WidgetHome selectedCurrency={(e) => { }} />
            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="flex justify-center" >
                <img src={banner} alt="MATW Admin Fee" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="MATW Admin Fee" className='min-[501px]:hidden w-full' />
            </section>
            <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center mt-6 md:mt-10 mx-6">
                <div className="basis-full bg-white rounded-md shadow-md p-5 md:p-10 mb-4">
                    <div className="flex flex-col items-center w-full md:w-10/12 md:mx-auto">
                        <h1 className='text-[#253B7E] w-full md:text-center font-bold my-4 md:my-8 text-[30px] md:text-[40px]'>Admin Explained</h1>
                        <h2 className='text-[#253B7E] w-full text-left my-2 text-[25px] md:text-[30px] leading-7 md:leading-9 font-bold'>Making your donations work.</h2>
                        <p className='text-[16px] md:text-[18px] text-[#78716C] mb-4 md:mb-6'>
                            MATW Project is fortunate to receive support from angel investors and businesses to cover our administrative costs, allowing us to allocate 100% of public donations directly to our projects. As we enter a new frontier, aiming to grow our impact and reach in reducing poverty and supporting those in need, we are now reaching out to our loyal donor base. Your support on this journey will be pivotal in enhancing our capabilities and expanding our reach. With the grace of Allah, we have grown so quickly and have been able to provide greater impact. But now we need your support to help us do even more!
                        </p>
                        <div className="w-full flex flex-col md:flex-row justify-between items-start rounded-lg bg-[#253B7E] p-4 md:p-6 mb-4 md:mb-6">
                            <h2 className='text-white text-[25px] md:text-[30px] font-bold mb-3 md:mb-0'>Help us maintain our 100% donation policy.</h2>
                            <CustomButton onClick={() => addProduct({product: adminProduct, currency: currentCurrency})} title="Donate Now" />
                        </div>
                        <h2 className='text-[#253B7E] w-full mb-2 md:mb-3 text-[16px] md:text-[18px] font-bold'>The 100% promise.</h2>
                        <p className='text-[16px] md:text-[18px] text-[#78716C] mb-4 md:mb-6'>
                            We are committed to our 100% donation policy, ensuring that every penny, pound, euro, dollar, or even crypto currency you give goes straight to supporting those in need, reflecting our dedication to Islamic principles of trust and transparency. Our policy guarantees that your contribution directly impacts the lives of those we aim to help.
                        </p>
                        <h2 className='text-[#253B7E] w-full mb-2 md:mb-3 text-[16px] md:text-[18px] font-bold'>How do we use admin funds?</h2>
                        <p className='text-[16px] md:text-[18px] text-[#78716C] mb-4 md:mb-6'>
                            From caring for more than 7,000 orphans to delivering 32 million litres of water in 2023, our life-changing projects require support. Our orphan care programme alone includes the facilitation of three entire villages in Togo, an orphanage in Lebanon, and care programs in other parts of the world. From 2023 to date, our donors have helped us deliver an impact of more than 3,944,891 in food aid projects. When emergencies and disasters struck, donors helped us respond within 24 hours, delivering an impact of more than 36,016,215–32,387,647 in Palestine to date.
                            <br />
                            Our excellence in delivery and response is only enabled by the support of our donors and the support costs (admin) that help further our work by providing expertise, logistical support, and more.
                        </p>
                        <h2 className='text-[#253B7E] w-full mb-2 md:mb-3 text-[16px] md:text-[18px] font-bold'>How are you able to maintain a 100% donation policy?</h2>
                        <p className='text-[16px] md:text-[18px] text-[#78716C] mb-4 md:mb-6'>
                            Since our inception, MATW’s administrative costs have been covered by angel investors who separately contribute towards our organisational growth. This is what helps us maintain a 100% donation policy.
                        </p>
                    </div>
                </div>
                {/* /* ------------------------------- Quote Wrapper -------------------------------------------------- */}
                <section className="py-6 md:py-9 lg:py-12 bg-[#E1EFF2]">
                <QuoteWrapper
                    quote={true}
                    quoteText={false}
                    text={`You don’t need to be an angel investor; now you too can contribute to help run MATW and gain the reward of every project we implement because you will directly be making it happen, which is an incredible Sadaqah itself.`}
                />
                </section>
                <div className="basis-full bg-white rounded-md shadow-md p-5 md:p-10 mb-4">
                    <div className="flex flex-col items-center justify-center w-full md:w-10/12 md:mx-auto">
                        <h2 className='text-[#253B7E] w-full mb-2 md:mb-3 text-[16px] md:text-[18px] font-bold'>Supporting our hardworking staff</h2>
                        <p className='text-[16px] md:text-[18px] text-[#78716C] mb-4 md:mb-6'>
                            A portion of our funds are allocated towards full-time salaries, allowing us to employ a team of dedicated professionals who are key to the efficient running of our projects. Making sure we have the best staff that can deliver your donations is critical to our success. These are the guys that make it happen as effectively as possible. Your support can keep this going.
                        </p>
                        <h2 className='text-[#253B7E] w-full mb-2 md:mb-3 text-[16px] md:text-[18px] font-bold'>Digital Innovation and Technology</h2>
                        <p className='text-[16px] md:text-[18px] text-[#78716C] mb-4 md:mb-6'>
                            Investment in digital innovation and technology is crucial for our operational efficiency and growth. This includes upgrading our digital platforms to improve donation processes, project management, and communication with our stakeholders. Such advancements ensure transparency and enhance our ability to deliver aid effectively.
                        </p>
                        <h2 className='text-[#253B7E] w-full mb-2 md:mb-3 text-[16px] md:text-[18px] font-bold'>Compliance</h2>
                        <p className='text-[16px] md:text-[18px] text-[#78716C] mb-4 md:mb-6'>
                            In this day and age, ensuring we are compliant is critical. The last thing we would want is to jeopardise our work because of a lack of effective systems, processes, and policies. We work hard to ensure that we deliver the best of what we can to our beneficiaries, and ensuring we are compliant with local and international laws and regulations is critical. It means we can operate without having to worry if we have done anything wrong and focus on the work at hand.
                        </p>
                        <h2 className='text-[#253B7E] w-full mb-2 md:mb-3 text-[16px] md:text-[18px] font-bold'>Increasing our global footprint</h2>
                        <p className='text-[16px] md:text-[18px] text-[#78716C] mb-4 md:mb-6'>
                            As the fastest-growing Muslim charity in the world, what we do and how we do it are really important to us. We lead by example and ensure we leave a positive imprint on the Muslim communities around the world we interact with. Showcasing the positive work Muslims do is a critical part of our identity. It’s an amanah and responsibility for us that we take seriously. We want to inspire as many Muslims as possible, and you can help make this happen on a global scale!
                        </p>
                        <div className="flex w-full justify-center items-center mb-4 md:mb-6">
                            <CustomButton onClick={() => addProduct({product: adminProduct, currency: currentCurrency}) } title="Donate Now" />
                        </div>
                        <p className='text-[16px] md:text-[18px] font-bold text-[#14A2DC] mb-4 md:mb-6'>
                            We invite our supporters to be part of this significant phase of our journey. By contributing towards our administrative costs, you are investing in the foundation of MATW Project, enabling us to extend our services and make a larger impact in the communities we serve. For more details on our funding and 100% donation policy, please visit our policy page. Together, we can continue to make strides in reducing poverty and supporting those in need.
                        </p>
                    </div>
                </div>
            </div>

        </NewHelmetLayout>
    );
}

export default AdminFeesPage;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import { useRef } from 'react';
import MultiSelectProductsBox from '../../../components/general/multiSelectProductsBox';
import { QuoteWrapper } from '../../../components/general/quoteWrapper';
import ProductBox from '../../../components/general/productBox';

const QurbanTabs = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const { /*sheepGoatProducts, cowShareProducts, cowProducts,*/ foodAidProducts, zakatProducts, qurbanProducts, qurbanPalestineProducts, qurbanEgyptProducts, /*recommendedTab, */headerText, footerText, contentText, isQurbaniPage, country, title, lang = 'en', hideWidget } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    // {
    //   id: "sheepgoat",
    //   title: lang === 'fr' ? 'mouton/chèvre' : lang === 'ar' ? 'الأغنام / الماعز' : 'Sheep/goat',
    //   boxTitle: sheepGoatProducts.title,
    //   products: sheepGoatProducts.products,
    //   checkboxProducts: sheepGoatProducts.checkboxProducts,
    //   bundleProduct: sheepGoatProducts.bundleProduct,
    // },
    // {
    //   id: "cowshare",
    //   // title: 'cow share',
    //   title: lang === 'fr' ? 'Partage de vaches' : lang === 'ar' ? 'حصة البقر' : 'cow share',
    //   boxTitle: cowShareProducts.title,
    //   products: cowShareProducts.products,
    //   checkboxProducts: cowShareProducts.checkboxProducts,
    //   bundleProduct: cowShareProducts.bundleProduct,
    // },
    // {
    //   id: "cow",
    //   title: cowProducts.title,
    //   boxTitle: cowProducts.title,
    //   products: cowProducts.products,
    //   checkboxProducts: cowProducts.checkboxProducts,
    // },
    {
      id: "foodaid",
      title: foodAidProducts.title,
      boxTitle: foodAidProducts.title,
      products: foodAidProducts.products,
      checkboxProducts: foodAidProducts.checkboxProducts,
    },
    {
      id: "zakat",
      title: zakatProducts.title,
      boxTitle: zakatProducts.title,
      products: zakatProducts.products,
    },
  ];
  // if (recommendedTab) {
  //   tabButtonsProducts.unshift(
  //     {
  //       id: "recommended",
  //       title: recommendedTab.title,
  //       boxTitle: recommendedTab.title,
  //       products: recommendedTab.products,
  //       checkboxProducts: recommendedTab.checkboxProducts,
  //       bundleProduct: recommendedTab.bundleProduct,
  //       isOneLine: false
  //     },
  //   );
  // }
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#093484]">
      <div className={`md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12 flex flex-col ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : ''}`}>
        <div className="order-2 md:order-1 flex flex-col">
          <h1 className="max-w-[900px] mx-auto text-white text-[25px] md:text-[40px] leading-7 md:leading-[48px] text-center font-bold mb-2 md:mb-4" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
            {headerText}
          </h1>
          <p className="text-white text-center mb-4 md:mb-8">
            {contentText &&
              <>
                {contentText}
                <br />
                <br />
              </>
            }
            {lang === 'fr'
              ? <>
                De nombreux membres de notre oumma ont été confrontés à des difficultés et à des souffrances sans précédent.
                <br />
                <br />
                La pauvreté, la maladie et les pénuries alimentaires généralisées laissent de nombreuses familles dans la faim. Dans des endroits comme la Palestine et le Yémen, la famine est imminente.
                <br />
                <br />
                Faites preuve de compassion en ce Dhou al-hijja et nourrissez les affamés – une profession de foi, de générosité et de gentillesse qui non seulement nourrit votre Imân, mais permettra également à une famille de célébrer l’Aïd comme nous tous.
              </>
              : lang === 'ar'
                ? <>
                  لقد واجه الكثيرون في أمتنا تحديات ومعاناة غير مسبوقة.
                  <br />
                  <br />
                  وقد ترك الفقر والمرض ونقص الغذاء على نطاق واسع بما في ذلك المجاعة التي تلوح في الأفق في أماكن مثل فلسطين واليمن العديد من الأسر على حافة المجاعة.
                  <br />
                  <br />
                  أظهر رحمتك في هذا ذو الحجة وقم بعمل يُطعم المحتاجين - تعبير عن الإيمان والكرم واللطف الذي يغذي إيمانك ويضمن لعائلة أخرى الاحتفال بالعيد كما نفعل نحن.
                </>
                : <>
                  Many in our Ummah have faced unprecedented challenges and suffering.
                  <br />
                  <br />
                  Poverty, disease and widespread food shortages including a looming famine
                  in places like Palestine and Yemen have left many families on the verge of starvation.
                  <br />
                  <br />
                  Show compassion this Dhul Hijjah and perform a Deed that Feeds - an expression
                  of faith, generosity, and kindness that not only feeds your Iman but also ensures
                  a family can celebrate Eid just as we do.
                </>
            }
          </p>
          {
            !!qurbanPalestineProducts?.length &&
            <div className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4">
              <h1 className="w-full text-center text-5xl text-white py-2 md:pb-8 font-['Gotcha'] my-2">{title} in Palestine</h1>
              <div className="flex justify-center flex-wrap">
                {qurbanPalestineProducts?.map(prod => {
                  return (
                    <div className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2" key={prod.id}>
                      <ProductBox product={prod} currency={selectedCurrency} />
                    </div>
                  );
                })}
              </div>
            </div>
          }
          {
            !!qurbanProducts?.length &&
            <div className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4 mt-6">
              <h2 className="w-full text-center text-5xl text-white py-2 md:pb-8 font-['Gotcha'] my-2">{title} by most affordable first</h2>
              <div className="flex justify-center flex-wrap">
                {qurbanProducts?.map(prod => {
                  return (
                    <div className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2" key={prod.id}>
                      <ProductBox product={prod} currency={selectedCurrency} />
                    </div>
                  );
                })}
              </div>
            </div>
          }
          {
            !!qurbanEgyptProducts?.length &&
            <div className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4 mt-6">
              <h2 className="w-full text-center text-5xl text-white py-2 md:pb-8 font-['Gotcha'] my-2">{title} in Egypt for Palestinian Refugees</h2>
              <div className="flex justify-center flex-wrap">
                {qurbanEgyptProducts?.map(prod => {
                  return (
                    <div className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2" key={prod.id}>
                      <ProductBox product={prod} currency={selectedCurrency} />
                    </div>
                  );
                })}
              </div>
            </div>
          }
        </div>
        {!hideWidget && <div className="order-1 md:order-2 flex flex-wrap items-center justify-center gap-6 md:gap-12 my-3 md:my-6">
            <div className="flex items-center w-full overflow-x-auto">
              <div className="flex flex-wrap gap-1 min-[768px]:gap-2 items-center justify-between- md:justify-center w-full min-w-[520px] max-w-[700px]- mx-auto">
                {tabButtonsProducts.map(({ title, id }, index) => (
                  <button
                    id={id}
                    key={`btn_${index}`}
                    onClick={() => {
                      window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 })
                      setActiveTabProducts(index);
                    }}
                    // className={`${index === activeTabProducts ? 'bg-[#F60362] text-white p-2' : 'md:bg-[#F8F8F8] text-white md:text-[#78716C]'} text-xs md:text-[14px] rounded-md md:rounded-full md:p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-['Montserrat'] font-bold`}>
                    // className={`${index === activeTabProducts ? 'bg-[#F60362] text-white p-2' : 'md:bg-[#F8F8F8] text-white md:text-[#78716C]'} text-xs md:text-[14px] rounded-md md:rounded-full md:p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-bold ${lang === 'ar' ? 'font-[AlmariMedium]' : "font-['Montserrat']"}`}>
                    className={`py-3 px-2 md:px-3 ${index === activeTabProducts ? 'bg-[#F60362] text-white' : 'md:bg-[#F8F8F8] text-white md:text-[#78716C]'} text-xs md:text-[14px] rounded-md md:rounded-full md:px-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${lang === 'ar' ? 'font-[AlmariMedium]' : "font-['Montserrat']"} font-bold`}>
                    {title}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full justify-center">
              {tabButtonsProducts.map((item, index) =>
                index === activeTabProducts && <React.Fragment key={`description_${index}`}>
                
                  <div ref={contentRef} className='flex w-full flex-wrap justify-center items-start gap-2 md:gap-4'>
                    <MultiSelectProductsBox
                      products={item.products}
                      isOneLine={item.isOneLine || false}
                      checkboxProducts={item.checkboxProducts || null}
                      bundleProduct={item.bundleProduct || null}
                      title={item.boxTitle}
                      currency={selectedCurrency}
                      country={country}
                      lang={lang}
                      className={`md:!w-2/3 lg:!w-5/12`}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        }
        <div className="order-3 flex flex-col">
          {isQurbaniPage
            ? <p className="text-white text-center mb-4 md:mb-8">
              From the time of Prophet Ibrahim (AS) to Prophet Muhammad (SAW), till this very day Qurbani
              is an important act of worship bringing us closer to Allah SWT. The meat provided gives
              communities essential sustenance that isn’t part of their daily diets. For most of these
              families, your Qurbani during Eid will be the only time they get to eat meat. For us it is
              the sunnah of our Prophet Ibrahim AS – our Ibadah. For the poorest people around the world,
              it’s nourishment, Eid joy and hope.
            </p>
            : <p className="text-white text-center mb-4 md:mb-8">
              {lang === 'fr'
                ? `De l’époque du prophète Ibrahim (Paix soit sur lui) à nos jours, en passant par l’ère du Prophète Mahomet (Paix et bénédiction sur lui), le Qurbane a toujours représenté une importante action de grâce qui nous rapproche d’Allah (Glorifié et Exalté soit-Il). La viande ainsi distribuée fournit aux communautés une subsistance essentielle, parfois bien loin de leur alimentation quotidienne. Pour la plupart de ces familles, votre Qurban de l’Aïd sera leur seul apport en viande de l’année. Pour nous, cela représente notre Ibadah et traduit notre respect pour la sunna de notre prophète Ibrahim (Paix soit sur lui). Pour les personnes les plus pauvres du monde, cette nourriture est synonyme de joie et d’espoir associés à l’Aïd.`
                : lang === 'ar'
                  ? `من زمن النبي إبراهيم (ع) إلى النبي محمد (صلى الله عليه وسلم) ، حتى يومنا هذا ، يعد الأضحية عملا مهما من أعمال العبادة التي تقربنا من الله سبحانه وتعالى. توفر اللحوم المقدمة للمجتمعات القوت الأساسي الذي لا يشكل جزءا من وجباتهم الغذائية اليومية. بالنسبة لمعظم هذه العائلات ، سيكون قربك خلال العيد هو الوقت الوحيد الذي يأكلون فيه اللحوم. بالنسبة لنا هي سنة نبينا إبراهيم صلى الله عليه وسلم - عبادتنا. بالنسبة لأفقر الناس في جميع أنحاء العالم ، إنه الغذاء وفرحة العيد والأمل.`
                  : `From the time of Prophet Ibrahim (AS) to Prophet Muhammad (SAW), till this very day ${title}
                is an important act of worship bringing us closer to Allah SWT. The meat provided gives
                communities essential sustenance that isn’t part of their daily diets. For most of these
                families, your ${title} during Eid will be the only time they get to eat meat. For us it is
                the sunnah of our Prophet Ibrahim AS – our Ibadah. For the poorest people around the world,
                it’s nourishment, Eid joy and hope.`
              }
            </p>
          }
          <div className="my-4">
            <QuoteWrapper
              quote={true}
              quoteText={false}
              className="bg-[#E1EFF2]"
              lang={lang}
              text={lang === 'fr'
                ? `Ni leurs chairs ni leurs sangs n’atteignent Allah, mais ce qui L’atteint de votre part c’est la piété. – Coran, 22:37`
                : lang === 'ar'
                  ? `لن ينال الله لحومها ولا دماؤها ولكن يناله التقوى منكم كذلك سخرها لكم لتكبروا الله على ما هداكم وبشر المحسنين ﴾ [ 22: 37]`
                  : `Neither their meat nor their blood reaches Allah, but it is your piety that reaches Him. ~ (Quran 22:37)`
              }
            />
          </div>
          <p className={`text-white text-center text-[25px] md:text-[40px] my-4 md:my-8 ${lang === 'ar' ? 'font-[AlmariMedium]' : "font-['Gotcha']"}`}>
            {footerText}
          </p>
        </div>
      </div>
    </section>

  );
};
export default QurbanTabs;
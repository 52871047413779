import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import Carousel from '../../components/Carousel';
// import useBreadCrumb from '../../hooks/breadCrumb';
import services from '../../services';
import { useTranslation } from 'react-i18next';
import { generateLinkWithLang } from '../../utils/linkGenerator';

export default function CarouselSection() {
  /* ----------------------------- Start variables ---------------------------- */
  // const { inserMenuRoute } = useBreadCrumb();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [banners, setBanners] = useState([])
  // const carouselItems = [
  //   { url: '/food-and-water-aid', img: 'carousel-homepage-food-and-water-aid.jpg', name: 'food-and-water-aid', isSubCategory: false },
  //   { url: '/donate-now/make-a-donation', img: 'carousel-homepage-general-donations.jpg', name: 'make-a-donation', parentName: 'donate-now', isSubCategory: true },
  //   { url: '/zakat-calculator', img: 'carousel-homepage-zakat-calculator.jpg', name: 'qurban', isSubCategory: false },
  //   { url: '/sadaqah-jariyah/build-a-masjid', img: 'carousel-homepage-build-a-masjid-.jpg', name: 'build-a-masjid', parentName: 'sadaqah-jariyah', isSubCategory: true },
  //   { url: '/sadaqah-jariyah/build-a-water-well', img: 'carousel-homepage-build-a-water-well-.jpg', name: 'build-a-water-well', parentName: 'sadaqah-jariyah', isSubCategory: true },
  //   { url: '/orphans', img: 'carousel-homepage-orphans-.jpg', name: 'orphans', isSubCategory: false },
  //   { url: '/sadaqah-jariyah', img: 'carousel-homepage-sadaqah-jariyah-.jpg', name: 'sadaqah-jariyah', isSubCategory: false },
  //   { url: '/donate-now', img: 'carousel-homepage-recurring-donations-.jpg', name: 'donate-now', isSubCategory: false },
  //   { url: '/app', img: 'carousel-homepage-mobile-app-.jpg', name: 'app', isSubCategory: false },
  //   // { url: '/qurban-2023', img: 'carousel-homepage-qurban-impact.jpg', name: 'qurban', isSubCategory: false },
  //   // { url: '/crisis-and-emergencies/palestine', img: 'carousel-homepage--palestine.jpg', name: 'palestine',parentName: 'crisis-and-emergencies', isSubCategory: true },
  //   // { url: '/crisis-and-emergencies/palestine', img: 'carousel-homepage-emergency-support-for-palestine.jpg', name: 'palestine',parentName: 'crisis-and-emergencies', isSubCategory: true },
  //   // { url: '/zakat', img: 'carousel-homepage-zakat-impact.jpg', name: 'qurban', isSubCategory: false },
  //   // { url: '/qurban', img: 'carousel-homepage-qurban.jpg', name: 'qurban', isSubCategory: false },
  //   // { url: 'https://blog.matwproject.org/', img: 'carousel-homepage-blog.jpg', name: 'blog', isSubCategory: false },
  // ];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleClick = () => {
    console.log('clicked');
  };
  const getBanners = async () => {
    try {
      const response = await services.getBanners();
      let temp = response.data.sort((a, b) => {
        return a.order - b.order;
      });
      // console.log('response get getBanners', response);
      // console.log('response get getBanners 2', temp);
      let temp2 = [];
      // temp = temp.filter(item => item.is_page_banner !== 1)
      temp.map((item,index) => {
        if(item.status !== 0 && item.section === "slider") {
          temp2.push(item)
        }
        return item;
      })
      setBanners(temp2)
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    getBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full md:py-2 min-h-[200px]- sm:min-h-[330px]- md:min-h-[393px] lg:min-h-[550px] xl:min-h-[674px] 2xl:min-h-[800px]">
      {banners.length ? <Carousel
      // {<Carousel
        withArrow={false}
        autoSwitch={true}
        // data={carouselItems}
        data={banners}
        renderItem={({ item, index, currentSlide }) => {
          const isActive = currentSlide === index;
          return (
            <div onClick={handleClick} className="md:p-2" key={`carousel${index}`}>
              <div className={`w-full h-auto transition-all transform md:rounded-xl overflow-hidden lg:min-h-[490px] xl:min-h-[614px] 2xl:min-h-[740px] ${isActive ? 'scale-100' : 'opacity-75 scale-90'}`}>
                {/* <a href={item.url} onClick={() => {
                  item.isSubCategory ? inserMenuRoute(true, item.name, `/${item.parentName}/${item.name}`, { name: item.parentName, id: `/${item.parentName}` })
                    : inserMenuRoute(false, item.name, `/${item.name}`)
                }}>
                  <img
                    className={`w-full h-full object-center cursor-pointer object-cover md:rounded-xl`}
                    alt={item}
                    src={`/images/carouselImages/${item.img}`}
                  />
                </a> */}
                <a href={generateLinkWithLang(item.link, lang)}>
                  <img
                    className={`w-full h-full object-center cursor-pointer object-cover md:rounded-xl`}
                    alt={item.name}
                    src={`${item.image}`}
                  />
                </a>
              </div>
            </div>
          );
        }}
        keyExtractor={item => item.image}
        // keyExtractor={item => item.url}
      />

    : <></>}
    </div>
  );
}

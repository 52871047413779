import React from 'react';
import { foodFaqs, waterFaqs } from '../faqs';
import Accordion from '../../../components/general/accordion';

const FAQSection = ({ arabic, lang = 'en' }) => {
  const RawHTML = ({ children, className = '' }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />;
  return (
    <section className="md:container md:mx-auto md:px-0 px-4 mt-10" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="text-[30px] lg:text-[60px] font-brandingBold text-[#253B7E] md:text-center leading-tight">
        {lang === 'fr' ? (
          <>
            <span className="text-[#F60362]">FAQ </span>
            sur les dons d'aide alimentaire
          </>
        ) : lang === 'ar' ? (
          <>
            حول تبرعات المساعدات الغذائية
            <span className="text-[#F60362]"> أسئلة شائعة</span>
          </>
        ) : (
          <>
            Food Aid Donations
            <span className="text-[#F60362]"> FAQ’s</span>
          </>
        )}
      </div>
      <div className="my-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-16 lg:gap-24">
        <div className="flex flex-col gap-4">
          <h5 className="text-lg lg:text-2xl text-[#253B7E] font-brandingBold">{foodFaqs[0].question[lang]}</h5>
          <p className="text-gray-600 font-brandingMedium">
            <RawHTML>{foodFaqs[0].answer[lang]}</RawHTML>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <h5 className="text-lg lg:text-2xl text-[#253B7E] font-brandingBold">{foodFaqs[1].question[lang]}</h5>
          <p className="text-gray-600 font-brandingMedium">
            <RawHTML>{foodFaqs[1].answer[lang]}</RawHTML>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <h5 className="text-lg lg:text-2xl text-[#253B7E] font-brandingBold">{foodFaqs[2].question[lang]}</h5>
          <p className="text-gray-600 font-brandingMedium">
            <RawHTML>{foodFaqs[2].answer[lang]}</RawHTML>
          </p>
        </div>
      </div>
      <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center mt-6">
        {foodFaqs.map((item, index) => {
          return index > 2 && (
            <div className="bg-white rounded-md p-4 mt-2 w-full shadow-lg" key={`faq${index}`}>
              <Accordion
                key={`keyallfaq${index}`}
                title={item.question[lang]}
                index={index}
                level={1}
                noBorder={true}
                className="!text-[#253B7E] text-lg font-brandingBold"
              >
                {item.answer[lang]}
              </Accordion>
            </div>
          );
        })}
      </div>
      {/* /* --------------------------------------------------------------------------  */}
      <div className="text-[30px] lg:text-[60px] font-brandingBold text-[#253B7E] md:text-center leading-tight mt-8 md:mt-16">
        {lang === 'fr' ? (
          <>
            <span className="text-[#F60362]">FAQ </span>
            sur les dons d'eau
          </>
        ) : lang === 'ar' ? (
          <>
            حول تبرعات مساعدة المياه
            <span className="text-[#F60362]"> أسئلة شائعة</span>
          </>
        ) : (
          <>
            Water Aid Donations
            <span className="text-[#F60362]"> FAQ’s</span>
          </>
        )}
      </div>
      <div className="my-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-16 lg:gap-24">
        <div className="flex flex-col gap-4">
          <h5 className="text-lg lg:text-2xl text-[#253B7E] font-brandingBold">{waterFaqs[0].question[lang]}</h5>
          <p className="text-gray-600 font-brandingMedium">
            <RawHTML>{waterFaqs[0].answer[lang]}</RawHTML>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <h5 className="text-lg lg:text-2xl text-[#253B7E] font-brandingBold">{waterFaqs[1].question[lang]}</h5>
          <p className="text-gray-600 font-brandingMedium">
            <RawHTML>{waterFaqs[1].answer[lang]}</RawHTML>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <h5 className="text-lg lg:text-2xl text-[#253B7E] font-brandingBold">{waterFaqs[2].question[lang]}</h5>
          <p className="text-gray-600 font-brandingMedium">
            <RawHTML>{waterFaqs[2].answer[lang]}</RawHTML>
          </p>
        </div>
      </div>
      <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center mt-6">
        {waterFaqs.map((item, index) => {
          return index > 2 && (
            <div className="bg-white rounded-md p-4 mt-2 w-full shadow-lg" key={`faq${index}`}>
              <Accordion
                key={`keyallfaq${index}`}
                title={item.question[lang]}
                index={index}
                level={1}
                noBorder={true}
                className="!text-[#253B7E] text-lg font-brandingBold"
              >
                {item.answer[lang]}
              </Accordion>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default FAQSection;
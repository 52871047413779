// import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
import InputField from '../components/fields/InputField';
import useDashboardLoginHook from '../../../hooks/dashboard/useDashboardLoginHook';
// import Checkbox from "../components/checkbox";

const VerifyOTP = () => {
  const { loading, step, setOTP, verifyOTP } = useDashboardLoginHook();

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] mx-auto w-full max-w-full flex-col items-center xl:max-w-[420px] bg-white rounded-2xl shadow-lg p-4 md:p-8">
        <h4 className="mb-2.5 text-4xl font-bold text-neutral-700 text-center">Verify OTP</h4>
        <p className="mb-9 ml-1 text-base text-gray-600 text-center">Enter OTP for login</p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer ">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 ">
            Sign In with Google
          </h5>
        </div> */}
        {/* <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 " />
          <p className="text-base text-gray-600 "> or </p>
          <div className="h-px w-full bg-gray-200 " />
        </div> */}
        {/* Email */}
        {step === 0 && (
          <InputField variant="auth" extra="mb-3" label="OTP*" placeholder="OTP" id="otp" type="text" onChange={e => setOTP(e.target.value)} />
        )}

        <button
          disabled={loading}
          className="linear mt-2 flex items-center justify-center w-full rounded-xl bg-[#f60362] transition-all duration-200 hover:bg-[#00a3da] py-[12px] text-base font-medium text-white active:bg-brand-700"
          onClick={verifyOTP}
        >
          {loading ? (
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : step === 0 ? (
            'Next'
          ) : (
            'Login'
          )}
        </button>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 ">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 "
          >
            Create an account
          </a>
        </div> */}
        <div className="mt-4">
          <a href="/" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 ">
            Back To Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;

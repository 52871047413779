import React from 'react';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import useHandleAddProduct from '../../utils/handleAddProduct';

function Support({ title, imageUrl, footerText, buttonText, contributes, layout, description, listItems, product }) {
  const isImageRight = layout === 'right';
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [addProduct] = useHandleAddProduct();

  const handleAddNewProduct = () => {
    let tempProduct = product;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: 100,
      recurring: 'month',
    });
    addProduct({product: tempProduct, currency: selectedCurrencyStore})
  };
  return (
    <MaxWidthContainer>
      <div className=" p-5 md:p-10">
        <div className="max-w-[1280px] mx-auto border bg-[#E2EFF3]  rounded-lg shadow-lg overflow-hidden">
          {/* Header Section */}
          <div className={`flex flex-col md:flex-row items-center  ${isImageRight ? 'md:flex-row-reverse' : ''}`}>
            <img src={imageUrl} alt="A beautiful sunset over the mountains" className="w-full md:w-[400px] p-5 h-auto rounded-4xl" />
            <div className="p-5 md:p-10 md:w-2/3">
              <h2 className="bg-[#00A3DA] text-center text-white text-lg md:text-4xl font-bold py-2 px-4 rounded-tr-2xl rounded-bl-2xl">{title}</h2>{' '}
              {description &&
                description.map((text, index) => (
                  <p key={index} className={`mt-2 xs:text-center md:text-left ${text.style}`}>
                    {text.data}
                  </p>
                ))}
              {listItems && (
                <ul className="mt-2 list-inside xs:text-center md:text-left xs:list-none md:list-disc">
                  {listItems.map((item, index) => (
                    <li key={index} className="text mb-4">
                      <span className="font-semibold xs:block md:inline">{item.title}</span>{' '}
                      <span className="font-semibold xs:hidden md:inline">-</span> {item.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Contribution Section */}
          <div className="bg-[#253B7E] py-5 px-5 md:px-10">
            <h2 className="text-3xl md:text-5xl text-[#fff]  text-center font-[Gotcha] my-4">Contribute monthly or weekly to</h2>
            <div className="mt-5 flex flex-col md:flex-row justify-between">
              {contributes.map(item => {
                return (
                  <div className="text-center md:w-1/4 xs:mb-5 md:mb-0">
                    <img src={item.imageUrl} alt="Orphan Feast" className="mx-auto md:w-[200px] md:h-[165px] rounded-2xl" />
                    <h3 className="mt-5 text-2xl md:text-lg  font-[Branding] font-extrabold	 text-[#FFFFFF]">{item.title}</h3>
                    <p className=" mt-3 text-[#FFFFFF] font-Roboto ">{item.description}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Call-to-Action Button */}
          <div className="text-center py-5 bg-gradient-to-b from-[#00A3DA] to-[#076DBC] text-white flex flex-col justify-center items-center ">
            <p className="mb-3 xs:text-sm p-2 md:text-3xl text-center md:w-3/4 ">{footerText}</p>
            <button
              className="bg-[#ED145B] hover:#14A2DC md:w-[250px] md:h-[50px] text-white font-bold py-2 px-4 rounded-full"
              onClick={handleAddNewProduct}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </MaxWidthContainer>
  );
}

export default Support;
import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from './MobileMenu';

const MegaMenu = ({ megaMenuData, TriangleRightArrowIcon }) => {
    const [activeParent, setActiveParent] = useState(null);

    const menuItems = megaMenuData?.data?.filter(item =>
        item.status && item.title !== "Home"
    ) || [];

    const handleMouseEnter = useCallback((item) => {
        setActiveParent({
            ...item,
            children: item.children ? [...item.children] : []
        });
    }, []);

    const handleMouseLeave = useCallback(() => {
        setActiveParent(null);
    }, []);

    const getChildColumns = useCallback((children, maxColumns, isTablet = false) => {
        if (!children?.length) return [];

        const uniqueChildren = Array.from(
            new Map(
                children
                    .filter(child => child.status)
                    .map(item => [item.id, item])
            ).values()
        );

        const totalItems = uniqueChildren.length;

        const getOptimalColumns = (items) => {
            if (items <= 6) return 1;
            if (items <= 12) return 2;
            return isTablet ? 2 : 3;
        };

        const numColumns = getOptimalColumns(totalItems);

        const baseItemsPerColumn = Math.floor(totalItems / numColumns);
        const remainingItems = totalItems % numColumns;

        const columns = [];
        let currentIndex = 0;

        for (let i = 0; i < numColumns; i++) {
            const itemsInThisColumn = i < remainingItems ?
                baseItemsPerColumn + 1 :
                baseItemsPerColumn;

            columns.push(
                uniqueChildren.slice(currentIndex, currentIndex + itemsInThisColumn)
            );
            currentIndex += itemsInThisColumn;
        }

        return columns;
    }, []);

    return (
        <div className="w-full">
            <div className="hidden lg:flex" onMouseLeave={handleMouseLeave}>
                <div
                    className="w-64 flex-shrink-0 max-h-[calc(100vh-200px)] overflow-y-auto"
                    style={{ backgroundColor: '#81c3ed' }}
                >
                    {menuItems.map(item => {
                        const isActive = activeParent?.id === item.id;
                        return (
                            <div
                                key={item.id}
                                className="relative"
                                onMouseEnter={() => handleMouseEnter(item)}
                            >
                                <div
                                    className={`
                                        flex items-center justify-between py-3 px-4
                                        transition-all duration-200 ease-in-out
                                        hover:bg-white/10
                                        ${isActive ? 'bg-white/15' : ''}
                                    `}
                                >
                                    <Link
                                        to={item.url}
                                        className={`
                                            text-white text-[14px] block flex-grow
                                            ${isActive ? 'font-medium' : ''}
                                        `}
                                    >
                                        {item.title}
                                    </Link>
                                    {item.children?.length > 0 && (
                                        <TriangleRightArrowIcon
                                            className={`
                                                w-3 h-3 ml-2 transition-all duration-200
                                                ${isActive ? 'text-white' : 'text-white/70'}
                                            `}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {activeParent && activeParent.children && (
                    <div className="flex">
                        {getChildColumns(activeParent.children, 3).map((columnItems, columnIndex) => (
                            <div
                                key={`${activeParent.id}-${columnIndex}`}
                                className="w-64 flex-shrink-0 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar-thin"
                                style={{ backgroundColor: '#65bff7' }}
                            >
                                {columnItems.map(child => (
                                    <div
                                        key={child.id}
                                        className="relative"
                                    >
                                        <div
                                            className="
                                                flex items-center justify-between py-3 px-4
                                                transition-all duration-200 ease-in-out
                                                hover:bg-white/10
                                            "
                                        >
                                            <Link
                                                to={child.url}
                                                className="text-white text-[14px] block flex-grow"
                                            >
                                                {child.title}
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="hidden sm:flex lg:hidden" onMouseLeave={handleMouseLeave}>
                <div
                    className="w-1/3 flex-shrink-0 max-h-[calc(100vh-200px)] overflow-y-auto"
                    style={{ backgroundColor: '#81c3ed' }}
                >
                    {menuItems.map(item => {
                        const isActive = activeParent?.id === item.id;
                        return (
                            <div
                                key={item.id}
                                className="relative"
                                onMouseEnter={() => handleMouseEnter(item)}
                            >
                                <div
                                    className={`
                                        flex items-center justify-between py-3 px-4
                                        transition-all duration-200 ease-in-out
                                        hover:bg-white/10
                                        ${isActive ? 'bg-white/15' : ''}
                                    `}
                                >
                                    <Link
                                        to={item.url}
                                        className={`
                                            text-white text-[14px] block flex-grow
                                            ${isActive ? 'font-medium' : ''}
                                        `}
                                    >
                                        {item.title}
                                    </Link>
                                    {item.children?.length > 0 && (
                                        <TriangleRightArrowIcon
                                            className={`
                                                w-3 h-3 ml-2 transition-all duration-200
                                                ${isActive ? 'text-white' : 'text-white/70'}
                                            `}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {activeParent && activeParent.children && (
                    <div className="flex flex-grow">
                        {getChildColumns(activeParent.children, 2, true).map((columnItems, columnIndex) => (
                            <div
                                key={`${activeParent.id}-${columnIndex}`}
                                className={`flex-1 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar-thin`}
                                style={{ backgroundColor: '#65bff7' }}
                            >
                                {columnItems.map(child => (
                                    <div
                                        key={child.id}
                                        className="relative"
                                    >
                                        <div
                                            className="
                                                flex items-center justify-between py-3 px-4
                                                transition-all duration-200 ease-in-out
                                                hover:bg-white/10
                                            "
                                        >
                                            <Link
                                                to={child.url}
                                                className="text-white text-[14px] block flex-grow"
                                            >
                                                {child.title}
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="block sm:hidden max-h-[60vh] overflow-y-auto">
                <MobileMenu
                    items={menuItems}
                    TriangleRightArrowIcon={TriangleRightArrowIcon}
                />
            </div>
        </div>
    );
};

export default MegaMenu;
export const zakatFaqs = [
    {
        id: 0,
        question: 'What is Zakat?',
        answer: ` Zakat is a mandatory charitable obligation for eligible Muslims, based on their accumulated wealth over a lunar year. It is considered one of the five pillars of Islam and is meant to purify one's wealth, promote social justice, and help those in need.`,
    },
    {
        id: 1,
        question: 'Who is eligible to pay Zakat?',
        answer: `Eligible Muslims who possess wealth above a certain minimum threshold, known as Nisab, and have held it for a full lunar year are required to pay Zakat. See how much you owe this year with our`,
    },
    {
        id: 2,
        question: 'What is the purpose of Zakat?',
        answer: `The purpose of Zakat is to purify one's wealth, promote social justice, and help those in need. It is meant to redistribute wealth from those with more to those who have less, and to empower the underprivileged.`,
    },
    {
        id: 3,
        question: 'Who is eligible to receive Zakat?',
        answer: `Zakat can be given to those who are poor, needy, or in debt. It can also be given to orphans, widows, and the disabled.`,
    },
    {
        id: 4,
        question: 'Can Zakat be given to non-Muslims?',
        answer: `No, Zakat can only be given to Muslims who are eligible to receive it. However, Muslims are encouraged to give voluntary charity to non-Muslims.`,
    },
    {
        id: 5,
        question: 'What assets are subject to Zakat?',
        answer: `The assets that are subject to Zakat include cash, gold, silver, business assets, and agricultural products.`,
    },
    {
        id: 6,
        question: 'When should Zakat be given?',
        answer: `Zakat should be given annually on the Islamic lunar calendar, usually during the month of Ramadan.`,
    },
    {
        id: 7,
        question: 'Can Zakat be given in non-monetary forms?',
        answer: `Yes, Zakat can be given in non-monetary forms, such as food, clothing, or shelter.`,
    },
    {
        id: 8,
        question: 'Is Zakat mandatory for all Muslims?',
        answer: `Yes, Zakat is mandatory for all Muslims who meet the eligibility criteria.`,
    },
    {
        id: 9,
        question: 'What is the minimum amount of wealth that a Muslim must possess to be eligible for Zakat?',
        answer: `The minimum amount of wealth that a Muslim must possess to be eligible for Zakat is known as Nisab. The Nisab amount is calculated based on the current value of silver or gold and is subject to change over time.`,
    },
    {
        id: 10,
        question: 'Can Zakat be given to charitable organizations?',
        answer: `Yes, Zakat can be given to charitable organizations that meet the eligibility criteria for Zakat recipients.`,
    },
    {
        id: 11,
        question: 'What can Zakat be used for?',
        answer: `Zakat can be used for a variety of purposes, including helping the poor and needy, supporting education and healthcare initiatives, providing debt relief, and promoting entrepreneurship and innovation.`,
    },
    {
        id: 12,
        question: 'How is Zakat calculated?',
        answer: `Zakat is typically calculated as 2.5% of one's eligible assets that have been held for a full lunar year. The exact calculation may vary depending on the type of asset and the individual's circumstances.`,
    },
    {
        id: 13,
        question: 'What is the deadline for paying Zakat?',
        answer: `The deadline for paying Zakat is typically before the end of Ramadan, although it may vary depending on the individual's circumstances and location.`,
    },
    {
        id: 14,
        question: 'Is Zakat voluntary or mandatory?',
        answer: `Zakat is a mandatory obligation for eligible Muslims and is considered one of the five pillars of Islam.`,
    },
    {
        id: 15,
        question: 'Can Zakat be given anonymously?',
        answer: `Yes, Zakat can be given anonymously, and it is often recommended to do so to protect the dignity of the recipient.`,
    },
    {
        id: 16,
        question: 'What are the benefits of giving Zakat?',
        answer: `Giving Zakat has several benefits, including purifying one's wealth and soul, promoting social justice, alleviating poverty, and empowering the underprivileged.`,
    },
    {
        id: 17,
        question: 'Can Zakat be given to family members?',
        answer: `Yes, Zakat can be given to family members who meet the eligibility criteria for Zakat recipients. However, it is recommended to prioritize those in greater need before giving Zakat to family members.`,
    },
    {
        id: 18,
        question: 'What are the benefits of giving Zakat?',
        answer: `Giving Zakat has several benefits, including purifying one's wealth and soul, promoting social justice, alleviating poverty, and empowering the underprivileged.`,
    },
    {
        id: 19,
        question: 'Can Zakat be given to pay off debts?',
        answer: `Yes, Zakat can be given to pay off debts of eligible Zakat recipients.`,
    },
    {
        id: 20,
        question: 'Can Zakat be given in advance?',
        answer: `Yes, Zakat can be given in advance, but it must be based on an estimated value of the eligible assets and should be adjusted when the actual value is determined.`,
    },
    {
        id: 21,
        question: 'Can Zakat be given anonymously?',
        answer: `Yes, Zakat can be given anonymously, and it is often recommended to do so to protect the dignity of the recipient.`,
    },
    {
        id: 22,
        question: 'What is the importance of Zakat in Islam?',
        answer: `Zakat is one of the five pillars of Islam and is considered a fundamental obligation for Muslims. It serves as a means of purifying wealth and promoting social justice and equity in society.`,
    },
    {
        id: 23,
        question: 'Can Zakat be given to support education or healthcare?',
        answer: `Yes, Zakat can be given to support education or healthcare initiatives that benefit eligible Zakat recipients.`,
    },
    {
        id: 24,
        question: 'Can Zakat be given to support environmental causes?',
        answer: `Yes, Zakat can be given to support environmental causes that benefit eligible Zakat recipients.`,
    },
    {
        id: 25,
        question: 'Is Zakat tax-deductible?',
        answer: `Zakat is not tax-deductible in most countries, as it is considered a religious obligation and not a tax.`,
    },
    {
        id: 26,
        question: 'What is the difference between Zakat and Sadaqah?',
        answer: `Zakat is a mandatory obligation that must be paid by eligible Muslims, while Sadaqah is a voluntary act of charity that can be given by anyone.`,
    },
    {
        id: 27,
        question: 'Can Zakat be given to support entrepreneurship?',
        answer: `Yes, Zakat can be given to support entrepreneurship initiatives that benefit eligible Zakat recipients.`,
    },
    {
        id: 28,
        question: 'Can Zakat be given to support disaster relief efforts?',
        answer: `Yes, Zakat can be given to support disaster relief efforts that benefit eligible Zakat recipients.`,
    },
    {
        id: 29,
        question: 'Can Zakat be given to support refugees?',
        answer: `Yes, Zakat can be given to support refugees who meet the eligibility criteria for Zakat recipients.`,
    },
    {
        id: 30,
        question: 'Can Zakat be given in installments?',
        answer: `Yes, Zakat can be given in installments throughout the year, as long as the full amount is paid by the deadline.`,
    },
    {
        id: 31,
        question: 'Can Zakat be given to support research and innovation?',
        answer: `Yes, Zakat can be given to support research and innovation initiatives that benefit eligible Zakat recipients.`,
    },
];
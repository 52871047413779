import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import Skeleton from 'react-loading-skeleton';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
// import useHandleAddProduct from '../../../utils/handleAddProduct';
// import YoutubeVideoHero from '../../../components/general/youtubeVideoHero';
import CustomButton from '../../../components/general/button';
// import ShowCurrencies from '../../../utils/showCurrencies';
// import ConvertAUD from '../../../utils/convertAUD';
import ConvertUSD from '../../../utils/convertUSD';
import Sticky from 'react-sticky-el';
import SocialBox from '../../fundraising/components/socialBox';
import moment from 'moment';
import { endDateOfAppealCampaign, endDateOfGazaCampCampaign, endDateOfLimbsAppealCampaign } from '../details';
import getSymbolFromCurrency from 'currency-symbol-map';
import useHandleAddProduct from '../../../utils/handleAddProduct';
// import { useLocation } from 'react-router-dom';

const AppealCurrentProduct = ({ lang = 'en', currentProduct, goal, campaign, selectedGivingProducts }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  // const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [addProduct] = useHandleAddProduct();
  // const location = useLocation();
  // const [isRefugeePage, setIsRefugeePage] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isTabsFixed, setIsTabsFixed] = useState(false);
  const moreInfo = [
    {
      title:
        'More than 39,000 Palestinians have lost their lives including nearly 15,000 children and more than 90,000 have been wounded since October 7, 2023.',
      description: (
        <div className="text-[16px] md:text-[18px]">
          Overwhelmingly, they are broken families with family members left behind or deceased. They have survived the unimaginable - conflict,
          bombing, displacement and hunger, having seen people they know or family members dying, only to escape to another country to receive no
          support. We cannot let that happen. MATW is providing support to refugee families who have fled in the form of housing, rental support,
          furnishing homes, food support, expense support and so much more. Help us support them NOW. Click to know more.
          <br />
          More than 1.9 million people are internally displaced having left everything they have ever known behind to flee south, where conditions are
          barely liveable. As famine and hunger spreads, so will waterborne diseases due to lack of access to safe and clean drinking water.
        </div>
      ),
      box_title: (
        <div className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl ">Time is running out for our brothers and sisters in Palestine.</div>
      ),
      box_description: (
        <div className="text-[16px] md:text-[18px]">
          Having delivered more than 100 trucks carrying essential relief items to Palestine, MATW has one of the largest relief operations on the
          ground. Our teams are working round the clock to reach as many people as possible but we can’t do it alone.
          <br />
          <p className="font-black text-[20px] mt-1"> Help us help them. Donate Now.</p>
        </div>
      ),
    },
    {
      title: 'Your support is needed now more than ever.',
      description: `You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100% Zakat-applicable.`,
      box_title: '',
      box_description: ``,
    },
    {
      title: 'Empower Lives with Prosthetic Support',
      description: (
        <p>
          Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone
          in need. Every contribution, big or small, goes toward covering the costs of acquiring, customizing, and fitting a prosthetic limb.
          Together, we can offer the gift of mobility, independence, and a brighter future for individuals who deserve a chance to live fully again.
          Join us in making a profound impact today!
          <br />
          <br />
          Among them, thousands have become amputees - losing an arm, a leg or in some cases all their limbs. <i>Imagine</i> not being able to stand
          in Salat, or wrap your arms around your loved ones!
          <br />
          <br />
          The people of Palestine have already lost so much. They’ve been forced from their homes, lost their livelihoods, their belongings and their
          loved ones - don’t let them lose their dignity or hope.
          <br />
          <br />
          <strong>
            As the official named partner of the Jordan Hashemite Charity Organisation, Muslims Around The World Project (MATW Project), will be
            providing prosthetic limbs in Gaza to amputees through a new ground-breaking technology that can fit a limb within one hour!
          </strong>
          <br />
          <br />
          <strong>
            A Sadaqah Jariyah like this not only has the power to save lives but will also reap immense rewards for your Akhirah Insha’Allah!
          </strong>
          <br />
          <br />
          <strong>Help us provide Limbs of Hope for Gazans in need and restore dignity and hope. Donate Now.</strong>
        </p>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title: 'MATW Camp Infrastructure and Services',
      description: (
        <div>
          <p>
            The MATW camp is a meticulously designed facility providing vital support to numerous families. Key amenities ensure a safe and
            sustainable living environment:
          </p>
          <ul className="pl-4 lg:pl-8">
            <li className="list-decimal pl-2">
              <h6 className="font-bold text-DarkerBlue">Shelter and Accommodation</h6>
              <ul>
                <li className="pl-4 lg:pl-8 list-inside list-disc">
                  The camp boasts over 500 tents, each offering temporary refuge to families displaced from their homes. These tents are crafted to
                  shield residents from harsh weather conditions, creating a secure and stable space, particularly for vulnerable groups such as
                  children and the elderly.
                </li>
              </ul>
            </li>
            <li className="list-decimal pl-2">
              <h6 className="font-bold text-DarkerBlue">Water Access</h6>
              <ul>
                <li className="pl-4 lg:pl-8 list-inside list-disc">
                  A central water well has been established within the camp, delivering a continuous supply of clean drinking water. This essential
                  resource supports daily survival and hygiene, especially critical in emergency situations, ensuring that residents have easy access
                  to water.
                </li>
              </ul>
            </li>
            <li className="list-decimal pl-2">
              <h6 className="font-bold text-DarkerBlue">Sanitation and Hygiene Facilities</h6>
              <ul>
                <li className="pl-4 lg:pl-8 list-inside list-disc">
                  Dedicated washrooms and sanitation facilities are in place to uphold a healthy living environment. Proper hygiene practices are
                  crucial to preventing disease spread in crowded settings, and these facilities guarantee that residents can meet their basic
                  sanitary needs, promoting a safer and healthier community.
                </li>
              </ul>
            </li>
            <li className="list-decimal pl-2">
              <h6 className="font-bold text-DarkerBlue">Solar Power System</h6>
              <ul>
                <li className="pl-4 lg:pl-8 list-inside list-disc">
                  A solar-powered system has been implemented to provide sustainable energy. This system powers the water well and offers charging
                  stations for mobile phones, allowing families to stay connected and access crucial information while maintaining contact with
                  relatives.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      ),
      box_title: '',
      box_description: ``,
    },
  ];
  const selectedInfo =
    campaign === 'palestine' ? moreInfo[0] : campaign === 'egyptian' ? moreInfo[1] : campaign === 'gaza-camp' ? moreInfo[3] : moreInfo[2];
  const _link = campaign === 'palestine' ? 'palestine-emergency' : campaign === 'egyptian' ? 'palestine-refugee-resettlement' : 'limbs-of-hope';
  /* ------------------------------ Start methods ----------------------------- */
  const handlePercentage = (raised, goal) => {
    return `${((Number(raised) / Number(goal)) * 100).toFixed(2)}%`;
  };
  const handleDaysLeft = () => {
    var now = moment(new Date());
    // var before = moment(new Date(startDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
    // var timeDifference = now.diff(before, 'days');
    // var after = moment(new Date(endDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
    var after = moment(
      new Date(
        campaign === 'limbs-of-hope' ? endDateOfLimbsAppealCampaign : campaign === 'gaza-camp' ? endDateOfGazaCampCampaign : endDateOfAppealCampaign,
      ),
      'DD-MM-YYYY HH:mm:ss',
    );
    var timeDifference = after.diff(now, 'days');
    return timeDifference;
  };
  const ShowCurrencies2 = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */

  return (
    <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 md:gap-8 pb-8 md:py-8">
      <div className="col-span-2 md:col-span-1">
        <div
          className={`basis-full xs:aspect-w-16  xs:aspect-h-9 sm:h-[300px] md:h-[600px] lg:h-[890px] !max-h-[300px] min-h-fit md:!max-h-[450px]   md:!rounded-lg overflow-hidden`}
        >
          <iframe
            className="video"
            width={'100%'}
            height={'100%'}
            title={'youtube'}
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={
              campaign === 'palestine'
                ? 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed'
                : campaign === 'egyptian'
                ? 'https://youtube.com/embed/_KD4SL8KDk0?autoplay=0&feature=oembed'
                : campaign === 'gaza-camp'
                ? 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed'
                : 'https://youtube.com/embed/ScnETpaXUSY?autoplay=0&feature=oembed'
            }
          />
        </div>
      </div>
      <div className="col-span-2 md:col-span-1 px-4 py-3 lg:pl-2 lg:pr-0 md:pt-0 md:pb-0">
        <h3 className="w-full text-DarkerBlue text-2xl lg:text-3xl xl:text-4xl font-medium my-3 md:my-2 md:leading-none">
          {campaign === 'palestine'
            ? 'Gaza Can’t Wait!'
            : campaign === 'egyptian'
            ? 'Help Rebuild Lives for Palestinians escaping conflict'
            : campaign === 'gaza-camp'
            ? 'Sheltering Hope: MATW’s Life-Saving Tent Village in Gaza'
            : 'GAZA: Limbs of Hope'}
        </h3>
        <div className="w-full relative">
          <div className={`my-2 relative overflow-hidden h-auto mb-4`}>
            <p className={`text-[#78716C] text-xs- sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]-`}>
              {campaign === 'palestine'
                ? 'For almost a year, too many people have suffered for far too long due to the devastation the conflict in Palestine has left in its wake. The lives of children and their families continue to be uprooted, leaving many orphaned with no one to protect them.'
                : campaign === 'egyptian'
                ? 'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.'
                : campaign === 'gaza-camp'
                ? 'We have undertaken significant relief efforts in Gaza. Addressing the escalating humanitarian crisis, MATW established a large-scale relief camp at Yarmouk Stadium on Al-Wahda Street in Gaza City. This camp aims to provide critical assistance to families displaced by recent violence and turmoil. As a temporary haven, the camp offers essential supplies and a safe environment for those who have been left homeless and are experiencing extreme hardships.'
                : `Deep inside the ruins of Gaza lies a problem not known to many. More than 94,000
                      Palestinians have been severely injured since the beginning of the most recent conflict on
                      October 7, 2023.`}
            </p>
          </div>
        </div>
        <CustomButton
          onClick={() => setShowInfo(!showInfo)}
          title={'Click to know more'}
          icon={<ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 ${showInfo ? ' transform rotate-180' : ''}`} />}
          type="button"
          className={`!bg-LightBlue !text-[10px] !h-8`}
        />
        <div
          className={`flex flex-col overflow-hidden w-full bg-stone-100- rounded-md ${
            showInfo ? 'min-h-[600px] py-4 md:py-8' : 'h-0 py-0'
          } transition-all duration-200`}
        >
          <div className={`text-2xl md:text-4xl mb-4 ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-DarkerBlue`}>
            {selectedInfo.title}
          </div>
          <div className={`text-[#78716C] md:text-lg ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
            {selectedInfo.description}
            {/* {<RawHTML>{selectedInfo.description}</RawHTML>} */}
          </div>
          <div className="bg-white rounded-md shadow-md mt-2 grid grid-cols-2 gap-2 md:p-4">
            <div className="col-span-2">
              <div className={`text-DarkerBlue text-4xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-gotcha- font-brandingBold'}`}>
                {selectedInfo.box_title}
              </div>
            </div>
            <div className="col-span-2 mb-4">
              <div className={`text-[#78716C] md:text-lg ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
                {selectedInfo.box_description}
              </div>
            </div>
          </div>
          <CustomButton
            onClick={() => setShowInfo(!showInfo)}
            title={'Close'}
            icon={<ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 transform rotate-180`} />}
            type="button"
            className={`!bg-white !text-LightBlue !text-[10px] !h-8 shadow-md`}
          />
        </div>
        <div className="w-full flex-col flex justify-center pt-2 md:pt-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex flex-col w-full">
              {/* {currentProduct
                ? <div className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold">
                  {currentProduct && ShowCurrencies(selectedCurrency, Math.round(currentProduct.totalRaised), true)}
                </div>
                : <div className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold">
                  {ShowCurrencies(selectedCurrency, 0, false)}
                  <span className="text-xs ml-0.5">{selectedCurrency}</span>
                </div>
              } */}
              {/* {console.log("current product=", currentProduct)} */}
              {/* {currentProduct && console.log("current product=", currentProduct.totalRaised)} */}
              <div className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold">
                {/* {currentProduct && currentProduct.totalRaised ? ShowCurrencies(selectedCurrency, Math.round(currentProduct.totalRaised), true) : ShowCurrencies(selectedCurrency, 0, true)} */}
                {currentProduct && currentProduct.totalRaised
                  ? ShowCurrencies2(selectedCurrency, Math.round(currentProduct.totalRaised), true)
                  : ShowCurrencies2(selectedCurrency, 50, true)}
                <span className="text-sm ml-0.5">{selectedCurrency}</span>
                {/* {currentProduct && currentProduct.totalRaised ? `${Math.round(currentProduct.totalRaised)} ${selectedCurrency}` : ShowCurrencies(selectedCurrency, 0, true)} */}
              </div>

              {/* {currentProduct ? (
                <div className="flex items-center gap-1">
                  <span className="text-sm inline-block lg:w-auto">Raised of </span>
                  <strong className="text-DarkerBlue font-bold">
                    {ShowCurrencies(selectedCurrency, Math.round(ConvertUSD(selectedCurrency, goal, false), false))}
                  </strong>
                  <span className="text-sm inline-block lg:w-auto">goal</span>
                </div>
              ) : (
                <Skeleton height={42} />
              )} */}
              {/* {console.log("goal=", selectedCurrency, goal)} */}
              <div className="flex items-center gap-1">
                <span className="text-sm inline-block lg:w-auto">Raised of </span>
                <div className="text-DarkerBlue font-bold">
                  {ShowCurrencies2(selectedCurrency, Math.round(ConvertUSD(selectedCurrency, goal || 500000, false), false))}
                  {/* {goal && ShowCurrencies(selectedCurrency, Math.round(goal || 500000, false))} */}
                </div>
                <span className="text-sm inline-block lg:w-auto">goal</span>
              </div>
            </div>
          </div>
          <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
            {/* <div
              className={`bg-[#F60362]`}
              style={{
                width: `${handlePercentage(currentProduct ? currentProduct.totalRaised : 0, ConvertUSD(selectedCurrency, goal, false))}`,
              }}
            /> */}
            {currentProduct && (
              <div
                className={`bg-[#F60362]`}
                style={{
                  // width: `${handlePercentage(ConvertUSD(selectedCurrency, currentProduct ? currentProduct.raised : 0, false), ConvertUSD(selectedCurrency, goal, false))}`,
                  width: `${handlePercentage(currentProduct ? currentProduct.totalRaised : 0, ConvertUSD(selectedCurrency, goal, false))}`,
                }}
              />
            )}
          </div>
          {/* {currentProduct && (
            <div className="flex w-full text-xs">
              <strong className="text-DarkerBlue font-bold mr-0.5">{currentProduct.total_count}</strong>
              <span className="inline-block lg:w-auto mr-1">Supporters,</span>
              <strong className="text-DarkerBlue font-bold mr-0.5">{handleDaysLeft()}</strong>
              <span className="inline-block lg:w-auto">days left</span>
            </div>
          )} */}
          <div className="flex w-full text-xs">
            <div className="text-DarkerBlue font-bold mr-0.5">{currentProduct ? currentProduct.totalSupporters : 0}</div>
            <span className="inline-block lg:w-auto mr-1">Supporters,</span>
            <div className="text-DarkerBlue font-bold mr-0.5">{handleDaysLeft()}</div>
            <span className="inline-block lg:w-auto">days left</span>
          </div>
        </div>
        <div className="w-full hidden sm:flex items-center mt-4 md:mt-8">
          {currentProduct ? (
            <div className="w-full hidden sm:flex items-center">
              {campaign === 'limbs-of-hope' || campaign === 'palestine' || campaign === 'gaza-camp' ? (
                selectedGivingProducts.length ? (
                  <CustomButton
                    link={`/checkout`}
                    title={'Donate Now'}
                    type="button"
                    className={`!rounded-full w-full sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                  />
                ) : (
                  <CustomButton
                    title={'Donate Now'}
                    type="button"
                    className={`!rounded-full w-full sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                    onClick={() => addProduct({ product: currentProduct, currency: selectedCurrency })}
                  />
                )
              ) : (
                <CustomButton
                  // link={campaign === 'palestine' ? '/appeals/appeal/palestine-emergency' : '/appeals/appeal/palestine-refugee-resettlement'}
                  link={`/appeals/appeal/${_link}`}
                  title={'Donate Now'}
                  type="button"
                  className={`!rounded-full w-full sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                />
              )}
              <div className="order-1 md:order-2">
                <SocialBox>
                  <div
                    className={`!rounded-full !bg-DarkerBlue flex items-center justify-center text-center font-['Montserrat'] font-bold px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase`}
                  >
                    Share
                  </div>
                </SocialBox>
              </div>
            </div>
          ) : (
            <Skeleton height={43} />
          )}
        </div>
        <div className="flex sm:hidden [&>div]:w-full z-10">
          <Sticky
            stickyStyle={{ bottom: '0px', top: 'unset' }}
            stickyClassName="w-full flex justify-end items-center shadow-[rgba(189,189,189,0.5)_0px_-15px_25px_-1px] z-10"
            mode="top"
            onFixedToggle={() => {
              setIsTabsFixed(!isTabsFixed);
            }}
          >
            <div className="w-full flex items-center bg-white rounded-t-md p-2">
              {campaign === 'limbs-of-hope' || campaign === 'palestine' || campaign === 'gaza-camp' ? (
                selectedGivingProducts.length ? (
                  <CustomButton
                    link={`/checkout`}
                    title={'Donate Now'}
                    type="button"
                    className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                  />
                ) : (
                  <CustomButton
                    title={'Donate Now'}
                    type="button"
                    className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                    onClick={() => addProduct({ product: currentProduct, currency: selectedCurrency })}
                  />
                )
              ) : (
                <CustomButton
                  // link={campaign === 'palestine' ? '/appeals/appeal/palestine-emergency' : '/appeals/appeal/palestine-refugee-resettlement'}
                  link={`/appeals/appeal/${_link}`}
                  title={'Donate Now'}
                  type="button"
                  className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
                />
              )}
              {/* <CustomButton
                title={'Donate Now'}
                // link={campaign === 'palestine' ? '/appeals/appeal/palestine-emergency' : '/appeals/appeal/palestine-refugee-resettlement'}
                link={`/appeals/appeal/${_link}`}
                type="button"
                className={`!rounded-full w-full- w-[calc(100%_-_140px)] sm:w-auto ml-2 md:ml-0 md:mr-2 order-2 md:order-1`}
              /> */}
              <SocialBox top={isTabsFixed}>
                <div
                  className={`!rounded-full !bg-DarkerBlue flex items-center justify-center text-center font-['Montserrat'] font-bold px-4 text-[12px] md:text-[14px] h-[42px] md:h-[44px] transition duration-150 ease-in-out uppercase`}
                >
                  Share
                </div>
              </SocialBox>
            </div>
          </Sticky>
        </div>
      </div>
    </section>
  );
};

export default AppealCurrentProduct;

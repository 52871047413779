import React, { useEffect, useRef, useState } from 'react'
// import { Link } from 'react-router-dom';
import useHandleAddProduct from '../../utils/handleAddProduct';
import CustomButton from '../../components/general/button';

const GiveWithConfidence = ({ image, title, des, subTitle, link, product, currency, minHeight, alt }) => {
    const [showMore, setShowMore] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false);
    const ref = useRef(null);
    const [addProduct] = useHandleAddProduct();
    const handleHeight = () => {
        setShowMore(!showMore);
    }
    /* ---------------------------------- Hooks --------------------------------- */
    useEffect(() => {

        if (ref && ref?.current?.clientHeight > 95) {
            setIsOverflow(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subTitle]);
    /* -------------------------------------------------------------------------- */
    return (
        // <div className={`${minHeight ? minHeight :'min-h-[260px]'} bg-white drop-shadow-xl  shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] relative sm:min-h-[300px] md:min-h-[455px] flex flex-col justify-between overflow-hidden max-w-[378.5px] sm:w-full md:w-full`}>
        <div className={`${minHeight ? minHeight : 'min-h-[260px]'} relative w-full p-2- md:p-3- md:pb-5- rounded-xl shadow-lg bg-white sm:min-h-[300px] md:min-h-[455px] flex flex-col justify-between overflow-hidden`}>
            {/* <div className={`max-h-[300px] ${['Crowdfund with us on Launch Good USA','Crowdfund With Us on Launch Good UK'].includes(title) ? 'border border-[#97856C]' : null} `}> <img alt="" src={image} /></div> */}
            <div className={`max-h-[300px] overflow-hidden rounded-lg p-2 md:p-3`}>
                <img alt={alt ? alt : "matw"} src={image} className='rounded-lg' />
            </div>
            {title === 'Crowdfund with us on Launch Good USA'
                ? <>
                    <div className={`text-[#00A3DA] hidden sm:flex mt-2 text-sm md:text-xl lg:text-2xl text-center mb-0 sm:mb-4 h-10 md:h-16 justify-center items-center`}>
                        {title}
                    </div>
                    <div className={`text-[#00A3DA] sm:hidden mt-2 text-sm md:text-xl lg:text-2xl text-center mb-0 sm:mb-4 h-10 md:h-16 flex justify-center items-center`}>
                        Launch Good USA
                    </div>
                </>
                :
                <div className={`text-[#00A3DA] mt-2 text-sm md:text-xl lg:text-2xl text-center px-2 sm:px-0 mb-0 sm:mb-4 h-10 md:h-16 flex justify-center items-center`}>
                    {title}
                </div>
            }
            {subTitle && <div className="relative px-4">
                <div className={`text-center my-2 relative overflow-hidden ${showMore ? 'h-auto' : 'max-h-[80px]'} ${subTitle.split(' ').length > 8 ? 'mb-4' : 'mb-4'}`}>
                    <p ref={ref} className={`text-stone-400 text-xs sm:text-sm md:text-base lg:text-md overflow-hidden overflow-y-scroll- transition-all duration-200 min-h-[79px] `}>
                        {subTitle}
                    </p>
                </div>
                {isOverflow && <span className='text-[#00a3da] block w-[calc(100%_-_32px)] absolute cursor-pointer -bottom-2 text-center' onClick={() => handleHeight()}>{showMore ? 'Show less' : 'Show more'}</span>}
            </div>}
            {/* {subTitle &&
                <div className={`overflow-hidden ${showMore ? 'h-auto' : 'max-h-[85px] sm:max-h-[105px]'}`}>
                    <div ref={ref} className='overflow-hidden overflow-y-scroll- transition-all duration-200 min-h-[104px] text-center px-5 text-[#78716C] font-brandingMedium text-sm sm:text-lg leading-5'>
                        {subTitle}
                    </div>
                </div>
            }
            {isOverflow && <span className='text-[#00a3da] block w-full mb-2  bg-white absolute cursor-pointer bottom-[38px] text-center' onClick={() => handleHeight()}>{showMore ? 'Show less' : 'Show more'}</span>} */}
            <div className="flex justify-center items-center p-2 md:p-3 md:pb-5-">
                {link
                    ? <CustomButton
                        link={link}
                        title={des}
                        className='w-full'
                    />
                    : <CustomButton
                        onClick={() => { addProduct({product: product, currency: currency}) }}
                        title={des}
                        className='w-full'
                    />
                }
            </div>
        </div>
    )
}

export default GiveWithConfidence;
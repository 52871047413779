import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import { useRef } from 'react';
import ProductBox from '../../../components/general/productBox';

const ChildrenOfGazaProducts = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { products, arabic = false, lang = 'en' } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            <React.Fragment>
              <div ref={contentRef} className="flex xs:flex-col md:flex-row gap-6 justify-center xs:items-center md:items-start">
                {products.map((item, index) => {
                  return (
                    <div key={`products${index}`} className="xs:max-w-[250px] md:max-w-[300px]">
                      <ProductBox
                        stateVariableObj={{ productName: item.name }}
                        product={item}
                        arabic={arabic}
                        lang={lang}
                        // linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : '!font-normal'}
                        linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                        isStaticProduct={item.isStaticProduct}
                        staticLink={item.staticLink}
                        staticLinkText={item.staticLinkText}
                        currency={selectedCurrency ? selectedCurrency : 'AUD'}
                        titleClasses={`uppercase`}
                        isPalestineRefugee={false}
                        isVisibleDescription={false}
                        childClasses={'!h-auto'}
                        defaultPrice={item.defaultPrice ? item.defaultPrice : 100}
                      />
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ChildrenOfGazaProducts;

import { CustomButton } from '../../../components/general';
import YoutubeVideoHero from '../../../components/general/youtubeVideoHero';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import useGetAllProducts from '../hooks/useGetIndexDataHook';
import donategirl from '../../../images/eidFitr2024/donate-girl.jpg';

const ProductComponent = ({ introText, highlightedText }) => {
  const [addProduct] = useHandleAddProduct();
  const { selectedCurrency, helpThemHearPrd } = useGetAllProducts();
  return (
    <>
      <div className="md:container md:mx-auto px-4 py-8 md:py-12">
        <div className="flex justify-center">
          {introText ? (
            <h1 className="text-2xl w-full xl:w-2/3 md:text-[40px] text-[#253b7e] font-montserratBold mb-5 md:mb-8 md:text-center md:leading-[42px]">
              {introText}
              <span className="text-[#f60362]"> {highlightedText}</span>
            </h1>
          ) : (
            <h1 className="text-2xl w-full xl:w-2/3 md:text-[40px] text-[#253b7e] font-montserratBold mb-5 md:mb-8 md:text-center md:leading-[42px]">
              Ramadan may be over but there’s still time to spread Eid joy and <span className="text-[#f60362]">Feel Your Rewards!</span>
            </h1>
          )}
        </div>
        <div className="mt-4 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-montserratMedium space-y-4">
          <p>
            Eid Mubarak from our family to yours! Thanks to the kindness and generosity of our donors, and the tireless efforts of our volunteers and
            staff, Ramadan 2024 was our biggest Ramadan to date - Allahu Akbar!
          </p>
          <p>
            Our MATW team has been hard at work delivering urgent relief to help ease the burden of those who face hardship and hunger on a daily
            basis.
          </p>
          <p>
            Together, we distributed food packs to last the month, hot iftar meals, masjid iftar meals to cater to entire communities, fed orphaned
            children for an entire month, built mosques and water wells, distributed your Zakat in the most impoverished communities and continued
            supporting families in conflict zones like Palestine.
          </p>
          <p>
            In total, you helped deliver an impact of <span className="text-[#1b4194] font-bold">3,815,829</span> across{' '}
            <span className="text-[#1b4194] font-bold">30 countries</span> this Ramadan!
          </p>
        </div>
      </div>
      <div className="max-w-[1440px] mx-auto">
        <section className="xl:px-20 mb-8 sm:mb-16">
          <div className="relative overflow-hidden flex flex-wrap">
            <div
              className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[390px] md:top-1/2 md:-translate-y-1/2
                      right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute px-9 py-5 md:px-[48px] md:py-[38px]"
            >
              <p className="md:text-[18px] md:mt-5 text-white text-center font-montserratMedium">
                Eid Mubarak! May Allah shower an abundance of blessings and rewards upon you!
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <YoutubeVideoHero src={`https://www.youtube.com/embed/G5_eqYRu94k?si=kE5_6ChRdIAnfHdJ`} />
          </div>
          {/* /* -------------------------------- Why Zakat ------------------------------- */}
          <div className="w-full flex flex-wrap justify-center items-center md:container mx-auto pt-6 px-4 md:px-0">
            <div className="basis-full flex gap-x-10 flex-wrap justify-center sm:flex-nowrap ">
              <div className="basis-full sm:basis-1/3">
                <img src={donategirl} alt="Islamic Donation for emergency appeals" className="rounded-lg" />
              </div>
              <div className="basis-full sm:basis-1/3 flex justify-center items-start flex-col">
                <span className="text-[#253B7E] text-[40px] my-2 font-montserratBold xs:text-[25px]">
                  Make an orphan smile -{' '}
                  <span className="text-[#253B7E] text-[40px] font-montserratBold xs:text-[25px]">
                    deliver an <span className="text-[#f60362] text-[40px] font-montserratBold xs:text-[25px]">Eid gift!</span>
                  </span>
                </span>

                <p className="text-[#78716C] leading-[19px] sm:leading-6 md:text-[18px] font-montserratMedium">
                  There’s still time to spread Eid Joy in the most impoverished communities and conflict zones around the world. Your generosity in
                  Ramadan meant the world to orphaned children and their families. Go a step further, make their Eid a little brighter.
                </p>
                <div className="flex items-center flex-col sm:flex-row mt-6 w-full">
                  <CustomButton
                    onClicked={() => addProduct({product: helpThemHearPrd, currency: selectedCurrency})}
                    title="Donate Now"
                    className="mb-2 sm:mb-0 min-w-[226px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProductComponent;

import MaxWidthContainer from '../../../components/general/maxWidthContainer';
import useGetIndexData from '../hooks/useGetIndexDataHook';
import impactSliderRightIcon from '../../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../../images/landings/palestine/slider-icon-left.png';
import matwImpactPhoto from '../../../images/landings/palestine/matw-impact.png';
import { Arrow } from '../components/general/Arrow';
import { impactSliderData } from '../data/impactSlider';

const Impact = () => {
  const { sliderRefImpactSlider, loadedImpact, instanceRefImpactSlider, currentImpactSlide } = useGetIndexData();
  return (
    <section className="bg-[#E1EFF2] py-6 md:py-8">
      <div className="flex items-center justify-center mb-4 md:mb-5">
        <img src={matwImpactPhoto} alt="Donate to Palestine to make your MATW impact" />
      </div>
      <div className="flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
        <h2 className="md:text-4xl font-['montserrat'] text-[#253B7E] text-center font-black- font-bold text-[25px]">
          <span className="text-[18px] font-['montserrat'] font-bold md:text-[25px] text-[#1b4194] mr-1">Ramadan 2024 Total Impact:</span>
          &nbsp; <span className="text-[25px] text-[#F60362] font-bold">15,840,000</span>
        </h2>
      </div>
      <div className="hidden sm:grid sm:grid-cols-4 xl:grid-cols-10 gap-2 md:gap-4 justify-between items-center w-full md:w-11/12 mx-auto">
        {impactSliderData.map(({ img, value, title }, index) => (
          <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
            <img src={img.src} alt={img.alt} />
            <div className="flex flex-col items-center text-center">
              <h4 className="md:text-2xl font-montserratBold text-[#253B7E]">{value}</h4>
              <small className="text-xs md:text-base font-montserratMedium uppercase text-[#78716C]">{title}</small>
            </div>
          </div>
        ))}
      </div>
      <MaxWidthContainer className="sm:hidden !max-w-[1440px]">
        <div className="relative">
          <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
            {impactSliderData.map(({ img, value, title }, index) => (
              <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                <img src={img.src} alt={img.alt} />
                <div className="flex flex-col items-center text-center">
                  <h4 className="md:text-2xl font-montserratBold text-[#253B7E]">{value}</h4>
                  <small className="text-xs md:text-base font-montserratMedium uppercase text-[#78716C]">{title}</small>
                </div>
              </div>
            ))}
          </div>
          {loadedImpact && instanceRefImpactSlider.current && (
            <>
              <Arrow
                left
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
                className="bg-transparent"
                disabled={currentImpactSlide === 0}
                icon={impactSliderLeftIcon}
              />

              <Arrow
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
                disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
                className="bg-transparent"
                icon={impactSliderRightIcon}
                rightMargin
              />
            </>
          )}
        </div>
      </MaxWidthContainer>
    </section>
  );
};
export default Impact;

import React, { useState } from 'react';
import AccordionLayout from './accordionLayout';

const Accordion = (props) => {
    const { title, index, level, noBorder, children,className, lang = 'en' } = props;
    const [activeIndex, setActiveIndex] = useState(-1);
    // console.log('out of layout - active / index',activeIndex,index);
    // console.log("active, index", children)
    return (
        <div className='flex flex-col justify-center items-center w-full text-[#78716C]'>
            <AccordionLayout
                title={title}
                index={index}
                level={level}
                lang={lang}
                noBorder={noBorder}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                className={className}
            >
                {children}
            </AccordionLayout>
        </div>
    );
};

export default Accordion;
import { Helmet } from 'react-helmet-new';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import { OurPromiseSection } from '../../components/general';
// import Subscribe from './components/subscribe';
import HeaderImage, { eidMubarakBannerImages } from './components/HeaderImage';
import ProductComponent from './components/ProductComponent';
import useGetIndexData from './hooks/useGetIndexDataHook';
import Impact from './components/impact';
import Slider from './components/Slider';
import Faq from './components/Faq';
import HeroTab from '../../components/general/heroTab';
import SadaqahJariyahTabs from '../home/components/sadaqaJariyahTabs';
import { sadaqahJariyaText, tabButtonsSadaqah } from '../ramadan/laylatulQadar/fixtures';
import { images } from './data/eidMubarakImages';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const EidMubarak2024 = () => {
  const { seo, sadaqahProducts, selectedCurrency, tabButtonsProducts } = useGetIndexData();
  return (
    <NewHelmetLayout className="bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['eidMubarak2024']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          {/* <meta name="description" content={`${seo['crisisAndEmergencies']['eidMubarak2024']['description']}`} /> */}
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: 'Where Most Needed',
            value: 255,
          }}
        />
      </section>
      <HeaderImage bannerImages={eidMubarakBannerImages} />

      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: 'Where Most Needed',
              value: 255,
            }}
          />
        </div>
      </section>

      <div className="xs:hidden sm:hidden md:block">
        <OurPromiseSection />
      </div>
      <HeroTab tabButtons={tabButtonsProducts} />
      {/* /* ------------------------------- Our Promise ------------------------------ */}
      <div className="xs:block sm:block md:hidden">
        <OurPromiseSection alt={'Muslim Charity with 100% donation policy'} />
      </div>
      <Impact />
      <ProductComponent introText={`Eid Mubarak from MATW - Eid 2024 may be over but there's still time to spread`} highlightedText="Eid joy!" />
      <div className="mt-4">
        <SadaqahJariyahTabs
          sadaqahProducts={sadaqahProducts}
          currency={selectedCurrency}
          text={sadaqahJariyaText}
          tabButtonsSadaqah={tabButtonsSadaqah}
        />
      </div>
      <Slider images={images} />
      <Faq />
    </NewHelmetLayout>
  );
};

export default EidMubarak2024;

import React from 'react';
import MultiProductsBox from '../../../components/general/multiProductsBox';
import ProductBox from '../../../components/general/productBox';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import { useKeenSlider } from 'keen-slider/react';

const ProductsSection = ({ products, productsRef }) => {
  const currency = useSelector(selectSelectedCurrency);
  const productCount = products.length > 1 ? (products.length > 2 ? 2.1 : 2) : 1;
  const origin = productCount === 1 ? 'center' : 'auto';
  const [sliderRef] = useKeenSlider({
    mode: 'snap',
    breakpoints: {
      '(min-width: 320px)': {
        slides: { perView: productCount, spacing: 10, origin: origin },
        loop: productCount > 2,
      },
      '(min-width: 768px)': {
        slides: { perView: 2.1, spacing: 10 },
      },
      '(min-width: 992px)': {
        slides: { perView: 3, spacing: 16, origin: 'auto' },
      },
    },
    slides: {
      origin: 'center',
    },
  });

  return (
    <section ref={productsRef} className="bg-DarkerBlue pt-9 lg:pt-[72px] pb-6 lg:pb-[45px] text-white overflow-hidden">
      <div className="max-w-[1280px] px-4 lg:px-8 mx-auto">
        <h1 className="text-4xl lg:text-[60px] leading-normal font-bold font-brandingBold">
          Urgent Gaza Appeal:
          <br />
          Lives Hang in the Balance
        </h1>
        <p className="text-xl lg:text-[40px] leading-normal font-brandingMedium py-5 lg:py-10 ">
          "And spend in the way of Allah and do not throw [yourselves] with your [own] hands into destruction [by refraining]. And do good; indeed,
          Allah loves the doers of good."
          <br />
          <br />
          <span className="font-BrandingMediumItalic">[Surah Al Baqarah, v195]</span>
          <br />
          <br />
          <span className="text-lg leading-6 lg:text-[28px] lg:leading-9 inline-block">
            The MATW Project is on the ground, delivering life-saving aid to the people of Gaza. With your support, we can provide:
          </span>
        </p>

        <div className="w-full flex-wrap justify-center items-center relative">
          <div ref={sliderRef} className="keen-slider">
            {products.map((item, index) => {
              return (
                <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center">
                  <div className="w-full">
                    {item.products ? (
                      <MultiProductsBox
                        multiProducts={item.products}
                        staticLink={'/'}
                        title={item.title}
                        description={item.description}
                        image={item.img}
                        alt={item?.alt}
                        currency={currency ? currency : 'AUD'}
                      />
                    ) : (
                      <ProductBox
                        stateVariableObj={{ productName: item.name }}
                        product={item}
                        linkBtnClasses={item.linkBtnClasses || ''}
                        isStaticProduct={item.isStaticProduct}
                        staticLink={item.staticLink}
                        staticLinkText={item.staticLinkText}
                        currency={currency ? currency : 'AUD'}
                        titleClasses={`uppercase`}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsSection;

import React, { useEffect, useState } from 'react';
import ReactSelect from "react-select";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectSelectedCurrency } from '../../store/user';
import services from '../../services';
import Layout from '../../components/general/layout';
import SelectCurrency from '../../components/general/selectCurrency';
import ShowCurrencies from '../../utils/showCurrencies';
import useHandleAddProduct from '../../utils/handleAddProduct';

const QuickDonatePage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [addProduct] = useHandleAddProduct();
    const [productAmount, setProductAmount] = useState('');
    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedPrice, setSelectedPrice] = useState(50);
    const [errorMessage, setErrorMessage] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const [currentProduct, setCurrentProduct] = useState({
        creator: "MKD-GD-MP001",
        label: "Where Most Needed",
        value: 122
    });
    const styles = {
        menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
                width: "6px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#f1f1f1"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        })
    }
    const suggestedPrices = [
        50, 100, 250, 500
    ]
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    // const getProducts = async () => {
    //     // setIsLoaded(false);
    //     try {
    //         const response = await services.categoryProducts(33);
    //         let temp = [];
    //         response.data[0].products.map((item, index) => {
    //             if (item.name === 'Where Most Needed') {
    //                 setCurrentProduct({ value: item.id, label: item.name, creator: item.creator })
    //                 setSelectedProduct(item)
    //             }
    //             return temp.push({ value: item.id, label: item.name, creator: item.creator })
    //         })

    //         // setIsLoaded(true);
    //         setProductsList(temp);
    //         setAllProducts(response.data[0].products);
    //     } catch (error) {
    //         // setIsLoaded(true);
    //         console.error('error========', error);
    //     }
    // };
    const getProductsByName = async () => {
        let tempAllProducts = [];
        let tempProducts = [];
        try {
            const response = await services.categoryProductsByName('Make A Donation');
            response.data.products.map(item => {
                if (Number(item.status) === 1) {
                    if (item.name === 'Where Most Needed') {
                        setCurrentProduct({ value: item.id, label: item.name, creator: item.creator })
                        setSelectedProduct(item)
                    }
                    tempProducts.push({ value: item.id, label: item.name, creator: item.creator });
                    tempAllProducts.push(item)
                }
                return item;
            });
            setProductsList(tempProducts);
            setAllProducts(tempAllProducts);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const handleCurrency = (currency) => {
        setCurrentCurrency(currency.value)
    }
    const handleProduct = (value) => {
        console.log("product", value, selectedProduct);
        allProducts.map(item => item.id === value.value && setSelectedProduct(item))
        setCurrentProduct(value)
    }
    const handleProductAmount = (value) => {
        setProductAmount(value);
        setSelectedPrice(0)
        if (value) {
            if (Number(value) < 2) {
                setErrorMessage('Your minimum donation must be higher than 1')
                setDisableBtn(true);
                toast.dismiss();
                toast.error('Your minimum donation must be higher than 1', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                setErrorMessage('');
                setDisableBtn(false);
            }
        } else {
            setErrorMessage('This field is required');
            setDisableBtn(true);
        }
    }
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // getProducts()
        getProductsByName()
    }, [])
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD')
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    return (
        <Layout>
            <div className={`w-full bg-[url('../src/images/banners/banner-bg.png')] bg-cover text-white h-auto md:h-[60px] flex justify-center items-center`}>
                <div className="w-full h-full bg-[#093484]- bg-opacity-70 py-4">
                    <div className="md:container md:mx-auto px-2 sm:px-3 h-full flex flex-col justify-center items-center">
                        <h1 className='w-full text-white text-lg sm:text-xl md:text-2xl text-center'>
                            Quick Donate
                        </h1>
                    </div>
                </div>
            </div>
            <div className="lg:container mx-auto px-6 lg:px-0 pt-6">
                <div className="flex flex-wrap justify-center">
                    <div className='basis-full lg:basis-2/3 xl:basis-1/2 bg-white flex flex-wrap justify-center rounded-lg shadow-md p-3 md:p-4'>
                        <div className="basis-full py-2 md:py-4">
                            <h2 className='text-stone-600 text-center text-lg md:text-2xl'>Help us change the world with just a few clicks!</h2>
                        </div>
                        <div className="basis-full md:basis-9/12 mt-2 flex flex-wrap">
                            <div className="basis-full md:basis-2/3 md:pr-2">
                                <ReactSelect
                                    options={productsList}
                                    className='max-h-[48px]'
                                    defaultValue={currentProduct}
                                    isSearchable={false}
                                    styles={styles}
                                    onChange={(e) => handleProduct(e)}
                                />
                            </div>
                            <div className="basis-full md:basis-1/3 mt-2 md:mt-0 md:pl-2">
                                <SelectCurrency onChangeCurrency={(e) => handleCurrency(e)} />
                            </div>
                        </div>
                        <div className="basis-full md:basis-9/12 mt-2 grid grid-cols-12 gap-2 md:gap-4 py-2 md:py-4">
                            <h2 className='text-stone-500 mb-1 col-span-12 text-center'>Select an amount</h2>
                            {suggestedPrices.map((price, index) => {
                                return <div key={`prices${index}`} className="col-span-6 sm:col-span-6 md:col-span-3">
                                    <div
                                        className={`h-12 rounded-md text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[100px] ${selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-stone-200 text-stone-700 hover:bg-stone-300'}`}
                                        onClick={() => {
                                            setSelectedPrice(price);
                                            setProductAmount('');
                                            setErrorMessage('');
                                            setDisableBtn(false)
                                        }}
                                    >
                                        {
                                            currentCurrency === 'MYR'
                                                ? ShowCurrencies(currentCurrency, price * 2, false)
                                                : ShowCurrencies(currentCurrency, price, false)
                                        }
                                        <span className={`uppercase ml-1 text-base`}>{currentCurrency}</span>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="basis-full md:basis-9/12 mt-2 grid grid-cols-12  py-2 md:py-4">
                            <h2 className='text-stone-500 mb-2 col-span-12 text-center'>Or custom amount</h2>
                            <input
                                value={productAmount}
                                className='col-span-12 min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                type="number"
                                onChange={(e) => {
                                    handleProductAmount(e.target.value)
                                }}
                                placeholder="Enter Amount" />
                            {errorMessage && <span className="text-red-500 col-span-12 h-4 text-xs md:text-base">{errorMessage}</span>}
                        </div>
                        <div className="basis-full md:basis-9/12 mt-2 py-2 md:py-4">
                            <button
                                type="submit"
                                disabled={disableBtn}
                                className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out ${!disableBtn ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]' : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'}`}
                                onClick={() => handleAddNewProduct()}
                            >
                                Donate Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default QuickDonatePage;

import React from 'react';
import Card1Image from '../../../images/landings/palestine/matw-response-activity-1.png';
import Card2Image from '../../../images/landings/palestine/matw-response-activity-2.png';
import Card3Image from '../../../images/landings/palestine/matw-response-activity-3.png';
import Card4Image from '../../../images/landings/palestine/matw-response-activity-4.png';
import Card5Image from '../../../images/landings/palestine/matw-response-activity-5.png';
import Card6Image from '../../../images/landings/palestine/matw-response-activity-6.png';

const cards = [
  {
    title: 'Delivering hot meals to those in need.',
    image: Card1Image,
  },
  {
    title: 'Providing emergency support to the orphans.',
    image: Card2Image,
  },
  {
    title: 'Distributing food packs and hygiene kits',
    image: Card3Image,
  },
  {
    title: 'Providing clean drinking water',
    image: Card4Image,
  },
  {
    title: 'Resettling Palestinian families in Egypt',
    image: Card5Image,
  },
  {
    title: 'Providing relief to those displaced from Rafah and resettle them in Khan Yunis village camp.',
    image: Card6Image,
  },
];

const MatwResponseActivity = () => {
  return (
    <div className="mx-auto max-w-[1120px] px-5 py-[30px] md:py-[50px] bg-white">
      <h2 className="mb-[30px] text-[35px] md:text-[40px] text-DarkerBlue font-brandingBold font-black text-center">MATW’S Response Activity</h2>
      <div className=" grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-5">
        {cards.map((card, idx) => (
          <div key={idx} className="p-2.5 md:px-5 md:py-4 rounded-[15px] border border-[#F2F2F2] shadow-[0px_5px_10px_0px_rgba(0,0,0,0.05)]">
            <img src={card.image} alt={card.title} className="w-full h-[147px] lg:h-[250px] object-cover rounded-[15px]" />
            <p className="text-lg leading-[100%] md:leading-[120%] md:text-2xl font-brandingBold font-bold text-DarkerBlue text-center md:px-4 pt-2.5 md:pt-3">
              {card.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatwResponseActivity;

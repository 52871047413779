const AddAdrollGlobalPixelUKScript = () => {
  const addScriptOnHead = () => {
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.id = 'adroll_script';
    script.innerHTML = `adroll_adv_id = "O6SKKIBINFCMPPGDDENOOK";adroll_pix_id = "JJ2NXIJ32VAMJD3UMSCAQF";adroll_version = "2.0";(function(w, d, e, o, a) {w.__adroll_loaded = true;w.adroll = w.adroll || [];w.adroll.f = [ 'setProperties', 'identify', 'track', 'identify_email' ];var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js";for (a = 0; a < w.adroll.f.length; a++) {w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n){return function() {w.adroll.push([ n, arguments ])}})(w.adroll.f[a])}e = d.createElement('script');o = d.getElementsByTagName('script')[0];e.async = 1;e.src = roundtripUrl;o.parentNode.insertBefore(e, o);})(window, document);adroll.track("pageView");`;
    document.head.appendChild(script);
  };
  var href = window.location.href;
  var scripts = document.getElementsByTagName('script');
  var is_exist = false;
  if (href.includes('matwproject.org.uk')) {
  // if (href.includes('localhost')) {
    for (var i = scripts.length; i--; ) {
      if (scripts[i].id === 'adroll_script') is_exist = true;
    }
    !is_exist && addScriptOnHead();
  }
};

export default AddAdrollGlobalPixelUKScript;

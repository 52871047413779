import React from 'react';
import { useKeenSlider } from 'keen-slider/react';

const VideosSection = ({ videos }) => {
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 1,
      spacing: 10,
    },

    spacing: 16,
    loop: false,

    controls: true,
    slideChanged() {
      // console.log('slide changed')
    },
  });

  return (
    <div className="py-4 md:container md:mx-auto ">
      {/* <h1 className='text-[#14A2DC] text-3xl md:text-[60px] md:mb-16 text-center'>Latest Updates</h1> */}

      <div className="hidden md:flex flex-wrap md:flex-nowrap justify-center gap-6 md:gap-4 px-5 ">
        {videos?.map((item, index) => (
          <div key={index} className={`basis-full  md:basis-1/3  min-h-[auto]  rounded-[10px]`}>
            <div className="col-span-12 ">
              <iframe
                className="video rounded-xl aspect-[9/16]"
                width={'100%'}
                title={'youtube'}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={item.link}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="block md:hidden px-5 relative">
        <div ref={sliderRef} className="keen-slider px-1 py-2">
          {videos.map((item, index) => (
            <div key={index} className={`keen-slider__slide rounded-[10px]`}>
              <iframe
                className="video rounded-xl aspect-[9/16] max-h-[600px]"
                width={'100%'}
                title={'youtube'}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={item.link}
              />
            </div>
          ))}
        </div>

        <svg
          onClick={() => {
            instanceRef.current.prev();
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          className={`arrow text-xl absolute left-0 top-72 md:top-52`}
        >
          <path d="M15.6 19.2124L8.59998 12.2124L15.6 5.2124" stroke="#14A2DC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <svg
          onClick={() => {
            instanceRef.current.next();
          }}
          className={`arrow text-xl absolute -right-1 top-72 md:top-52`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path d="M8.40002 5.21338L15.4 12.2134L8.40002 19.2134" stroke="#14A2DC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
  );
};

export default VideosSection;

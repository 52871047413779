import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import services from '../../../../services';
import Multiselect from 'multiselect-react-dropdown';
import AddBanner from './addBanner';
import PreviewPageModal from './previewPage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../../../components/general/button';
import CheckBox from '../../../../components/general/checkbox';
// import ReactSelect from 'react-select';

const HandlePage = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    // const navigate = useNavigate();
    const { onClose, onSaved, selectedPage } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [allProducts, setAllProducts] = useState();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [banners, setBanners] = useState([]);
    const [selectedBanners, setSelectedBanners] = useState([]);
    const [showAddBannerModal, setShowAddBannerModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showHomePageWidget, setShowHomePageWidget] = useState(false);
    const [pageContent, setPageContent] = useState('');
    const [page, setPage] = useState(
        {
            page_url: '',
            name: '',
            meta: '',
            status: 'published',
            meta_description: '',
            banners: [],
            products: [],
            title: '',
            contents: '',
            home_widget: 0,
            // imgUrl: '',
            id: 0,
        }
    )
    const suggestedRecurringModes = [
        { label: <div><img src={'https://matw-staging.s3.eu-central-1.amazonaws.com/images/banners/water--food-aid-banner.jpg'} height="50px" width="50px" alt='test' />afghanistan-hero-banner-desktop.jpg</div>, value: 3 },
        { label: <div><img src={'https://matw-staging.s3.eu-central-1.amazonaws.com/images/banners/carousel-homepage-recurring-donations-.jpg'} height="50px" width="50px" alt='test' />carousel-homepage-recurring-donations-.jpg</div>, value: 10 },
    ]
    const pageSchema = Yup.object().shape({
        page_url: Yup.string().min(3, 'Should be 3 character long').required('Enter page URL'),
        name: Yup.string().min(3, 'Should be 3 character long').required('Enter page name'),
        meta: Yup.string().min(3, 'Should be 3 character long').required('Enter meta title'),
        meta_description: Yup.string().min(3, 'Should be 3 character long').required('Enter meta description'),
        products: Yup.array().required('Select products'),
        title: Yup.string().min(3, 'Should be 3 character long').required('Enter content title'),
        contents: Yup.string().min(3, 'Should be 3 character long').required('Enter content body'),
    });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = async () => {
        try {
            const response = await services.searchProducts();
            function compare(a, b) {
                if (a.name.trim() < b.name.trim()) {
                    return -1;
                }
                if (a.name.trim() > b.name.trim()) {
                    return 1;
                }
                return 0;
            }
            let temp = response.data.sort(compare);
            setAllProducts(temp)
        } catch (error) {
            console.error('error========', error);
        }
    };
    // const handleChange = (field, value) => {
    //     let _value = value;
    //     if (field === 'page_url') {
    //         if (_value.substring(0, 1) !== '/') _value = `/${_value}`
    //     }
    //     setPage({ ...page, [field]: _value });
    // }
    // const handleSave = () => {
    //     savePage();
    // }
    const handleSave = async (values) => {
        setIsLoading(true);
        let tempPrds = selectedProducts.map(item => item.id);
        let tempBnrs = selectedBanners.map(item => item.id);
        let tempPageUrl = values.page_url.substring(0, 1) === '/' ? values.page_url.substring(1) : values.page_url;
        const req = {
            page_url: tempPageUrl,
            meta: values.meta,
            description: values.meta_description,
            name: values.name,
            title: values.title,
            contents: values.contents,
            status: values.status,
            products: tempPrds,
            banners: tempBnrs,
            home_widget: showHomePageWidget ? 1 : 0
        }
        try {
            const response = await services.createPage(req);
            toast.success('The page has been created successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
            console.log('response get getBanners', response);
        } catch (error) {
            toast.error('Something went wrong!');
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const handleUpdate = async (values) => {
        setIsLoading(true);
        let tempPrds = selectedProducts.map(item => item.id)
        let tempBnrs = selectedBanners.map(item => item.id)
        let tempPageUrl = values.page_url.substring(0, 1) === '/' ? values.page_url.substring(1) : values.page_url;
        const req = {
            page_url: tempPageUrl,
            meta: values.meta,
            description: values.meta_description,
            name: values.name,
            title: values.title,
            contents: values.contents,
            status: values.status,
            products: tempPrds,
            banners: tempBnrs,
            home_widget: showHomePageWidget ? 1 : 0
        }
        try {
            console.log('before send', req);
            const response = await services.updatePage(page.id, req);
            // const response = await services.updateProduct(page.id,tempData);
            toast.success('The page has been updated successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
            console.log('response update page', response);
        } catch (error) {
            toast.error('Something went wrong!');
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const getBanners = async (_selectedBanners, isNewBanner = false) => {
        try {
            const response = await services.getBanners();
            // let tempBanners = response.data.map(item => item)
            setBanners(response.data);
            if (_selectedBanners) {
                let tempBnrs = [];
                response.data.map(item => {
                    _selectedBanners.map(inner_item => {
                        // if (inner_item.id === item.id) tempBnrs.push({value: item.id, name: item, label: <div><img src={item.image} height="50px" width="50px" alt='test'/>{item.name}</div>})
                        if (inner_item.id === item.id) tempBnrs.push(item)
                        // if (inner_item.id === item.id) tempBnrs.push( { label: item.name, value: item.id })
                        return inner_item
                    })
                    return item
                })
                console.log("tgemp banners=", tempBnrs, suggestedRecurringModes)
                if (isNewBanner) {
                    // let tempBnrs = selectedBanners.length ? selectedBanners : [];
                    tempBnrs.push(response.data[response.data.length - 1])
                    // setSelectedBanners(tempBnrs)
                }
                setSelectedBanners(tempBnrs)
            }
            console.log('response get getBanners', response.data);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const closeModal = () => {
        setPage({
            ...page,
            title: '',
            page_url: '',
            name: '',
            contents: '',
            imgUrl: '',
            meta: '',
            meta_description: '',
            status: 'published',
            home_widget: 0,
            id: 0,
        });
        onClose();
    }
    const handleSelectProduct = (selectedList, selectedItem) => {
        console.log("products=", selectedList);
        setSelectedProducts(selectedList)
    }
    const handleRemoveProduct = (selectedList, selectedItem) => {
        setSelectedProducts(selectedList)
    }
    const handleSelectBanner = (selectedList, selectedItem) => {
        console.log("banners=", selectedList);
        setSelectedBanners(selectedList)
    }
    const handleRemoveBanner = (selectedList, selectedItem) => {
        setSelectedBanners(selectedList)
    }
    // const handlePageURL = (value) => {

    // }
    const handleKeyDown = (value) => {
        let checkIfNum;
        if (value.key !== undefined) {
            if (value.key === "." || value.key === " " || value.key === "/") checkIfNum = true;
        }
        return checkIfNum && value.preventDefault();
    }
    const handleSubmit = (values) => {
        selectedPage ? handleUpdate(values) : handleSave(values)
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (selectedPage) {
            let tempPage = selectedPage;
            // if(tempPage.page_url.substring())
            // tempPage.page_url = `/${tempPage.page_url}`
            tempPage.page_url = tempPage.page_url.substring(0, 1) === '/' ? tempPage.page_url : `/${tempPage.page_url}`;
            tempPage.meta_description = tempPage.description;
            tempPage.home_widget === 1 ? setShowHomePageWidget(true) : setShowHomePageWidget(false)
            setPage(tempPage);
            let tempPrds = [];
            selectedPage.products.map(item => tempPrds.push({ creator: item.creator, id: item.id, name: item.name }))
            setPageContent(selectedPage.contents)
            getBanners(selectedPage.banners);
            setSelectedProducts(tempPrds)
        } else {
            getBanners();
        }
        selectedPage && console.log("page=", selectedPage);
        getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <div className='w-full flex flex-wrap items-end'>
            <Formik
                enableReinitialize
                initialValues={{
                    page_url: page.page_url,
                    name: page.name,
                    meta: page.meta,
                    status: page.status,
                    meta_description: page.meta_description,
                    banners: page.banners,
                    products: page.products,
                    title: page.title,
                    contents: page.contents,
                }}
                validationSchema={pageSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                    <form onSubmit={(values) => { handleSubmit(values); console.log("submited=", errors, values) }} className='w-full'>
                        <div className="grid grid-cols-12 items-end w-full md:pl-3- mt-3 md:mt-0 gap-2">
                            <div className="flex order-2 md:order-1 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start">
                                <label htmlFor="page_url">Page URL</label>
                                <input name='page_url' type="text" value={values.page_url}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    // onChange={(e) => handleChange('page_url', e.target.value)}
                                    onChange={(e) => {
                                        let _value = e;
                                        if (_value.target.value.substring(0, 1) !== '/') _value.target.value = `/${_value.target.value}`
                                        handleChange(_value)
                                    }}
                                    onBlur={handleBlur}
                                    className='border lowercase w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                                    />
                                {(errors.page_url && touched.page_url) &&
                                    <small id='page_url-error' className="text-[#f60362] text-xs">
                                        {errors.page_url}
                                    </small>}
                            </div>
                            <div className="flex order-3 md:order-2 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start">
                                <label htmlFor="name">Page Name</label>
                                <input name='name' type="text" value={values.name}
                                    // onChange={(e) => handleChange('name', e.target.value)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.name && touched.name) &&
                                    <small id='name-error' className="text-[#f60362] text-xs">
                                        {errors.name}
                                    </small>}
                            </div>
                            <div className="flex order-4 md:order-3 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start">
                                <label htmlFor="meta">Meta Title</label>
                                <input name='meta' type="text" value={values.meta}
                                    // onChange={(e) => handleChange('meta', e.target.value)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.meta && touched.meta) &&
                                    <small id='meta-error' className="text-[#f60362] text-xs">
                                        {errors.meta}
                                    </small>}
                            </div>
                            <div className="flex order-1 md:order-4 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start justify-start">
                                <label htmlFor="status">Status</label>
                                <select
                                    name="status"
                                    // disabled
                                    value={values.status}
                                    className='cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full'
                                    // onChange={(e) => {
                                    //     // console.log("bvalue", e, page.status)
                                    //     handleChange('status', e.target.value)
                                    // }}
                                    onChange={handleChange}
                                >
                                    <option value={"published"} className='cursor-pointer'>
                                        Published
                                    </option>
                                    <option value={"draft"} className='cursor-pointer'>
                                        Draft
                                    </option>
                                </select>
                            </div>
                            <div className="flex order-5 md:order-5 col-span-12 flex-col min-h-[80px] items-start">
                                <label htmlFor="meta_description">Meta Description</label>
                                <textarea rows="2" name='meta_description' type="text"
                                    value={values.meta_description}
                                    // onChange={(e) => handleChange('meta_description', e.target.value)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 p-2' />
                                {(errors.meta_description && touched.meta_description) &&
                                    <small id='meta_description-error' className="text-[#f60362] text-xs">
                                        {errors.meta_description}
                                    </small>}
                            </div>
                            <div className="flex order-6 col-span-12 flex-col mb-4">
                                <label htmlFor="banners">
                                    Banners
                                    <small className='ml-1 text-stone-400'>(Select banners from list or add new banner from bottom)</small>
                                </label>
                                <div className="flex flex-col md:flex-row [&>div]:w-full">
                                    {/* {console.log("selected banners=", selectedBanners)} */}
                                    <Multiselect
                                        avoidHighlightFirstOption={true}
                                        // options={[{name: 'banner 1', id: 1}, {name: 'banner 2', id: 2}, {name: 'banner 3', id: 3}]} // Options to display in the dropdown
                                        options={banners} // Options to display in the dropdown
                                        selectedValues={selectedBanners} // Preselected value to persist in dropdown
                                        onSelect={handleSelectBanner} // Function will trigger on select event
                                        onRemove={handleRemoveBanner} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                    <CustomButton
                                        onClick={() => setShowAddBannerModal(true)}
                                        type={'button'}
                                        title={'Add Banner'}
                                        className='border border-[#0f960f] hover:bg-[#096909] !text-[#0f960f] bg-transparent mt-2 md:mt-0 md:ml-2 hover:!text-white px-4 min-w-[140px] h-[46px] flex items-center justify-center rounded transition-all duration-200'
                                    />
                                    {/* <button className='border border-[#0f960f] hover:bg-[#096909] text-[#0f960f] mt-2 md:mt-0 md:ml-2 hover:text-white px-4 min-w-[140px] h-[46px] flex items-center justify-center rounded transition-all duration-200 mr-2' onClick={() => setShowAddBannerModal(true)}>Add Banner</button> */}
                                </div>
                                {/* {banners[0] && banners[0].id && <ReactSelect
                            // options={banners}
                            options={suggestedRecurringModes}
                            className='max-h-[48px] [&>div]:!rounded-md'
                            defaultValue={selectedBanners}
                            isSearchable={false}
                            // isMulti
                            name="colors"
                            // styles={styles}
                            onChange={(e) => console.log("handle changte=",e)}
                        />} */}
                            </div>
                            {selectedBanners.length > 0 &&
                                <div className="grid order-7 grid-cols-2 gap-2 col-span-12 mb-2 max-h-[300px] overflow-y-scroll border border-stone-300 p-2 rounded-md">
                                    {selectedBanners.map((item, index) => {
                                        return <div key={`banners${index}`} className='col-span-2 md:col-span-1'>
                                            <img src={item.image} alt={item.name} />
                                        </div>
                                    })}
                                </div>
                            }
                            <div className="flex order-8 col-span-12 flex-col mb-4">
                                <label htmlFor="products">Products</label>
                                <Multiselect
                                    avoidHighlightFirstOption={true}
                                    name="products"
                                    options={allProducts} // Options to display in the dropdown
                                    selectedValues={selectedProducts} // Preselected value to persist in dropdown
                                    onSelect={(val, i) => {
                                        let e = { target: { value: val, name: 'products', id: 'products' } };
                                        handleChange(e);
                                        handleSelectProduct(val, i)
                                    }} // Function will trigger on select event
                                    onBlur={handleBlur}
                                    onRemove={(val, i) => {
                                        let e = { target: { value: val, name: 'products', id: 'products' } };
                                        handleChange(e);
                                        handleRemoveProduct(val, i)
                                    }}
                                    // onRemove={handleRemoveProduct} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                                {(errors.products && touched.products) &&
                                    <small id='products-error' className="text-[#f60362] text-xs">
                                        {errors.products}
                                    </small>}
                            </div>
                            <div className="flex order-9 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start">
                                <label htmlFor="title">Content Title (H1)</label>
                                <input name='title' type="text" value={values.title}
                                    // onChange={(e) => handleChange('title', e.target.value)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.title && touched.title) &&
                                    <small id='title-error' className="text-[#f60362] text-xs">
                                        {errors.title}
                                    </small>}
                            </div>
                            <div className="flex order-10 col-span-12 flex-col mb-2">
                                <label htmlFor="contents">Content Body Text (P)</label>
                                <textarea rows="4" name='contents' type="text" value={values.contents}
                                    // onChange={(e) => { setPageContent(e.target.value); handleChange('contents', e.target.value) }}
                                    onChange={(e) => {
                                        setPageContent(e.target.value);
                                        handleChange(e)
                                    }}
                                    onBlur={handleBlur}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md p-2' />
                                {(errors.contents && touched.contents) &&
                                    <small id='contents-error' className="text-[#f60362] text-xs">
                                        {errors.contents}
                                    </small>}
                            </div>
                            <div className="flex order-11 col-span-12 items-center [&>input]:mt-0 my-3">
                                <CheckBox
                                    name="showHomePageWidget"
                                    value={showHomePageWidget}
                                    checked={showHomePageWidget}
                                    setter={(e) => setShowHomePageWidget(e)}
                                >
                                    <span className='text-stone-500'>Use HomePage Widget</span>
                                </CheckBox>
                            </div>
                        </div>
                        <div className="flex w-full flex-col md:flex-row justify-end pt-4 gap-2">
                            <CustomButton
                                onClick={() => closeModal()}
                                title='Cancel'
                                className='border border-red-500 hover:bg-red-500 !text-red-500 hover:!text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 md:mr-2'
                            />
                            {/* <CustomButton
                    onClick={() => setShowPreviewModal(true)}
                    title='Preview'
                    className='border border-[#1c0f96] hover:bg-[#1c0f96] !text-[#1c0f96] hover:!text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 md:mr-2'
                /> */}
                            <CustomButton
                                // onClick={() => selectedPage ? handleUpdate() : handleSave()}
                                disabled={isLoading}
                                isLoading={isLoading}
                                title={selectedPage ? 'Update' : 'Save'}
                                className='!bg-[#0f960f] hover:!bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200'
                            />
                        </div>
                    </form>
                )}
            </Formik>
            <AddBanner
                title='Add New Banner'
                show={showAddBannerModal}
                onClose={() => { setShowAddBannerModal(false); }}
                onSaved={() => {
                    toast.success('The banner has been added successfully');
                    getBanners(selectedBanners, true);
                }}
            />
            {showPreviewModal && <PreviewPageModal
                title={page.title}
                show={showPreviewModal}
                products={selectedProducts}
                banners={selectedBanners}
                content={pageContent}
                onClose={() => { setShowPreviewModal(false); }}
            />}
        </div>
    );
};
export default HandlePage;
import { combineReducers } from 'redux';

import user from './user';
import products from './products';
import visibility from './visibility';
import menu from './menu';
const allReducers = combineReducers({
    user,
    products,
    visibility,
    menu
});
export default allReducers;
// export default allReducers;
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
// import Layout from '../../components/general/layout';
// import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import ChildrenOfGazaProducts from './components/ChildrenOfGazaProducts';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import SeoData from '../../utils/seo.json';
import useWindowSize from '../../hooks/general/useWindowSize';
import { Helmet } from 'react-helmet-new';
import ChildrenOfGazaCrisisWidget from './components/ChildrenOfGazaCrisesWidget';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const ChildrenOfGaza = () => {
  const bannerDesktop = 'https://cdn.matwproject.org/images/banners/children-of-gaza-banner-desktop.webp';
  const bannerMobile = 'https://cdn.matwproject.org/images/banners/children-of-gaza-banner-mobile.webp';

  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const { allProducts } = useSelector(state => state.products);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);

  const [isLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();

  const productRef = useRef(null);

  const seo = SeoData[selectedcountryCode];

  const getAllProducts = async () => {
    try {
      let products = [];
      allProducts.forEach(item => {
        item.sub_categories.forEach(inner_item => {
          inner_item.products.forEach(inner_item_product => {
            if (['EMR-PAL-BMP-2024-001', 'EMR-PAL-BMP-2024-002', 'EMR-PAL-BMP-2024-003'].includes(inner_item_product.creator)) {
              products.push(inner_item_product);
            }
          });
        });
      });

      setProducts(products);
    } catch (error) {
      console.error('error========', error);
    }
  };

  const handleScrollToProducts = () => {
    if (productRef.current) {
      const topPos = productRef.current.getBoundingClientRect().top + window.scrollY - 100; // 100 pixels offset, adjust as needed
      window.scrollTo({
        top: topPos,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  useEffect(() => {
    const fetchData = async () => {
      await getAllProducts();
    };
    fetchData();
  });

  useEffect(() => {
    if (products.length > 0) {
      setIsLoaded(true);
    }
  }, [products]);

  return (
    <NewHelmetLayout layoutBgColor={isMobile ? '#DFEEF2' : '#fff'} childClasses={'!pb-0'} footerChildClasses={'!mt-0 !pt-10'}>
      {seo && (
        <Helmet>
          <title>{seo['children-of-gaza']['title']}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={seo['children-of-gaza']['description']} />
        </Helmet>
      )}
      <section className="flex justify-center">
        <img
          src={bannerDesktop}
          alt={'Save the children of Gaza'}
          className="max-[500px]:hidden w-full cursor-pointer"
          onClick={handleScrollToProducts}
        />
        <img
          src={bannerMobile}
          alt={'Save the children of Gaza'}
          className="min-[501px]:hidden w-full cursor-pointer"
          onClick={handleScrollToProducts}
        />
      </section>
      <section className="bg-[#DFEEF2] xs:hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          {/* {console.log("products :",products)} */}
          {isLoaded ? (
            <ChildrenOfGazaCrisisWidget
              floating
              className={'bg-[#DFEEF2] h-auto pt-10'}
              fixPrice={false}
              products={products}
              defaultProduct={{
                creator: products[0].creator,
                label: products[0].name,
                value: products[0].id,
                [selectedCurrencyStore.toLowerCase()]: products[0][selectedCurrencyStore.toLowerCase()],
                image_link: products[0].image_link,
              }}
            />
          ) : (
            ''
          )}
        </div>
      </section>
      <section className="bg-[#FFFFFF] py-6 md:py-8 flex flex-col">
        <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
          <div className="text-content md:px-52">
            <h1 className="text-[30px] md:text-[40px] text-[#253b7e] mb-8 leading-8 md:leading-10 text-center font-brandingBold">
              Urgent Appeal: Nourish Hope By Providing Baby Milk In Palestine For Infants
            </h1>
            <h2 className="text-sm md:text-[30px] text-[#14A2DC] md:leading-10 mb-8 italic text-center font-brandingBold">
              “Blessed is the wealth of the Muslim, from which he gives to the poor, the orphan and the wayfarer”. [Muslim]
            </h2>
          </div>
        </MaxWidthContainer>
        <p className="text-[#253B7E] font-bold md:text-[25px] mb-5 bg-[url('/src/images/children-of-gaza/text-bg-banner.webp')] text-center md:leading-7 font-brandingMedium">
          <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
            <div className="text-content md:py-10 xs:py-5">
              <p>No parent should ever have to watch their child suffer…</p>
              <p>No parent should ever have to lose their child to starvation…</p>
              <p>And no parent should outlive their child…</p>
            </div>
          </MaxWidthContainer>
        </p>
        <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
          <div className="text-content md:px-52">
            <p className="text-[#78716C] mb-5 text-center md:leading-5 font-brandingMedium">
              This is the sad reality of parents in Palestine where infants are facing a dire crisis due to the ongoing conflict.  Families are
              without access to basic necessities - and baby milk is in critically short supply.
            </p>
            <p className="text-[#78716C] mb-5 text-center md:leading-5 font-brandingMedium">
              Newborns and toddlers are suffering from malnutrition, and their mothers are struggling to provide the essential nourishment needed
              for their growth and survival.
            </p>
            <p className="text-[#78716C] font-extrabold mb-5 text-center md:leading-5 font-brandingMedium">
              Your support can make an immediate, life-saving impact and difference. By donating today, you can help us deliver vital baby milk to
              the most vulnerable infants in Palestine.
            </p>
          </div>
        </MaxWidthContainer>
      </section>
      <section className="aid-section bg-[#FFFFFF]" ref={productRef}>
        <>
          {isLoaded && (
            <div className="bg-[#253b7e]">
              <MaxWidthContainer className="!max-w-[1440px]">
                <ChildrenOfGazaProducts products={products} />
              </MaxWidthContainer>
            </div>
          )}
          <div className="md:bg-[#DFEEF2] xs:bg-[#fff] xs:px-16 xs:py-5 md:px-0 md:py-0">
            <p className="text-[#253B7E] md:text-[30px] xs:text-[20px] font-extrabold text-center md:py-10 font-brandingMedium">
              Act now to save an infant's life and secure the next generation of Palestine.
            </p>
          </div>
        </>
      </section>
    </NewHelmetLayout>
  );
};

export default ChildrenOfGaza;

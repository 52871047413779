const specialCasesFaqs = [
  {
    id: 0,
    question: 'How do special cases differ to MATWs other projects?',
    answer: `A special case is a one-to-one project where our field ambassadors or field case officers identify a case where the beneficiary situation is of extreme hardship , example ( homeless, extremely poor with limited to no Income, urgent medical operation, poor living condition , family Support )`,
  },
  {
    id: 1,
    question: 'Are special cases zakat eligible?',
    answer: `They can be Zakat applicable, but this to be introduced at a later stage when we have the capabilities on the website.`,
  },
  {
    id: 2,
    question: 'How does a case get selected?',
    answer: `Case is selected by our field case officers, they seek people with difficulties and investigate the case thoroughly then share their feedback if it’s genuine and it qualifies our support.`,
  },
  {
    id: 3,
    question: 'How can I submit a case for consideration in the special cases program?',
    answer: `You can submit for a case via Mostafa.a@matwproject.org, this will then get evaluated and assessed by the field case officers.`,
  },
];

export { specialCasesFaqs };

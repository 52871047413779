import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
// import foodInitImg from '../../images/landings/food-aid/food-initiative.jpg';
// import feedAnOrphanImg from '../../images/landings/food-aid/feed-orphan.jpg';
// import sadaqahImg from '../../images/landings/food-aid/sadaqah-jariyah.jpg';
// import buildWaterImg from '../../images/landings/food-aid/build-water.jpg';
// import crisisInitiativeImg from '../../images/landings/food-aid/crisis-initiative.jpg';
import { selectProducts } from '../../store/products';
// import banner from '../../images/landings/food-aid/food-and-water-hero-banner-desktop.jpg';
// import bannerMobile from '../../images/landings/food-aid/food-and-water-hero-banner-mobile.jpg';
import matwImpactPhoto from '../../images/landings/palestine/matw-impact.png';
import impactSliderRightIcon from '../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../images/landings/palestine/slider-icon-left.png';
import { OurPromiseSection } from '../../components/general';
import FoodWaterTabs from './components/foodWaterTabs';
import ImagesSection from './components/images';
import FAQSection from './components/faq';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import { impactSliderData } from './components/fixtures';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import CustomButton from '../../components/general/button';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { standWithMatwSection, helpUsReachMore, safeAndCleanDrinkWaterSection } from './food-and-water-content';
import { aids } from './aids';
import WidgetHome from '../../components/widgets/widgetHome';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const FoodAndWaterLandingPage = ({ lang = 'en' }) => {
  // const bannerFr = 'https://cdn.matwproject.org/images/banners/Food-Water-Aid-Generic-Hero-Banner-Desktop-FR.webp';
  // const bannerMobilFr = 'https://cdn.matwproject.org/images/banners/Food-Water-Aid-Generic-Hero-Banner-Mobile-FR.webp';
  // const bannerAr = 'https://cdn.matwproject.org/images/banners/Food-Water-Aid-Generic-Hero-Banner-Desktop-AR.webp';
  // const bannerMobileAr = 'https://cdn.matwproject.org/images/banners/Food-Water-Aid-Generic-Hero-Banner-Mobile-AR.webp';
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const [addProduct] = useHandleAddProduct();
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [foodAidProducts, setFoodAidProducts] = useState([0, 0, 0, 0]);
  const [waterAidProducts, setWaterAidProducts] = useState([0, 0, 0]);
  const [babyMilkProducts, setBabyMilkProducts] = useState([0, 0, 0]);
  const [ourFoodProduct, setOurFoodProduct] = useState();
  const [waterCrisisProduct, setWaterCrisisProduct] = useState();
  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempFoodAidProducts = [];
    let tempWaterAidProducts = [];
    let tempBabyMilkProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') console.log('ppp=', inner_item_product);
          if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') setOurFoodProduct(inner_item_product);
          if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') setWaterCrisisProduct(inner_item_product);
          // foodAidProducts
          // if (inner_item_product.creator === 'RM24-FA-009') tempFoodAidProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-002') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr' ? `NOURRIR 10 PERSONNES AFFAMÉES` : lang === 'ar' ? 'قدم وجبات لـ 10 أفراد جوعى' : inner_item_product.name,
              description:
                lang === 'fr'
                  ? `Le prix que vous payez pour un bon repas pourrait répondre aux prières de 10 personnes.`
                  : lang === 'ar'
                  ? 'يمكن أن يُلبي سعر وجبة لذيذة دعاء 10 أشخاص في حاجة.'
                  : inner_item_product.description,
            });
            tempFoodAidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-DBD-2023-01-051') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `PAIN` : lang === 'ar' ? 'الخبز' : inner_item_product.name,
              description:
                lang === 'fr'
                  ? `Acheter plusieurs miches de pain pour assurer la subsistance d'une famille nombreuse pendant une semaine.`
                  : lang === 'ar'
                  ? 'اشترِ عدة ارغفة من الخبز لتوفير الغذاء لأسرة كبيرة على مدار أسبوع واحد'
                  : inner_item_product.description,
            });
            tempFoodAidProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-RBG-2023-01-052') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `SACS DE RIZ` : lang === 'ar' ? 'أكياس الأرز' : inner_item_product.name,
              short_description:
                lang === 'fr'
                  ? `Dans les régions touchées par l'insécurité alimentaire, le riz est un aliment de base qui permet aux familles
de survivre pendant les périodes difficiles. Le programme « Sacs de riz » de la MATW vise à répondre à
 ce besoin élémentaire en fournissant du riz essentiel aux communautés vulnérables. Votre soutien peut avoir
un impact durable, en garantissant que les familles ont accès à une source vitale de nutrition.
Rejoignez-nous dans notre mission de lutte contre la faim et de fourniture de subsistance aux personnes
dans le besoin.`
                  : lang === 'ar'
                  ? 'في المناطق التي تعاني من عدم الأمن الغذائي، يعد الأرز من المواد الأساسية التي تساعد الأسر على الصمود خلال الظروف الصعبة. يهدف برنامج أكياس الأرز التابع لمنظمة MATW إلى تلبية هذه الحاجة الحيوية من خلال توفير الأرز الأساسي للمجتمعات الضعيفة. يمكن أن يكون دعمك لنا له تأثيرًا دائمًا، مما يضمن للأسر الوصول إلى مصدر غذائي حيوي. انضم إلينا في مهمتنا للتخفيف من الجوع وتوفير الغذاء لأولئك الذين في حاجة.'
                  : inner_item_product.description,
            });
            tempFoodAidProducts[2] = inner_item_product;
          }

          // waterAidProducts
          if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `AIDE POUR APPROVISIONNEMENT EN EAU` : lang === 'ar' ? 'مساعدة المياه' : inner_item_product.name,
              short_description:
                lang === 'fr'
                  ? `Faire un don pour fournir de l'eau potable aux communautés dans le besoin est un moyen d'avoir
                      un impact tangible et de changer des vies. Le manque d'accès à l'eau potable touche des millions
                      de personnes dans le monde et peut entraîner de graves problèmes de santé, notamment des
                      maladies et la malnutrition. En soutenant les programmes d'aide à l'approvisionnement en eau, vous
                      pouvez contribuer à apporter de l'eau propre et potable à ceux qui en ont désespérément besoin et leur
                      donner la possibilité de vivre une vie plus saine et plus productive. Rejoignez-nous dans notre mission qui
                      consiste à fournir un accès à l'eau potable aux communautés dans le besoin, et contribuez à créer
                      un avenir meilleur pour tous.`
                  : lang === 'ar'
                  ? 'التبرع لتوفير المياه النظيفة للمجتمعات المحتاجة هو وسيلة لتحقيق تأثير ملموس ويغير الحياة. نقص الوصول إلى المياه النظيفة يؤثر على ملايين الأشخاص في جميع أنحاء العالم ويمكن أن يؤدي إلى مشاكل صحية خطيرة، بما في ذلك الأمراض والسوء التغذية. من خلال دعم برامج مساعدات المياه، يمكنك مساعدة في جلب المياه النظيفة والآمنة لأولئك الذين يحتاجون إليها بشدة، ومنحهم الفرصة ليعيشوا حياة أكثر صحة وإنتاجية. انضم إلينا في مهمتنا لتوفير الوصول إلى المياه النظيفة للمجتمعات المحتاجة، وساهم في خلق مستقبل أفضل للجميع.'
                  : inner_item_product.description,
            });
            tempWaterAidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `DE L'EAU POUR 500 PERSONNES` : lang === 'ar' ? 'ماء لـ 500 شخص' : inner_item_product.name,
              short_description:
                lang === 'fr'
                  ? `Donnez de l'eau à 500 personnes, en apportant une nouvelle vie et de l'énergie à leur existence.`
                  : lang === 'ar'
                  ? 'قدم الماء لـ 500 شخص، مما يجلب حياة جديدة وطاقة لوجودهم.'
                  : inner_item_product.description,
            });
            tempWaterAidProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'AO07-FWD-DIS-FAO-2023-01-062') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `DE L'EAU POUR 250 PERSONNES` : lang === 'ar' ? 'ماء لـ 250 شخص' : inner_item_product.name,
              short_description:
                lang === 'fr'
                  ? `Grâce à notre appel pour un « Nouveau départ », vous pouvez avoir un impact direct sur la vie de 250
                      personnes en leur donnant accès à une source d'eau propre et salubre. Un petit don apportera une nouvelle
                      vie et une nouvelle énergie à leur existence. Imaginez la joie sur les visages de ceux qui n'auront plus à marcher
                      de longues distances pour chercher de l'eau ou à consommer de l'eau contaminée. Votre soutien permet
                      non seulement d'étancher leur soif, mais aussi d'améliorer leur santé, leurs conditions sanitaires et leur
                      bien-être général.`
                  : lang === 'ar'
                  ? 'أحدث تأثيراً مباشراً في حياة 250 شخصاً، من خلال حملتنا "بداية جديدة"، بتوفيرهم وصولاً إلى مصدر مياه نظيفة وآمنة. التبرع الصغير سيجلب حياة وطاقة جديدة لوجودهم. تخيل الفرح على وجوه أولئك الذين لن يضطروا بعد الآن إلى المشي لمسافات طويلة أو استهلاك المياه الملوثة. دعمك لا يريح عطشهم فقط ولكنه يحسن أيضاً صحتهم ونظافتهم ورفاهيتهم العامة.'
                  : inner_item_product.description,
            });
            tempWaterAidProducts[2] = inner_item_product;
          }

          // Baby milk
          if (inner_item_product.creator === 'FWD-DIS-BMW-2023-01-048') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr' ? `LAIT POUR BÉBÉ PENDANT 1 SEMAINE` : lang === 'ar' ? 'حليب الأطفال لمدة أسبوع' : inner_item_product.name,
              short_description:
                lang === 'fr'
                  ? `Pour les familles qui ont du mal à se procurer une alimentation adéquate, fournir du lait essentiel à leurs
bébés peut être un immense défi. Plus de 350 millions d'enfants souffrent de la faim chaque jour.
Grâce à l'initiative « Lait pour bébé pendant 1 semaine » de la MATW, vous pouvez contribuer à combler
ce fossé et à faire en sorte que les nourrissons reçoivent l'alimentation dont ils ont besoin pour grandir en
bonne santé. Jusqu'à présent, vous nous avez aidés à fournir du lait pour bébés à 14 308 nourrissons.
Faites un don supplémentaire pour améliorer la vie des bébés vulnérables.`
                  : lang === 'ar'
                  ? 'بالنسبة للعائلات التي تعاني من صعوبات في توفير التغذية الكافية، توفير حليب الأطفال الأساسي يمكن أن يكون تحديًا كبيرًا. أكثر من 350 مليون طفل يعانون من الجوع كل يوم. من خلال مبادرة حليب الأطفال لمدة أسبوع من MATW، يمكنك مساعدة في تقليص هذا الفجوة وضمان تلقي الرضع الغذاء اللازم لنمو صحي. حتى الآن، ساعدتنا في توفير حليب الأطفال لـ 14,308 رضيع. تبرع لتؤثر في حياة الأطفال الرضع الضعفاء.'
                  : inner_item_product.description,
            });
            tempBabyMilkProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMM-2023-01-049') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr' ? `LAIT POUR BÉBÉ PENDANT 4 SEMAINES` : lang === 'ar' ? 'حليب الأطفال لمدة 4 أسبوعًا' : inner_item_product.name,
              short_description:
                lang === 'fr'
                  ? `Plus de 350 millions d'enfants sont confrontés à l'extrême pauvreté et à la faim. Donnez aux
nourrissons vulnérables le meilleur départ possible dans la vie dans le cadre de l'appel de la MATW
« Du lait pour bébé pendant 4 semaines ». De nombreuses familles luttent pour fournir une alimentation
adéquate à leurs petits. Grâce à votre générosité, 18 270 enfants ont reçu du lait pour bébés.
Faites un don maintenant pour nous aider à fournir du lait infantile essentiel à ceux qui en ont besoin.`
                  : lang === 'ar'
                  ? 'أكثر من 350 مليون طفل يواجهون الفقر المدقع الذي يؤدي إلى الجوع. قدموا للرضع الضعفاء أفضل بداية في الحياة مع حملة حليب الأطفال لمدة 4 أسابيع من منظمة MATW. تعاني العديد من الأسر من صعوبات في توفير التغذية السليمة لأطفالهم الصغار. لقد تم توفير حليب الأطفال لـ 18,270 رضيع بفضل كرمكم. تبرعوا الآن لمساعدتنا في توفير حليب الأطفال الأساسي لأولئك الذين في حاجة.'
                  : inner_item_product.description,
            });
            tempBabyMilkProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr' ? `LAIT POUR BÉBÉ PENDANT 52 SEMAINES` : lang === 'ar' ? 'حليب الأطفال لمدة 52 أسبوعًا' : inner_item_product.name,
              short_description:
                lang === 'fr'
                  ? `Chaque enfant mérite d'avoir accès à une nutrition adéquate, mais plus de 350 millions d'enfants souffrent
de la faim. L'appel « Lait pour bébé pendant 52 semaines » de la MATW répond à ce besoin essentiel en fournissant
du lait pour bébés aux familles dans le besoin. En contribuant à cette initiative, vous pouvez avoir un impact direct
sur le bien-être des nourrissons vulnérables, en soutenant leur croissance, leur santé et leur développement
général. Jusqu'à présent, 7 665 enfants ont bénéficié de votre don de lait infantile. Donnez davantage pour aider
encore plus de bébés dans le besoin.`
                  : lang === 'ar'
                  ? 'يستحق كل طفل الوصول إلى التغذية السليمة، ومع ذلك أكثر من 350 مليون طفل يعانون من الجوع. تعالج حملة حليب الأطفال لمدة 52 أسبوعًا منMATW هذه الحاجة الحيوية من خلال توفير حليب الأطفال للأسر المحتاجة. من خلال المساهمة في هذه المبادرة، يمكنك أن تؤثر مباشرة في رفاهية الرضع الضعفاء، داعمًا لنموهم وصحتهم وتطورهم العام. استفاد 7665 رضيعًا من تبرعك بحليب الأطفال حتى الآن. تبرع الآن لمساعدة المزيد من الرضع الذين في حاجة.'
                  : inner_item_product.description,
            });
            tempBabyMilkProducts[2] = inner_item_product;
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    tempFoodAidProducts = tempFoodAidProducts.filter(n => n);
    tempWaterAidProducts = tempWaterAidProducts.filter(n => n);
    tempBabyMilkProducts = tempBabyMilkProducts.filter(n => n);
    setFoodAidProducts(tempFoodAidProducts);
    setWaterAidProducts(tempWaterAidProducts);
    setBabyMilkProducts(tempBabyMilkProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['foodAndWaterAid']['foodAndWaterAid']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['foodAndWaterAid']['foodAndWaterAid']['description']}`} />
        </Helmet>
      )}

      <section className=" sticky top-16 lg:top-24 z-30">
        <WidgetHome
          selectedCurrency={() => 0}
          defaultProduct={{
            creator: 'MKD-GEN-MFA-2023-01-118',
            label: lang === 'fr' ? `AIDE ALIMENTAIRE` : lang === 'ar' ? 'مساعدات غذائية' : 'Food Aid',
            value: 103,
          }}
        />
      </section>

      {/* <section className="flex justify-center">
        <img src={lang === 'fr' ? bannerFr : lang === 'ar' ? bannerAr : banner} alt="Donate to Palestine" className="max-[500px]:hidden w-full" />
        <img
          src={lang === 'fr' ? bannerMobilFr : lang === 'ar' ? bannerMobileAr : bannerMobile}
          alt="Donations to Palestine"
          className="min-[501px]:hidden w-full"
        />
      </section> */}
      {/* ------------------- Products Section -------------------- */}
      <FoodWaterTabs babyMilkProducts={babyMilkProducts} foodAidProducts={foodAidProducts} waterAidProducts={waterAidProducts} lang={lang} />
      {/* /* ---------------------------- Our Promise Section ---------------------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------------ Total Impact Section */}
      <section className="bg-[#E1EFF2] py-6 md:py-8">
        <div className="flex items-center justify-center mb-4 md:mb-5">
          <img src={matwImpactPhoto} alt="Donate to Palestine to make your MATW impact" />
        </div>
        <MaxWidthContainer className="!max-w-[1440px]">
          <div className="hidden sm:flex flex-wrap gap-8 justify-center items-center">
            {impactSliderData.map(({ img, value, title }, index) => {
              return (
                <div className="keen-slider__slide flex basis-1/8 md:basis-1/12 flex-col items-center gap-1" key={index}>
                  <div className="flex rounded-full p-4 bg-white justify-center items-center w-[80px] h-[80px]">
                    <img src={img.src} alt={img.alt} className="" />
                  </div>
                  <div className="flex flex-col items-center text-center">
                    <h4 className={`${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} md:text-2xl text-[#253B7E]`}>
                      {value[lang]}
                    </h4>
                    <small
                      className={`${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} text-xs md:text-base uppercase text-[#78716C]`}
                    >
                      {title[lang]}
                    </small>
                  </div>
                </div>
              );
            })}
          </div>
        </MaxWidthContainer>
        <MaxWidthContainer className="sm:hidden !max-w-[1440px]">
          <div className="relative" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
            <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
              {impactSliderData.map(({ img, value, title }, index) => (
                <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                  <img src={img.src} alt={img.alt} />
                  <div className="flex flex-col items-center text-center">
                    <h4 className={`${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} md:text-2xl text-[#253B7E]`}>
                      {value[lang]}
                    </h4>
                    <small
                      className={`${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} text-xs md:text-base uppercase text-[#78716C]`}
                    >
                      {title[lang]}
                    </small>
                  </div>
                </div>
              ))}
            </div>
            {loadedImpact && instanceRefImpactSlider.current && (
              <>
                <Arrow
                  left
                  onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
                  className="bg-transparent"
                  disabled={currentImpactSlide === 0}
                  icon={impactSliderLeftIcon}
                />

                <Arrow
                  onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
                  disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
                  className="bg-transparent"
                  icon={impactSliderRightIcon}
                  rightMargin
                />
              </>
            )}
          </div>
        </MaxWidthContainer>
      </section>
      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center font-brandingBold`}>
          {standWithMatwSection.title.main[lang]}
          <span className="text-[#F60362] ml-1">{standWithMatwSection.title.highlighted[lang]}</span>
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-lg space-y-4 font-brandingMedium`}>
          <p>
            {standWithMatwSection.description.section1[lang]}
            <br />
            <br />
            {standWithMatwSection.description.section2[lang]}
            <br />
            <br />
            {standWithMatwSection.description.section3[lang]}
            <br />
            <br />
            {standWithMatwSection.description.section4[lang]}
          </p>
        </div>
        <div className="relative overflow-hidden flex flex-wrap md:py-16 mb-8 md:mb-16">
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <YoutubeVideoHero
            src={`https://www.youtube.com/embed/IgENJBleHK4?controls=0&autoplay=1&loop=1&mute=1&playlist=IgENJBleHK4`}
            className={'md:!h-[800px] lg:!h-[1000px]'}
          />
        </div>
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] md:leading-[48px] mb-5 md:mb-8 md:text-center font-brandingBold`}>
          {helpUsReachMore.title.main1[lang]}
          <span className="text-[#F60362] mx-1">{helpUsReachMore.title.highlighted1[lang]}</span>
          {helpUsReachMore.title.main2[lang]}
          <span className="text-[#F60362] mx-1">{helpUsReachMore.title.highlighted2[lang]}</span>
          {helpUsReachMore.title.main3[lang]}
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-lg space-y-4 font-brandingMedium`}>
          <p>
            {helpUsReachMore.description.section1[lang]}
            <br />
            <br />
            {helpUsReachMore.description.section2[lang]}
          </p>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ------------------------ Section Three - Food AID ------------------------ */}
      {aids.map((item, index) => {
        return index < 2 ? (
          <div
            className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mt-8 mb-8 md:mb-0"
            dir={lang === 'ar' ? 'rtl' : 'ltr'}
          >
            <div className="basis-full md:basis-1/3 flex justify-center items-center">
              <img src={item.image} alt="" className="w-full- max-h-[550px] rounded-lg" />
            </div>
            <div className="basis-full md:basis-2/3 flex flex-col justify-around items-start md:pl-8 md:pr-2">
              <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">{item.name[lang]}</h1>
              <p className="text-lg md:text-xl">
                {item.description.main[lang]}{' '}
                {item.description.bottom ? (
                  <>
                    <br /> <br /> {item.description.bottom[lang]}
                  </>
                ) : (
                  ''
                )}
              </p>
              {item.link ? (
                <CustomButton
                  link={item.link}
                  title={item.buttonText[lang]}
                  className="text-white flex items-center justify-center text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
                />
              ) : (
                <CustomButton
                  onClick={() => {
                    ourFoodProduct && addProduct({ product: ourFoodProduct, currency: selectedCurrencyStore || 'AUD' });
                  }}
                  title={item.buttonText[lang]}
                  className="text-white flex items-center justify-center text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
                />
              )}
            </div>
          </div>
        ) : (
          ''
        );
      })}
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <div className="relative overflow-hidden flex flex-wrap md:py-16 mb-8 md:mb-16">
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <YoutubeVideoHero
            src={`https://www.youtube.com/embed/vYkDk2J4068?controls=0&autoplay=1&loop=1&mute=1&playlist=vYkDk2J4068`}
            className={'md:!h-[800px] lg:!h-[1000px]'}
          />
        </div>
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center font-brandingBold`}>
          {safeAndCleanDrinkWaterSection.title.main1[lang]}{' '}
          <span className="text-[#F60362] mx-1">{safeAndCleanDrinkWaterSection.title.highlighted[lang]}</span>
          {safeAndCleanDrinkWaterSection.title.main2[lang]}
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-lg space-y-4 font-brandingMedium`}>
          <p>{safeAndCleanDrinkWaterSection.description[lang]}</p>
        </div>
      </div>
      {aids.map((item, index) => {
        return index >= 2 ? (
          <div
            className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mt-8 mb-8 md:mb-0"
            dir={lang === 'ar' ? 'rtl' : 'ltr'}
          >
            <div className="basis-full md:basis-1/3 flex justify-center items-center">
              <img src={item.image} alt="" className="w-full- max-h-[550px] rounded-lg" />
            </div>
            <div className="basis-full md:basis-2/3 flex flex-col justify-around items-start md:pl-8 md:pr-2">
              <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">{item.name[lang]}</h1>
              <p className="text-lg md:text-xl">
                {item.description.main[lang]}{' '}
                {item.description.bottom ? (
                  <>
                    <br /> <br /> {item.description.bottom[lang]}
                  </>
                ) : (
                  ''
                )}
              </p>
              {item.link ? (
                <CustomButton
                  link={item.link}
                  title={item.buttonText[lang]}
                  className="text-white flex items-center justify-center text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
                />
              ) : (
                <CustomButton
                  onClick={() => {
                    waterCrisisProduct && addProduct({ product: waterCrisisProduct, currency: selectedCurrencyStore || 'AUD' });
                  }}
                  title={item.buttonText[lang]}
                  className="text-white flex items-center justify-center text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
                />
              )}
            </div>
          </div>
        ) : (
          ''
        );
      })}
      {/* /* -------------------------------------------------------------------------- */}
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* /* --------------------------------- Images --------------------------------- */}
        <ImagesSection />
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* --------------------------------- FAQ’s --------------------------------- */}
      <FAQSection lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};
export default FoodAndWaterLandingPage;

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`${
        props.bg ? props.bg : 'bg-[#F60362]'
      } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${
        props.className ? props.className : ''
      } ${props.left ? 'arrow--left left-[0px]' : 'arrow--right left-auto right-[0px]'}`}
      onClick={props.onClick}
    >
      {props.icon ? (
        <img alt="arrow-img" src={props.icon} className={`left-0 relative top-10 ${props.rightMargin ? 'ml-3' : '-ml-3'} `} />
      ) : (
        <svg
          className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
          {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
        </svg>
      )}
    </div>
  );
}

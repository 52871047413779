import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import Accordion from '../../components/general/accordion';
import muslimAroundImg from '../../images/landings/qurban/muslim-arounds.jpg';
import { qurbanArabicFaqs, qurbanFaqs, qurbanFranceFaqs } from './faqs';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import { selectProducts } from '../../store/products';
import QurbanTabs from './components/qurbanTabs';
import QurbanSlider from './components/qurban-slider';
import sheepBundleImg from '../../images/landings/qurban/sheep-bundle.png';
// import cowBundleImg from '../../images/landings/qurban/cow-bundle.png';
import qurbanSlider1 from '../../images/landings/qurban/qurban-slider1.jpg';
import qurbanSlider2 from '../../images/landings/qurban/qurban-slider2.jpg';
import qurbanSlider3 from '../../images/landings/qurban/qurban-slider3.jpg';
import qurbanSlider4 from '../../images/landings/qurban/qurban-slider4.jpg';
import qurbanSlider5 from '../../images/landings/qurban/qurban-slider5.jpg';
import qurbanSlider6 from '../../images/landings/qurban/qurban-slider6.jpg';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const images = [
  { src: qurbanSlider1, alt: 'Donate to palestine, they need you' },
  { src: qurbanSlider2, alt: 'Palestine Charity to help the palestinian people' },
  { src: qurbanSlider3, alt: 'Palestine donation to make an impact' },
  { src: qurbanSlider4, alt: 'Palestine appeal, help them today' },
  { src: qurbanSlider5, alt: 'donate for palestine emergency appeal' },
  { src: qurbanSlider6, alt: 'donate for palestine emergency appeal' }
];

const QurbanMainLandingPage = ({ isCampaign, lang, title = 'Qurban' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const seo = SeoData[selectedcountryCode];
  const [faqs, setFaqs] = useState(qurbanFaqs)
  const [sheepGoat, setSheepGoat] = useState({
    // title: 'qurban sheep/goat',
    title: lang === 'fr' ? 'MOUTONS/CHÈVRES QURBANI' : lang === 'ar' ? 'الأضاحي الأغنام / الماعز' : 'qurban sheep/goat',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      type: 'goat',
      title: lang === 'fr' ? 'Qurbane ultime' : lang === 'ar' ? 'حزمة أضاحي' : 'Ultimate Qurban',
    }
  });
  const [cowShare, setCowShare] = useState({
    title: lang === 'fr' ? 'Partage de vaches' : lang === 'ar' ? 'حصة البقر' : 'cow share',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      // title: 'Ultimate Qurban',
      title: lang === 'fr' ? 'Qurbane ultime' : lang === 'ar' ? 'حزمة أضاحي' : 'Ultimate Qurban',
      // type: 'cow',
      type: 'goat',
    }
  });
  const [cow, setCow] = useState({
    title: lang === 'fr' ? 'vache' : lang === 'ar' ? 'بقرة' : 'cow',
    description: '',
    checkboxProducts: [],
    products: [],
  });
  const [foodAid, setFoodAid] = useState({
    // title: 'Food Aid',
    title: lang === 'fr' ? 'aide alimentaire' : lang === 'ar' ? 'المعونة الغذائية' : 'Food Aid',
    description: '',
    checkboxProducts: [],
    products: [],
  });
  const [zakat, setZakat] = useState({
    title: lang === 'fr' ? 'zakat' : lang === 'ar' ? 'زكاة' : 'Zakat',
    description: '',
    products: [],
  });
  const [recommendedTab, setRecommendedTab] = useState({
    title: lang === 'fr' ? 'recommandée' : lang === 'ar' ? 'مُستَحسَن' : 'recommended',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      // title: 'Ultimate Qurban',
      title: lang === 'fr' ? 'Qurbane ultime' : lang === 'ar' ? 'حزمة أضاحي' : 'Ultimate Qurban',
      // type: 'cow',
      type: 'goat',
    }
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSheepGoatProducts = [];
    let tempSheepGoatBundleProduct = {};
    let tempCowShareProducts = [];
    // let tempCowShareBundleProduct = {};
    let tempCowProducts = [];
    let tempFoodAidProducts = [];
    let tempZakatProducts = [];
    let tempCheckBoxProducts = [];
    let tempRecommendedProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // Checkbox products
          if (inner_item_product.creator === 'TK-QB-2024-FA-00') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Palestine 2kg Viande` : lang === 'ar' ? `فلسطين 2 كيلو لحم` : `Palestine 2kg Meat`,
            });
            tempCheckBoxProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-FA-02') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Palestine 10kg de riz` : lang === 'ar' ? `فلسطين 10 كغ أرز` : `Palestine 10kg of Rice`,
            });
            tempCheckBoxProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-EG28') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Cadeau de l’Aïd Qurbane` : lang === 'ar' ? `أضحية هدية العيد` : `Qurban Eid Gift`,
            });
            tempCheckBoxProducts[2] = inner_item_product;
          }

          // SheepGoat
          if (inner_item_product.creator === 'QB-2024-S17') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Palestine` : lang === 'ar' ? `فلسطين` : `Palestine`,
            });
            tempSheepGoatProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S18') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Égypte` : lang === 'ar' ? `مصر` : `Egypt`,
              // nameDescription: lang === 'fr' ? `(pour les réfugiés palestiniens)` : lang === 'ar' ? `(لللاجئين الفلسطينيين)` : `(for Palestinian Refugees)`,
              name: lang === 'fr' ? `Réfugiés palestiniens` : lang === 'ar' ? `اللاجئين الفلسطينيين` : `Palestinian Refugees`,
              nameDescription: lang === 'fr' ? `(basé en Égypte)` : lang === 'ar' ? `(مقرها في مصر)` : `(based in Egypt)`,
              showHeartIcon: true,
            });
            tempSheepGoatProducts[1] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Réfugiés palestiniens moutons/chèvres` : lang === 'ar' ? `لاجئي الأغنام والماعز الفلسطينيين` : `Palestinian Sheep/Goat Refugees`,
              name: lang === 'fr' ? `Réfugiés palestiniens` : lang === 'ar' ? `اللاجئين الفلسطينيين` : `Palestinian Refugees`,
              nameDescription: lang === 'fr' ? `basé en Égypte - Moutons` : lang === 'ar' ? `مقرها مصر - الأغنام` : `based in Egypt - Sheep`,
            });
            tempRecommendedProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S19') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Yémen` : lang === 'ar' ? `اليمن` : `Yemen`,
              showHeartIcon: true,
            });
            tempSheepGoatProducts[2] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `MOUTONS/CHÈVRES QURBANI (Yémen)` : lang === 'ar' ? `الأضاحي الأغنام / الماعز (اليمن)` : `${title} Sheep/Goat (Yemen)`,
              name: lang === 'fr' ? `Moutons du Yémen` : lang === 'ar' ? `الأغنام اليمن` : `Yemen Sheep`,
            });
            tempRecommendedProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S20') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Afrique` : lang === 'ar' ? `أفريقيا` : `Africa`,
              name: lang === 'fr' ? `Afrique (Le moins cher)` : lang === 'ar' ? `أفريقيا (الأرخص)` : `Africa (Cheapest)`,
              showHeartIcon: true,
            });
            tempSheepGoatProducts[3] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `MOUTONS/CHÈVRES QURBANI (Afrique)` : lang === 'ar' ? `الأضاحي الأغنام / الماعز (أفريقيا)` : `${title} Sheep/Goat (Africa)`,
              name: lang === 'fr' ? `Afrique Mouton/Chèvre` : lang === 'ar' ? `أفريقيا الأغنام/الماعز` : `Africa Sheep/Goat`,
            });
            tempRecommendedProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S24') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Syrie` : lang === 'ar' ? `سوريا` : `Syria`,
              name: lang === 'fr' ? `Réfugiés syriens` : lang === 'ar' ? `اللاجئون السوريون` : `Syrian Refugees`,
              nameDescription: lang === 'fr' ? `(basé en Turquie)` : lang === 'ar' ? `(مقرها في تركيا)` : `(based in Türkiye)`,
            });
            tempSheepGoatProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S23') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Afghanistan` : lang === 'ar' ? `أفغانستان` : `Afghanistan`,
            });
            tempSheepGoatProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S25') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Jordan',
              name: lang === 'fr' ? `Jordanie` : lang === 'ar' ? `الأردن` : `Jordan`,
            });
            tempSheepGoatProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S21') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Pakistan',
              name: lang === 'fr' ? `Pakistan` : lang === 'ar' ? `باكستان` : `Pakistan`,
            });
            tempSheepGoatProducts[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S27') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Lebanon',
              name: lang === 'fr' ? `Liban` : lang === 'ar' ? `لبنان` : `Lebanon`,
            });
            tempSheepGoatProducts[8] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S22') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Bangladesh',
              name: lang === 'fr' ? `Bangladesh` : lang === 'ar' ? `بنغلاديش` : `Bangladesh`,
            });
            tempSheepGoatProducts[9] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-UQ-01') {
            tempSheepGoatBundleProduct = inner_item_product;
          }

          // Cow share
          // if (inner_item_product.creator === 'QB-2024-S9') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: 'Palestine',
          //   });
          //   tempCowShareProducts[0] = inner_item_product;
          // }
          if (inner_item_product.creator === 'QB-2024-S11') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'India',
              name: lang === 'fr' ? `Inde (Le moins cher)` : lang === 'ar' ? `الهند (الأرخص)` : `India (Cheapest)`,
              showHeartIcon: true,
            });
            tempCowShareProducts[0] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Part de Vache Qurban (Inde)` : lang === 'ar' ? `حصة بقرة قربان (الهند)` : `Qurban Cow Share (India)`,
              name: lang === 'fr' ? `Part de vache indienne (Le moins cher)` : lang === 'ar' ? `الهند حصة بقرة (الأرخص)` : `India Cow Share (Cheapest)`,
            });
            tempRecommendedProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S10') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Égypte` : lang === 'ar' ? `مصر` : `Egypt`,
              // nameDescription: lang === 'fr' ? `(pour les réfugiés palestiniens)` : lang === 'ar' ? `(لللاجئين الفلسطينيين)` : `(for Palestinian Refugees)`,
              name: lang === 'fr' ? `Réfugiés palestiniens` : lang === 'ar' ? `اللاجئين الفلسطينيين` : `Palestinian Refugees`,
              nameDescription: lang === 'fr' ? `(basé en Égypte)` : lang === 'ar' ? `(مقرها في مصر)` : `(based in Egypt)`,
              showHeartIcon: true,
            });
            tempCowShareProducts[1] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Part de Vache Qurban (Égypte)` : lang === 'ar' ? `حصة بقرة قربان (مصر)` : `Qurban Cow Share (Egypt)`,
              // name: lang === 'fr' ? `Réfugiés palestiniens de vaches` : lang === 'ar' ? `لاجئي البقر الفلسطينيين` : `Palestinian Cow Refugees`,
              name: lang === 'fr' ? `Réfugiés palestiniens` : lang === 'ar' ? `اللاجئين الفلسطينيين` : `Palestinian Refugees`,
              nameDescription: lang === 'fr' ? `basé en Égypte - Part de Vache` : lang === 'ar' ? `مقرها مصر - حصة بقرة` : `based in Egypt - Cow Share`,
            });
            tempRecommendedProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S12') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Afrique` : lang === 'ar' ? `أفريقيا` : `Africa`,
              showHeartIcon: true,
            });
            tempCowShareProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S16') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Liban` : lang === 'ar' ? `لبنان` : `Lebanon`,
            });
            tempCowShareProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S14') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Pakistan` : lang === 'ar' ? `باكستان` : `Pakistan`,
              isSoldOut: true,
            });
            tempCowShareProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S15') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Turkey',
              name: lang === 'fr' ? `Turquie` : lang === 'ar' ? `تركيا` : `Turkey`,
              isSoldOut: true,
            });
            tempCowShareProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S13') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Bangladesh` : lang === 'ar' ? `بنغلاديش` : `Bangladesh`,
            });
            tempCowShareProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S9') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Palestine` : lang === 'ar' ? `فلسطين` : `Palestine`,
              isSoldOut: true,
            });
            tempCowShareProducts[7] = inner_item_product;
          }
          // if (inner_item_product.creator === 'QB-2024-UQ-02') {
          //   tempCowShareBundleProduct = inner_item_product;
          // }

          // Cow
          // if (inner_item_product.creator === 'QB-2024-S1') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: 'Palestine',
          //   });
          //   tempCowProducts[0] = inner_item_product;
          // }
          // if (inner_item_product.creator === 'QB-2024-S3') {
          if (inner_item_product.creator === 'QB-2024-S3') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Inde` : lang === 'ar' ? `الهند` : `India`,
              name: lang === 'fr' ? `Inde (Le moins cher)` : lang === 'ar' ? `الهند (الأرخص)` : `India (Cheapest)`,
              showHeartIcon: true,
            });
            tempCowProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S2') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Égypte` : lang === 'ar' ? `مصر` : `Egypt`,
              // nameDescription: lang === 'fr' ? `(pour les réfugiés palestiniens)` : lang === 'ar' ? `(لللاجئين الفلسطينيين)` : `(for Palestinian Refugees)`,
              name: lang === 'fr' ? `Réfugiés palestiniens` : lang === 'ar' ? `اللاجئين الفلسطينيين` : `Palestinian Refugees`,
              nameDescription: lang === 'fr' ? `(basé en Égypte)` : lang === 'ar' ? `(مقرها في مصر)` : `(based in Egypt)`,
              showHeartIcon: true,
            });
            tempCowProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S4') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Afrique` : lang === 'ar' ? `أفريقيا` : `Africa`,
              showHeartIcon: true,
            });
            tempCowProducts[2] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Part de Vache Qurban (Afrique)` : lang === 'ar' ? `حصة بقرة قربان (أفريقيا)` : `Qurban Cow Share (Africa)`,
              name: lang === 'fr' ? `Afrique de Vache` : lang === 'ar' ? `أفريقيا بقرة` : `Africa Cow`,
            });
            tempRecommendedProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S8') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Liban` : lang === 'ar' ? `لبنان` : `Lebanon`,
            });
            tempCowProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S6') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Pakistan` : lang === 'ar' ? `باكستان` : `Pakistan`,
              isSoldOut: true,
            });
            tempCowProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S7') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Turquie` : lang === 'ar' ? `تركيا` : `Turkey`,
              isSoldOut: true,
            });
            tempCowProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S5') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Bangladesh` : lang === 'ar' ? `بنغلاديش` : `Bangladesh`,
            });
            tempCowProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S1') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Palestine` : lang === 'ar' ? `فلسطين` : `Palestine`,
              isSoldOut: true,
            });
            tempCowProducts[7] = inner_item_product;
          }

          // Food Aid
          if (inner_item_product.creator === 'QB-2024-FA-03') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Packs alimentaires Qurbane` : lang === 'ar' ? `عبوات طعام الأضاحي` : `Qurban Food Packs`,
            });
            tempFoodAidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-RBG-2023-01-052') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Sacs de riz` : lang === 'ar' ? `أكياس الأرز` : `Rice Bags`,
            });
            tempFoodAidProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Feed 10 Orphans'
              name: lang === 'fr' ? `Nourrir 10 orphelins` : lang === 'ar' ? `إطعام 10 أيتام` : `Feed 10 Orphans`,
            });
            tempFoodAidProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Feed 20 Orphans'
              name: lang === 'fr' ? `Nourrir 20 orphelins` : lang === 'ar' ? `إطعام 20 يتيما` : `Feed 20 Orphans`,
            });
            tempFoodAidProducts[3] = inner_item_product;
          }

          // Zakat
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Verser la Zakat al Maal` : lang === 'ar' ? `اعطاء زكاة المال` : `Give Zakat Al Mal`,
            });
            tempZakatProducts[1] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              staticLink: '/zakat-calculator',
              name: lang === 'fr' ? `Calculer votre Zakat` : lang === 'ar' ? `احسب زكاتك` : `Calculate your Zakat`,
              creator: 'RM24-ZM-013-'
            });
            tempZakatProducts[0] = inner_item_product;
          }

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempSheepGoatProducts = tempSheepGoatProducts.filter(n => n)
    tempCowShareProducts = tempCowShareProducts.filter(n => n)
    tempCowProducts = tempCowProducts.filter(n => n)
    tempFoodAidProducts = tempFoodAidProducts.filter(n => n)
    tempCheckBoxProducts = tempCheckBoxProducts.filter(n => n)
    tempZakatProducts = tempZakatProducts.filter(n => n)
    tempRecommendedProducts = tempRecommendedProducts.filter(n => n)

    console.log("products=", tempSheepGoatProducts, tempCowShareProducts,)
    setSheepGoat({
      ...sheepGoat,
      products: tempSheepGoatProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...sheepGoat.bundleProduct, product: tempSheepGoatBundleProduct }
    });
    setCowShare({
      ...cowShare,
      products: tempCowShareProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...cowShare.bundleProduct, product: tempSheepGoatBundleProduct }
      // bundleProduct: { ...cowShare.bundleProduct, product: tempCowShareBundleProduct }
    })
    setCow({
      ...cow,
      checkboxProducts: tempCheckBoxProducts,
      products: tempCowProducts
    })
    setFoodAid({
      ...foodAid,
      checkboxProducts: tempCheckBoxProducts,
      products: tempFoodAidProducts
    });
    setZakat({
      ...zakat,
      products: tempZakatProducts
    });
    setRecommendedTab({
      ...recommendedTab,
      products: tempRecommendedProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...recommendedTab.bundleProduct, product: tempSheepGoatBundleProduct }
    })
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    lang === 'fr'
      ? setFaqs(qurbanFranceFaqs)
      : lang === 'ar'
        ? setFaqs(qurbanArabicFaqs)
        : setFaqs(qurbanFaqs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */

  return (
    <NewHelmetLayout className="!bg-white" showRegularImg={false}>
      {seo && (
        <Helmet>
          <title>{`${seo['qurban']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['qurban']['description']}`} />
        </Helmet>
      )}
      {/* /* -------------------------------------------------------------------------- */}

      <div className='flex flex-col'>
        {/* --------------------------- Video Section ----------------------------------- */}
        <div className={`w-full md:container md:mx-auto md:py-16 ${isCampaign ? 'mt-10 order-1' : 'order-2 md:order-2'}`}>
          <div className="relative overflow-hidden flex flex-wrap">
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <YoutubeVideoHero src={`https://www.youtube.com/embed/IKvFUTs8Etc?controls=0&autoplay=1&loop=1&mute=1&playlist=IKvFUTs8Etc`} className={'md:!h-[800px] lg:!h-[1000px]'} />
          </div>
        </div>
        {/* /* -------------------------------------------------------------------------- */}

        {/* ------------------------------- Products Section ---------------------------- */}
        <div className="w-full order-1 md:order-1">
          <QurbanTabs
            hideWidget
            headerText={lang === 'fr' ? 'Ce Dhou al-hijja, redistribuez votre Qurbane pour la bonne cause en nourrissant les affamés' : lang === 'ar' ? 'في هذا ذو الحجة، قم بإطعام المحتاجين بأضحيتك!' : `This Dhul Hijjah, perform the Deed that Feeds with your ${title}!`}
            // footerText={lang === 'fr' ? 'N’attendez pas. Faites don de votre Qurban dès aujourd’hui.' : lang === 'ar' ? 'لا تنتظر. تبرع بأضاحيك اليوم.' : `Don’t wait. Donate your ${title} Today.`}
            footerText=""
            recommendedTab={recommendedTab}
            sheepGoatProducts={sheepGoat}
            cowShareProducts={cowShare}
            cowProducts={cow}
            foodAidProducts={foodAid}
            zakatProducts={zakat}
            lang={lang}
            title={title}
          />
        </div>
        {/* /* -------------------------------------------------------------------------- */}
      </div>
      {/* /* -------------------------------- helped us ------------------------------- */}
      <div className="md:container md:mx-auto px-4" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <div className="flex flex-col py-8 md:py-16">
          <h1 className={`text-[#253B7E] md:text-center leading-[30px] md:leading-[50px] text-[25px] md:text-[40px] mb-4 md:mb-6 ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'}`}>
            {lang === 'fr'
              ? `En 2023, vos contributions ont permis à plus de 6 millions de personnes de bénéficier de votre Qurbane. Chez MATW, nous accordons beaucoup de valeur à cette Amanah.`
              : lang === 'ar'
                ? `في عام 2023 ، ساعدتنا في الوصول إلى أكثر من 6 ملايين شخص من خلال الأضاحي الخاص بك. إنها أمانة نأخذها على محمل الجد في MATW.`
                : `In 2023, you helped us reach more than 6 million people with your ${title}. It’s an Amanah we at MATW take very seriously.`
            }
          </h1>
          <p className={`md:text-lg text-[#78716C] font-brandingMedium- pr-0 ${lang === 'ar' ? 'font-[AlmariMedium]' : "font-['Montserrat'] text-left sm:pr-12"}`}>
            {lang === 'fr'
              ? <>
                Nos équipes œuvrent sans relâche pour acheminer vos généreux dons de Qurbane dans les communautés les plus démunies du monde. L’année dernière, nous avons atteint certaines des zones les plus reculées de la planète, mais aussi des zones de conflit, notamment en Palestine, au Yémen et au Myanmar.
                <br />
                <br />
                De l’achat du bétail à la livraison, en passant par le soin des animaux, nous veillons à ce que chaque étape du processus du Qurbane soit méticuleusement respectée et conforme aux directives et préceptes islamiques.
              </>
              : lang === 'ar'
                ? <>
                  تعمل فرق MATW لدينا بجد لتوصيل تبرعات الأضحية داخل المجتمعات الأكثر فقرًا حول العالم. وفي العام الماضي، قمنا بالتسليم في المناطق النائية ومناطق النزاع بما في ذلك فلسطين واليمن وميانمار.
                  <br />
                  <br />
                  بدءًا من شراء الماشية والعناية بها وحتى التسليم، فإننا نضمن الالتزام الدقيق بكل خطوة من عملية الأضحية بعناية ووفقًا للمبادئ التوجيهية والمبادئ الإسلامية.
                </>
                : <>
                  Our MATW teams work hard to deliver your {title} donations within the most impoverished
                  communities around the world. Last year, we delivered within the most remote areas and
                  conflict zones including Palestine, Yemen and Myanmar.
                  <br />
                  <br />
                  From procuring livestock and tending to the animals, right through to delivery, we ensure
                  that every step of the {title} process is meticulously adhered to with care and in accordance
                  with Islamic guidelines and principles.
                </>
            }
          </p>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap mt-8 justify-center gap-x-0 sm:gap-x-8 gap-y-8">
          <div className={`basis-full md:basis-1/2 sm:bg-[url('../src/images/landings/qurban/muslim-arounds.jpg')] bg-center bg-cover rounded-md`}>
            <img className="sm:hidden" src={muslimAroundImg} alt="img3" />
          </div>
          <div className={`basis-full md:basis-1/2 flex flex-col justify-center ${lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}>
            <div className="text-[25px] md:text-[40px] text-[#253A7E] font-bold leading-[30px] md:leading-[45px]">
              {lang === 'fr'
                ? `Nous nous occupons de VOTRE Qurbane`
                : lang === 'ar'
                  ? `مسلمون حول العالم (MATW) من أجل أضاحيكم`
                  : `Muslims Around The World (MATW) for YOUR ${title}`
              }
            </div>
            <p className={`md:text-lg text-[#F60362] mt-3 sm:mt-6 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium text-left'}`}>
              {lang === 'fr'
                ? `Pendant l’Aïd al-Adha, l’équipe de Muslims Around The World (MATW) s’emploie ardemment à remplir votre obligation et à répandre la joie auprès d’autant de familles que possible.`
                : lang === 'ar'
                  ? `يعمل فريقنا بجد للوفاء بالتزامك وإدخال الفرحة لأكبر عدد ممكن من العائلات خلال عيد الأضحى.`
                  : `Our team works hard to fulfill your obligation and bring joy to as many families as possible during Eid Al Adha.`
              }
            </p>
            <p className={`md:text-lg text-[#78716C] pr-0 ${lang === 'ar' ? 'font-[AlmariMedium] sm:pl-12' : 'font-brandingMedium text-left sm:pr-12'}`}>
              {lang === 'fr'
                ? <>
                  Au cours de cette période, nous veillons à ce que les personnes les plus vulnérables – les orphelins, les veuves et les personnes âgées – soient prioritaires pour recevoir une viande sacrificielle de la plus haute qualité.
                  <br />
                  <br />
                  Toutes les commandes de Qurbane des donateurs de MATW sont traitées et préparées le jour de l’Aïd, afin de garantir leur fraîcheur.
                  <br />
                  <br />
                  Nos équipes travaillent sans relâche pour que l’Aïd et les célébrations du 10e au 13e jour de Dhou al-hijja soient un véritable succès. Le Qurbane est fraîchement préparé et livré aux familles vulnérables, chaque panier contenant 3 à 5 kg de viande, destinés à nourrir une famille de 5 à 8 personnes.
                </>
                : lang === 'ar'
                  ? <>
                    نحن نضمن إعطاء الأولوية للأسر الأكثر ضعفًا للحصول على أفضل أنواع لحوم الأضحية، والتي يتم تسليمها طازجة خلال العيد. ويشمل ذلك الأيتام والأرامل وكبار السن.
                    <br />
                    <br />
                    تتم معالجة جميع طلبات الأضاحي الإسلامية حول العالم الواردة من الجهات المانحة وتقديمها طازجة في العيد.
                    <br />
                    <br />
                    تعمل فرقنا بلا كلل استعدادًا للعيد، ومن اليوم العاشر إلى اليوم الثالث عشر من ذي الحجة، لن تدخر لحظة واحدة. تتم معالجة الأضحية وتسليمها طازجة للعائلات الضعيفة، حيث تحتوي كل عبوة قربان على 3-5 كجم من اللحوم وإطعام أسرة مكونة من 5-8 أشخاص.
                  </>
                  : <>
                    We ensure that the most vulnerable families are prioritised to receive
                    the best quality {title} meat, delivered fresh during Eid.
                    This includes orphans, widows and the elderly.
                    <br />
                    <br />
                    All of Muslim Around The World’s {title} orders received from donors are
                    processed and done fresh on Eid.
                    <br />
                    <br />
                    Our teams work tirelessly in preparation for Eid so that from the 10th to
                    13th days of Dhul Hijjah, not a single moment is spared. {title} is processed
                    and delivered fresh to vulnerable families with each {title} pack containing
                    3-5kg of meat and feeding a family of 5-8 people.
                  </>
              }
            </p>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'}`}>
          {lang === 'fr'
            ? `Comment procède-t-on ?`
            : lang === 'ar'
              ? `كيف يعمل؟`
              : `How does it work?`
          }
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-lg space-y-4 ${lang === 'ar' ? 'font-[AlmariMedium] font-medium' : 'font-brandingMedium'}`}>
          <p>
            {lang === 'fr'
              ? <>
                Notre processus est simple. À l’approche du Qurbane, faites simplement un don et notre équipe MATW s’occupera du reste. Commencez par sélectionner l’endroit où vous souhaitez envoyer votre Qurbane, ainsi que les quantités que vous voulez offrir. Notre équipe achète et contrôle le bétail à l’avance pour vérifier que les animaux répondent bien aux exigences. Votre Qurbane est ensuite préparé pendant l’Aïd et distribué aux familles les plus pauvres.
                <br />
                <br />
                Récoltez les fruits de vos bonnes actions au cours des premiers jours bénis de Dhou al-hijja, les 10 jours les plus sacrés de l’année ! Faites don de votre Qurbane dès aujourd’hui.
              </>
              : lang === 'ar'
                ? <>
                  عمليتنا بسيطة. عندما يحين وقت التبرع بالأضحية، ما عليك سوى التبرع وسيتولى فريق MATW الخاص بنا مهمة الباقي. ابدأ باختيار المكان الذي تريد إرسال الأضحية إليه والكمية التي ترغب في التبرع بها. يتم شراء الماشية وفحصها مسبقًا مع فريقنا لضمان تلبية الحيوانات لمتطلبات الفحص. ومن ثم تتم معالجة قربانك خلال العيد وتوزيعه على الأسر الأكثر فقراً!
                  <br />
                  <br />
                  احصد مكافآت أفضل 10 أيام في السنة – الأيام الأولى المباركة من ذي الحجة! أعط قربانك اليوم.
                </>
                : <>
                  Our process is simple. When the time comes to give your {title} donation, simply donate and our MATW
                  team takes care of the rest. Start by selecting where you’d like to send your {title} and the quantity
                  you’d like to donate. The livestock is purchased and checked ahead of time with our team ensuring the
                  animals meet the checking requirements. Your {title} is then processed during Eid and distributed to the
                  poorest families!
                  <br />
                  <br />
                  Reap the rewards of the 10 best days of the year – the blessed first days of Dhul Hijjah! Give your {title} today.
                </>
            }
          </p>
        </div>
        {/* <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-brandingMedium- font-['Montserrat'] space-y-4">
          <p>
            Our process is simple. When the time comes to give your Qurban donation, simply donate and our MATW
            team takes care of the rest. Start by selecting where you’d like to send your Qurban and the quantity
            you’d like to donate. The livestock is purchased and checked ahead of time with our team ensuring the
            animals meet the checking requirements. Your Qurban is then processed during Eid and distributed to the
            poorest families!
            <br />
            <br />
            Reap the rewards of the 10 best days of the year – the blessed first days of Dhul Hijjah! Give your Qurban today.
          </p>
        </div> */}
      </div>

      {/* /* --------------------------------- Images --------------------------------- */}
      <QurbanSlider images={images} />
      {/* /* -------------------------------------------------------------------------- */}

      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h2 className={`text-[#253B7E] sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'text-left font-brandingBold'}`}>
          {lang === 'fr'
            ? <><span className="text-[#F60362] mr-1">FAQ</span>sur le Qurbane</>
            : lang === 'ar'
              ? <>الأسئلة الشائعة حول<span className="text-[#F60362] mr-1">الأضاحي</span></>
              : <>{title} <span className="text-[#F60362]"> FAQ’s</span></>
          }
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <p className={`text-[#253B7E] text-[18px] md:text-2xl ${lang === 'ar' ? 'font-bold font-[AlmariMedium]' : 'font-brandingBold'}`}>{faqs[0].question}</p>
            <p className={`text-base ${lang === 'ar' ? 'font-medium font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{faqs[0].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className={`text-[#253B7E] text-[18px] md:text-2xl ${lang === 'ar' ? 'font-bold font-[AlmariMedium]' : 'font-brandingBold'}`}>{faqs[1].question}</p>
            <p className={`text-base ${lang === 'ar' ? 'font-medium font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{faqs[1].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className={`text-[#253B7E] text-[18px] md:text-2xl ${lang === 'ar' ? 'font-bold font-[AlmariMedium]' : 'font-brandingBold'}`}>{faqs[2].question}</p>
            <p className={`text-base ${lang === 'ar' ? 'font-medium font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{faqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {faqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion
                    key={`keyallfaq${index}`}
                    shadow
                    title={item.question}
                    index={index}
                    level={1}
                    noBorder={true}
                    className="!text-[#253B7E] text-lg font-brandingBold"
                  >
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};
export default QurbanMainLandingPage;
import { useEffect } from 'react';

const useScript = (url,content) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.innerHTML = content;

    // document.body.appendChild(script);
    document.getElementsByTagName('head')[0].appendChild(script);
    return () => {
      // document.body.removeChild(script);
      document.getElementsByTagName('head')[0].removeChild(script);

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
};

export default useScript;
import React from 'react';
import ProductBox from '../../../components/general/productBox';
import MultiProductsBox from '../../../components/general/multiProductsBox';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import CustomButton from '../../../components/general/button';
import SingleProductBox from '../../../components/general/singleProductBox';

const HomePageTabContent = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    products,
    currency,
    productsOnMobile,
    showArrows = true,
    showMultiProductsOnRow = true,
    viewAppealsLink,
    isPalestineRefugee = false,
    arabic = false,
    lang = 'en',
  } = props;
  const productCount = products.length > 1 ? (products.length > 2 ? 2.1 : 2) : 1;
  const origin = productCount === 1 ? 'center' : 'auto';
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    breakpoints: {
      '(min-width: 320px)': {
        slides: { perView: productCount, spacing: 10, origin: origin },
        loop: productCount > 2,
      },
      '(min-width: 768px)': {
        slides: { perView: 2, spacing: 10 },
      },
      '(min-width: 992px)': {
        slides: { perView: 3, spacing: 16, origin: 'center' },
      },
    },
    slides: {
      origin: 'center',
    },
  });
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="hidden sm:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4">
        {products &&
          products.map((item, index) => (
            <div key={`products__${index}`} className="w-full basis-1/3 lg:basis-1/4 max-w-[300px]">
              {item.products ? (
                <MultiProductsBox
                  multiProducts={item.products}
                  staticLink={'/'}
                  redirectUrl={item.redirectUrl}
                  title={item.title}
                  description={item.description}
                  image={item.img}
                  arabic={arabic}
                  _lang={lang}
                  alt={item?.alt}
                  currency={currency ? currency : 'AUD'}
                  showMultiProductsOnRow={showMultiProductsOnRow}
                />
              ) : (
                <ProductBox
                  stateVariableObj={{ productName: item.name }}
                  product={item}
                  arabic={arabic}
                  _lang={lang}
                  // linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : '!font-normal'}
                  linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                  isStaticProduct={item.isStaticProduct}
                  staticLink={item.staticLink}
                  staticLinkText={item.staticLinkText}
                  currency={currency ? currency : 'AUD'}
                  titleClasses={`uppercase`}
                  isPalestineRefugee={isPalestineRefugee}
                />
              )}
            </div>
          ))}
        {viewAppealsLink && (
          <div className="flex w-full justify-center items-center py-2 md:py-4">
            <CustomButton
              link={viewAppealsLink}
              title={arabic || lang === 'ar' ? 'عرض كافة الاستئنافات' : lang === 'fr' ? `VOIR TOUS LES APPELS` : 'View all Appeals'}
              className={arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}
            />
          </div>
        )}
      </div>
      <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
        {showArrows && products.length > 1 && (
          <>
            <div
              className="absolute translate-y-1/2 h-[24px] top-[47%] -left-[15px] z-10"
              onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
            >
              <LiaAngleLeftSolid color="white" size={30} />
            </div>
            <div
              className="absolute translate-y-1/2 h-[24px] top-[47%] -right-[15px] z-10"
              onClick={e => e.stopPropagation() || instanceRef.current?.next()}
            >
              <LiaAngleRightSolid color="white" size={30} />
            </div>
          </>
        )}
        <div ref={sliderRef} className="keen-slider">
          {productsOnMobile ? (
            <div className="keen-slider__slide basis-full flex justify-center">
              <div className="w-full max-w-[300px]">
                {productsOnMobile.products.length && (
                  <MultiProductsBox
                    multiProducts={productsOnMobile.products}
                    staticLink={'/'}
                    title={productsOnMobile.title}
                    description={productsOnMobile.description}
                    image={productsOnMobile.img}
                    alt={productsOnMobile?.alt}
                    arabic={arabic}
                    _lang={lang}
                    currency={currency ? currency : 'AUD'}
                  />
                )}
              </div>
            </div>
          ) : products.length > 1 ? (
            products.map((item, index) => {
              return (
                <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center">
                  <div className="w-full max-w-[300px]">
                    {item.products ? (
                      <MultiProductsBox
                        multiProducts={item.products}
                        staticLink={'/'}
                        title={item.title}
                        description={item.description}
                        arabic={arabic}
                        _lang={lang}
                        image={item.img}
                        alt={item?.alt}
                        currency={currency ? currency : 'AUD'}
                      />
                    ) : (
                      <ProductBox
                        roundedButton
                        stateVariableObj={{ productName: item.name }}
                        product={item}
                        arabic={arabic}
                        _lang={lang}
                        linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                        isStaticProduct={item.isStaticProduct}
                        staticLink={item.staticLink}
                        staticLinkText={item.staticLinkText}
                        currency={currency ? currency : 'AUD'}
                        titleClasses={`uppercase`}
                        isPalestineRefugee={isPalestineRefugee}
                      />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            products[0] && (
              <SingleProductBox
                stateVariableObj={{ productName: products[0].name }}
                product={products[0]}
                arabic={arabic}
                lang={lang}
                linkBtnClasses={products[0].linkBtnClasses ? products[0].linkBtnClasses : ''}
                isStaticProduct={products[0].isStaticProduct}
                staticLink={products[0].staticLink}
                staticLinkText={products[0].staticLinkText}
                currency={currency ? currency : 'AUD'}
                titleClasses={`uppercase`}
                isPalestineRefugee={isPalestineRefugee}
              />
            )
          )}
        </div>
        {viewAppealsLink && (
          <div className="flex w-full justify-center items-center py-2 md:py-4 mt-2">
            <CustomButton
              link={viewAppealsLink}
              title={
                arabic ? 'عرض كافة الاستئنافات' : lang === 'fr' ? 'VOIR TOUS LES APPELS' : lang === 'ar' ? 'عرض كافة الاستئنافات' : 'View all Appeals'
              }
              className={arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default HomePageTabContent;

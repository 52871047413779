const countDownTimer = (countDownDate)=>{
     // Get the current date and time
  const now = new Date().getTime();
  
  // Calculate the time remaining
  const distance = countDownDate - now;
  
  // Calculate days, hours, minutes, and seconds
  const days = ("0" + Math.floor(distance / (1000 * 60 * 60 * 24)).toString()).slice(-2);
  const hours = ("0" + Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString()).slice(-2);
  const minutes = ("0" + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString()).slice(-2);
  const seconds = ("0" + Math.floor((distance % (1000 * 60)) / 1000).toString()).slice(-2);
  
  
  
  // If the countdown is finished, display a message
  if (distance < 0) {
      return 0;
    }
    return [days,hours,minutes,seconds];
}
const formatNumberWithTwoDecimals=(number)=> {
  // Check if the number is not already a float
  if (Number.isInteger(number)) {
    return number; // Convert integer to float with two decimal places
  } else {
    return parseFloat(number).toFixed(2); // Ensure a float has exactly two decimal places
  }
}
export {countDownTimer,formatNumberWithTwoDecimals};
const feastFaqs = [
  {
    id: 0,
    question: 'How do food donations support orphans?',
    answer: `Donating food helps support orphans by giving them nutritious meals.
    For the orphans in their care to have access to food, orphanages need a consistent supply of food.
    Food donations help to ensure that the orphanages have the necessary supplies to provide meals to the orphans.`,
  },
  {
    id: 1,
    question: 'Why is it significant in Islam to feed orphans?',
    answer: `In Islam, feeding orphans is significant since it is regarded as both a basic requirement and a way to carry out the obligation to aid the poor.
    Additionally, feeding orphans gives the children who have lost their parents comfort and security.`,
  },
  {
    id: 2,
    question: 'Are there any specific food items that should be donated to orphanages?',
    answer: `When contributing food to orphanages, it's crucial to include wholesome, nutritional foods that can give the kids a balanced diet.
    The best donations are typically non-perishable items, dry products, and canned food.`,
  },
  {
    id: 3,
    question: 'What are some ways I can help MATW provide food for Muslim orphans?',
    answer: `Muslim orphan food charities can be supported through donations, volunteering, and spreading awareness about their work.
    Many of these charities have specific programs that allow donors to sponsor meals for orphans on a monthly or yearly basis.`,
  },
  {
    id: 4,
    question: 'How do orphan food aid programs differ from other food aid programs?',
    answer: `Orphan food aid programs specifically target orphaned children, who are often among the most vulnerable and in need of support.
    These programs focus on providing regular meals to orphaned children, as opposed to providing emergency food aid in response to a crisis or disaster.`,
  },
  {
    id: 5,
    question: 'Can orphan food aid programs help address malnutrition among orphaned children?',
    answer: `Yes, orphan food aid programs can help address malnutrition among orphaned children
    by providing them with regular access to nutritious and balanced meals.
    This can help improve their overall health and well-being.`,
  },
  {
    id: 6,
    question: 'How can I support an orphan food aid program?',
    answer: `You can help an organization that implements orphan food aid programs by volunteering with them,
    making a donation to a reputable charity such as MATW, that helps orphans with their food needs,
    or organizing a food drive to raise money for a charity or orphanage.`,
  },
];
const supportFaqs = [
  {
    id: 0,
    question: 'What is orphan support?',
    answer: `Support and care for children who have lost one or both of their parents is referred to as orphan support.
    Support for orphans may take the form of housing, food, instruction, medical treatment, and emotional support.`,
  },
  {
    id: 1,
    question: 'How can I support an orphan through MATW Project?',
    answer: `You can support an orphan through MATW Project by donating to one of our support programs.
    The support programs cover the cost of food, shelter, education, and healthcare for the orphan for a year, three months, or one month.`,
  },
  {
    id: 2,
    question: 'Can I choose the country where I want to support an orphan through MATW Project?',
    answer: `Yes, you can choose to support an orphan in Togo, Bangladesh, Lebanon, Jordan, Palestine, or Yemen.`,
  },
  {
    id: 3,
    question: 'How does MATW Project ensure the well-being of orphans?',
    answer: `MATW Project's highly qualified staff and volunteers put through countless hours to make sure the orphans get the finest care possible.
    The orphanage homes are furnished with all the amenities required to ensure the children's welfare and growth.`,
  },
  {
    id: 4,
    question: 'How is the money donated to MATW Project used?',
    answer: `The money donated to MATW Project is used to support our various initiatives,
    including orphanage houses, orphanage maintenance, and support programs for orphans.
    The organization is committed to ensuring that the funds are used in a transparent and effective manner.`,
  },
  {
    id: 5,
    question: 'How can I make a donation to MATW Project?',
    answer: `You can make a donation to MATW Project through our website.
    The organization accepts donations through various payment methods, including credit card, PayPal, and bank transfer.`,
  },
  {
    id: 6,
    question: 'How can I get involved with MATW Project?',
    answer: `You can get involved with MATW Project by volunteering your time or skills.
    Volunteers who can assist with a variety of duties, such as fundraising, marketing, and event preparation, are constantly needed by the organization.
    Through social media and word-of-mouth, you can also spread the word about the company and its initiatives.`,
  },
  {
    id: 7,
    question: 'Can I sponsor an orphan through MATW Project?',
    answer: `Yes, you can sponsor an orphan through MATW Project by donating to one of their support programs.
    The support programs cover the cost of food, shelter, education, and healthcare for the orphan for a year, three months, or one month.`,
  },
  {
    id: 8,
    question: 'How much does it cost to support an orphan through MATW Project?',
    answer: `MATW Project orphan support fees vary by nation and term of support, as well as other factors.
    You can visit the organization's website for more information on the cost of each support program.`,
  },
  {
    id: 9,
    question: 'How long does it take for my donation to reach an orphan?',
    answer: `The MATW Project works to ensure that your donation reaches the orphan as soon as possible.
    The organization has efficient systems in place to ensure that the funds are transferred to the orphanage houses and used to support the orphans.`,
  },
  {
    id: 10,
    question: 'How can I track the progress of the orphan I am supporting through MATW Project?',
    answer: `The MATW Project provides regular updates on the progress of the orphans through their website and social media channels.
    You can also contact the organization directly for more information on the orphan you are supporting.`,
  },
  {
    id: 11,
    question: 'Can I support more than one orphan through MATW Project?',
    answer: `Yes, you can support multiple orphans through MATW Project by donating to multiple support programs or sponsoring multiple orphans.`,
  },
  {
    id: 12,
    question: 'What is an orphanage house?',
    answer: `An orphanage house is a facility that provides shelter and care for orphans who have lost one or both parents.
    The orphanage house provides a safe and nurturing environment for the orphans to grow and develop.`,
  },
  {
    id: 13,
    question: 'How does MATW Project ensure the safety and security of the orphans in the orphanage houses?',
    answer: `MATW takes the safety and security of orphans very seriously.
    The organization has strict policies and procedures in place to ensure that our orphans are protected from harm.
    The orphanage houses are equipped with security systems, and the staff and volunteers are carefully screened and trained.`,
  },
  {
    id: 14,
    question: 'Can I volunteer at an orphanage house supported by MATW Project?',
    answer: `Yes, you can volunteer at an orphanage house supported by MATW Project.
    The organization welcomes volunteers who can help with various tasks, including tutoring, mentoring, and organizing activities for the orphans.`,
  },
  {
    id: 15,
    question: 'What other support does MATW Project offer to orphans?',
    answer: `The MATW Project offers a wide range of support to orphans, including healthcare, counselling, and emotional support.
    The organization also works to reunite orphans with their extended families or place them with suitable caregivers.`,
  },
  {
    id: 16,
    question: 'How does MATW Project ensure the long-term well-being of orphans?',
    answer: `MATW Project has a comprehensive approach to ensuring the long-term well-being of orphans.
    The organization works to provide orphans with the necessary skills and resources to become self-sufficient adults.
    The organization also works to ensure that the orphans have a safe and stable home environment.`,
  }
];
const educationFaqs = [
  {
    id: 0,
    question: 'What kind of education do the orphans receive through MATW Project?',
    answer: `The orphans supported by MATW Project receive a quality education that is tailored to their needs and abilities.
    The organization works to ensure that orphans have access to the necessary resources and tools to succeed academically.`,
  },
  {
    id: 1,
    question: 'How does MATW Project support orphan education?',
    answer: `MATW Project supports orphan education by providing the necessary resources and infrastructure, such as school supplies, textbooks, and classrooms.
    The organization also hires qualified teachers and provides scholarships for higher education.`,
  },
  {
    id: 2,
    question: 'Can I donate specifically to support orphan education through MATW Project?',
    answer: `Yes, you can donate specifically to support orphan education through MATW Project.
    The organization has specific education-focused programs that you can donate to.`,
  },
  {
    id: 3,
    question: 'Why is supporting education for orphans important?',
    answer: `Supporting education for orphans is crucial because it provides them with the necessary knowledge and skills to improve their lives
    and become self-sufficient members of society. Education also empowers them to break the cycle of poverty, achieve their goals, and build a better future.
    Orphans who have access to education are more likely to secure employment and have a higher standard of living, improving their overall well-being.`,
  },
  {
    id: 4,
    question: `How does MATW Project support orphans' education?`,
    answer: `MATW Project supports orphans' education by providing financial assistance, school supplies,
    and mentorship to ensure orphans have the necessary resources to succeed.
    The charity covers the costs of tuition, transportation, and other educational expenses, ensuring that orphans have access to education.
    The mentorship programs offered by MATW Project provide orphans with guidance and support as they navigate their educational journey.`,
  },
  {
    id: 5,
    question: `What impact has MATW Project had on orphans' education?`,
    answer: `MATW Project has had a significant impact on the lives of orphans by providing education support to hundreds of children.
    The financial assistance provided by the charity has enabled orphans to attend school, which would otherwise have been impossible.
    The mentorship programs offered by MATW Project have helped orphans develop valuable skills and build their confidence,
    enabling them to overcome the challenges they face.
    The school supplies provided by the charity have also had a significant impact, ensuring that orphans have everything they need to succeed academically.`,
  }
];
const orphansFaqs = [
  {
    id: 0,
    question: {
      en: 'What is orphan sponsorship and charity, exactly?',
      fr: 'Qu’est-ce que le parrainage d’orphelins et la charité, exactement ?',
      ar: 'س: ما هي رعاية الأيتام والجمعيات الخيرية بالضبط؟'
    },
    answer: {
      en: 'Orphan sponsorship and charity refers to charitable organizations and initiatives created to help and look after kids who have lost both of their parents.',
      fr: 'Le parrainage d’orphelins et la charité désignent les organismes de bienfaisance et les initiatives caritatives créés pour aider et s’occuper des enfants qui ont perdu leurs deux parents.',
      ar: 'رعاية الأيتام والجمعيات الخيرية تشير إلى المؤسسات الخيرية والمبادرات التي تم إنشاؤها لمساعدة ورعاية الأطفال الذين فقدوا والديهم.'
    }
  },
  {
    id: 1,
    question: {
      en: 'Why is orphan charity important?',
      fr: 'Pourquoi la charité en faveur des orphelins est-elle importante?',
      ar: 'س: لماذا تعتبر الجمعيات الخيرية للأيتام مهمة؟'
    },
    answer: {
      en: `Orphans are among the most vulnerable people in society and frequently don't receive the attention and support they need. Orphan charity is significant because it gives these kids the resources and assistance they need to succeed despite their challenging situations.`,
      fr: `Les orphelins sont parmi les personnes les plus vulnérables de la société et ne reçoivent souvent pas l’attention et le soutien dont ils ont besoin. L'action caritative en faveur des orphelins est importante parce qu’elle offre à ces enfants les ressources et l’aide dont ils ont besoin pour s’en sortir malgré leurs situations difficiles.`,
      ar: 'الأيتام من بين أكثر الأشخاص ضعفًا في المجتمع وكثيرًا ما لا يتلقون الاهتمام والدعم اللازمين. تعتبر الجمعيات الخيرية للأيتام مهمة لأنها تمنح هؤلاء الأطفال الموارد والدعم الذين يحتاجون إليه للنجاح على الرغم من وضعهم الصعب.'
    }
  },
  {
    id: 2,
    question: {
      en: 'What type of assistance do orphan charities offer?',
      fr: 'Quel type d’aide les organismes caritatifs pour orphelins offrent-ils?',
      ar: 'س: ما نوع المساعدة التي تقدمها الجمعيات الخيرية للأيتام؟'
    },
    answer: {
      en: 'Orphan organizations offer a wide range of assistance services, such as food and clothes, shelter, healthcare, and education.',
      fr: 'Les organismes d’aide aux orphelines offrent un large éventail de services d’aide, tels que la nourriture et les vêtements, l’hébergement, les soins de santé et l’éducation.',
      ar: 'تقدم المؤسسات الخيرية للأيتام مجموعة متنوعة من خدمات المساعدة، مثل توفير الطعام والملابس، والإسكان، والرعاية الصحية، والتعليم.'
    }
  },
  {
    id: 3,
    question: {
      en: 'How do I donate to an orphanage?',
      fr: 'Comment faire un don à un orphelinat?',
      ar: 'يمكنك التبرع من خلال التبرع لدى مركز الأيتام التابع لمنظمة MATW، أو رعاية يتيم، أو دعم العديد من التكاليف المرتبطة بإدارة دار الأيتام.'
    },
    answer: {
      en: 'You can donate by contributing to MATW’S orphan centre, sponsoring an orphan, or supporting with the many costs associated with running an orphanage.',
      fr: 'Vous pouvez faire un don en contribuant au centre pour orphelins de la MATW, en parrainant un orphelin ou en assumant les nombreux coûts associés à la gestion d’un orphelinat.',
      ar: 'يمكنك التبرع من خلال التبرع لدى مركز الأيتام التابع لمنظمة MATW، أو رعاية يتيم، أو دعم العديد من التكاليف المرتبطة بإدارة دار الأيتام'
    }
  },
  {
    id: 4,
    question: {
      en: 'What does it mean to sponsor an orphan?',
      fr: 'Que signifie parrainer un orphelin?',
      ar: 'س: ماذا يعني رعاية يتيم؟'
    },
    answer: {
      en: 'Sponsoring an orphan means committing to providing regular financial support to help cover the costs of their education, healthcare, and other basic needs. This type of support can be life-changing for a child who has lost their parents and is struggling to make ends meet.',
      fr: 'Parrainer un orphelin signifie s’engager à fournir un soutien financier régulier pour aider à couvrir les coûts de son éducation, de ses soins de santé et d’autres besoins fondamentaux. Ce type de soutien peut changer la vie d’un enfant qui a perdu ses parents et qui a du mal à joindre les deux bouts.',
      ar: 'رعاية اليتيم تعني الالتزام بتقديم الدعم المالي الدوري للمساعدة في تغطية تكاليف تعليمهم ورعايتهم الصحية واحتياجاتهم الأساسية الأخرى. هذا النوع من الدعم يمكن أن يكون مغايرًا للحياة بالنسبة للطفل الذي فقد والديه ويواجه صعوبة في توفير احتياجاته الأساسية.'
    }
  },
  {
    id: 5,
    question: {
      en: 'Can I volunteer with an orphan charity?',
      fr: 'Puis-je faire du bénévolat auprès d’un organisme caritatif pour orphelins?',
      ar: 'س: هل يمكنني التطوع مع جمعية خيرية للأيتام؟'
    },
    answer: {
      en: 'Yes, many orphan charities rely on volunteers to help with their programs and services. We at MATW do assist in volunteering opportunities.',
      fr: 'Oui, de nombreux organismes caritatifs pour orphelins comptent sur des bénévoles pour les aider dans leurs programmes et leurs services. À la MATW, nous les aidons à trouver des occasions pour faire du bénévolat.',
      ar: 'نعم، العديد من جمعيات الأيتام يعتمدون على المتطوعين للمساعدة في برامجهم وخدماتهم. نحن في منظمة MATW نقدم فرصًا للتطوع.'
    }
  },
  {
    id: 6,
    question: {
      en: 'How else can I help orphans without making a donation?',
      fr: 'Q. Comment puis-je aider les orphelins sans faire de don?',
      ar: 'س: كيف يمكنني مساعدة الأيتام بطرق أخرى دون التبرع المالي؟'
    },
    answer: {
      en: 'In addition to making a financial contribution, you may support orphan charities by raising awareness of the issue, giving up your time, planning fundraising events, and speaking out in favour of laws that will benefit abandoned children.',
      fr: 'Outre une contribution financière, vous pouvez appuyer des organismes d’aide aux orphelins en sensibilisant la population à cette question, en donnant de votre temps, en organisant des activités de financement et en vous exprimant en faveur de lois qui profiteront aux enfants abandonnés.',
      ar: 'بالإضافة إلى التبرع المالي، يمكنك دعم جمعيات الأيتام بزيادة الوعي بالمشكلة، وإيلاء وقتك، وتنظيم فعاليات لجمع التبرعات، والتحدث لصالح القوانين التي ستعود بالفائدة على الأطفال المهجورين'
    }
  },
  {
    id: 7,
    question: {
      en: 'Are there any specific orphan charities that focus on Islamic orphans?',
      fr: 'Y a-t-il des organismes caritatifs islamiques consacrés aux orphelins?',
      ar: 'س: هل هناك جمعيات خيرية محددة تركز على الأيتام الإسلاميين؟'
    },
    answer: {
      en: 'Yes, there are many orphan charities that focus specifically on supporting Islamic orphans. These charities are guided by the principles of compassion and generosity that are central to the Islamic faith. Our MATW’s orphan sponsorship project is one of the leading Islamic orphan charities.',
      fr: 'Oui, il y a de nombreux organismes caritatifs qui se consacrent spécifiquement au soutien des orphelins dans un cadre islamique. Ces organismes sont guidés par les principes de compassion et de générosité qui sont au cœur de la foi islamique. Le projet de parrainage d’orphelins de la MATW est l’un des principaux organismes caritatifs d’orphelins islamiques.',
      ar: 'نعم، هناك العديد من جمعيات الأيتام التي تركز بشكل خاص على دعم الأيتام الإسلاميين. توجه هذه الجمعيات بمبادئ الرحمة والسخاء التي تشكل جوهر الإيمان الإسلامي. مشروع رعاية الأيتام التابع لمنظمة MATW هو واحد من أبرز جمعيات الأيتام الإسلامية.'
    }
  },
  {
    id: 8,
    question: {
      en: 'How can I make sure my donation to an orphan charity is being put to good use?',
      fr: 'Comment puis-je m’assurer que mon don à un organisme caritatif pour orphelins est utilisé à bon escient?',
      ar: 'س: كيف يمكنني التحقق من أن تبرعي لجمعية الأيتام يُستخدم بشكل جيد؟'
    },
    answer: {
      en: `Before making a donation, do your research to ensure that the charity you are donating to has a good reputation and is using its resources effectively. Look for transparency and accountability in the charity's financial and operational practices.`,
      fr: 'Avant de faire un don, faites des recherches pour vous assurer que l’organisme caritatif auquel vous faites un don a une bonne réputation et qu’il utilise ses ressources efficacement. Recherchez la transparence et la responsabilisation dans les pratiques financières et opérationnelles de l’organisme caritatif.',
      ar: 'قبل التبرع، قم بإجراء أبحاثك للتأكد من أن الجمعية التي تتبرع لها لديها سمعة جيدة وتستخدم مواردها بشكل فعال. ابحث عن الشفافية والمساءلة في الأمور المالية والتشغيلية لدى الجمعية.'
    }
  },
  {
    id: 9,
    question: {
      en: 'What are the benefits of giving to orphans?',
      fr: 'Quels sont les avantages de faire des dons aux orphelins?',
      ar: 'س: ما هي فوائد إعطاء الأيتام؟'
    },
    answer: {
      en: 'Giving to orphans is considered a virtuous act in Islam, and it brings numerous benefits to both the giver and the receiver. It helps provide basic needs, education, and healthcare to children who are in desperate need of support. It also brings blessings and rewards in the afterlife for the giver, as it is considered a form of sadaqah or charity.',
      fr: 'Donner aux orphelins est considéré comme un acte vertueux dans l’islam, et il apporte de nombreux avantages  à la fois au donateur et au bénéficiaire. Cela permet de répondre aux besoins fondamentaux, d’assurer l’éducation et les soins de santé des enfants qui ont désespérément besoin d’aide. Il apporte également des bénédictions et des récompenses dans l’au-delà pour le donateur, car cet acte est considéré comme une forme de sadaqah ou de charité.',
      ar: 'إعطاء الأيتام يُعتبر عملاً فاضلًا في الإسلام، ويحمل العديد من الفوائد للمانح والمتلقي على حد سواء. إنه يساعد في توفير الاحتياجات الأساسية والتعليم والرعاية الصحية للأطفال الذين يحتاجون بشدة إلى الدعم. كما أنه يجلب البركات والمكافآت في الآخرة للمانح، حيث يُعتبر نوعًا من الصدقة.'
    }
  },
  {
    id: 10,
    question: {
      en: 'What does Islam say about helping orphans?',
      fr: 'Que dit l’islam à propos de l’aide aux orphelins?',
      ar: 'س: ماذا تقول الإسلام عن مساعدة الأيتام؟'
    },
    answer: {
      en: 'Islam places a high value on helping orphans and views it as a noble deed. The Prophet Muhammad (peace be upon him) himself was an orphan and he stressed the importance of treating orphans with kindness and compassion. In Islam, it is considered a major sin to mistreat orphans or neglect their needs.',
      fr: `L’Islam accorde une grande importance à l’aide aux orphelins et la considère comme un acte noble. Le prophète Muhammad (que la paix soit sur lui) était lui-même orphelin et il a souligné l'importance de traiter les orphelins avec gentillesse et compassion. Dans l'islam, maltraiter les orphelins ou négliger leurs besoins est considéré comme un péché majeur.`,
      ar: 'يضع الإسلام قيمة عالية على مساعدة الأيتام ويعتبرها فعلًا نبيلًا. كان النبي محمد (صلى الله عليه وسلم) يتيمًا وأكد على أهمية معاملة الأيتام باللطف والرحمة. في الإسلام، يُعتبر السوء المعاملة للأيتام أو إهمال حقوقهم خطيئة كبيرة.'
    }
  },
  {
    id: 11,
    question: {
      en: 'How does one treat orphans in Islam?',
      fr: `Comment traite-t-on les orphelins dans l'islam ?`,
      ar: 'س: كيف يتعامل الإنسان مع الأيتام في الإسلام؟'
    },
    answer: {
      en: 'Islam regards it as a religious duty to treat orphans with care and compassion. Muslims are encouraged to love, support, and care for orphans just as they would their own children. They should also ensure that orphans receive their rights and are not mistreated or neglected in any way.',
      fr: `L'islam considère que c'est un devoir religieux de traiter les orphelins avec soin et compassion. Les musulmans sont encouragés à aimer, à soutenir les orphelins et à prendre soin d'eux comme ils le feraient pour leurs propres enfants. Ils doivent également veiller à ce que les orphelins bénéficient de leurs droits, et qu'ils ne soient pas maltraités ou négligés de quelque manière que ce soit.`,
      ar: 'يُعتبر في الإسلام أنه واجب ديني التعامل مع الأيتام برعاية ورحمة. يُشجع على أن يحب المسلمون ويدعموا ويعتنوا بالأيتام تمامًا كما يفعلون مع أطفالهم الخاصين. كما يجب على المسلمين التأكد من أن الأيتام يحصلون على حقوقهم ولا يتعرضون للإساءة أو الإهمال بأي شكل من الأشكال.'
    }
  },
  {
    id: 12,
    question: {
      en: 'What is the reward for helping orphans in Islam?',
      fr: 'Quelle est la récompense promise aux personnes qui soutiennent les orphelins dans l’islam ?',
      ar: 'س: ما هو مكافأة مساعدة الأيتام في الإسلام؟'
    },
    answer: {
      en: 'Supporting orphans is viewed as a virtue in Islam and is associated with numerous benefits both in this world and the next. Muslims who help orphans are promised by Allah a large recompense, and their charitable deeds will be considered a source of good works on the Day of Judgment.',
      fr: 'Soutenir les orphelins est considéré comme une vertu dans l’islam et est associé à de nombreux avantages dans ce monde et dans l’au-delà. Les musulmans qui aident les orphelins sont promis par Allah une abondante récompense, et leurs actions caritatives seront considérées comme une source de bonnes œuvres, le Jour du jugement.',
      ar: 'يُعتبر دعم الأيتام فضيلة في الإسلام ويترتب عليه العديد من الفوائد في هذه الدنيا والآخرة. يُعد للمسلمين الذين يساعدون الأيتام مكافأة عظيمة من الله، وستعتبر أعمالهم الخيرة مصدرًا للأعمال الصالحة يوم القيامة.'
    }
  },
  {
    id: 13,
    question: {
      en: 'Can you give sadaqah to orphans?',
      fr: 'Pouvez-vous donner du sadaqah aux orphelins?',
      ar: 'س: هل يمكنني إعطاء الصدقة للأيتام؟'
    },
    answer: {
      en: 'Yes, giving sadaqah or charity to orphans is highly encouraged in Islam. It is considered a virtuous act that brings blessings and rewards to both the giver and the receiver.',
      fr: 'Oui, donner de la sadaqah ou de la charité aux orphelins est fortement encouragé dans l’islam. C’est considéré comme un acte vertueux qui apporte des bienfaits et des récompenses à la fois au donateur et au bénéficiaire.',
      ar: 'نعم، إعطاء الصدقة أو الصدقة للأيتام مشجع جدًا في الإسلام. إنها تُعتبر فعلًا فاضلًا يجلب البركة والمكافآت لكل من المانح والمتلقي.'
    }
  },
  {
    id: 14,
    question: {
      en: 'What does Allah say about helping orphans?',
      fr: 'Que dit Allah au sujet de l’aide aux orphelins?',
      ar: 'س: ماذا يقول الله عن مساعدة الأيتام؟'
    },
    answer: {
      en: 'In numerous verses of the Quran, Allah stresses the need of aiding orphans. According to Allah, "As for the orphan, do not oppress him, and as for the petitioner, do not repel him" (93:9–10) are two such verses. This verse emphasizes the significance of providing for the needs of orphans and treating them with respect and compassion.',
      fr: 'Dans de nombreux versets du Coran, Allah souligne la nécessité d’aider les orphelins. Allah dit : "Quant à l’orphelin, ne l’opprime pas, et quant au quémandeur, ne le repousse pas" (93, 9-10) Ce sont deux versets coraniques qui soulignent l’importance de répondre aux besoins des orphelins et de les traiter avec respect et compassion.',
      ar: 'في العديد من آيات القرآن، يؤكد الله على ضرورة مساعدة الأيتام. وفقًا لكلام الله، "فأما اليتيم فلا تقهر، وأما السائل فلا تنهر" (93:9-10) هي إحدى هذه الآيات. تؤكد هذه الآية على أهمية تلبية احتياجات الأيتام ومعاملتهم بالاحترام والرحمة.'
    }
  },
  {
    id: 15,
    question: {
      en: 'What is the best charity for orphans?',
      fr: 'Quel est le meilleur organisme caritatif d’aide aux orphelins?',
      ar: 'س: ما هي أفضل جمعية للأيتام؟'
    },
    answer: {
      en: 'There are many reputable charities and organizations that focus on helping orphans. The best charity for orphans is one that has a good reputation, is transparent in its operations, and is aligned with your values and beliefs. Some popular orphan charities include Muslim Around the World.',
      fr: 'Il y a de nombreux organismes caritatifs réputés qui se concentrent sur l’aide aux orphelins. Le meilleur organisme caritatif d’aide aux orphelins est celui qui a une bonne réputation, qui est transparent dans ses activités et qui est aligné sur vos valeurs et vos croyances. Parmi les organismes caritatifs les plus populaires, mentionnons Muslim Around The World (MATW).',
      ar: 'هناك العديد من الجمعيات والمنظمات الموثوقة التي تركز على مساعدة الأيتام. أفضل جمعية للأيتام هي تلك التي تتمتع بسمعة جيدة وتكون شفافة في عملياتها ومتسقة مع قيمك ومعتقداتك. تشمل بعض الجمعيات الشهيرة جمعية المسلمين حول العالم.'
    }
  }
];
export { feastFaqs, supportFaqs, educationFaqs, orphansFaqs };

import React from 'react'
import Layout from '../../components/general/layout'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

const UpTimeStatus = () => {
    return (
        <Layout>
            <div className='my-6 mx-auto px-4 max-w-[700px]'>
                <div className=' bg-[#253B7E] rounded-md pb-3'>
                    <div className='#253B7E text-[#9abd45] h-20 rounded justify-center items-center flex '>
                        <h1 className='text-2xl sm:text-3xl'>All Systems Operational  </h1>
                        <CheckCircleIcon className='h-12' />
                    </div>
                    <p className='text-sm sm:text-lg text-center text-gray-400'>Don't agree with what's reported here? Contact us </p>
                </div>
                {["MATW", "Checkout", "Products", "Api", "Third Party Services", "Stripe", "Ads"].map((item, index) => (

                    <div key={`status-${index}`} className='bg-white flex h-10 justify-between mt-4 px-3 items-center border-l-2 border-[#253B7E]'>
                        <div className='text-[#F60362] '>{item}</div>
                        <div className='text-[#9abd45]  rounded justify-center items-center flex '>
                            <h1 className='text-xl'> Operational  </h1>
                            <CheckCircleIcon className='h-8' />
                        </div>
                    </div>
                ))}
            </div>
        </Layout>
    )
}

export default UpTimeStatus
// import CloseIcon from '../../images/general/close-Icon.png';
import Report from '../../images/ramadan/popupReport.jpg';
import { Link } from 'react-router-dom';



const ReportModal = ({ closeModal }) => {

    return (
        <main className="antialiased top-0 fixed  overflow-x-hidden z-50 w-full h-full">
            <div className="relative px-4 min-h-screen flex items-center justify-center">

                <div className=" bg-[#0D1943] relative overflow-hidden rounded-xl h-auto sm:min-h-[550px]
                sm:min-w-[500px]  sm:max-w-[500px] p-4 inset-x-0 bottom-0 z-50 mb-4 mx-4 w-full">
                    <div className="md:flex justify-center ">
                        <div className="sm:mt-4 md:mt-0  text-center sm:text-center">
                            <img src={Report} alt="report" className='object-fit h-[450px] sm:h-[550px] ' />
                        </div>
                    </div>

                    <div className='flex flex-row-reverse cursor-pointer' onClick={() => closeModal()}>
                    </div>
                    <div className='flex justify-center mt-3'>
                        <button onClick={() => closeModal()} className='w-[150px] mr-5 leading-3 h-[30px] rounded-md text-xs font-brandingMedium text-white bg-[#00A3DA]'>Close</button>
                        <Link className='w-[150px] flex justify-center items-center  leading-3 h-[30px] rounded-md text-xs font-brandingMedium text-white bg-[#093686]' to={`../pdf/MATW_Ramadan_2023_Report - FINAL.pdf`} target="_blank">
                            Download Impact Report
                        </Link>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default ReportModal
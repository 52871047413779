import axios from 'axios';

const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_API_HEADER;

let config = {
  headers: {
    'X-user-matw': Header,
    Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
  },
};

function getBanners() {
  return axios.get(`${Base}/banners`, config);
}
function createBanner(data, file) {
  let config = {
    headers: {
      // accept: '*/*',
      accept: 'application/json',
      'content-type': file.type,
      'content-length': `${file.size}`,
      'X-user-matw': Header,
    },
  };
  console.log('file before send save=', file, data, config);
  return axios.post(`${Base}/banners`, data, config);
  // return axios.post(`${Base}/banners`, data, config);
}
function deleteBanner(id) {
  return axios.delete(`${Base}/banners/${id}`, config);
}
function updateBanner(id, data, file) {
  let config = {
    headers: {
      // accept: '*/*',
      accept: 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      'X-user-matw': Header,
    },
  };
  return axios.post(`${Base}/banners/${id}`, data, config);
}

function updateProduct(id, data, file) {
  let config = {
    headers: {
      accept: 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  console.log('file before send update=', file, data, config);
  return axios.post(`${Base}/admin-dashboard/products/${id}`, data, config);
  // return axios.patch(`${Base}/admin-dashboard/products/${id}`, data,);
}
function createProduct(data, file) {
  let config = {
    headers: {
      accept: 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  console.log('file before send update=', file, data, config);
  return axios.post(`${Base}/admin-dashboard/products`, data, config);
}
function createSubCategory(data, file) {
  let config = {
    headers: {
      accept: 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  return axios.post(`${Base}/admin-dashboard/categories`, data, config);
}

function getPage(pageName) {
  return axios.get(`${Base}/admin-dashboard/page-builder/${pageName}`, config);
}
function getAllPages() {
  return axios.get(`${Base}/admin-dashboard/page-builder`, config);
}
function getUserAllPages() {
  return axios.get(`${Base}/pages`);
}
function getUserPage(page) {
  return axios.get(`${Base}/pages/single?page_url=${page}`);
}
function createPage(data, file) {
  let config = {
    headers: {
      accept: 'application/json',
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  console.log('file before send update=', file, data, config);
  return axios.post(`${Base}/admin-dashboard/page-builder`, data, config);
}
function updatePage(id, data) {
  let config = {
    headers: {
      accept: 'application/json',
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  return axios.put(`${Base}/admin-dashboard/page-builder/${id}`, data, config);
}
function deletePage(id) {
  let config = {
    headers: {
      accept: 'application/json',
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  return axios.delete(`${Base}/admin-dashboard/page-builder/${id}`, config);
}

function login(email) {
  const loginUrl = `https://staging-backend.matwcheckout.org/api/auth/donor/login`;
  const loginData = {
    email,
  };

  return axios
    .post(loginUrl, loginData)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
}

function verifyOTP(email, code) {
  const verifyOTPUrl = `https://staging-backend.matwcheckout.org/api/auth/donor/verify`;
  const verifyOTPData = {
    email,
    code
  };

  return axios
    .post(verifyOTPUrl, verifyOTPData)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
}

function logout() {
  const token = localStorage.getItem("token");
  const verifyOTPUrl = `https://staging-backend.matwcheckout.org/api/auth/donor/logout`;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  }
  return axios
    .post(verifyOTPUrl, null, {
      headers
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
}

function sendPassword(email) {
  const sendPasswordUrl = `${Base}/donor/login`;
  const sendPasswordData = {
    email,
  };

  return axios
    .post(sendPasswordUrl, sendPasswordData)
    .then(response => {
      localStorage.setItem('email', email);
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
}

// function logout() {
//   const url = `${Base}/donor/logout`;
//   const token = localStorage.getItem('token');
//   const header = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   return axios
//     .post(url, null, header)
//     .then(response => {
//       return response.data;
//     })
//     .catch(error => {
//       return error.response.data;
//     });
// }

function getDashboardSubscriptions() {
  const token = localStorage.getItem('token');
  const email = localStorage.getItem('email');
  const url = `https://staging-backend.matwcheckout.org/api/donor/subscriptions?email=${email}`;
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, header)
    .then(response => {
      return response.data.data.subscriptions;
    })
    .catch(error => {
      return error.response.data;
    });
}

function cancelDashboardSubscription(account_type, subscription_id) {
  const url = `${Base}/donor/cancel-subscription`;
  const token = localStorage.getItem('token');
  const user_id = localStorage.getItem('userid');
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = { account_type, subscription_id, user_id };
  return axios
    .post(url, data, header)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
}

async function getDashboardReceipts(filterData) {
  const url = `${Base}/donor/get-all-receipts`;
  const token = localStorage.getItem('token');
  const donor_id = localStorage.getItem('userid');
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  if (filterData.currency === '') delete filterData.currency;
  if (filterData.mode === '') delete filterData.mode;
  if (filterData.payment_gateway === '') delete filterData.payment_gateway;
  if (filterData.payment_status === '') delete filterData.payment_status;
  const data = { ...filterData, donor_id };
  return axios
    .post(url, data, header)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
}

function getDashboardReceiptProducts(order_id, payment_method) {
  const url = `${Base}/donor/get-receipt-products`;
  const token = localStorage.getItem('token');
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = { order_id, payment_method };
  return axios
    .post(url, data, header)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
}
function getAllContentBlocks() {
  return axios.get(`${Base}/admin-dashboard/page-builder/content-blocks`, config);
}
function createContentBlock(data) {
  let config = {
    headers: {
      accept: 'application/json',
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  return axios.post(`${Base}/admin-dashboard/page-builder/content-blocks`, data, config);
}
function getAllProductBlocks() {
  return axios.get(`${Base}/admin-dashboard/page-builder/product-blocks`, config);
}
function createProductBlock(data) {
  let config = {
    headers: {
      accept: 'application/json',
      Authorization: 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      'X-user-matw': Header,
    },
  };
  return axios.post(`${Base}/admin-dashboard/page-builder/product-blocks`, data, config);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDashboardSubscriptions,
  sendPassword,
  getDashboardReceiptProducts,
  getDashboardReceipts,
  getBanners,
  getPage,
  getAllPages,
  createPage,
  updatePage,
  deletePage,
  logout,
  cancelDashboardSubscription,
  login,
  verifyOTP,
  createBanner,
  deleteBanner,
  updateBanner,
  updateProduct,
  createProduct,
  createSubCategory,
  getUserAllPages,
  getAllProductBlocks,
  createProductBlock,
  getAllContentBlocks,
  createContentBlock,
  getUserPage,
};
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import TabContent from '../../home/components/tab-content';
import Sticky from 'react-sticky-el';
import { useKeenSlider } from 'keen-slider/react';

const PalestineRefugeeTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { supportRefugeeSettlementProducts, sponsorFamilyProducts, lang = 'en' } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const [isTabsFixed, setIsTabsFixed] = useState(false);
  const tabButtonsProducts = [
    {
      title: lang === 'fr' ? `Soutenir l'installation de réfugiés` : lang === 'ar' ? 'دعم توطين اللاجئين' : 'Support Refugee Settlement',
      products: supportRefugeeSettlementProducts,
    },
    {
      title: lang === 'fr' ? `Parrainer une famille` : lang === 'ar' ? 'مأوى الأيتام' : 'Sponsor a Family',
      products: sponsorFamilyProducts,
    },
  ];
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'free-snap',
    slides: {
      perView: 2.3,
      spacing: 10,
    },
    breakpoints: {
      '(min-width: 300px)': {
        slides: { perView: 1.8, spacing: 10 },
      },
      '(min-width: 375px)': {
        slides: { perView: 2.14, spacing: 10 },
      },
      '(min-width: 420px)': {
        slides: { perView: 2.4, spacing: 10 },
      },
    },
  });

  /* ------------------------------- Start hooks ------------------------------ */
  const handleTabChange = index => {
    setActiveTabProducts(index);
    instanceRef.current?.moveToIdx(index - 1, false);
  };
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]-" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className={`${activeTabProducts === 1 || activeTabProducts === 3 ? '' : 'md:container'} w-full mx-auto pl-2 pr-0 md:px-0 py-4 md:py-12`}>
        <div className="hidden sm:flex items-center w-full overflow-x-auto ">
          <div className="flex gap-1 min-[768px]:gap-2 items-center md:justify-center w-full min-w-[500px] max-w-[600px] mx-auto">
            {tabButtonsProducts.map(({ title }, index) => (
              <button
                key={`btn_${index}`}
                onClick={() => {
                  window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
                  setActiveTabProducts(index);
                }}
                className={`${
                  index === activeTabProducts ? '!bg-DarkerBlue text-white' : '!bg-[#F8F8F8] !border !border-DarkerBlue !text-DarkerBlue'
                } mr-2 md:mr-0 min-w-fit !rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${
                  lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'
                }`}
              >
                {title}
              </button>
            ))}
          </div>
        </div>
        <div className="flex sm:hidden [&>div]:w-full relative z-20 bg-white overflow-hidden">
          <Sticky
            stickyStyle={{ top: '0', bottom: 'unset' }}
            stickyClassName="!w-full flex justify-end items-center shadow-[rgba(189,189,189,0.5)_0px_15px_25px_-1px] z-10 bg-white left-0"
            mode="top"
            onFixedToggle={() => setIsTabsFixed(!isTabsFixed)}
          >
            <div ref={sliderRef} className="keen-slider !min-h-[0px]">
              {tabButtonsProducts.map(({ title }, index) => (
                <div key={`btn_${index}`} className="keen-slider__slide">
                  <button
                    onClick={() => handleTabChange(index)}
                    className={`${
                      index === activeTabProducts ? 'bg-DarkerBlue text-[#fff]' : 'bg-[#fff] text-DarkerBlue'
                    } border border-DarkerBlue rounded-3xl py-2 px-1 md:px-2 w-[100%] 2xs:text-[11px] xs:text-[12px] flex justify-center items-center`}
                  >
                    {title}
                  </button>
                </div>
              ))}
            </div>
          </Sticky>
        </div>
      </div>
      <div className={`w-full mx-auto px-4 md:px-0 py-4 md:py-12 bg-[#253B7E]`}>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 mt-5 mb-5 md:mt-10">
          <div className="flex flex-col w-full justify-center">
            {tabButtonsProducts.map(
              (item, index) =>
                index === activeTabProducts && (
                  <React.Fragment key={`description_${index}`}>
                    <div ref={contentRef}>
                      <TabContent
                        showArrows={false}
                        showDots={false}
                        lang={lang}
                        products={item.products}
                        currency={selectedCurrency}
                        productsOnMobile={item.productsOnMobile ? item.productsOnMobile : null}
                        isPalestineRefugee
                      />
                    </div>
                  </React.Fragment>
                ),
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default PalestineRefugeeTabs;

/* eslint-disable react-hooks/rules-of-hooks */
// import { useEffect } from 'react';

// import IsGTMScriptLoaded from './isGTMScriptLoaded';

const GoogleTagManager = referrer => {
  const customUseScript = (content, id) => {
    const script = document.createElement('script');
    script.async = true;
    script.id = id || '';
    script.innerHTML = content;
    document.getElementsByTagName('head')[0].appendChild(script);
  };
  // const useNoScript = content => {
  //   const script = document.createElement('noscript');
  //   script.innerHTML = content;
  //   document.body.prepend(script);
  // };
  const href = referrer;
  const findTerm = term => {
    if (href.includes(term)) {
      return href;
    }
  };
  switch (referrer) {
    case findTerm('matwprojectme.org'): // Middle East Website
      // console.log('on Middle East Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //       new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //       j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //       'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //       })(window,document,'script','dataLayer','GTM-NFVDST5');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIING3C77U6580FMBM0');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIING3C77U6580FMBM0');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-NFVDST5'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      break;
    case findTerm('matwproject.org.uk'): // UK Website
      // console.log('on UK Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-M43R9TC');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
      )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIIQ7RC77U17F5DF10G');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIIQ7RC77U17F5DF10G');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-M43R9TC'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      break;
    case findTerm('matwprojectca.org'): // Canada Website
      // console.log('on Canada Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-5BRP7RF');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIINK3C77UANPJ93H40');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIINK3C77UANPJ93H40');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-5BRP7RF'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      break;
    case findTerm('matwprojectfr.org'): // France Website
      // console.log('on France Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-NMGJTKC');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIIMHBC77U17F5DF0OG');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIIMHBC77U17F5DF0OG');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-NMGJTKC'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      break;
    case findTerm('matwprojectusa.org'): // USA Website
      // console.log('on USA Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-WWN89V6');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIIO8JC77U17F5DF0RG');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIIO8JC77U17F5DF0RG');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-WWN89V6'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      break;
    case findTerm('matwproject.org'): // Australia Website
      // console.log('on Australia Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-5972DGJ');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIIA2JC77U17F5DEVQG');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIIA2JC77U17F5DEVQG');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-5972DGJ'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      break;
    case findTerm('matwprojectsgp.org'): // Singapore Website
      // console.log('on Singapore Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-M7ZBKD6');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIIK7RC77U2JMMJ2KLG');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIIK7RC77U2JMMJ2KLG');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-M7ZBKD6'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      break;
    case findTerm('matwprojectmys.org'): // Malaysia Website
      // console.log('on Malaysia Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-MHBPQZK');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIIHVRC77UANPJ93GTG');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIIHVRC77UANPJ93GTG');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-MHBPQZK'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      break;
    case findTerm('matwcheckout.org'): // Checkout Website
      // console.log('on Checkout Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-WR553Z7');`,
      //     'gtm_tag',
      //   );
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-WR553Z7'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      break;
    case findTerm('matwprojectid.org'): // Indonesia Website
      // console.log('on Indonesia Website');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //     })(window,document,'script','dataLayer','GTM-MQR98MH3');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIIFMBC77UA008MUJHG');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIIFMBC77UA008MUJHG');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MQR98MH3" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      // );
      break;
    default:
      // console.log('on default');
      // !IsGTMScriptLoaded() &&
      //   customUseScript(
      //     `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-WR553Z7');`,
      //     'gtm_tag',
      //   );
      customUseScript(
        `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

            ttq.load('CKIIA2JC77U17F5DEVQG');
            ttq.page();
          }(window, document, 'ttq');`,
      );
      localStorage.setItem('TikTokID', 'CKIIA2JC77U17F5DEVQG');
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-WR553Z7'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
      // useNoScript(
      //   `<iframe src="https://www.googletagmanager.com/gtm.js?id='GTM-WR553Z7'" height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      // );
  }
};

export default GoogleTagManager;
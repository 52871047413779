import { useEffect, useState } from 'react';
import CustomButton from './button';
import { CloseIcon } from './customIcons';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
};

const getCookie = name => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

const FloatEmailSubscriptionWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      email: '',
      aggree: false,
    },
  });

  useEffect(() => {
    const isSubscribed = getCookie('subscribed');
    if (isSubscribed !== 'true') {
      setIsOpen(true);
    }
  }, []);

  const onSubmitHandler = async data => {
    if (!emailRegExp.test(data.email)) {
      return toast.warning('Please enter a valid email');
    }
    if (!data.aggree) {
      return toast.warning('Please agree to receive newsletters and updates from MATW Project in line with the privacy policy.');
    }

    const response = await sendEmailToSibForm(data.email);

    if (response.ok) {
      toast.success('Thank you for subscribing to our newsletter');
      reset();
      setIsOpen(false);
      setCookie('subscribed', 'true', 30);
    } else {
      toast.error('Something went wrong, please try again later');
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setCookie('subscribed', 'true', 7);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="bg-pink py-3 px-2 xl:px-4 fixed bottom-0 w-full z-40">
      <div className="relative w-full">
        <div className="max-w-7xl mx-auto flex gap-x-6 xl:items-center flex-col xl:flex-row items-center">
          <div className="text-white text-sm xl:text-base">
            <h5 className="font-bold">Want to see our work in action & your good deeds?</h5>
            <p>Get exculusive updates from Palestine, Lebanon & more.</p>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmitHandler)} className="flex flex-col xl:flex-row xl:items-end gap-y-2">
              <div className="flex items-end gap-x-3 w-full max-w-[500px] xl:max-w-[380px]">
                <div className="flex flex-col w-full">
                  <label htmlFor="email" className="text-sm text-white font-light">
                    Email
                  </label>
                  <input {...register('email')} id="email" className="px-2 py-1 w-full outline-none" />
                </div>
                <CustomButton onClick={() => 0} title="Subscribe" className="!bg-LightBlue !rounded-sm !h-8" />
              </div>
              <div className="max-w-[500px] flex items-center gap-x-2 xl:ml-6">
                <input
                  id="aggree-receive-newsletter"
                  className="relative float-left mr-1 h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-[#00a3da] checked:bg-[#00a3da] checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-[#00a3da] checked:focus:bg-[#00a3da] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                  type="checkbox"
                  {...register('aggree')}
                />
                <label
                  htmlFor="aggree-receive-newsletter"
                  className="hover:cursor-pointer text-white w-[calc(100%_-_1.125rem)] text-xs sm:text-sm flex items-start flex-col sm:flex-row"
                >
                  <span>I agree to receive newsletters and updates from MATW Project in line with the privacy policy.</span>
                </label>
              </div>
            </form>
          </div>
        </div>
        <button className="absolute text-white -top-1 -right-1 xl:right-1 xl:top-1" onClick={handleClose}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

async function sendEmailToSibForm(email) {
  const formdata = new FormData();
  formdata.append('EMAIL', email);
  formdata.append('OPT_IN', '1');
  formdata.append('email_address_check', '');
  formdata.append('locale', 'en');

  return await fetch(
    'https://a942f65f.sibforms.com/serve/MUIFAJ2LQwb7EqGsSo8F-JP_MGGRXLQuYoXW7cVX3y96Lmg1yv9JYU4YXFtlnsCdJsyio9PlmlBuemUM05QvoTywEWwZEIHhWLTHGE_yRXaz5w09T5-h_pftX3Zo5rnj7t3-Tniz7YailIIJT9A70nOg7UiS4NbnKlmKk6jDLPHqD-qC1Ixm8CAQUD22GE04f0uvMneEK7X_lebj?isAjax=1',
    {
      method: 'POST',
      body: formdata,
    },
  );
}

export default FloatEmailSubscriptionWidget;

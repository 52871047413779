import { useEffect, useState } from 'react';
import services from '../../services';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import QurbanProductBox from '../../components/general/qurbanProductBox';
import img1 from '../../images/qurban/sheep1.jpg';
import img2 from '../../images/qurban/sheep2.jpg';
import imgSlider from '../../images/qurban/Water for School 1-27 screenshot 2.jpg';
import { allFaqs } from './sadaqah-mostPopular';
import Accordionn from '../../components/general/accordion';
import useHandleAddProduct from '../../utils/handleAddProduct';

const SheepsPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [products, setProducts] = useState();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [yemenSheepProduct, setYemenSheepProduct] = useState();
    const [africaSheepProduct, setAfricaSheepProduct] = useState();
    const [addProduct] = useHandleAddProduct();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getProductsByName = async (name) => {
        setProducts(null)
        try {
            const response = await services.categoryProductsByName("sheep");
            console.log('response get categoryProductsByName', response);
            setProducts(response.data);
            response.data.products.map(item => {
                if (Number(item.status) === 1) {
                    item.name === 'Yemen Sheep Or Goat Qurban' && setYemenSheepProduct(item);
                    item.name === 'Africa Sheep Qurban' && setAfricaSheepProduct(item);
                }
                return item;
            })
            // ChangeDocumentTitle(`${response.data.name} - ${response.data.category.name} - MATW Project Muslim Charity`);
        } catch (error) {
            console.error('error========', error);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    useEffect(() => {
        let pathNames = window.location.pathname.split('/');
        console.log("params", pathNames)
        if (pathNames.length > 2) {
            let temp = pathNames[2].split('-');
            temp = temp.join(' ');
            getProductsByName(temp);
            console.log("params", temp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout>
            {products && <img src={products.tile_image} alt={products.name} className='w-full' onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/general/logo-replace.png";
                currentTarget.className = 'max-h-[150px] mx-auto'
            }} />}
            <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
            <div className="w-full bg-[#F60362] py-6">
                <div className="md:container md:mx-auto lg:mt-5">
                    <div className="flex flex-wrap flex-col- justify-center px-3 md:px-4">
                        <div className="basis-full lg:basis-1/2 text-center text-6xl text-white py-2 lg:py-8 font-['Gotcha']">
                            Sheep
                            {/* <h2 className='text-white text-3xl md:text-4xl lg:text-5xl mb-4 md:mb-6'>Deliver fresh sheep Sadaqah and spread Eid joy!</h2> */}
                            {/* <p className="text-white text-base md:text-2xl lg:text-3xl font-['Abeezee']">
                                For many families living in poverty, Eid Al Adha is the only time they get to eat meat- a staple food in our daily diet. Your Qurban donation is more than just a meal, it helps provide essential nourishment to those who often struggle to obtain enough food daily. Donate your Qurbani Now.
                            </p> */}
                        </div>
                        <div className="basis-full rounded-md">
                            <div className="flex flex-row justify-center flex-wrap">
                                {products ? products.products.map((item, index) => {
                                    return <div key={`products.products${index}`} className="basis-1/2 lg:basis-1/3 xl:basis-1/4 p-2">
                                        <QurbanProductBox product={item} currency={selectedCurrency} />
                                    </div>
                                })
                                    : <div role="status" className='w-full col-span-12 flex justify-center items-center min-h-[500px]'>
                                        <svg aria-hidden="true" className="w-20 h-20 mr-2 text-gray-200 animate-spin fill-[#00a3da]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /* ------------------------------- Section Two ------------------------------ */}
            <div className='w-full flex- hidden flex-wrap items-stretch justify-start bg-[#E2EFF3]'>
                <div className="basis-full lg:basis-5/12 xl:basis-6/12 h-full max-[575px]:max-h-[200px] max-[767px]:max-h-[300px] max-[1023px]:max-h-[400px] overflow-hidden">
                    <img src={img1} alt="" className='w-full min-h-full' />
                </div>
                <div className="basis-full lg:basis-7/12 xl:basis-5/12 flex flex-col justify-center px-8 py-8 lg:pt-16 order-2 lg:order-1">
                    <h2 className='font-bold text-3xl mb-4 xl:mb-8'>Provide a lifeline to the people of Yemen with your Qurban</h2>
                    <p className='text-sm'>Currently known as the worst humanitarian crisis in the world, the situation in Yemen is dire. More than 17 million people are food insecure. Families often wonder where they’ll get their next meal from. Imagine the joy a vulnerable family would feel on Eid if they had a meal with sustenance, that they don’t often get to eat. Our MATW team is preparing to deliver fresh sheep Qurban this Eid to families in need, giving families the chance to enjoy Eid. Be the reason for their smiles.
                    </p>
                    <div className="flex justify-start mt-8">
                        {yemenSheepProduct && <button
                            className='border border-[#F60362] bg-transparent text-[#F60362] hover:bg-[#F60362] hover:text-white px-8 h-10 flex items-center justify-center rounded'
                            onClick={(e) => { addProduct({product: yemenSheepProduct, currency: selectedCurrency}) }}
                        >
                            Donate Now
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>}
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ------------------------------- Section Three ---------------------------- */}
            <div className='w-full flex- hidden flex-wrap items-stretch justify-end bg-[#E2EFF3]'>
                <div className="basis-full lg:basis-7/12 xl:basis-4/12 flex flex-col justify-center px-8 py-8 order-2 lg:order-1">
                    <h2 className='font-bold text-3xl mb-4 xl:mb-8'>Ease the hunger of thousands across Africa</h2>
                    <p className='text-sm'>
                        Africa is home to numerous regions where access to sufficient and nutritious food is a daily struggle for a large portion of the population. Donating sheep meat to those in need across Africa, through MATW, holds significant importance in addressing the food insecurity and nutritional challenges faced by many communities in the region. Help MATW provide a lifeline and vital source of protein and nourishment through the donation of sheep meat this Eid al Adha.
                    </p>
                    <div className="flex justify-start mt-8">
                        {africaSheepProduct && <button
                            className='border border-[#F60362] bg-transparent text-[#F60362] hover:bg-[#F60362] hover:text-white px-8 h-10 flex items-center justify-center rounded'
                            onClick={(e) => { addProduct({product: africaSheepProduct, currency: selectedCurrency}) }}
                        >
                            Donate Now
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>}
                    </div>
                </div>
                <div className="basis-full lg:basis-5/12 xl:basis-7/12 h-full max-[575px]:max-h-[200px] max-[767px]:max-h-[300px] max-[1023px]:max-h-[400px] overflow-hidden order-1 lg:order-2">
                    <img src={img2} alt="" className='w-full min-h-full' />
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ----------------------------------- FAQ ---------------------------------- */}
            <div className='w-full h-auto flex- hidden flex-col justify-center items-center bg-[#E2EFF3] pb-16'>
                <h2 className="text-2xl text-center mt-8 mb-4">Frequently Asked Questions</h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-8 md:px-0 mt-6">
                    {allFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full' key={`faq${index}`}>
                                <Accordionn key={`keyallfaq${index}`} title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordionn>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ----------------------------------- Image -------------------------------- */}
            <div className='w-full h-auto flex- hidden items-center'>
                <a href="/zakat/give-zakat" className='w-full'>
                    <img src={imgSlider} alt="" className='w-full min-h-full' />
                </a>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
        </Layout>
    );
}

export default SheepsPage;

import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import services from '../../../../services';
import CustomModal from '../../components/modal';
import CheckBox from '../../../../components/general/checkbox';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import ReactSelect from "react-select";
// import { Loader } from '../../../../components/general';

const HandleProduct = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    const fileRef = useRef(null);
    const { show, currentCategoryInfo, onClose, onSaved, selectedProduct, searchItems, categories, currentSubCategories, allProducts } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState();
    const [isForAddOn, setIsForAddOn] = useState(false);
    const [subCategories, setSubCategories] = useState(currentSubCategories);
    const [hasImgSelected, setHasImgSelected] = useState({
        hasImg: false,
        isSubmited: false
    });
    const [errorMessages, setErrorMessages] = useState({
        name: '',
        creator: '',
        description: '',
        short_description: '',
        image: '',
        aud: '',
        usd: '',
        gbp: '',
        eur: '',
        cad: '',
        sgd: '',
        myr: '',
        aed: '',
        category_id: ''
    })
    // const [tileFile, setTileFile] = useState();
    // const [thumbnamilFile, setThumbnamilFile] = useState();
    const [categoryInfo, setCategoryInfo] = useState({
        category_id: 0,
        category_name: '',
        sub_category_id: 0,
        sub_category_name: '',
    })
    const [product, setProduct] = useState(
        {
            name: '',
            image_link: '',
            image_name: '',
            imgUrl: '',
            category_id: 0,
            creator: '',
            custom_amount: 'Yes',
            description: '',
            short_description: '',
            id: 0,
            paypal_product_id: '',
            stripe_product_id: '',
            recurring: 'Allowed',
            status: "1",
            is_addon: "0",
            aud: 1,
            usd: 1,
            gbp: 1,
            cad: 1,
            eur: 1,
            sgd: 1,
            myr: 1,
            aed: 1,
            idr: 1,
        }
    )
    const productSchema = Yup.object().shape({
        name: Yup.string().min(3, 'Should be 3 character long').required('Enter product name'),
        creator: Yup.string().min(3, 'Should be 3 character long').required('Enter creator code'),
        description: Yup.string().min(3, 'Should be 3 character long').required('Enter description'),
        short_description: Yup.string(),
        imgUrl: Yup.string().required('Select Image'),
        aud: Yup.number(),
        usd: Yup.number(),
        gbp: Yup.number(),
        eur: Yup.number(),
        cad: Yup.number(),
        sgd: Yup.number(),
        myr: Yup.number(),
        aed: Yup.number(),
        addon: Yup.boolean()
    });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    // const handleChange = (field, value) => {
    //     setProduct({ ...product, [field]: value });
    // }
    const handleSave = () => {
        saveProduct();
        // addSubCategory();
        // toast.success('The sliders have been updated successfully');
    }
    const handleProductFileChange = (e) => {
        console.log("files", e.target.files, fileRef.current)
        if (e.target.files) {
            let tempFile = e.target.files[0];
            if (tempFile.type !== 'image/jpeg') {
                toast.warning('Please upload a jpg file');
                fileRef.current.value = '';
                return false;
            }
            if (tempFile.size > 500000) {
                toast.warning('Please upload a file smaller than 500 KB');
                fileRef.current.value = '';
                return false;

            }
            setFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(tempFile);
            setProduct({ ...product, imgUrl: objectUrl, image_name: tempFile.name });
            console.log("name,file", tempFile, objectUrl,)
        }
    };
    // const addSubCategory = async () => {
    //     setIsLoading(true);
    //     const formData = new FormData();
    //     formData.append('category_id', 7);
    //     formData.append('name', 'Afghanistan');
    //     tileFile && formData.append('tile_image', tileFile);
    //     thumbnamilFile && formData.append('thumbnail_image', thumbnamilFile);
    //     formData.append('page_title', 'The earthquake in Syria and Turkiye has caused widespread devastation, leaving thousands dead and many more injured or homeless');
    //     formData.append('page_subtitle', '');
    //     formData.append('page_description', '');
    //     try {
    //         const response = await services.createSubCategory(formData, file);
    //         onSaved();
    //         closeModal();
    //         setIsLoading(false);
    //         console.log('response get getBanners', response);
    //     } catch (error) {
    //         setIsLoading(false);
    //         console.error('error========', error);
    //     }
    // };
    const saveProduct = async () => {
        setIsLoading(true);
        console.log("banner before save", product.image)
        const formData = new FormData();
        formData.append('category_id', categoryInfo.sub_category_id);
        // formData.append('sub_category_id', currentCategoryInfo.sub_category_id);
        // formData.append('category_id', currentCategoryInfo.sub_category_id);
        // formData.append('category_id', categoryInfo.sub_category_id);
        // formData.append('category_id', 49);
        formData.append('name', product.name);
        formData.append('description', product.description);
        product.short_description && formData.append('short_description', product.short_description);
        formData.append('creator', product.creator);
        file && formData.append('image', file);
        formData.append('aud', Math.floor(product.aud));
        formData.append('usd', Math.floor(product.usd));
        formData.append('gbp', Math.floor(product.gbp));
        formData.append('cad', Math.floor(product.cad));
        formData.append('eur', Math.floor(product.eur));
        formData.append('sgd', Math.floor(product.sgd));
        formData.append('myr', Math.floor(product.myr));
        formData.append('aed', Math.floor(product.aed));
        formData.append('idr', product.idr ? Math.floor(product.idr) : 1);
        formData.append('custom_amount', 'Yes');
        formData.append('recurring', 'Allowed');
        formData.append('status', product.status);
        formData.append('is_addon', isForAddOn ? 1 : 0);
        console.log("before save=", formData, categoryInfo, currentCategoryInfo)
        try {
            const response = await services.createProduct(formData, file);
            onSaved();
            closeModal();
            setIsLoading(false);
            console.log('response get getBanners', response);
        } catch (error) {
            setIsLoading(false);
            let tempErrors = errorMessages;
            // tempErrors[]
            // error.response.data.errors
            Object.keys(error.response.data.errors).forEach(function (key, index) {
                tempErrors[key] = error.response.data.errors[key][0]
            });
            toast.error(error.response.data.message || 'Something went wrong!');
            setErrorMessages(tempErrors);
            console.error('error========', error);
        }
    };
    const handleUpdate = async () => {
        setIsLoading(true);
        const formData = new FormData();
        // formData.append('category_id', categoryInfo.sub_category_id);
        // formData.append('category_id', product.category_id);
        formData.append('category_id', categoryInfo.sub_category_id);
        console.log("product before update", formData, product, product.image, categoryInfo)
        formData.append('name', product.name);
        formData.append('description', product.description);
        product.short_description && formData.append('short_description', product.short_description);
        formData.append('creator', product.creator);
        file && formData.append('image', file);
        formData.append('aud', Math.floor(product.aud));
        formData.append('usd', Math.floor(product.usd));
        formData.append('gbp', Math.floor(product.gbp));
        formData.append('cad', Math.floor(product.cad));
        formData.append('eur', Math.floor(product.eur));
        formData.append('sgd', Math.floor(product.sgd));
        formData.append('myr', Math.floor(product.myr));
        formData.append('aed', Math.floor(product.aed));
        formData.append('idr', product.idr ? Math.floor(product.idr) : 1);
        formData.append('custom_amount', 'Yes');
        formData.append('recurring', 'Allowed');
        formData.append('status', product.status);
        formData.append('_method', 'patch');
        formData.append('is_addon', isForAddOn ? 1 : 0);
        // console.log("before send", formData, file)
        // console.log('before update product', product.category_id, product.id, formData, file);
        console.log("before update=", formData, categoryInfo)
        try {
            const response = await services.updateProduct(product.id, formData, file);
            // const response = await services.updateProduct(product.id,tempData);
            toast.success('The product has been updated successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
            console.log('response update product', response);
        } catch (error) {
            // toast.error('Something went wrong!');
            let tempErrors = errorMessages;
            // tempErrors[]
            // error.response.data.errors
            setErrorMessages(tempErrors);
            setIsLoading(false);
            console.error('error========', error);
            toast.error(error.response.data.message || 'Something went wrong!');
            Object.keys(error.response.data.errors).forEach(function (key, index) {
                tempErrors[key] = error.response.data.errors[key][0]
            });
        }
    };

    const closeModal = () => {
        setProduct({
            ...product,
            name: '',
            image_link: '',
            imgUrl: '',
            category_id: 0,
            creator: '',
            custom_amount: '',
            description: '',
            short_description: '',
            id: 0,
            paypal_product_id: '',
            stripe_product_id: '',
            recurring: 'Allowed',
            status: "1",
            is_addon: "0",
            aud: 1,
            usd: 1,
            gbp: 1,
            cad: 1,
            eur: 1,
            sgd: 1,
            myr: 1,
            aed: 1,
            idr: 1,
        });
        onClose();
    }
    const handleKeyDown = (value) => {
        // console.log("new key down",value);
        let checkIfNum;
        if (value.key !== undefined) {
            if (value.key === "." || value.key === " ") checkIfNum = true;
            // checkIfNum = value.key === ".";
        }
        return checkIfNum && value.preventDefault();
    }
    const handleSubmit = (values) => {
        selectedProduct ? handleUpdate(values) : handleSave(values)
    }
    // const handleSearch = (name, value) => {
    //     let tempSearchItems = searchItems;
    //     tempSearchItems[name] = value;
    //     onSearchItems({ [name]: value })
    // }
    const handleSearchedCategory = async (category, subCategory, product, isAdd = false) => {
        let tempProductSubCategories = [];
        if (subCategory) {
            allProducts.map(item => {
                item.name === categoryInfo.category_name && item.sub_categories.map(innerItem => {
                    if (innerItem.name === subCategory) {
                        setCategoryInfo({
                            ...categoryInfo,
                            category_id: item.id,
                            category_name: item.name,
                            sub_category_id: innerItem.id,
                            sub_category_name: innerItem.name,
                        })
                    }
                    return null
                });
                return null
            });
            console.log("subcategroy", subCategory, categoryInfo)
            // setSubCategories(tempProductSubCategories);
        } else if (category) {
            allProducts.map(item => {
                item.name === category && item.sub_categories.map((innerItem, innerIndex) => {
                    tempProductSubCategories.push({ value: innerItem.name, lable: innerItem.name });
                    if (innerIndex === 0) {
                        setCategoryInfo({
                            ...categoryInfo,
                            category_id: item.id,
                            category_name: item.name,
                            sub_category_id: innerItem.id,
                            sub_category_name: innerItem.name,
                        })
                    };
                    return null
                });
                return null
            });
            console.log("category has searched in search-setProductSubCategories=", tempProductSubCategories)
            setSubCategories(tempProductSubCategories);
        } else if (product) {
            allProducts.map(item => {
                item.sub_categories.map((innerItem, innerIndex) => {
                    if (innerItem.id === product.category_id) {
                        item.sub_categories.map(_sub => {
                            tempProductSubCategories.push({ value: _sub.name, lable: _sub.name });
                            return _sub
                        })
                        setCategoryInfo({
                            ...categoryInfo,
                            category_id: item.id,
                            category_name: item.name,
                            sub_category_id: innerItem.id,
                            sub_category_name: innerItem.name,
                        })
                    };
                    return null
                });
                return null
            });
            setSubCategories(tempProductSubCategories);
        }
        if (isAdd) {
            allProducts.map((item, index) => {
                if (searchItems.category) {
                    item.name === searchItems.category && item.sub_categories.map((innerItem, innerIndex) => {
                        tempProductSubCategories.push({ value: innerItem.name, lable: innerItem.name });
                        if (searchItems.subCategory && innerItem.name === searchItems.subCategory) {
                            setCategoryInfo({
                                ...categoryInfo,
                                category_id: item.id,
                                category_name: item.name,
                                sub_category_id: innerItem.id,
                                sub_category_name: innerItem.name,
                            })
                        } else {
                            if (innerIndex === 0) {
                                setCategoryInfo({
                                    ...categoryInfo,
                                    category_id: item.id,
                                    category_name: item.name,
                                    sub_category_id: innerItem.id,
                                    sub_category_name: innerItem.name,
                                })
                            };
                        }
                        return null
                    });
                } else {
                    index === 0 && item.sub_categories.map((innerItem, innerIndex) => {
                        tempProductSubCategories.push({ value: innerItem.name, lable: innerItem.name });
                        if (searchItems.subCategory && innerItem.name === searchItems.subCategory) {
                            setCategoryInfo({
                                ...categoryInfo,
                                category_id: item.id,
                                category_name: item.name,
                                sub_category_id: innerItem.id,
                                sub_category_name: innerItem.name,
                            })
                        } else {
                            if (innerIndex === 0) {
                                setCategoryInfo({
                                    ...categoryInfo,
                                    category_id: item.id,
                                    category_name: item.name,
                                    sub_category_id: innerItem.id,
                                    sub_category_name: innerItem.name,
                                })
                            };
                        }
                        return null
                    });
                }
                return null
            });
            setSubCategories(tempProductSubCategories);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        show && console.log("show modal", selectedProduct);
        show && console.log("category info", categoryInfo, searchItems);
        show && console.log("category info=", currentCategoryInfo);
        // (selectedProduct && show) && setProduct(selectedProduct);
        if (selectedProduct && show) {
            setProduct(selectedProduct);
            // handleCheckCategory(selectedProduct)
            handleSearchedCategory('', '', selectedProduct)
            selectedProduct.is_addon === 1 ? setIsForAddOn(true) : setIsForAddOn(false)
        }
        (!selectedProduct && show) && handleSearchedCategory('', '', '', true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    /* -------------------------------------------------------------------------- */
    return (
        <CustomModal
            title={selectedProduct && show ? 'Edit Product' : 'Add New Product'}
            show={show}
            className='pb-0'
            onClose={() => closeModal()}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    name: product.name,
                    creator: product.creator,
                    status: product.status,
                    description: product.description,
                    short_description: product.short_description || '',
                    imgUrl: product.image_link ? product.image_link : product.imgUrl,
                    aud: product.aud,
                    usd: product.usd,
                    gbp: product.gbp,
                    eur: product.eur,
                    cad: product.cad,
                    sgd: product.sgd,
                    myr: product.myr,
                    aed: product.aed,
                    addon: product.is_addon
                }}
                validationSchema={productSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                    <form onSubmit={(values) => { handleSubmit(values); console.log("submited=", errors, values, file, product.imgUrl, product.image_link); setHasImgSelected({ ...hasImgSelected, isSubmited: true, hasImg: file ? true : false }) }}>
                        <div className="grid grid-cols-12 items-end w-full md:pl-3 mt-3 md:mt-0 gap-2 md:gap-3 max-h-[400px] md:max-h-[590px] overflow-y-auto">
                            <div className="flex col-span-12 md:col-span-4 py-2- flex-col">
                                <div className="flex w-full relative justify-center border border-stone-300 rounded-md">
                                    <div className="absolute bottom-1 left-[55%] rounded h-6 flex flex-col items-center md:flex-row overflow-hidden">
                                        <span className="h-6 w-6 flex items-center justify-center rounded-full cursor-pointer text-blue-700 bg-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                <path fillRule="evenodd" d="M1 8a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 018.07 3h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0016.07 6H17a2 2 0 012 2v7a2 2 0 01-2 2H3a2 2 0 01-2-2V8zm13.5 3a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM10 14a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                        <input ref={fileRef} type="file" accept="image/jpeg" name='imgUrl' className='opacity-0 absolute w-full cursor-pointer' onChange={(e) => handleProductFileChange(e)} />
                                        {/* <input type="file" accept="image/jpeg" className='opacity-0- absolute w-full cursor-pointer' onChange={(e) => setTileFile(e.target.files[0])} /> */}
                                    </div>
                                    <div className="flex sm:w-24 sm:h-24 lg:w-28 lg:h-28 overflow-hidden">
                                        <img src={
                                            product.imgUrl
                                                ? product.imgUrl
                                                : product.image_link ? product.image_link : `/images/general/logo-replace.png`
                                        } alt="slider" className='w-full- w-auto h-auto rounded' />
                                        {/* {(errors.imgUrl && touched.imgUrl) &&
                                            <small id='imgUrl-error' className="text-[#f60362] text-sm">
                                                {errors.imgUrl}
                                            </small>} */}
                                        {(hasImgSelected.isSubmited && !file && !product.imgUrl && !product.image_link) &&
                                            <small id='imgUrl-error' className="text-[#f60362] text-sm">
                                                Select Image
                                            </small>}
                                        {(errorMessages.image) &&
                                            <small id='image-error' className="text-[#f60362] text-xs">
                                                {errorMessages.image}
                                            </small>}
                                    </div>
                                </div>
                                {(hasImgSelected.isSubmited && !file && !product.imgUrl && !product.image_link) &&
                                    <small id='imgUrl-error' className="text-[#f60362] text-sm">
                                        Select Image
                                    </small>}
                            </div>
                            <div className="flex col-span-12 md:col-span-8 flex-col">
                                <div className="flex w-full flex-col mb-2 md:mb-3">
                                    <div className="flex flex-col items-start w-full">
                                        <label className="text-sm text-stone-500" htmlFor="category">Category</label>
                                        <select
                                            name="category"
                                            // value={searchItems.category}
                                            value={categoryInfo.category_name}
                                            className='cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full'
                                            onChange={e => {
                                                // handleSearch('category', e.target.value);
                                                handleSearchedCategory(e.target.value, '', '')
                                            }}
                                        >
                                            {categories.map(type => (
                                                <option key={type.lable} value={type.value} className='cursor-pointer'>
                                                    {type.lable}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex w-full flex-col">
                                    <div className="flex flex-col items-start w-full">
                                        <label className="text-sm text-stone-500" htmlFor="subCategory">Sub Category</label>
                                        <select
                                            name="subCategory"
                                            // value={searchItems.subCategory}
                                            value={categoryInfo.sub_category_name}
                                            className='cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full'
                                            onChange={e => {
                                                // handleSearch('subCategory', e.target.value);
                                                handleSearchedCategory('', e.target.value, '')
                                            }}
                                        >
                                            {subCategories.map((type, index) => (
                                                <option key={`subcategori${index}`} value={type.value} className='cursor-pointer'>
                                                    {type.lable}
                                                </option>
                                            ))}
                                        </select>
                                        {(errorMessages.category_id) &&
                                            <small id='category_id-error' className="text-[#f60362] text-xs">
                                                {errorMessages.category_id}
                                            </small>}
                                    </div>
                                </div>
                            </div>
                            <div className="flex col-span-12 md:col-span-4 flex-col min-h-[76px] justify-end-">
                                <label className="text-sm text-stone-500" htmlFor="name">Name</label>
                                <input
                                    name='name'
                                    type="text"
                                    value={values.name}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setProduct({ ...product, name: e.target.value });
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.name && touched.name) &&
                                    <small id='name-error' className="text-[#f60362] text-xs">
                                        {errors.name}
                                    </small>}
                                {(errorMessages.name) &&
                                    <small id='name-error' className="text-[#f60362] text-xs">
                                        {errorMessages.name}
                                    </small>}
                            </div>
                            <div className="flex col-span-12 sm:col-span-6 md:col-span-4 flex-col min-h-[76px] justify-end-">
                                <label className="text-sm text-stone-500" htmlFor="creator">Creator</label>
                                <input
                                    name='creator'
                                    type="text"
                                    value={values.creator}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setProduct({ ...product, creator: e.target.value });
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.creator && touched.creator) &&
                                    <small id='creator-error' className="text-[#f60362] text-xs">
                                        {errors.creator}
                                    </small>}
                                {(errorMessages.creator) &&
                                    <small id='creator-error' className="text-[#f60362] text-xs">
                                        {errorMessages.creator}
                                    </small>}
                            </div>
                            <div className="flex col-span-12 sm:col-span-6 md:col-span-4 flex-col min-h-[76px] justify-end-">
                                <label className="text-sm text-stone-500" htmlFor="status">Status</label>
                                <select
                                    name="status"
                                    value={values.status}
                                    className='cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full'
                                    onChange={(e) => {
                                        handleChange(e)
                                        setProduct({ ...product, status: e.target.value });
                                    }}
                                >
                                    <option value={"1"} className='cursor-pointer'>
                                        Active
                                    </option>
                                    <option value={"0"} className='cursor-pointer'>
                                        Inactive
                                    </option>
                                </select>
                            </div>
                            <div className="flex col-span-12 md:col-span-4 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="short_description">Short Description</label>
                                <textarea
                                    rows="3"
                                    type="text"
                                    name='short_description'
                                    onBlur={handleBlur}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md p-2'
                                    value={values.short_description}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setProduct({ ...product, short_description: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="flex col-span-12 md:col-span-8 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="description">Long Description</label>
                                <textarea
                                    rows="3"
                                    type="text"
                                    name='description'
                                    onBlur={handleBlur}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md p-2'
                                    value={values.description}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setProduct({ ...product, description: e.target.value });
                                    }}
                                />
                                {(errors.description && touched.description) &&
                                    <small id='description-error' className="text-[#f60362] text-xs">
                                        {errors.description}
                                    </small>}
                                {(errorMessages.description) &&
                                    <small id='description-error' className="text-[#f60362] text-xs">
                                        {errorMessages.description}
                                    </small>}
                            </div>
                            <div className="flex col-span-6 md:col-span-4 lg:col-span-3 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="aud">AUD</label>
                                <input
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                                    name='aud'
                                    type="text"
                                    value={values.aud}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => {
                                        if (e.target.value > 0) {
                                            handleChange(e)
                                            setProduct({ ...product, aud: e.target.value });
                                        }
                                    }}
                                />
                                {(errorMessages.aud) &&
                                    <small id='aud-error' className="text-[#f60362] text-xs">
                                        {errorMessages.aud}
                                    </small>}
                            </div>
                            <div className="flex col-span-6 md:col-span-4 lg:col-span-3 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="usd">USD</label>
                                <input
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                                    name='usd'
                                    type="text"
                                    value={values.usd}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => {
                                        if (e.target.value > 0) {
                                            handleChange(e)
                                            setProduct({ ...product, usd: e.target.value });
                                        }
                                    }}
                                />
                                {(errorMessages.usd) &&
                                    <small id='usd-error' className="text-[#f60362] text-xs">
                                        {errorMessages.usd}
                                    </small>}
                            </div>
                            <div className="flex col-span-6 md:col-span-4 lg:col-span-3 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="gbp">GBP</label>
                                <input name='gbp' type="text"
                                    value={values.gbp}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => {
                                        if (e.target.value > 0) {
                                            handleChange(e)
                                            setProduct({ ...product, gbp: e.target.value });
                                        }
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                                />
                                {(errorMessages.gbp) &&
                                    <small id='gbp-error' className="text-[#f60362] text-xs">
                                        {errorMessages.gbp}
                                    </small>}
                            </div>
                            <div className="flex col-span-6 md:col-span-4 lg:col-span-3 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="eur">EUR</label>
                                <input name='eur' type="text"
                                    value={values.eur}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => {
                                        if (e.target.value > 0) {
                                            handleChange(e)
                                            setProduct({ ...product, eur: e.target.value });
                                        }
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                                />
                                {(errorMessages.eur) &&
                                    <small id='eur-error' className="text-[#f60362] text-xs">
                                        {errorMessages.eur}
                                    </small>}
                            </div>
                            <div className="flex col-span-6 md:col-span-4 lg:col-span-3 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="cad">CAD</label>
                                <input name='cad' type="text"
                                    value={values.cad}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => {
                                        if (e.target.value > 0) {
                                            handleChange(e)
                                            setProduct({ ...product, cad: e.target.value });
                                        }
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                                />
                                {(errorMessages.cad) &&
                                    <small id='cad-error' className="text-[#f60362] text-xs">
                                        {errorMessages.cad}
                                    </small>}
                            </div>
                            <div className="flex col-span-6 md:col-span-4 lg:col-span-3 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="sgd">SGD</label>
                                <input name='sgd' type="text"
                                    value={values.sgd}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => {
                                        if (e.target.value > 0) {
                                            handleChange(e)
                                            setProduct({ ...product, sgd: e.target.value });
                                        }
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                                />
                                {(errorMessages.sgd) &&
                                    <small id='sgd-error' className="text-[#f60362] text-xs">
                                        {errorMessages.sgd}
                                    </small>}
                            </div>
                            <div className="flex col-span-6 md:col-span-4 lg:col-span-3 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="myr">MYR</label>
                                <input name='myr' type="text"
                                    value={values.myr}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => {
                                        if (e.target.value > 0) {
                                            handleChange(e)
                                            setProduct({ ...product, myr: e.target.value });
                                        }
                                    }}
                                    className='border border-stone-200 focus-visible:outline-none focus-visible:border-stone-400 text-xs md:text-base rounded-md h-10 px-2'
                                />
                                {(errorMessages.myr) &&
                                    <small id='myr-error' className="text-[#f60362] text-xs">
                                        {errorMessages.myr}
                                    </small>}
                            </div>
                            <div className="flex col-span-6 md:col-span-4 lg:col-span-3 flex-col">
                                <label className="text-sm text-stone-500" htmlFor="aed">AED</label>
                                <input name='aed' type="text"
                                    value={values.aed}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => {
                                        if (e.target.value > 0) {
                                            handleChange(e)
                                            setProduct({ ...product, aed: e.target.value });
                                        }
                                    }}
                                    className='border border-stone-200 focus-visible:outline-none focus-visible:border-stone-400 text-xs md:text-base rounded-md h-10 px-2'
                                />
                                {(errorMessages.aed) &&
                                    <small id='aed-error' className="text-[#f60362] text-xs">
                                        {errorMessages.aed}
                                    </small>}
                            </div>
                            <div className="flex col-span-12 items-center [&>input]:mt-0 my-3">
                                <CheckBox
                                    name="isForAddOn"
                                    value={isForAddOn}
                                    checked={isForAddOn}
                                    setter={(e) => setIsForAddOn(e)}
                                >
                                    <span className='text-stone-500'>Add it in Upsell Section</span>
                                </CheckBox>
                            </div>
                        </div>
                        <div className="flex w-full justify-end pt-4">
                            <button className='border border-red-500 hover:bg-red-500 text-red-500 hover:text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 mr-2' onClick={() => closeModal()}>Cancel</button>
                            <button
                                disabled={isLoading}
                                type="submit"
                                className='bg-[#0f960f] hover:bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200'
                            // onClick={() => selectedProduct ? handleUpdate() : handleSave()}
                            >
                                {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                </>) : (
                                    selectedProduct && show ? 'Update' : 'Save'
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
            {/* <div className='w-full flex flex-wrap items-end'>
                <div className="flex- w-full hidden">
                    <div className="w-full border border-red-600 bg-red-50 flex flex-col justify-center items-center text-center rounded-lg mb-3 py-2">
                        <h2 className='w-full text-xl'>Please ensure:</h2>
                        <p className=''>Your banner image is in progressive <b>'.jpg'</b> format
                            <br />
                            width: <b>1920px</b> | height: <b>934px</b>
                            <br />
                            It's size is less than <b>500kb</b> and in RGB colour format.</p>
                    </div>
                </div>
            </div> */}
            {/* <div className='w-full flex flex-wrap items-end'>
                <div className="flex- w-full hidden">
                    <div className="w-full border border-red-600 bg-red-50 flex flex-col justify-center items-center text-center rounded-lg mb-3 py-2">
                        <h2 className='w-full text-xl'>Please ensure:</h2>
                        <p className=''>Your banner image is in progressive <b>'.jpg'</b> format
                            <br />
                            width: <b>1920px</b> | height: <b>934px</b>
                            <br />
                            It's size is less than <b>500kb</b> and in RGB colour format.</p>
                    </div>
                </div>
                <div className="flex w-full md:basis-auto- md:w-auto- relative justify-center">
                    <div className="absolute bottom-1 left-[55%] rounded h-6 flex flex-col items-center md:flex-row overflow-hidden">
                        <span className="h-6 w-6 flex items-center justify-center rounded-full cursor-pointer text-blue-700 bg-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path fillRule="evenodd" d="M1 8a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 018.07 3h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0016.07 6H17a2 2 0 012 2v7a2 2 0 01-2 2H3a2 2 0 01-2-2V8zm13.5 3a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM10 14a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                            </svg>
                        </span>
                        <input ref={fileRef} type="file" accept="image/jpeg" className='opacity-0 absolute w-full cursor-pointer' onChange={(e) => handleProductFileChange(e)} />
                    </div>
                    <div className="flex sm:w-24 sm:h-24 lg:w-28 lg:h-28 overflow-hidden">
                        <img src={
                            product.imgUrl
                                ? product.imgUrl
                                : product.image_link ? product.image_link : `/images/general/logo-replace.png`
                        } alt="slider" className='w-full- w-auto h-auto rounded' />
                    </div>
                </div>
                <div className="flex flex-wrap items-end w-full md:pl-3 mt-3 md:mt-0">
                    <div className="flex basis-full md:basis-1/3 flex-col mb-2 sm:pr-3">
                        <label htmlFor="name">Name</label>
                        <input name='name' type="text" value={product.name} onChange={(e) => handleChange('name', e.target.value)} className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                    </div>
                    <div className="flex basis-full sm:basis-1/2 md:basis-1/3 flex-col mb-2 sm:pr-3">
                        <label htmlFor="creator">Creator</label>
                        <input name='creator' type="text" value={product.creator} onChange={(e) => handleChange('creator', e.target.value)} className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                    </div>
                    <div className="flex basis-full sm:basis-1/2 md:basis-1/3 flex-col mb-2 min-h-[80px] justify-end">
                        <label htmlFor="creator">Status</label>
                        <select
                            name="status"
                            // disabled
                            value={product.status}
                            className='cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full'
                            onChange={(e) => {
                                // console.log("bvalue", e, product.status)
                                handleChange('status', e.target.value)
                            }}
                        >
                            <option value={"1"} className='cursor-pointer'>
                                Active
                            </option>
                            <option value={"0"} className='cursor-pointer'>
                                Inactive
                            </option>
                        </select>
                    </div>
                    <div className="flex basis-full flex-col mb-2">
                        <label htmlFor="description">Description</label>
                        <textarea rows="4" name='description' type="text" value={product.description} onChange={(e) => handleChange('description', e.target.value)} className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md p-2' />
                    </div>
                    <div className="flex basis-1/2 sm:basis-1/2 md:basis-1/4 lg:basis-1/4 flex-col mb-2 sm:pr-3">
                        <label htmlFor="aud">AUD</label>
                        <input
                            className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                            name='aud'
                            disabled-
                            type="text"
                            value={product.aud}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => {
                                if (e.target.value > 0) {
                                    handleChange('aud', e.target.value)
                                }
                            }}
                        />
                    </div>
                    <div className="flex basis-1/2 sm:basis-1/2 md:basis-1/4 lg:basis-1/4 flex-col mb-2 sm:pr-3">
                        <label htmlFor="usd">USD</label>
                        <input
                            className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                            name='usd'
                            type="text"
                            value={product.usd}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => {
                                if (e.target.value > 0) {
                                    handleChange('usd', e.target.value)
                                }
                            }}
                        />
                    </div>
                    <div className="flex basis-1/2 sm:basis-1/2 md:basis-1/4 lg:basis-1/4 flex-col mb-2 sm:pr-3">
                        <label htmlFor="gbp">GBP</label>
                        <input name='gbp' type="text"
                            value={product.gbp}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => {
                                if (e.target.value > 0) {
                                    handleChange('gbp', e.target.value)
                                }
                            }}
                            className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                    </div>
                    <div className="flex basis-1/2 sm:basis-1/2 md:basis-1/4 lg:basis-1/4 flex-col mb-2 sm:pr-3">
                        <label htmlFor="eur">EUR</label>
                        <input name='eur' type="text" value={product.eur}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => {
                                if (e.target.value > 0) {
                                    handleChange('eur', e.target.value)
                                }
                            }}
                            className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                    </div>
                    <div className="flex basis-1/2 sm:basis-1/2 md:basis-1/4 lg:basis-1/4 flex-col mb-2 sm:pr-3">
                        <label htmlFor="cad">CAD</label>
                        <input name='cad' type="text" value={product.cad}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => {
                                if (e.target.value > 0) {
                                    handleChange('cad', e.target.value)
                                }
                            }}
                            className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                    </div>
                    <div className="flex basis-1/2 sm:basis-1/2 md:basis-1/4 lg:basis-1/4 flex-col mb-2 sm:pr-3">
                        <label htmlFor="sgd">SGD</label>
                        <input name='sgd' type="text" value={product.sgd}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => {
                                if (e.target.value > 0) {
                                    handleChange('sgd', e.target.value)
                                }
                            }}
                            className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                    </div>
                    <div className="flex basis-1/2 sm:basis-1/2 md:basis-1/4 lg:basis-1/4 flex-col mb-2 sm:pr-3">
                        <label htmlFor="myr">MYR</label>
                        <input name='myr' type="text" value={product.myr}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => {
                                if (e.target.value > 0) {
                                    handleChange('myr', e.target.value)
                                }
                            }}
                            className='border border-stone-200 focus-visible:outline-none focus-visible:border-stone-400 text-xs md:text-base rounded-md h-10 px-2' />
                    </div>
                    <div className="flex basis-1/2 sm:basis-1/2 md:basis-1/4 lg:basis-1/4 flex-col mb-2 sm:pr-3">
                        <label htmlFor="aed">AED</label>
                        <input name='aed' type="text" value={product.aed}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => {
                                if (e.target.value > 0) {
                                    handleChange('aed', e.target.value)
                                }
                            }}
                            className='border border-stone-200 focus-visible:outline-none focus-visible:border-stone-400 text-xs md:text-base rounded-md h-10 px-2' />
                    </div>
                    <div className="flex basis-full my-2 md:my-4">
                        <CheckBox
                            name="isForAddOn"
                            value={isForAddOn}
                            checked={isForAddOn}
                            setter={(e) => setIsForAddOn(e)}
                        >
                            <span>Add it Add-On Category</span>
                        </CheckBox>
                    </div>
                </div>
                <div className="flex w-full justify-end pt-4">
                    <button className='border border-red-500 hover:bg-red-500 text-red-500 hover:text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 mr-2' onClick={() => closeModal()}>Cancel</button>
                    <button disabled={isLoading} className='bg-[#0f960f] hover:bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200' onClick={() => selectedProduct ? handleUpdate() : handleSave()}>
                        {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        </>) : (
                            selectedProduct && show ? 'Update' : 'Save'
                        )}
                    </button>
                </div>
            </div> */}
        </CustomModal>
    );
};
export default HandleProduct;

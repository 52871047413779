import { useEffect, useState } from 'react';
import services from '../../services';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';

const GetReceiptPage = () => {
  const [isLoading, setIsloading] = useState(false);
  const [userEmail, setUserEmail] = useState('')
  // eslint-disable-next-line no-useless-escape
  const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const UserEmailSchema = Yup.object().shape({
    email: Yup.string().matches(emailRegExp, 'Invalid email address').required('Enter your email address'),
  });
  /* ------------------------------ Start methods ----------------------------- */
  const handleSubmit = (values) => {
    setIsloading(true);
    sendEmail();
  }
  const sendEmail = async () => {
    try {
      const response = await services.sendEmailForGetReceipt({email: userEmail});
      console.log('response sendEmailForGetReceipt', response);
      setIsloading(false);
      toast.success('The receipt has been sent to your email address');
    } catch (error) {
      setIsloading(false);
      toast.error('Something went wrong. Please try again later.');
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0,0);
}, [])
  /* --------------- ----------------------------------------------------------- */

  return (
    <Layout>
      <WidgetHome selectedCurrency={(e) => { }} />
      <div className={`w-full bg-[url('../src/images/banners/banner-bg.png')] bg-cover text-white h-auto md:h-[340px] flex justify-center items-center`}>
        <div className="w-full h-full bg-[#093484]- bg-opacity-70 py-4">
          <div className="md:container md:mx-auto px-2 sm:px-3 h-full flex flex-col justify-center items-center">
            <h1 className='w-full text-white text-lg sm:text-2xl md:text-5xl text-center'>
              Get Your Receipt
            </h1>
          </div>
        </div>
      </div>
      <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center mt-6 mx-6">
        <div className="basis-full md:w-3/5 bg-white rounded-md shadow-md py-7 md:py-10 px-2 md:px-6">
          <p className='text-[#777] text-lg md:text-xl mb-2'>
            Please enter your email address to receive a copy of your receipt.
          </p>
          <p className='text-[#777] text-xs sm:text-sm md:text-md mb-8'>
            Please note, currently the receipt service is for donations made after 22nd March 2023, donations made prior to this will be available in the next few weeks.
          </p>
          <Formik
            initialValues={{
              email: userEmail,
            }}
            validationSchema={UserEmailSchema}
            onSubmit={(values) => {
              console.log("values", values)
              handleSubmit(values);
            }}
          >
            {({ errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-4 gap-4 items-end">
                  <div className="col-span-4">
                    <label className='text-sm md:text-md text-[#777] w-full flex'>Email<small className='text-[#f60362] text-lg h-5 flex items-start ml-1'>*</small></label>
                    <small className='w-full text-stone-400 mb-1'>You should receive the receipt within 1 minute, please check your promotions or spam folder. If you still haven't received it, please contact us.</small>
                    <input
                      type="text"
                      name='email'
                      id='email'
                      placeholder='Enter your email'
                      className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                      onBlur={handleBlur}
                      value={userEmail}
                      onChange={(e) => {
                        handleChange(e);
                        setUserEmail(e.target.value);
                      }}
                    />
                    {(errors.email && touched.email) &&
                      <small id='email-error' className="text-[#f60362] text-sm">
                        {errors.email}
                      </small>}
                  </div>
                  <div className="col-span-4 sm:col-span-3 sm:col-start-2 md:col-start-3 md:col-span-2">
                    <button
                      type="submit"
                      className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]`}
                    >
                      {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      </>) : <><span>Send</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                      </svg>
                      </>}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default GetReceiptPage;

import React, { useEffect, useRef, useState } from 'react';
import services from '../../services';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { AsYouType } from 'libphonenumber-js';
import * as Yup from 'yup';
import { Formik } from 'formik';
// import { Formik } from 'formik';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
import handIcon from '../../images/landings/opt-out/hand-icon.png';
import heartIcon from '../../images/landings/opt-out/heart-icon.png';
import togetherIcon from '../../images/landings/opt-out/together-icon.png';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
const OptOutPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const refPhone = useRef(null);
    const refWhatsapp = useRef(null);
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: '',
        phone: '',
        whatsapp: '',
        email: '',
    });
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    // eslint-disable-next-line no-useless-escape
    const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const StepOneSchema = Yup.object().shape({
        name: Yup.string().min(3, 'Should be 3 character long').required('Enter your name'),
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Enter phone number').min(10, 'Should be 10 digits'),
        whatsapp: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Enter whatsapp').min(10, 'Should be 10 digits'),
        email: Yup.string().matches(emailRegExp, 'Invalid email address').required('Enter your email address'),
    });
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    // const iframe = '<iframe src="https://forms.zohopublic.com/zohosam40/form/EmailSubscription/formperma/V3nYG5u3rbu8GpM3EAsNOyupk3hzCdswE_I8p9CdeE8" height="100%" width="100%"></iframe>';
    const iframeSms = '<iframe src="https://forms.zohopublic.com/zohosam40/form/unsubsms/formperma/WWG6m7NXkVI20pJAJ1mkwQMdzRXN_S_H20ptqhI8f9c" height="100%" width="100%"></iframe>';
    const iframeEmail = '<iframe src="https://forms.zohopublic.com/zohosam40/form/unsubEmail/formperma/BiOpwj4-obAH_fLZSZ4zFYxKgKTbzImx5Pqfqihsyps" height="100%" width="100%"></iframe>';
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSubmit = () => {
        if (isPhoneValid) {
            setIsloading(true);
            submitForm();
        }
    }
    const submitForm = async () => {
        try {
            let tempUser = {};
            tempUser.name = userInfo.name;
            tempUser.email = userInfo.email;
            let phoneNumber = '';
            let whatsappNumber = '';
            if (userInfo.phone.charAt(0) === '0') {
                phoneNumber = `0${userInfo.phone}`
            } else {
                phoneNumber = `00${userInfo.phone}`
            }
            if (userInfo.whatsapp.charAt(0) === '0') {
                whatsappNumber = `0${userInfo.whatsapp}`
            } else {
                whatsappNumber = `00${userInfo.whatsapp}`
            }
            tempUser.phone = phoneNumber;
            tempUser.whatsapp = whatsappNumber;
            console.log('tempUser createUser', tempUser);
            const response = await services.postFormOpt(tempUser);
            console.log('response createUser', response);
            toast.success('Thank you for complete the form.');
            setIsloading(false);
            setUserInfo({
                ...userInfo,
                name: '',
                phone: '',
                whatsapp: '',
                email: ''
            })
        } catch (error) {
            setIsloading(false);
            toast.error('Something went wrong. Please try again later.');
        }
    };
    const handlePhoneValidation = (value, country, type) => {
        let temp = new AsYouType(country.countryCode).input(`+${value}`)
        if (temp.split(' ')[1] && Number(temp.split(' ')[1].charAt(0)) === 0) {
            let temp3 = country.dialCode + value.substr(value.indexOf(country.dialCode) + country.dialCode.length + 1, value.length);
            console.log("temp3==", temp3);
            if (type === 'whatsapp') {
                refWhatsapp.current.value = temp3;
                setUserInfo({
                    ...userInfo,
                    whatsapp: temp3,
                });
            } else {
                refPhone.current.value = temp3;
                setUserInfo({
                    ...userInfo,
                    phone: temp3,
                });
            }

        } else {
            if (type === 'whatsapp') {
                setUserInfo({
                    ...userInfo,
                    whatsapp: value,
                });
            } else {
                setUserInfo({
                    ...userInfo,
                    phone: value,
                });
            }
        }
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' })
        window.scroll(0, 0);
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <NewHelmetLayout>
            {seo &&
                <Helmet>
                    <title>{`${seo['resourcesAndInformation']['optOut']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo['resourcesAndInformation']['optOut']['description']}`} />
                </Helmet>
            }
            <div className="lg:container mx-auto px-6 lg:px-0 pt-28">
                <div className="bg-white rounded-md shadow-md px-0 md:px-6 py-6 lg:py-12 flex flex-wrap justify-center">
                    <div className="w-full md:w-3/4 flex items-center flex-col px-4 md:px-0">
                        <h2 class="font-['Gotcha'] font-medium text-4xl md:text-6xl text-[#253b7e] mx-2 md:mx-4 text-center">We’re sorry to see you go!</h2>
                        <p className='text-lg md:text-2xl text-[#253b7e] my-4 md:my-8 text-center w-full'>
                            We know inboxes can get a little crowded but we hope you’ll keep making room for us to help you stay updated on all the incredible differences
                            we’re making in the world together.
                        </p>
                    </div>
                    <div className="w-full md:w-3/4 flex items-center md:items-start flex-col md:flex-row text-[#00a3da] py-2 px-4 md:px-0">
                        <img src={handIcon} alt="hand" className='mb-3 md:mb-0' />
                        <div className='text-[#777] w-full pb-4 pl-0 md:pl-8 mb-4 md:mb-0'>
                            <p className='text-lg md:text-xl font-bold text-[#253b7e]'>
                                Because we will always make room for you.
                            </p>
                            <p className='text-lg text-[#777] mb-2'>
                                You initially subscribed to our emails so that you could stay updated about the impact we’re making all over the world,
                                and we loved showing you why we do what we do.
                                <br />
                                <br />
                                We really don’t want you to leave us but we’ll give you some space to decide…
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-3/4 flex items-center md:items-start flex-col md:flex-row text-[#00a3da] py-2 px-4 md:px-0">
                        <img src={togetherIcon} alt="together" className='mb-3 md:mb-0' />
                        <div className='text-[#777] w-full pb-4 pl-0 md:pl-8 mb-4 md:mb-0'>
                            <p className='text-base md:text-xl font-bold text-[#253b7e]'>
                                Let’s stay together on this incredible journey for change
                            </p>
                            <p className='text-lg text-[#777] mb-2'>
                                Yes! We’re doing amazing things together and Insha’Allah, they’ll only get better. We’re so glad you’re sticking around.
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-3/4 flex items-center md:items-start flex-col md:flex-row text-[#00a3da] py-2 px-4 md:px-0">
                        <img src={heartIcon} alt="heart" className='mb-3 md:mb-0' />
                        <div className='text-[#777] w-full pb-4 pl-0 md:pl-8 mb-4 md:mb-0'>
                            <p className='text-base md:text-xl font-bold text-[#253b7e]'>
                                Nah… we need a break
                            </p>
                            <p className='text-lg text-[#777] mb-2'>
                                If you no longer wish to receive emails from us,  please tell us why before you go. (We’re sad to see you leave,
                                but maybe our paths will join once again,  Insha’Allah!)
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-3/4 flex flex-wrap items-start justify-end text-[#00a3da] py-2">
                        <div className={'w-full md:w-[calc(100%_-_100px)] h-[270px]'} dangerouslySetInnerHTML={{ __html: iframeSms }} />
                        <div className={'w-full md:w-[calc(100%_-_100px)] h-[270px]'} dangerouslySetInnerHTML={{ __html: iframeEmail }} />
                    </div>
                    {/* <div className="w-full flex items-center text-[#00a3da] py-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                        <div className='text-[#777] border-b border-stone-300 w-full pb-4'>
                            <p className='text-lg text-[#777] mb-2'>To opt-out from SMS please send <strong>STOP</strong> to:</p>
                            <a href="tel:+44 7478 724055" target="_self" className="text-primary" rel="noopener noreferrer">+44 7478 724055</a> for UK or <br />
                            <a href="tel:+61 429 558 108" target="_self" className="text-primary" rel="noopener noreferrer">+61 429 558 108</a> for Australia
                        </div>
                    </div>
                    <div className="w-full flex items-center text-[#00a3da] py-2 mt-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                        <div className='text-[#777] border-b border-stone-300 w-full pb-4'>
                            <p className='text-lg text-[#777] mb-2'>To opt-out from WhatsApp marketing please message <strong>STOP</strong> to:</p>
                            <a href="tel:+61 452 375 738" target="_self" className="text-primary" rel="noopener noreferrer">+61 452 375 738</a> from WhatsApp.
                        </div>
                    </div>
                    <div className="w-full flex items-center- text-[#00a3da] py-2 mt-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                        <div className='text-[#777] border-b border-stone-300 w-full pb-4'>
                            <p className='text-lg text-[#777] mb-2'>To remove yourself from email campaigns please use the unsubscribe link in the footer of the email you received.</p>
                        </div>
                    </div> */}
                </div>
                <div className="bg-white rounded-md shadow-md p-4 lg:p-6 hidden">
                    <h2 className="text-[#777] text-lg">
                        Please complete your details to opt-out from our marketing.
                    </h2>
                    <Formik
                        initialValues={{
                            name: userInfo.name,
                            email: userInfo.email,
                            phone: userInfo.phone,
                            onBehalf: userInfo.onBehalf,
                        }}
                        validationSchema={StepOneSchema}
                        onSubmit={(values) => {
                            console.log("values", values)
                            handleSubmit(values);
                        }}
                    >
                        {({ errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-cols-4 gap-4">
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777]'>Name<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <input
                                                type="text"
                                                name='name'
                                                id='name'
                                                placeholder='Enter your name'
                                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                                onBlur={handleBlur}
                                                value={userInfo.name}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setUserInfo({
                                                        ...userInfo,
                                                        name: e.target.value,
                                                    });
                                                }}
                                            />
                                            {(errors.name && touched.name) &&
                                                <small id='name-error' className="text-[#f60362] text-sm">
                                                    {errors.name}
                                                </small>}
                                        </label>
                                    </div>
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777] w-full'>Phone<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <PhoneInput
                                                name="phone"
                                                ref={refPhone}
                                                className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md even:min-h-[48px]"
                                                id="phone"
                                                placeholder='Enter your phone number'
                                                onBlur={(val) => {
                                                    let e = { target: { value: val, name: 'phone', id: 'phone' } }
                                                    handleBlur(e)
                                                }}
                                                isValid={(value, country) => {
                                                    if (value.match(/12345/)) {
                                                        setIsPhoneValid(false)
                                                        return 'Invalid value: ' + value + ', ' + country.name;
                                                    } else if (value.match(/1234/)) {
                                                        return false;
                                                    } else {
                                                        setIsPhoneValid(true)
                                                        return true;
                                                    }
                                                }}
                                                country={'au'}
                                                inputClass={'!w-full'}
                                                value={userInfo.phone || ""}
                                                onChange={(val, country) => {
                                                    let e = { target: { value: val, name: 'phone', id: 'phone' } };
                                                    handlePhoneValidation(val, country, 'phone')
                                                    handleChange(e);
                                                }}
                                            />
                                            {(errors.phone && touched.phone) &&
                                                <small id='phone-error' className="text-[#f60362] text-sm">
                                                    {errors.phone}
                                                </small>}
                                        </label>
                                    </div>
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777]'>WhatsApp<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <PhoneInput
                                                name="whatsapp"
                                                ref={refWhatsapp}
                                                className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md even:min-h-[48px]"
                                                id="whatsapp"
                                                placeholder='Enter whatsapp number'
                                                onBlur={(val) => {
                                                    let e = { target: { value: val, name: 'whatsapp', id: 'whatsapp' } }
                                                    handleBlur(e)
                                                }}
                                                isValid={(value, country) => {
                                                    if (value.match(/12345/)) {
                                                        setIsPhoneValid(false)
                                                        return 'Invalid value: ' + value + ', ' + country.name;
                                                    } else if (value.match(/1234/)) {
                                                        return false;
                                                    } else {
                                                        setIsPhoneValid(true)
                                                        return true;
                                                    }
                                                }}
                                                country={'au'}
                                                inputClass={'!w-full'}
                                                value={userInfo.whatsapp || ""}
                                                onChange={(val, country) => {
                                                    let e = { target: { value: val, name: 'whatsapp', id: 'whatsapp' } };
                                                    handlePhoneValidation(val, country, 'whatsapp')
                                                    handleChange(e);
                                                }}
                                            />
                                            {(errors.whatsapp && touched.whatsapp) &&
                                                <small id='whatsapp-error' className="text-[#f60362] text-sm">
                                                    {errors.whatsapp}
                                                </small>}
                                        </label>
                                    </div>
                                    <div className="col-span-4 sm:col-span-2">
                                        <label className='text-sm md:text-md text-[#777]'>Email<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                                            <input
                                                type="text"
                                                name='email'
                                                id='email'
                                                placeholder='Enter your email'
                                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                                onBlur={handleBlur}
                                                value={userInfo.email}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setUserInfo({
                                                        ...userInfo,
                                                        email: e.target.value,
                                                    });
                                                }}
                                            />
                                            {(errors.email && touched.email) &&
                                                <small id='email-error' className="text-[#f60362] text-sm">
                                                    {errors.email}
                                                </small>}
                                        </label>
                                    </div>
                                </div>
                                <div className="grid grid-cols-6 mt-8 gap-2 md:gap-4">
                                    <div className="col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2">
                                        <button
                                            type="submit"
                                            disabled={isLoading}
                                            // className="group active:bg-blue-400 w-full flex items-center justify-center rounded bg-blue-600 text-white px-6 pt-2 pb-2 font-normal text-lg h-[48px] shadow-[0_4px_9px_-4px_#3b71ca]   hover:bg-blue-800 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-800 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                            className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]`}
                                        >
                                            {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            </>) : <><span>Submit</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                            </svg>
                                            </>}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </NewHelmetLayout>
    );
}

export default OptOutPage;

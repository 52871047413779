import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import Accordion from '../../components/general/accordion';
import { eidAlAdhaFaqs } from './faqs';
import OrphanSmileImg from '../../images/landings/qurban/orphan-smile.png';

import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import banner from '../../images/landings/qurban/eid-al-adha-banner.jpg';
import bannerMobile from '../../images/landings/qurban/eid-al-adha-banner-mobile.png';
import { useEffect, useState } from 'react';
import { selectProducts } from '../../store/products';
import eidAlAdhaSlider1 from '../../images/landings/qurban/eid-al-adha-slider-1.png';
import eidAlAdhaSlider2 from '../../images/landings/qurban/eid-al-adha-slider-2.jpg';
import eidAlAdhaSlider3 from '../../images/landings/qurban/eid-al-adha-slider-3.png';
import eidAlAdhaSlider4 from '../../images/landings/qurban/eid-al-adha-slider-4.png';
import eidAlAdhaSlider5 from '../../images/landings/qurban/eid-al-adha-slider-5.png';
import eidAlAdhaSlider6 from '../../images/landings/qurban/eid-al-adha-slider-6.png';
import eidAlAdhaSlider7 from '../../images/landings/qurban/eid-al-adha-slider-7.jpg';
import { OurPromiseSection } from '../../components/general';
// import HeroTab from '../../components/general/heroTab';
import SadaqahJariyahTabs from '../home/components/sadaqaJariyahTabs';
import useGetIndexDataHook from '../eidAlFitr2024/hooks/useGetIndexDataHook';
import { sadaqahJariyaText, tabButtonsSadaqah } from '../ramadan/laylatulQadar/fixtures';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { useKeenSlider } from 'keen-slider/react';
import NewHelmetLayout from '../../components/general/newHelmetLayout';


const images = [
  { src: eidAlAdhaSlider1, alt: '' },
  { src: eidAlAdhaSlider2, alt: '' },
  { src: eidAlAdhaSlider3, alt: '' },
  { src: eidAlAdhaSlider4, alt: '' },
  { src: eidAlAdhaSlider5, alt: '' },
  { src: eidAlAdhaSlider6, alt: '' },
  { src: eidAlAdhaSlider7, alt: '' },
];

const QurbanEidAlAdhaLandingPage = ({ title }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const { sadaqahProducts, selectedCurrency } = useGetIndexDataHook();
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const [isLoaded, setIsLoaded] = useState(false);
  const seo = SeoData[selectedcountryCode];
  const [addProduct] = useHandleAddProduct();
  const [/*palestineEmergencyProducts*/, setPalestineEmergencyProducts] = useState([0, 0, 0]);
  const [eidGiftProducts, setEidGiftProducts] = useState([0]);
  const [/*zakatProducts*/, setZakatProducts] = useState([0, 0]);
  const [/*orphansProducts*/, setOrphansProducts] = useState([0, 0, 0]);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });

  // const tabButtonsProducts = [
  //   {
  //     title: 'EID Gifts',
  //     description: `Spread Eid joy this Eid Al Adha to vulnerable orphaned children by delivering a gift to make them smile! `,
  //     products: eidGiftProducts,
  //   },
  //   {
  //     title: 'Zakat Al Mal',
  //     description: `Zakat can be given throughout the year. Fulfil your obligation today and Feel Your Rewards!`,
  //     products: zakatProducts,
  //   },
  //   {
  //     title: 'Orphans',
  //     description: `Help us save orphaned children in Palestine by providing urgent relief`,
  //     products: orphansProducts,
  //   },
  //   {
  //     title: 'Palestine Emergency',
  //     description: `Start the Islamic New Year by saving a life. The suffering continues throughout Palestine as famine spreads and the conflict continues to claim lives.. Help us continue supporting our Palestinian brothers and sisters.`,
  //     products: palestineEmergencyProducts,
  //   },
  // ];

  /* -------------------------------------------------------------------------- */
  /* ------------------------------ Start Methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempPalestineEmergencyProducts = [];
    let tempEidGiftProducts = [];
    let tempZakateProducts = [];
    let tempOrphansProducts = [];

    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          // Eid Gift Products
          if (inner_item_product.creator === 'TK-QB-2024-EG28') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Qurban Eid Gifts',
              description: 'Lets put a smile on Orphans, Widows and Less Privilege by distributing Meat and Eid Gifts.',
            });
            tempEidGiftProducts[0] = inner_item_product;
          }

          // Zakat Products
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/zakat',
              staticLinkText: 'GIVE ZAKAT AL MAL',
            });
            tempZakateProducts[0] = inner_item_product;
            tempZakateProducts[1] = Object.assign({}, inner_item_product, {
              name: 'ZAKAT CALCULATOR',
              description: 'Use our calculator to find out what you need to give in zakat.',
              image_link: '/images/zakat/zakat-cal.jpg',
              isStaticProduct: true,
              staticLink: '/zakat-calculator',
              staticLinkText: 'CALCULATE YOUR ZAKAT',
              // linkBtnClasses: 'lg:!text-base !font-normal'
            });
          }

          // Orphans Products
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-204') {
            tempOrphansProducts[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            tempOrphansProducts[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-311') {
            tempOrphansProducts[2] = inner_item_product;
          }

          // Palestine Emergency Products
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            tempPalestineEmergencyProducts[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {
            tempPalestineEmergencyProducts[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            tempPalestineEmergencyProducts[2] = inner_item_product;
          }
        });
      });
    });
    setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
    setEidGiftProducts(tempEidGiftProducts);
    setZakatProducts(tempZakateProducts);
    setOrphansProducts(tempOrphansProducts);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout className="!bg-white" showRegularImg={false}>
      {seo && (
        <Helmet>
          <title>{`${seo[title.toLowerCase()]['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={`${seo[title.toLowerCase()]['description']}`} />
        </Helmet>
      )}
      {/* /* -------------------------------------------------------------------------- */}

      {/* --------------------------- Widget Section ----------------------------------- */}
      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: 'Where Most Needed',
            value: 122,
          }}
        />
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Banner ---------------------------------  */}
      <section className="flex justify-center">
        <img src={banner} alt="banner" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="banner" className="min-[501px]:hidden w-full" />
      </section>
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 md:flex justify-center items-center">
            <CrisisWidget
              floating
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={{
                creator: 'MKD-MN-001',
                label: 'Where Most Needed',
                value: 122,
              }}
            />
          </div>
        </section>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Our Promise Section ---------------------------------  */}
      <OurPromiseSection />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Tabs Section ---------------------------------  */}
      {/* <HeroTab tabButtons={tabButtonsProducts} /> */}
      {/* /* -------------------------------------------------------------------------- */}

      {/* ------------------------------- Header Section ---------------------------- */}
      <section className="md:container md:mx-auto my-8 md:my-12 text-center px-5 lg:px-0">
        <h1 className="font-brandingBold text-[30px] md:text-[40px] text-[#253B7E] leading-[32px] md:leading-[42px]">
          Help us spread Eid joy and reap the rewards of
          <br />
          <span className="text-[#F60362]">Dhul Hijjah!</span>
        </h1>
        <p className="max-w-[1080px] mx-auto mt-8 font-brandingMedium text-left text-base md:text-lg leading-normal  text-[#78716C]">
          Eid Mubarak from our family to yours! Thanks to the kindness and generosity of our donors, and the tireless efforts of our volunteers and
          staff, our Qurban 2024 delivery was our biggest to date - Allahu Akbar!
          <br />
          <br />
          Our MATW team has been hard at work delivering nourishing meals and fresh meat to families who wouldn't otherwise get to eat meat throughout
          the year.
          <br />
          <br />
          Your Qurban donations this Dhul Hijjah not only provide nourishment but a sense of hope and dignity for families living in poverty and
          facing extreme hardship whether it be through their economic circumstances, conflict or disaster.
        </p>

        {/* /* --------------------------------- Youtube Video Section ---------------------------------  */}
        <iframe
          className="video w-full aspect-[16/9] mt-10"
          width={'100%'}
          title={'youtube'}
          frameBorder={0}
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={`https://www.youtube.com/embed/1tX_yAf4-zA?controls=1&autoplay=0&mute=1&playlist=1tX_yAf4-zA`}
        ></iframe>
        {/* /* -------------------------------------------------------------------------- */}
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Orphan Smile Section ---------------------------------  */}
      <section className="md:container !max-w-[1080px] md:mx-auto my-8 md:my-12 px-5 lg:px-0 flex gap-x-8 gap-y-5 flex-col lg:flex-row md:items-center">
        <img src={OrphanSmileImg} alt="Orphan Smile" className="w-full lg:basis-1/2" />
        <div className="basis-1/2">
          <h1 className="font-brandingBold text-[30px] md:text-[40px] text-[#253B7E] leading-[32px] md:leading-[42px]">
            Make an orphan smile - deliver an <span className="text-[#F60362]">Eid gift!</span>
          </h1>
          <p className="text-base md:text-lg text-[#78716C] font-brandingMedium leading-4 md:leading-5 mt-2.5 mb-5">
            There’s still time to spread Eid Joy in the most impoverished communities and conflict zones around the world. Your generosity in Ramadan
            meant the world to orphaned children and their families. Go a step further, make their Eid a little brighter.{' '}
          </p>
          <button
            className="text-white text-base lg:text-xl col-span-6 xl:col-span-4 xl:col-start-2 flex rounded-full- rounded-md transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] mx-auto lg:mx-0 cursor-pointer px-2 py-2 justify-center items-center"
            onClick={() => eidGiftProducts[0] && addProduct({product: eidGiftProducts[0], currency: selectedCurrency})}
          >
            Donate Now
          </button>
        </div>
      </section>

      {/* /* --------------------------------- Sadaqah Jariyah Tabs Section ---------------------------------  */}
      <section className="mt-4">
        <SadaqahJariyahTabs
          sadaqahProducts={sadaqahProducts}
          currency={selectedCurrency}
          text={sadaqahJariyaText}
          tabButtonsSadaqah={tabButtonsSadaqah}
        />
      </section>
      {/* /* -------------------------------------------------------------------------- */}


      {/* /* --------------------------------- Images --------------------------------- */}
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        <div className="md:container md:mx-auto flex justify-center flex-wrap ">
          <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
            <div ref={sliderRefImages} className="keen-slider">
              {images.map((item, index) => {
                return (
                  <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                    {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                    <img src={item.src} alt={item.alt} className=" bg-top object-cover w-full object-top" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full  flex justify-center">
            {loaded && instanceRefImages.current && (
              <div className="dots flex pt-3 ">
                {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRefImages.current?.moveToIdx(idx);
                      }}
                      className={
                        'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                        (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                      }
                    ></button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- FAQ’s Section ---------------------------------  */}
      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h2 className="text-[#253B7E] text-left  sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] font-brandingBold">
          Eid Mubarak Donations <span className="text-[#F60362]"> FAQ’s</span>
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 md:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{eidAlAdhaFaqs[0].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{eidAlAdhaFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 md:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{eidAlAdhaFaqs[1].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]  whitespace-pre-line">{eidAlAdhaFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 md:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{eidAlAdhaFaqs[2].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C] whitespace-pre-line">{eidAlAdhaFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {eidAlAdhaFaqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion
                    key={`keyallfaq${index}`}
                    shadow
                    title={item.question}
                    index={index}
                    level={1}
                    noBorder={true}
                    className="!text-[#253B7E] text-lg font-brandingBold"
                  >
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};

export default QurbanEidAlAdhaLandingPage;
import React, { useState, useEffect } from "react";
import Card from '../../components/card';
import services from "../../../../services";
import MainMenu from "./mainMenu";
import HighlightMenu from "./highlightMenu";
import { toast } from 'react-toastify';
import HandleMenu from "./handleMenu";

const Menus = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [allNavbarMenus, setAllNavbarMenus] = useState([]);
    const [allHighlightsMenus, setAllHighlightsMenus] = useState([]);
    const [allFooterMenus, setAllFooterMenus] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState();
    const [showModal, setShowModal] = useState(false);
    const [currentParentId, setCurrentParentId] = useState(0);
    const [currentSection, setCurrentSection] = useState('main');
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const tabs = [
        'Navbar',
        'Highlights',
        'Footer',
    ]
    const columnsDataHighlightMenus = [
        {
            Header: "Image",
            accessor: "icon",
        },
        {
            Header: "Title",
            accessor: "title",
        },
        {
            Header: "Link",
            accessor: "url",
        },
        {
            Header: "Order",
            accessor: "order",
        },
        {
            Header: "Created Date",
            accessor: "created_at",
        },
        {
            Header: "Updated Date",
            accessor: "updated_at",
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Actions",
            accessor: 'id',
        },
    ];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllMenus = async () => {
        setIsLoading(true);
        try {
            const response = await services.getAdminAllMenus();
            let tempNavbarMenus = response.data.data.filter(n => n.section === 'main');
            let tempFooterMenus = response.data.data.filter(n => n.section === 'footer');
            let tempHighlightMenus = response.data.data.filter(n => n.section === 'circle');
            setAllNavbarMenus(tempNavbarMenus);
            setAllFooterMenus(tempFooterMenus);
            setAllHighlightsMenus(tempHighlightMenus);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const handleAddMenuItem = (_parentID, section) => {
        setCurrentParentId(_parentID);
        setCurrentSection(section);
        setShowModal(true);
    }
    const handleDeleteMenuItem = async (id) => {
        try {
            await services.deleteMenu(id);
            toast.success('The menu has been deleted successfully');
            getAllMenus();
        } catch (error) {
            toast.error(error.response.data.message || 'Something went wrong!');
            console.error('error========', error);
            console.error('error========||=', error.response.data);
        }
    }
    const handleEditMenuItem = (_menu, section) => {
        console.log("menu=",_menu)
        setCurrentSection(section)
        setSelectedMenuItem(_menu);
        setShowModal(true);
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        getAllMenus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <>
            <Card className={"w-full h-full sm:overflow-auto px-2 md:px-4 2xl:px-6"}>
                <div className="flex flex-col w-full">
                    <div className="flex w-full border-b border-stone-400 mb-4">
                        {tabs.map((item, index) => {
                            return <div
                                key={`tabs${index}`}
                                className={`flex justify-center items-center px-4 py-2 rounded-tl-md rounded-tr-md cursor-pointer ${activeTab === index ? 'bg-primaryDark text-white font-bold' : ' text-stone-600'}`}
                                onClick={() => setActiveTab(index)}
                            >
                                {item}
                            </div>
                        })}
                    </div>
                    {activeTab === 0 && <MainMenu
                        type="main"
                        allNavbarMenus={allNavbarMenus}
                        onAddMenu={(e) => handleAddMenuItem(e,'main')}
                        onUpdateMenu={(e) => handleEditMenuItem(e,'main')}
                        onDeleteMenu={(e) => handleDeleteMenuItem(e,'main')}
                    />}
                    {activeTab === 1 && <HighlightMenu
                        columnsData={columnsDataHighlightMenus}
                        tableData={allHighlightsMenus || []}
                        isLoading={isLoading}
                        onAddMenu={(e) => handleAddMenuItem(e,'circle')}
                        onUpdateMenu={(e) => handleEditMenuItem(e,'circle')}
                        onDeleteMenu={(e) => handleDeleteMenuItem(e,'circle')}
                        />
                    }
                    {activeTab === 2 && <MainMenu
                        type="footer"
                        allFooterMenus={allFooterMenus}
                        onAddMenu={(e) => handleAddMenuItem(e,'footer')}
                        onUpdateMenu={(e) => handleEditMenuItem(e,'footer')}
                        onDeleteMenu={(e) => handleDeleteMenuItem(e,'footer')}
                        />}
                </div>
            </Card>
            {showModal && <HandleMenu
                show={showModal}
                selectedMenuItem={selectedMenuItem}
                currentSection={currentSection}
                currentParentId={currentParentId}
                onClose={() => { setShowModal(false); setSelectedMenuItem(null) }}
                onSaved={getAllMenus}
            >
            </HandleMenu>}
        </>
    );
};
export default Menus;
import React, { useEffect } from 'react';
import { GlobalDebug } from './utils/removeConsole';
import AllRoutes from './routes';
import GoogleTagManager from './utils/googleTagManager';
import { selectSelectedCurrency, setCountry, setSelectedCurrencyInStore } from './store/user';
import { useDispatch, useSelector } from 'react-redux';
// import { BASKET_CACHE_KEY } from './utils/handleLocalStorage';

const App = () => {
  const dispatch = useDispatch();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    (process.env.APP_ENV === 'production' || process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') && GlobalDebug(false);
    GoogleTagManager(window.location.href);
    // if (!window.location.href.includes('checkout')) {
    // }
    fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
      .then(res => res.json())
      .then(response => {
        if (!selectedCurrencyStore) {
          switch (response.countryCode) {
            case 'AU':
              dispatch(setSelectedCurrencyInStore('AUD'));
              break;
            case 'US':
              dispatch(setSelectedCurrencyInStore('USD'));
              break;
            case 'MY':
              dispatch(setSelectedCurrencyInStore('MYR'));
              break;
            case 'SG':
              dispatch(setSelectedCurrencyInStore('SGD'));
              break;
            case 'CA':
              dispatch(setSelectedCurrencyInStore('CAD'));
              break;
            // case 'AE':
            //   dispatch(setSelectedCurrencyInStore('AED'));
            //   break;
            default:
              dispatch(setSelectedCurrencyInStore('USD'));
          }
          if (response.timezone.includes('Europe')) {
            if (response.countryCode === 'GB') {
              dispatch(setSelectedCurrencyInStore('GBP'));
            } else {
              dispatch(setSelectedCurrencyInStore('EUR'));
            }
          }
        }
        dispatch(setCountry(response.country === 'United Kingdom' ? 'uk' : ''));
      })
      .catch((data, status) => {
        console.log('Request failed:', data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */

  return <AllRoutes />;
};

export default App;
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import Accordion from '../../components/general/accordion';
import muslimAroundImg from '../../images/landings/qurban/muslim-arounds.jpg';
import { qurbanFaqs } from './faqs';
import { selectProducts } from '../../store/products';
import QurbanTabs from './components/qurbanTabs';
import QurbanSlider from './components/qurban-slider';
import sheepBundleImg from '../../images/landings/qurban/sheep-bundle.png';
// import cowBundleImg from '../../images/landings/qurban/cow-bundle.png';
import qurbanSlider1 from '../../images/landings/qurban/qurban-slider1.jpg';
import qurbanSlider2 from '../../images/landings/qurban/qurban-slider2.jpg';
import qurbanSlider3 from '../../images/landings/qurban/qurban-slider3.jpg';
import qurbanSlider4 from '../../images/landings/qurban/qurban-slider4.jpg';
import qurbanSlider5 from '../../images/landings/qurban/qurban-slider5.jpg';
import qurbanSlider6 from '../../images/landings/qurban/qurban-slider6.jpg';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const images = [
  { src: qurbanSlider1, alt: 'Qurbani 2024: feed the needy this eid 2024 with your qurbani' },
  { src: qurbanSlider2, alt: 'Qurbani Eid 2024: Feed thousands of people, make your qurbani today' },
  { src: qurbanSlider3, alt: 'When is Qurbani Eid 2024: Do it in time with MATW' },
  { src: qurbanSlider4, alt: 'How much is Qurbani 2024, see our prices above.' },
  { src: qurbanSlider5, alt: '' },
  { src: qurbanSlider6, alt: '' },
];

const qurbanProductCreators = [
  'TK-QB-2024-EG28',
  'TK-QB-2024-FA-02',
  'QB-2024-FA-03',
  'QB-2024-S27',
  'QB-2024-S25',
  'QB-2024-S24',
  'QB-2024-S23',
  'QB-2024-S22',
  'QB-2024-S21',
  'QB-2024-S20',
  'QB-2024-S19',
  'QB-2024-S18',
  'QB-2024-S16',
  'QB-2024-S15',
  'QB-2024-S14',
  'QB-2024-S13',
  'QB-2024-S12',
  'QB-2024-S11',
  'QB-2024-S10',
  'QB-2024-S8',
  'QB-2024-S7',
  'QB-2024-S6',
  'QB-2024-S5',
  'QB-2024-S4',
  'QB-2024-S3',
  'QB-2024-S2',
];
const qurbanPalestineProductCreators = ['AO-QB-2024-FA-01', 'TK-QB-2024-FA-00', 'QB-2024-UQ-01', 'QB-2024-S17'];

const Qurbani2024LandingPage = ({ country, title = 'Qurbani' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const seo = SeoData[selectedcountryCode];
  const seoData = useMemo(() => {
    if (!country) {
      return {
        title: seo[`${title.toLowerCase()}2024`]['title'],
        description: seo[`${title.toLowerCase()}2024`]['description'],
      };
    }
    return {
      title: seo['qurbani-country']['title'].replaceAll('{country}', country),
      description: seo['qurbani-country']['description'].replaceAll('{country}', country),
    };
  }, [country, seo, title]);
  const [qurbanProducts, setQurbanProducts] = useState([]);
  const [qurbanEgyptProducts, setQurbanEgyptProducts] = useState([]);
  const [qurbanPalestineProducts, setQurbanPalestineProducts] = useState([]);
  const [sheepGoat, setSheepGoat] = useState({
    title: `${title} sheep/goat`,
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      title: `Ultimate ${title}`,
      type: 'goat',
    },
  });
  const [cowShare, setCowShare] = useState({
    title: 'cow share',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      title: `Ultimate ${title}`,
      type: 'goat',
    },
  });
  const [cow, setCow] = useState({
    title: 'cow',
    description: '',
    products: [],
  });
  const [foodAid, setFoodAid] = useState({
    title: 'Food Aid',
    description: '',
    products: [],
  });
  const [zakat, setZakat] = useState({
    title: 'Zakat',
    description: '',
    products: [],
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSheepGoatProducts = [];
    let tempSheepGoatBundleProduct = {};
    let tempCowShareProducts = [];
    // let tempCowShareBundleProduct = {};
    let tempCowProducts = [];
    let tempFoodAidProducts = [];
    let tempZakatProducts = [];
    let tempCheckBoxProducts = [];
    let tempQurbanProducts = [];
    let tempQurbanEgyptProducts = [];
    let tempQurbanPalestineProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // Checkbox products
          if (qurbanProductCreators.includes(inner_item_product.creator)) {
            tempQurbanProducts.push(inner_item_product);
          }
          if (qurbanPalestineProductCreators.includes(inner_item_product.creator)) {
            tempQurbanPalestineProducts.push(inner_item_product);
          }
          if (inner_item_product.creator === 'TK-QB-2024-FA-00') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine 2kg Meat',
            });
            tempCheckBoxProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-FA-02') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine 10kg of Rice',
            });
            tempCheckBoxProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-EG28') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Qurban Eid Gift',
            });
            tempCheckBoxProducts[2] = inner_item_product;
          }

          // SheepGoat
          if (inner_item_product.creator === 'QB-2024-S17') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine',
            });
            tempSheepGoatProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S18') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Egypt',
              name: 'Palestinian Refugees',
            });
            tempSheepGoatProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S19') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Yemen',
            });
            tempSheepGoatProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S20') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
            });
            tempSheepGoatProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S24') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Syria',
              name: 'Syrian Refugees',
            });
            tempSheepGoatProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S23') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Afghanistan',
            });
            tempSheepGoatProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S25') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Jordan',
            });
            tempSheepGoatProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S21') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
            });
            tempSheepGoatProducts[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S27') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempSheepGoatProducts[8] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S22') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempSheepGoatProducts[9] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-UQ-01') {
            tempSheepGoatBundleProduct = inner_item_product;
          }

          // Cow share
          // if (inner_item_product.creator === 'QB-2024-S9') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: 'Palestine',
          //   });
          //   tempCowShareProducts[0] = inner_item_product;
          // }
          if (inner_item_product.creator === 'QB-2024-S11') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'India',
            });
            tempCowShareProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S10') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Egypt',
              name: 'Palestinian Refugees',
            });
            tempCowShareProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S12') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
            });
            tempCowShareProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S16') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempCowShareProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S14') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
            });
            tempCowShareProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S15') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Turkey',
            });
            tempCowShareProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S13') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempCowShareProducts[6] = inner_item_product;
          }
          // if (inner_item_product.creator === 'QB-2024-UQ-02') {
          //   tempCowShareBundleProduct = inner_item_product;
          // }

          // Cow
          // if (inner_item_product.creator === 'QB-2024-S1') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: 'Palestine',
          //   });
          //   tempCowProducts[0] = inner_item_product;
          // }
          if (inner_item_product.creator === 'QB-2024-S3') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'India',
            });
            tempCowProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S2') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Egypt',
              name: 'Palestinian Refugees',
            });
            tempCowProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S4') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
            });
            tempCowProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S8') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempCowProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S6') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
            });
            tempCowProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S7') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Turkey',
            });
            tempCowProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S5') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempCowProducts[6] = inner_item_product;
          }

          // Qurban Egypt
          if (inner_item_product.creator === 'QB-2024-S2') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Qurban Cow (egypt)',
            });
            tempQurbanEgyptProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S10') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Qurban Cow share (egypt)',
            });
            tempQurbanEgyptProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S18') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Qurban sheep or goat (egypt)',
            });
            tempQurbanEgyptProducts[2] = inner_item_product;
          }
          // Food Aid
          if (inner_item_product.creator === 'QB-2024-FA-03') {
            tempFoodAidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-RBG-2023-01-052') {
            tempFoodAidProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            tempFoodAidProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            tempFoodAidProducts[3] = inner_item_product;
          }

          // Zakat
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Give Zakat Al Mal',
            });
            tempZakatProducts[1] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              staticLink: '/zakat-calculator',
              name: 'Calculate your Zakat',
              creator: 'RM24-ZM-013-',
            });
            tempZakatProducts[0] = inner_item_product;
          }

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempSheepGoatProducts = tempSheepGoatProducts.filter(n => n);
    tempCowShareProducts = tempCowShareProducts.filter(n => n);
    tempCowProducts = tempCowProducts.filter(n => n);
    tempFoodAidProducts = tempFoodAidProducts.filter(n => n);
    tempCheckBoxProducts = tempCheckBoxProducts.filter(n => n);
    tempZakatProducts = tempZakatProducts.filter(n => n);

    setSheepGoat({
      ...sheepGoat,
      products: tempSheepGoatProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...sheepGoat.bundleProduct, product: tempSheepGoatBundleProduct, type: 'goat' },
    });
    setCowShare({
      ...cowShare,
      products: tempCowShareProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...cowShare.bundleProduct, product: tempSheepGoatBundleProduct, type: 'goat' },
      // bundleProduct: { ...cowShare.bundleProduct, product: tempCowShareBundleProduct, type: 'cow', }
    });
    setCow({
      ...cow,
      products: tempCowProducts,
    });
    setFoodAid({
      ...foodAid,
      products: tempFoodAidProducts,
    });
    setZakat({
      ...zakat,
      products: tempZakatProducts,
    });
    tempQurbanProducts.sort((a, b) => a.usd - b.usd);
    tempQurbanPalestineProducts.sort((a, b) => a.usd - b.usd);
    tempQurbanEgyptProducts.sort((a, b) => a.usd - b.usd);
    setQurbanProducts(tempQurbanProducts);
    setQurbanEgyptProducts(tempQurbanEgyptProducts);
    setQurbanPalestineProducts(tempQurbanPalestineProducts);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */

  return (
    <NewHelmetLayout className="!bg-white">
      {seo && (
        <Helmet>
          <title>{seoData.title}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={seoData.description} />
        </Helmet>
      )}

      {/* <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={true}
          defaultProduct={{
            creator: "MKD-MN-001",
            label: "Where Most Needed",
            value: 255
          }}
        />
      </section> */}
      {/* /* --------------------------------- Widget --------------------------------- */}
      {/* <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={true}
            defaultProduct={{
              creator: "MKD-MN-001",
              label: "Where Most Needed",
              value: 255
            }}
          />
        </div>
      </section> */}
      {/* /* -------------------------------------------------------------------------- */}

      {/* ------------------------------- Products Section ---------------------------- */}
      <QurbanTabs
        hideWidget
        isQurbaniPage={title === 'Qurbani'}
        headerText={
          country
            ? `Qurbani in ${country} 2024 - Perform your qurbani this year with MATW Project. We select, fresh, healthy and high quality animals to ensure a proper Qurbani with the greatest impact.`
            : `${title} 2024 - Perform the deed that feeds with MATW ${title} for Eid al adha 2024`
        }
        // footerText={country ? `Don't wait, do your Qurbani in ${country} with MATW Project, today.` : `Don’t wait. Donate your ${title} Today.`}
        footerText={''}
        title={title}
        country={country}
        sheepGoatProducts={sheepGoat}
        cowShareProducts={cowShare}
        cowProducts={cow}
        foodAidProducts={foodAid}
        zakatProducts={zakat}
        qurbanEgyptProducts={qurbanEgyptProducts}
        qurbanProducts={qurbanProducts}
        qurbanPalestineProducts={qurbanPalestineProducts}
      />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- helped us ------------------------------- */}
      <div className="md:container md:mx-auto px-4">
        <div className="flex flex-col py-8 md:py-16">
          <p className="text-[#253B7E] md:text-center leading-[30px] md:leading-[50px] text-[25px] md:text-[40px] mb-4 md:mb-6 font-brandingBold">
            In 2023, you helped us reach more than 6 million people with your {title}. It’s an Amanah we at MATW take very seriously.
          </p>
          <p className="md:text-lg text-[#78716C] font-['Montserrat'] text-left sm:pr-12 pr-0">
            Our MATW teams work hard to deliver your {title} donations within the most impoverished communities around the world. Last year, we
            delivered within the most remote areas and conflict zones including Palestine, Yemen and Myanmar.
            <br />
            <br />
            From procuring livestock and tending to the animals, right through to delivery, we ensure that every step of the {title} process is
            meticulously adhered to with care and in accordance with Islamic guidelines and principles.
          </p>
          <h2 className="text-[#253B7E] md:text-center leading-[30px] md:leading-[50px] text-[25px] md:text-[40px] my-4 md:my-6 font-brandingBold">
            <strong>
              Qurbani Eid 2024: This Eid Al Adha, Share The Joy of Eid With Those Who Need It Most By Donating An Online Qurbani
            </strong>
          </h2>
          <p className="md:text-lg text-[#78716C] font-['Montserrat'] text-left sm:pr-12 pr-0">
            Right now, the Ummah is facing unprecedented challenges and suffering. Poverty, disease and widespread food shortages including a looming
            famine in places like Palestine and Yemen have left many families on the verge of starvation.
          </p>
          <h3 className="text-[#253B7E] md:text-center leading-[25px] md:leading-[40px] text-[20px] md:text-[35px] my-4 md:my-6 font-brandingBold">
            <strong>
              Online Qurbani 2024 - The Easy Way to Make A Difference
            </strong>
          </h3>
          <p className="md:text-lg text-[#78716C] font-['Montserrat'] text-left sm:pr-12 pr-0">
            Show compassion this Dhul Hijjah and perform a Deed that Feeds - an expression of faith, generosity, and kindness that feeds your Iman while helping a needy family celebrate a Qurbani Eid just as we do.
            <br />
            <br />
            This Eid, offer your Qurbani donation to places like Palestine, Yemen, Africa and India and reap the rewards of this blessed act. The Prophet (PBUH) said:
            <br />
            <br />
            ‘There is nothing dearer to Allah during the days of Qurbani than the sacrificing of animals. The sacrificed animal shall come on the Day of Qiyamah with its horns, hair, and hooves (to be weighed on a person's good deeds). The sacrifice is accepted by Allah before the blood reaches the ground. Therefore sacrifice with an open and happy heart.’ (Tirmidhi)
          </p>
        </div>

        {/* --------------------------- Video Section ----------------------------------- */}
        <div className="md:container mx-auto md:pb-8">
          <div className="relative overflow-hidden flex flex-wrap">
            <div className="hidden sm:block absolute w-full h-full" />
            <YoutubeVideoHero
              src={`https://www.youtube.com/embed/IKvFUTs8Etc?controls=0&autoplay=1&loop=1&mute=1&playlist=IKvFUTs8Etc`}
              className={'md:!h-[800px] lg:!h-[1000px]'}
            />
          </div>
        </div>
        {/* /* -------------------------------------------------------------------------- */}

        <div className="flex flex-wrap sm:flex-nowrap mt-8 justify-center gap-x-0 sm:gap-x-8 gap-y-8">
          <div className={`basis-full md:basis-1/2 sm:bg-[url('../src/images/landings/qurban/muslim-arounds.jpg')] bg-center bg-cover rounded-md`}>
            <img className="sm:hidden" src={muslimAroundImg} alt={`${title} for 2024, feeding people through ${title} for eid 2024`} />
          </div>
          <div className="basis-full md:basis-1/2 flex flex-col justify-center">
            <div className="text-[25px] md:text-[40px] text-[#253A7E] font-bold leading-[30px] md:leading-[45px]">
              Muslims Around The World (MATW) for YOUR {title}
            </div>
            <p className="md:text-lg text-[#F60362] font-brandingMedium mt-3 sm:mt-6 text-left">
              Our team works hard to fulfill your obligation and bring joy to as many families as possible during Eid Al Adha.
            </p>
            <p className="md:text-lg text-[#78716C] font-brandingMedium text-left sm:pr-12 pr-0">
              We ensure that the most vulnerable families are prioritised to receive the best quality {title} meat, delivered fresh during Eid. This
              includes orphans, widows and the elderly.
              <br />
              <br />
              All of Muslim Around The World’s {title} orders received from donors are processed and done fresh on Eid.
              <br />
              <br />
              Our teams work tirelessly in preparation for Eid so that from the 10th to 13th days of Dhul Hijjah, not a single moment is spared.{' '}
              {title} is processed and delivered fresh to vulnerable families with each {title} pack containing 3-5kg of meat and feeding a family of
              5-8 people.
            </p>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12">
        <p className="text-2xl md:text-[40px] text-[#253b7e] font-brandingBold mb-5 md:mb-8 md:text-center">How does it work?</p>
        <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-brandingMedium- font-['Montserrat'] space-y-4">
          <p>
            Our process is simple. When the time comes to give your {title} donation, simply donate and our MATW team takes care of the rest. Start by
            selecting where you’d like to send your {title} and the quantity you’d like to donate. The livestock is purchased and checked ahead of
            time with our team ensuring the animals meet the checking requirements. Your {title} is then processed during Eid and distributed to the
            poorest families!
            <br />
            <br />
            Reap the rewards of the 10 best days of the year - the blessed first days of Dhul Hijjah! Give your {title} today.
          </p>
        </div>
      </div>

      {/* /* --------------------------------- Images --------------------------------- */}
      <QurbanSlider images={images} />
      {/* /* -------------------------------------------------------------------------- */}

      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h2 className="text-[#253B7E] text-left  sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] font-brandingBold">
          {title} Eid 2024: <span className="text-[#F60362]"> FAQ’s</span>
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{qurbanFaqs[0].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{qurbanFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{qurbanFaqs[1].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{qurbanFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{qurbanFaqs[2].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{qurbanFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {qurbanFaqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion
                    key={`keyallfaq${index}`}
                    shadow
                    title={item.question}
                    index={index}
                    level={1}
                    noBorder={true}
                    className="!text-[#253B7E] text-lg font-brandingBold"
                  >
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};
export default Qurbani2024LandingPage;
export const palestineFaqs = [
  {
    id: 0,
    question: 'What is Eid Al Fitr 2024?',
    answer:
      'Eid Al Fitr Is a celebration to commemorate the end of Ramadan each year. Eid Al Adha occurs on the 10th day of Dhul Hijjah, which commemorates the last month of the Islamic calendar year.',
  },
  {
    id: 1,
    question: 'What do Muslims do on Eid Al Fitr?',
    answer: (
      <span>
        Both Eid’s are a time to celebrate with loved ones. There are many things Muslims do to commemorate Eid including:
        <ul class="list-disc mt-2 p-4">
          <li>Eid prayer</li>
          <li>Wearing new or your best clothes (A Sunnah of the Prophet PBUH!) </li>
          <li>Gather with family and friends.</li>
          <li>Share gifts and glad tidings</li>
          <li>Engage in acts of Ibadah and make dua.</li>
        </ul>
      </span>
    ),
  },
  {
    id: 2,
    question: 'Do MATW implement Eid projects?',
    answer: (
      <div>
        <span>
          Yes! Our incredible team works tirelessly during Eid to ensure the joy is spread among those in need, so that families can celebrate just as
          we do.
        </span>
        <p className="my-2">We deliver Eid meals so families in need can engage in festivities. We also deliver Eid gifts for orphaned children.</p>
        <span>During Eid Al Adha, we deliver your Qurban and serve in impoverished communities, the same as Eid Al Fitr.</span>
      </div>
    ),
  },
];

// Common Images
import monitoringImg from '../../../../images/landings/palestine-refugee/monitoring.png';
import sponsorshipImg from '../../../../images/landings/palestine-refugee/sponsorship.png';
import identificationImg from '../../../../images/landings/palestine-refugee/identification.png';
import assessmentImg from '../../../../images/landings/palestine-refugee/assessment.png';
import banner from '../../../../images/landings/palestine-refugee/Egypt-Resettlement-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../../../images/landings/palestine-refugee/Egypt-Resettlement-Hero-Banner-Mobile.jpg';
import matwImpactPhoto from '../../../../images/landings/palestine/matw-impact.png';
import impactSliderRightIcon from '../../../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../../../images/landings/palestine/slider-icon-left.png';
import imageJHCO from '../../../../images/landings/palestine/image-jhco.png';
import imageAlHilal from '../../../../images/landings/palestine/image-alhilal.png';
import imageEyc from '../../../../images/landings/palestine/image-eyc.png';
import sponsorFamilyPhoto from '../../../../images/landings/palestine/multi-prod-palestine.png';

// Carousel Images
import Palestinepreramadan1 from '../../../../images/landings/palestine-refugee/palestine-refugee-slider-1.jpg';
import Palestinepreramadan2 from '../../../../images/landings/palestine-refugee/palestine-refugee-slider-2.jpg';
import Palestinepreramadan3 from '../../../../images/landings/palestine-refugee/palestine-refugee-slider-3.jpg';
import Palestinepreramadan4 from '../../../../images/landings/palestine-refugee/palestine-refugee-slider-4.jpg';
import Palestinepreramadan5 from '../../../../images/landings/palestine-refugee/palestine-refugee-slider-5.jpg';
import Palestinepreramadan6 from '../../../../images/landings/palestine-refugee/palestine-refugee-slider-6.jpg';
import Palestinepreramadan7 from '../../../../images/landings/palestine-refugee/palestine-refugee-slider-7.jpg';
import Palestinepreramadan8 from '../../../../images/landings/palestine-refugee/palestine-refugee-slider-8.jpg';

export const commonImages = {
    monitoringImg:{
        src:monitoringImg,
        alt:'monitoringImg',
    },
    sponsorshipImg:{
        src:sponsorshipImg,
        alt:'sponsorshipImg',
    },
    identificationImg:{
        src:identificationImg,
        alt:'identificationImg',
    },
    assessmentImg:{
        src:assessmentImg,
        alt:'assessmentImg',
    },
    banner:{
        src:banner,
        alt:'Donate to Palestine',
    },
    bannerMobile:{
        src:bannerMobile,
        alt:'Donations to Palestine',
    },
    matwImpactPhoto:{
        src:matwImpactPhoto,
        alt:'Donate to Palestine to make your MATW impact',
    },
    impactSliderRightIcon:{
        src:impactSliderRightIcon,
        alt:'impactSliderRightIcon',
    },
    impactSliderLeftIcon:{
        src:impactSliderLeftIcon,
        alt:'impactSliderLeftIcon',
    },
    imageJHCO:{
        src:imageJHCO,
        alt:'jhco logo',
    },
    imageAlHilal:{
        src:imageAlHilal,
        alt:'alhilal logo',
    },
    imageEyc:{
        src:imageEyc,
        alt:'eyc logo',
    },
    sponsorFamilyPhoto:{
        src:sponsorFamilyPhoto,
        alt:'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan',
    },
}

export const carouselImages = [
    { src: Palestinepreramadan1, alt: 'Donate to palestine, they need you' },
    { src: Palestinepreramadan2, alt: 'Palestine Charity to help the palestinian people' },
    { src: Palestinepreramadan3, alt: 'Palestine donation to make an impact' },
    { src: Palestinepreramadan4, alt: 'Palestine appeal, help them today' },
    { src: Palestinepreramadan5, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan6, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan7, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan8, alt: 'donate for palestine emergency appeal' },
  ];
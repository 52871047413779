import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = ({ items, TriangleRightArrowIcon }) => {
    const [openItems, setOpenItems] = useState({});

    const toggleItem = (id) => {
        setOpenItems(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return (
        <ul className="flex flex-col">
            {items.map(item => (
                <li
                    key={item.id}
                    style={{ backgroundColor: '#81c3ed' }}
                >
                    <div
                        className={`
                            flex items-center justify-between p-4 cursor-pointer
                            hover:bg-white/10 transition-all duration-200
                            active:bg-white/20
                        `}
                        onClick={() => item.children?.length > 0 && toggleItem(item.id)}
                    >
                        <Link
                            to={item.url}
                            className="text-white text-[14px] block flex-grow"
                        >
                            {item.title}
                        </Link>
                        {item.children?.length > 0 && (
                            <TriangleRightArrowIcon
                                className={`
                                    w-3 h-3 ml-2 transition-transform duration-300
                                    ${openItems[item.id] ? 'rotate-90' : ''}
                                    text-white/70
                                `}
                            />
                        )}
                    </div>
                    {item.children?.length > 0 && openItems[item.id] && (
                        <div className="bg-[#65bff7] py-2">
                            {item.children.filter(child => child.status).map(child => (
                                <div key={child.id} className="py-2 px-6">
                                    <Link
                                        to={child.url}
                                        className="text-white text-[14px] hover:translate-x-0.5 transition-transform duration-200 block"
                                    >
                                        {child.title}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default MobileMenu;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import CountriesCrisisTabContent from './countries-crisis-tab-content';

const CountriesCrisisTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { currentProducts, lang = 'en', arabic = false } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  return (
    <section className="bg-[#fff]" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className={`w-full mx-auto px-4 md:px-0 py-4 md:py-12 bg-[#253B7E]`}>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 mt-5 md:mt-10">
          <div className="flex flex-col w-full justify-center">
            <CountriesCrisisTabContent
              arabic={arabic}
              lang={lang}
              products={currentProducts}
              currency={selectedCurrency || 'AUD'}
              showDots={false}
              showArrows={false}
              showMultiProductsOnRow={false}
              viewAppealsLink={null}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default CountriesCrisisTabs;

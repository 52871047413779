import React, { useEffect, useState } from 'react';
import CrisisWidget from './components/crisisWidget';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import banner from '../../images/landings/palestine/impact.png';
import bannerMobile from '../../images/landings/palestine/impact-mobile.png';
import interventionPalestineImg from '../../images/landings/palestine/intervention-palestine.png';
import interventionPalestineMobileImg from '../../images/landings/palestine/intervention-palestine-mobile.png';
import implementStrategyImg from '../../images/landings/palestine/implement-strategy.png';
import supportImg1 from '../../images/landings/palestine/support-1.png';
import supportImg2 from '../../images/landings/palestine/support-2.png';
import supportImg3 from '../../images/landings/palestine/support-3.png';
import supportImg4 from '../../images/landings/palestine/support-4.png';
import supportImg5 from '../../images/landings/palestine/support-5.png';
import supportImg6 from '../../images/landings/palestine/support-6.png';
import supportImg7 from '../../images/landings/palestine/support-7.png';
import supportImg8 from '../../images/landings/palestine/support-8.png';
import supportImg9 from '../../images/landings/palestine/support-9.png';
import supportImg10 from '../../images/landings/palestine/support-10.png';
import supportImg11 from '../../images/landings/palestine/support-11.png';
import MatwResponseActivity from './components/matw-response-activity';
import ImpactReport from './components/impact-report';
import CustomButton from '../../components/general/button';
import VideosSection from './components/videos-section';
import AnnualReports from './components/annual-reports';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectProducts } from '../../store/products';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

export const videos = [
  {
    link: 'https://www.youtube.com/embed/0dhqNmL1uS4?controls=0&loop=1&playlist=0dhqNmL1uS4',
  },
  {
    link: 'https://www.youtube.com/embed/j0v11vDhzV8?controls=0&loop=1&playlist=j0v11vDhzV8',
  },
  {
    link: 'https://www.youtube.com/embed/FRp5i7MhV9w?controls=0&loop=1&playlist=FRp5i7MhV9w',
  },
];

const PalestineImpact = () => {
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const selectedCurrency = useSelector(selectSelectedCurrency);
  const [currentProduct, setCurrentProduct] = useState();
  const seo = SeoData[selectedcountryCode];
  const [addProduct] = useHandleAddProduct();
  const products = useSelector(state => selectProducts(state));

  const getAllProducts = () => {
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            setCurrentProduct(inner_item_product);
          }
        });
      });
    });
  };

  useEffect(() => {
    products && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const handleAddProduct = () => {
    addProduct({ product: currentProduct, currency: selectedCurrency });
  };

  return (
    <NewHelmetLayout className={'bg-white'}>
      {seo && (
        <Helmet>
          <title>{seo['palestine-impact']['title']}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['palestine-impact']['description']}`}/>
        </Helmet>
      )}
      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: 'Where Most Needed',
            value: 255,
          }}
        />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: 'Where Most Needed',
              value: 255,
            }}
          />
        </div>
      </section>
      <section className="flex justify-center">
        <img src={banner} alt="Donate to Palestine" className="max-[500px]:hidden w-full max-h-[1200px] object-cover object-bottom" />
        <img src={bannerMobile} alt="Donations to Palestine" className="min-[501px]:hidden w-full" />
      </section>
      <ImpactReport />

      <CustomButton
        disabled={!currentProduct}
        onClick={handleAddProduct}
        title="DONATE NOW"
        className="mx-auto !py-3 !px-10 !text-[28px] font-brandingBold !h-fit my-10"
      />

      <MatwResponseActivity />
      <section className="mx-auto max-w-[1120px] px-5 pb-[30px] md:pb-[50px] bg-white">
        <h2 className="mb-5 md:mb-[30px] text-DarkerBlue font-brandingBold font-black text-[35px] leading-[42px] md:text-[60px] md:leading-[72px] capitalize text-center">
          On the ground situation
        </h2>
        <p className="text-center font-brandingMedium font-semibold text-base leading-[19.2px] md:text-[35px] md:leading-[42px] text-DarkerBlue">
          Millions of displaced Palestinians have been subjected to great hardship that has completely devastated the population.
        </p>
        <br />
        <br />
        <p className="text-center font-BrandingMediumItalic font-semibold text-[24px] md:text-[45px] leading-[120%] text-pink">
          The death toll in Palestine has surpassed 38,345, including 14,000+ children and 9,300+ women.
        </p>
        <br />
        <br />
        <p className="text-center font-brandingMedium font-semibold text-base leading-[19.2px] md:text-[35px] md:leading-[42px] text-DarkerBlue">
          More than 2 million people in Palestine have been displaced and have been forced to evacuate their homes.
        </p>
        <br />
        <br />
        <p className="text-center font-brandingMedium font-semibold text-base leading-[19.2px] md:text-[35px] md:leading-[42px] text-DarkerBlue">
          It’s been a year of raging conflict has left many families without the basic necessities they need to survive, and each day, the situation
          gets worse.
        </p>
        <br />
        <br />
        <p className="text-center font-brandingMedium font-semibold text-base leading-[19.2px] md:text-[35px] md:leading-[42px] text-DarkerBlue">
          With the escalation in Rafah, hundreds of thousands have been displaced again and with no place to find refuge.
        </p>
        <br />
        <br />
        <p className="text-center font-BrandingMediumItalic font-semibold text-[24px] md:text-[45px] leading-[120%] text-pink">
          Humanitarian needs are at an all-time high.
        </p>
      </section>
      <div className="bg-white">
        <img src={interventionPalestineImg} alt="Donate to Palestine" className="max-[500px]:hidden w-full  object-cover object-bottom" />
        <img src={interventionPalestineMobileImg} alt="Donations to Palestine" className="min-[501px]:hidden w-full" />
      </div>
      <section className="mx-auto max-w-[1320px] px-5 py-[20px] md:py-[80px] flex gap-x-[50px] flex-col-reverse lg:flex-row bg-white">
        <img
          src={implementStrategyImg}
          alt="Implement Strategy"
          className="mt-[30px] lg:mt-0 w-full max-h-[400px] object-cover rounded-xl lg:max-h-none lg:w-[506px]"
        />
        <div>
          <h2 className="mb-5 md:mb-[30px] text-DarkerBlue font-brandingBold font-black text-[35px] leading-[42px] md:text-[60px] md:leading-[72px] capitalize">
            Implementation Strategy
          </h2>
          <p className="font-brandingMedium font-semibold text-base leading-[19.2px] md:text-[35px] md:leading-[120%] text-DarkerBlue">
            We were able to deliver a quick response in October as we had a long-standing presence in Palestine. Now, it’s been over 10 months and we
            continue to provide emergency relief.
          </p>
          <br />
          <br />
          <p className="font-brandingMedium font-semibold text-base leading-[19.2px] md:text-[35px] md:leading-[120%] text-DarkerBlue">
            Our primary focus is reaching those most in need, including thousands of orphaned children.
          </p>
          <br />
          <br />
          <p className="font-BrandingMediumItalic font-semibold text-[24px] md:text-[35px] leading-[120%] text-pink">
            Our current response includes the distribution of food packs, hot meals and clean water for those displaced.
          </p>
        </div>
      </section>
      <div className="bg-white">
        <VideosSection videos={videos} />
      </div>
      <section className="mx-auto max-w-[1120px] px-5 py-[30px] md:py-[50px] flex flex-col gap-y-[30px] md:gap-y-[50px] bg-white">
        <h2 className="text-[35px] leading-[120%] md:text-[60px] font-brandingBold font-black text-center text-DarkerBlue">
          How is MATW <span className="text-pink">Providing Support On The Ground</span> In Palestine?
        </h2>
        <SupportCard title="Delivering hot meals to those in need" img={supportImg1} imageFirst>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            MATW has been on the ground in Palestine from the very start, providing essential relief to those in need. With unwavering dedication, our
            team ensures that hot meals reach those facing hunger and hardship. Through our efficient network, we deliver sustenance and hope to
            several in need.
          </p>
        </SupportCard>
        <SupportCard title="Providing emergency support to the orphans" img={supportImg2}>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            More than 17,000 children have been orphaned since October 2023 in Palestine. We have been quietly but consistently delivering essential
            support to these children in need. Our emergency support includes emotional, educational, financial, and psychological support for the
            orphan’s overall well-being, ensuring they receive the necessary assistance during challenging times.
          </p>
        </SupportCard>
        <SupportCard title="Distributing food packs" img={supportImg3} imageFirst>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            Reports have come in of children picking up flour off the roads and saving it to make bread. To cope with the challenge of limited and
            depleting resources, we are providing essential food packs in Palestine. These food packs contain essentials for making nutritious meals,
            including 2 rice bags, 2 pasta bags, 6 cans of vegetables, 1 can of fruit, 4 cans of meat and fish, 1 cooking oil, 1 lentil pack and 1
            flour bag
          </p>
        </SupportCard>
        <SupportCard title="Providing hygiene kits" img={supportImg4}>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            The prices of everyday hygiene products are on the rise amidst the conflict. Our team is providing hygiene essentials to people in need.
            These kits contain soap, toothpaste, toothbrushes, adult and baby diapers, and other necessary things.
          </p>
        </SupportCard>
        <SupportCard title="Providing urgent medical kits to the injured" img={supportImg5} imageFirst>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            There are more than 85,452 wounded people in Palestine but the medical supplies are limited. We are continuously trying to provide medical
            relief to those in need, and also sending medical kits that contain:
            <ul className="list-disc pl-5">
              <li>Sterile gauze </li>
              <li>Large wound dressing </li>
              <li>Medical gloves for personal hygiene </li>
              <li>Wound plaster </li>
              <li>Paracetamol </li>
              <li>Manual pressure gauge </li>
              <li>Bruise reliever </li>
              <li>Wide medical tape to change surgical areas </li>
              <li>Bandage </li>
              <li>Lodine (medical use) </li>
            </ul>
            These help in stabilizing the patients and preventing further complications.
          </p>
        </SupportCard>
        <SupportCard title="Resettling Palestinian families in Egypt" img={supportImg6}>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            We are providing ongoing support to Palestinian families residing in Egypt by offering monthly allowances to cover their essential needs,
            including food, medical expenses, and education costs whenever feasible.
          </p>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            Our efforts extend beyond borders as we undertake the vital task of resettling Palestinian families in Egypt. Through this initiative, we
            aim to provide a new beginning for families facing displacement and uncertainty. By offering support and resources, we want to ensure that
            these families can rebuild their lives with dignity and stability.
          </p>
          <br />
          <h4 className="text-pink font-brandingBold text-[25px]">Starter Pack Contents</h4>
          <h5 className="mt-2 text-base lg:text-lg font-brandingBold font-bold text-DarkerBlue">Essential household appliances</h5>
          <p className="text-[#78716C] text-[18px] font-medium font-brandingMedium">
            Washing Machine, Cooker, Fridge, Kettle, Water Heater, Water Filter, Stand Fan, Heater
          </p>
          <h5 className="mt-2 text-base lg:text-lg font-brandingBold font-bold text-DarkerBlue">Household essentials</h5>
          <p className="text-[#78716C] text-[18px] font-medium font-brandingMedium">
            Clothes Hangers, Cleaning Kit, Kitchen Utensils, Carpets, Prayer Mat Mattresses, Pillows, Bed Sheets, Towels
          </p>
          <h5 className="mt-2 text-base lg:text-lg font-brandingBold font-bold text-DarkerBlue">Hygiene Kit</h5>
          <p className="text-[#78716C] text-[18px] font-medium font-brandingMedium">
            Toothpaste, Toothbrush, Hand Soap, Shampoo, Sanitary Pads, Razors, Toilet Tissue, Laundry Detergent, Hairbrush, Deodorant
          </p>

          <h5 className="mt-2 text-base lg:text-lg font-brandingBold font-bold text-DarkerBlue">Food Box</h5>
          <p className="text-[#78716C] text-[18px] font-medium font-brandingMedium">
            2kg Rice, 4 x Pasta(400g), 1kg Sugar, Tea, 2 x Lentil(500g), 2 x Beans(400g), 1 kg date 700ml Oil and 500g Ghee
          </p>
        </SupportCard>
        <SupportCard title="Providing clean drinking water" img={supportImg7} imageFirst>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            We are dedicated to ensuring access to safe and clean drinking water for families facing water scarcity in Palestine. We aim to provide a
            week’s supply of drinking water to families in need, addressing a critical need for hydration and promoting overall health and well-being.
            Specially designed water packages, sufficient for a week, are provided to each family to meet their hydration requirements. We are
            providing immediate relief by addressing the urgent need for clean drinking water in households, ensuring families have access to safe
            water for drinking and cooking.
          </p>
        </SupportCard>
        <SupportCard title="Impact of support and team members" img={supportImg8}>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            The impact of the humanitarian team on the ground is immeasurable. Their presence serves as a beacon of hope for the affected and their
            families. Despite the relentless challenges they face, the team works with unwavering dedication, often exceeding sixteen hours of work
            each day. Furthermore, their collaboration with other teams showcases their commitment to the relief work.
          </p>
        </SupportCard>
        <SupportCard title="Emergency relief facts" img={supportImg9} imageFirst>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            With a long-standing presence in Palestine and supplies on standby for an immediate response, our MATW team has been quick to deliver
            urgent essentials to those directly affected by the conflict in Palestine. Our direct response efforts are focused on those who are most
            vulnerable, including orphans, widows, the elderly and families.
          </p>
          <p className="text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            Our operations team is delivering emergency medical kits, urgent food packs, fuel, hot meals and more to those who have been displaced.
          </p>
          <p className="mt-3 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            Our medical response within hospitals proved to be a critical lifeline. Thanks to donors, our team was able to deliver stretchers, beds,
            mattresses, blankets, medical kits and supplies. We also were able to provide staff support and fuel to ensure medical make-shift camps
            and operational hospitals continue to run. Moreover, the team recognizes the importance of psychological and moral support to alleviate
            the emotional burden on the affected individuals, helping them cope with the trauma of the crisis.
          </p>
          <p className="mt-3 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            Our team has now partnered with the Egyptian Youth Council, the Egyptian Red Crescent and The Jordanian Hashemite Charity Organisation
            (JHCO) to ensure relief is reaching those in need in Palestine.
          </p>
          <p className="mt-3 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            We are providing urgent essentials and family relocation support to Palestinian families seeking refuge in Egypt. Through these projects,
            donors will be able to help us provide family sponsorship and settlement support to ensure a new start for families with all that they
            need.
          </p>
          <p className="mt-3 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            More recently, we are focused on helping those displaced from Rafah. These people have no shelter and security and are foraging for food.
            We are providing them with tents, food, medical care and clean drinking water. We have established a village camp in Khan Younis that
            currently houses 500 refugees from Rafah, of which 70% are orphans and 30% widows. This camp has a store, a kitchen, a mosque and a
            medical centre.
          </p>
        </SupportCard>
        <SupportCard title="Financial allocations" img={supportImg10}>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            The majority of these funds are directed towards addressing the immediate daily requirements of those affected. This includes providing
            essential items like food supplies and hot meals, emergency support to the orphans, urgent medical kits to the injured, distributing
            hygiene kits consisting of essential items for daily use and resettling Palestinian families in Egypt.
          </p>
        </SupportCard>
        <SupportCard title="Support with donations" img={supportImg11} imageFirst>
          <p className="mt-5 text-[#78716C] text-base lg:text-lg !leading-[120%] font-brandingMedium font-medium">
            The donations received are instrumental in providing critical support. They are utilized for procuring ingredients for meals, getting food
            packs ready for families, gathering hygiene essentials and facilitating operations of relief delivery. Your donations help in offering
            support to those displaced internally and to those moving to Egypt.
          </p>
        </SupportCard>
      </section>
      <div className="md:hidden">
        <AnnualReports />
      </div>
    </NewHelmetLayout>
  );
};

function SupportCard({ title, img, children, imageFirst = false }) {
  return (
    <div className="flex flex-col lg:flex-row gap-x-[63px] gap-y-[20px] lg:items-center ">
      <img src={img} alt="Support" className={`w-full lg:w-[500px] ${!imageFirst && 'lg:order-1'}`} />
      <div>
        <h3 className="text-DarkerBlue font-brandingBold font-bold leading-[120%] text-[25px] lg:text-[40px]">{title}</h3>
        {children}
      </div>
    </div>
  );
}

export default PalestineImpact;

import React, { useState } from 'react';
import AppealFundraiserProductBoxV2 from '../../MATWAppeal/components/appealFundraiserProductBoxV2';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import { useKeenSlider } from 'keen-slider/react';

const Appeals = ({ products, appealsRef }) => {
  const selectedCurrency = useSelector(selectSelectedCurrency);
  const [current, setCurrent] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrent(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      '(min-width: 320px)': {
        slides: { perView: 1.1, spacing: 10, origin: 'auto' },
        loop: true,
      },
      '(min-width: 640px)': {
        slides: { perView: 2.2, spacing: 10, origin: 'auto' },
        loop: true,
      },
    },
    slides: {
      origin: 'center',
    },
  });

  return (
    <section ref={appealsRef} className="bg-LighterBlue py-[30px] lg:py-[50px]">
      <div className="max-w-[1280px] w-full mx-auto px-5 lg:px-10">
        <h1 className="text-center text-[30px] lg:text-[40px] leading-[32px] lg:leading-[42px] font-bold text-DarkerBlue font-brandingBold mb-[10px] lg:mb-[30px]">
          MATW Appeals
        </h1>
        <div className="hidden lg:grid grid-cols-3 gap-x-5">
          {products?.map((product, index) => (
            <AppealFundraiserProductBoxV2
              key={index}
              campaign={product.campaign || 'palestine'}
              goal={product.goal}
              totalRaised={product.totalRaised}
              subTitle="Orphan Education"
              product={product}
              isHomePage={false}
              currency={selectedCurrency || 'AUD'}
            />
          ))}
        </div>
        <div ref={sliderRef} className="keen-slider lg:!hidden">
          {products?.map((product, index) => {
            return (
              <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center">
                <div className="w-full">
                  <AppealFundraiserProductBoxV2
                    key={index}
                    campaign={product.campaign || 'palestine'}
                    goal={product.goal}
                    totalRaised={product.totalRaised}
                    subTitle="Orphan Education"
                    product={product}
                    isHomePage={false}
                    currency={selectedCurrency || 'AUD'}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full  flex justify-center lg:hidden mt-3">
          {loaded && instanceRef.current && (
            <div className="dots flex pt-3 ">
              {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx);
                    }}
                    className={
                      'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                      (current === idx ? ' active bg-[#14A2DC]' : ' bg-gray-300')
                    }
                  ></button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Appeals;

const foodFaqs = [
    {
        id: 0,
        question: {
            en: 'What is food donation?',
            fr: `Qu'est-ce que le don alimentaire ?`,
            ar: 'ما هو التبرع بالطعام؟'
        },
        answer: {
            en: 'Food donation is the act of providing food to individuals or organisations in need. It involves contributing monetary funds to combat hunger and nourish the less fortunate.',
            fr: `Le don alimentaire est l'acte de fournir de la nourriture à des personnes ou à des organisations dans le besoin. Il s'agit de contribuer financièrement à la lutte contre la faim et à l'alimentation des plus démunis.`,
            ar: 'التبرع بالطعام هو تقديم الطعام للأفراد أو المنظمات المحتاجة. يتضمن ذلك تقديم التبرعات المالية لمكافحة الجوع وتغذية الأقل حظاً.'
        },
    },
    {
        id: 1,
        question: {
            en: 'Can I make monetary donations to food charity organisations?',
            fr: 'Puis-je faire des dons en argent à des organisations caritatives alimentaires ?',
            ar: 'هل يمكنني التبرع بالمال لمنظمات الخيرية التي تقدم المساعدات الغذائية؟'
        },
        answer: {
            en: '<div>Absolutely! <a class="text-[#00a3da]" href="/">MATW Project</a>, accept monetary donations. These funds are used to purchase bulk food items and cover operational expenses, allowing flexibility in acquiring the most needed and nutritious foods.</div>',
            fr: `<div>Tout à fait ! <a class="text-[#00a3da]" href="/">Le projet MATW</a> accepte les dons en argent. Ces fonds sont utilisés pour acheter des produits alimentaires en vrac et couvrir les dépenses opérationnelles, ce qui permet une certaine flexibilité dans l'acquisition des aliments les plus nécessaires et les plus nutritifs.</div>`,
            ar: '<div>بالتأكيد! <a class="text-[#00a3da]" href="/">يقبل مشروع MATW</a> التبرعات المالية. يتم استخدام هذه الأموال لشراء كميات كبيرة من المواد الغذائية وتغطية نفقات التشغيل، مما يتيح مرونة في اقتناء أكثر أنواع الطعام الضرورية والمغذية.</div>'
        },
    },
    {
        id: 2,
        question: {
            en: 'What is the importance of food donation in Islam?',
            fr: `Quelle est l'importance des dons alimentaires dans l'islam ?`,
            ar: 'ما أهمية التبرع بالطعام في الإسلام؟'
        },
        answer: {
            en: 'In Islam, feeding the hungry and assisting the needy is highly regarded. It fulfils the duty of caring for others and offers an opportunity to earn rewards and seek the pleasure of Allah.',
            fr: `Dans l'islam, nourrir les affamés et aider les nécessiteux est hautement apprécié et respecté. Cela répond au devoir de prendre soin des autres et offre la possibilité de gagner la récompense divine et de rechercher l'agrément d'Allah.`,
            ar: 'في الإسلام، يُعتبر إطعام الجائعين ومساعدة المحتاجين أمراً مرموقاً. إنه يفي بواجب الرعاية للآخرين ويتيح فرصة لكسب الأجر وطلب مرضاة الله.'
        },
    },
    {
        id: 3,
        question: {
            en: 'How can I donate food in accordance with Islamic principles?',
            fr: 'Comment puis-je faire un don de nourriture conformément aux principes islamiques ?',
            ar: 'كيف يمكنني التبرع بالطعام وفقاً للمبادئ الإسلامية؟'
        },
        answer: {
            en: 'To donate food according to Islamic principles, ensure it is halal and of good quality. Non-perishable items like canned goods, rice & others are commonly accepted. Donating fresh and healthy produce is also encouraged whenever possible.',
            fr: `Pour donner de la nourriture conformément aux principes islamiques, il faut s'assurer qu'elle est halal et de bonne qualité. Les produits non périssables tels que les conserves, le riz et autres sont généralement acceptés. Le don de produits frais et sains est également encouragé dans la mesure du possible.`,
            ar: 'للتبرع بالطعام وفقاً للمبادئ الإسلامية، تأكد من أنه حلال وذو جودة جيدة. يتم قبول العناصر غير القابلة للتلف مثل السلع المعلبة والأرز وغيرها بشكل شائع. كما يُشجع أيضاً التبرع بالمنتجات الطازجة والصحية كلما كان ذلك ممكناً.'
        },
    },
    {
        id: 4,
        question: {
            en: 'How can I donate food to the poor through the MATW Project?',
            fr: 'Comment puis-je donner de la nourriture aux pauvres dans le cadre du projet MATW ?',
            ar: 'كيف يمكنني التبرع بالطعام للفقراء من خلال مشروع MATW؟'
        },
        answer: {
            en: 'To donate food to the poor through MATW, visit our official website(matwproject.org) and navigate to the <a class="text-[#00a3da]" href="/food-and-water-aid/food-aid">food aid donation</a> section. Detailed instructions, including drop-off locations and collection drives, will be provided.',
            fr: `Pour faire un don de nourriture aux pauvres par l'intermédiaire de la MATW, visitez notre site  Web officiel(matwproject.org) et naviguez jusqu'à la section <a class="text-[#00a3da]" href="/food-and-water-aid/food-aid">Don d'aide alimentaire</a>. Des instructions détaillées, y compris les lieux de dépôt et les collectes, seront fournies.`,
            ar: 'للتبرع بالطعام للفقراء من خلال MATW، قم بزيارة موقعنا الرسمي (matwproject.org) وانتقل إلى قسم <a class="text-[#00a3da]" href="/food-and-water-aid/food-aid">التبرعات بالمساعدات الغذائية</a>. سيتم توفير تعليمات مفصلة، بما في ذلك مواقع التسليم وحملات جمع الطعام.'
        },
    },
    {
        id: 5,
        question: {
            en: 'Does MATW Project have a food donation program for schools?',
            fr: `Le projet MATW dispose-t-il d'un programme de dons alimentaires pour les écoles ?`,
            ar: 'هل لدى مشروع MATW برنامج للتبرع بالطعام للمدارس؟'
        },
        answer: {
            en: 'The availability of school-based food donation programs may vary. Please visit MATW Project\'s website or contact us directly to inquire about our initiatives related to school donations or support for school meal programs.',
            fr: `La disponibilité des programmes de dons alimentaires pour les écoles peut varier. Veuillez consulter le site Web du projet MATW ou nous contacter directement pour vous renseigner sur nos initiatives en matière de dons aux écoles ou de soutien aux programmes de repas scolaires.`,
            ar: 'قد تختلف توفر برامج التبرع بالطعام في المدارس. يرجى زيارة موقع مشروع MATW أو التواصل معنا مباشرة للاستفسار عن مبادراتنا المتعلقة بالتبرعات للمدارس أو دعم برامج وجبات الطعام المدرسية.'
        },
    },
];

const waterFaqs = [
    {
        id: 0,
        question: {
            en: 'What is water donation?',
            fr: `Qu'est-ce que le don d'eau ?`,
            ar: 'ما هو التبرع بالمياه؟'
        },
        answer: {
            en: 'Water donation refers to contributing resources, such as money, supplies, or infrastructure, with the specific purpose of providing access to clean and safe water to communities in need.',
            fr: `Le don d'eau consiste à apporter des ressources, telles que de l'argent, des fournitures ou des infrastructures, dans le but spécifique de fournir un accès à l'eau propre et potable aux communautés dans le besoin.`,
            ar: 'يشير التبرع بالمياه إلى تقديم الموارد مثل الأموال أو اللوازم أو البنية التحتية، بهدف توفير الوصول إلى مياه نظيفة وآمنة للمجتمعات المحتاجة.'
        },
    },
    {
        id: 1,
        question: {
            en: 'Why is water donation important?',
            fr: `Pourquoi le don d'eau est-il important ?`,
            ar: 'لماذا يعد التبرع بالمياه مهماً؟'
        },
        answer: {
            en: 'Water donation is important because it addresses the urgent need for clean water in communities that lack access to this essential resource. It helps prevent waterborne diseases, improves health and hygiene, and empowers communities to thrive.',
            fr: `Le don d'eau est important parce qu'il répond au besoin urgent d'eau potable des communautés qui n'ont pas accès à cette ressource essentielle. Il permet de prévenir les maladies d'origine hydrique, d'améliorer la santé et l'hygiène et de donner aux communautés les moyens de prospérer.`,
            ar: 'التبرع بالمياه مهم لأنه يلبي الحاجة الملحة للمياه النظيفة في المجتمعات التي تفتقر إلى هذه المورد الأساسي. يساعد على منع الأمراض المنقولة بالمياه، ويحسن الصحة والنظافة، ويمكن المجتمعات من التطور والازدهار.'
        },
    },
    {
        id: 2,
        question: {
            en: 'What does a water charity organisation do?',
            fr: `Que fait une organisation caritative pour l'eau ?`,
            ar: 'ماذا تفعل منظمة خيرية متخصصة في المياه؟'
        },
        answer: {
            en: 'A water charity organisation such as MATW Project is committed to providing access to clean and safe water solutions to communities in need. MATW Project collects donations for initiatives aimed at addressing water scarcity and improving access to clean water sources.',
            fr: `Une organisation caritative de l'eau telle que le projet MATW s'engage à fournir un accès à des solutions d'eau propre et potable aux communautés dans le besoin. Le projet MATW recueille des dons pour des initiatives visant à lutter contre la pénurie d'eau et à améliorer l'accès à des sources d'eau propre.`,
            ar: 'تتعهد منظمة خيرية متخصصة في المياه مثل مشروع MATW بتوفير حلول للوصول إلى مياه نظيفة وآمنة للمجتمعات المحتاجة. يقوم مشروع MATW بجمع التبرعات للمبادرات التي تهدف إلى معالجة ندرة المياه وتحسين الوصول إلى مصادر المياه النظيفة.'
        },
    },
    {
        id: 3,
        question: {
            en: 'What is the significance of water aid donations in Islam?',
            fr: `Quelle est l'importance des dons d'eau dans l'islam ?`,
            ar: 'ما هو أهمية التبرعات لمساعدة المياه في الإسلام؟'
        },
        answer: {
            en: 'Water aid donations hold great significance in Islam as they align with the teachings of compassion, empathy, and social responsibility. It promotes the concept of brotherhood in Islam by helping to provide clean water to communities in need, improving health and hygiene.',
            fr: `Les dons d'aide à l'eau revêtent une grande importance dans l'islam, car ils sont conformes aux enseignements de la compassion, de l'empathie et de la responsabilité sociale. Ils favorisent le concept de fraternité dans l'islam en contribuant à fournir de l'eau propre aux communautés dans le besoin, améliorant ainsi la santé et l'hygiène. `,
            ar: 'تحمل التبرعات لمساعدة المياه أهمية كبيرة في الإسلام حيث تتماشى مع تعاليم الرحمة والتعاطف والمسؤولية الاجتماعية. إنها تعزز مفهوم الأخوة في الإسلام من خلال مساعدة في توفير المياه النظيفة للمجتمعات المحتاجة، مما يحسن الصحة والنظافة.'
        },
    },
    {
        id: 4,
        question: {
            en: 'How does water scarcity affect developing countries?',
            fr: `Comment la pénurie d'eau affecte-t-elle les pays en développement ?`,
            ar: 'كيف تؤثر ندرة المياه على الدول النامية؟'
        },
        answer: {
            en: 'Water scarcity in developing countries has wide-ranging impacts. It hinders agricultural productivity, leading to food insecurity. Lack of clean water and sanitation facilities also contributes to the spread of diseases, affecting overall community health.',
            fr: `La pénurie d'eau dans les pays en développement a des conséquences très diverses. Elle entrave la productivité agricole, ce qui conduit à l'insécurité alimentaire. Le manque d'eau propre et d'installations sanitaires contribue également à la propagation des maladies, ce qui affecte la santé générale de la communauté. `,
            ar: 'تؤثر ندرة المياه في الدول النامية بتأثيرات واسعة النطاق. تعيق إنتاجية الزراعة مما يؤدي إلى عدم الأمن الغذائي. كما أن نقص المياه النظيفة والمرافق الصحية يسهم أيضًا في انتشار الأمراض، مما يؤثر على صحة المجتمع بشكل عام.'
        },
    },
    {
        id: 5,
        question: {
            en: 'What is the difference between a one-time donation and a recurring donation?',
            fr: `Quelle est la différence entre un don unique et un don récurrent ?`,
            ar: 'ما الفرق بين التبرع مرة واحدة والتبرع المتكرر؟'
        },
        answer: {
            en: 'A one-time donation is a single contribution, while a recurring donation involves regular contributions made at set intervals (monthly, quarterly, or annually). Recurring donations provide sustained support to charity projects and allow to plan for the long term.',
            fr: `Un don ponctuel est une contribution unique, tandis qu'un don récurrent implique des contributions régulières effectuées à intervalles fixes (mensuels, trimestriels ou annuels). Les dons récurrents apportent un soutien durable aux projets caritatifs et permettent de planifier à long terme.`,
            ar: 'التبرع مرة واحدة هو تبرع واحد، بينما التبرع المتكرر يتضمن تقديم مساهمات منتظمة في فترات محددة (شهريًا، ربع سنويًا، أو سنويًا). يوفر التبرع المتكرر دعمًا مستدامًا لمشاريع الخير ويسمح بالتخطيط للمدى الطويل.'
        },
    },
    {
        id: 6,
        question: {
            en: 'Can I donate water to international communities?',
            fr: `Puis-je faire un don d'eau à des communautés internationales ?`,
            ar: 'هل يمكنني التبرع بالمياه للمجتمعات الدولية؟'
        },
        answer: {
            en: 'Yes, it is possible to donate water to international communities. MATW operates worldwide to implement water projects in various countries facing water scarcity. <a class="text-[#00a3da]" href="/donate-now/make-a-donation">Donate now</a>',
            fr: `Oui, il est possible de donner de l'eau à des communautés internationales. La MATW opère dans le monde entier pour mettre en œuvre des projets d'approvisionnement en eau dans divers pays confrontés à une pénurie d'eau. Faire un don maintenant. `,
            ar: 'نعم، من الممكن التبرع بالمياه للمجتمعات الدولية. يعمل MATW على مستوى العالم لتنفيذ مشاريع المياه في مختلف البلدان التي تواجه نقصاً في المياه. تبرع الآن.'
        },
    },
    {
        id: 7,
        question: {
            en: 'Can I donate water to areas affected by natural disasters or emergencies?',
            fr: `Puis-je donner de l'eau à des régions touchées par des catastrophes naturelles ou des situations d'urgence ?`,
            ar: 'هل يمكنني التبرع بالمياه للمناطق المتأثرة بالكوارث الطبيعية أو الطوارئ؟'
        },
        answer: {
            en: 'Yes, water donations are particularly crucial in areas affected by natural disasters or emergencies. MATW provides you with the opportunity to donate towards water initiatives and emergency relief efforts to provide immediate access to safe drinking water in critical situations.',
            fr: `Oui, les dons d'eau sont particulièrement importants dans les régions touchées par des catastrophes naturelles ou des situations d'urgence. La MATW vous offre la possibilité de faire des dons pour des initiatives dans le domaine de l'eau et des efforts de secours d'urgence afin de fournir un accès immédiat à l'eau potable dans des situations critiques. `,
            ar: 'نعم، التبرعات بالمياه ضرورية بشكل خاص في المناطق المتأثرة بالكوارث الطبيعية أو الطوارئ. يوفر MATW لك الفرصة للتبرع نحو مبادرات المياه وجهود الإغاثة الطارئة لتوفير وصول فوري إلى مياه شرب آمنة في الحالات الحرجة.'
        },
    },
    {
        id: 8,
        question: {
            en: 'What kind of water wells does MATW build?',
            fr: `Quel type de puits d'eau la MATW construit-elle ?`,
            ar: 'أي نوع من آبار المياه يقوم MATW ببنائه؟'
        },
        answer: {
            en: 'At MATW Project, we’re solution driven and ensure that our solutions cater to each community\'s needs. We build hand-dug wells, drilled wells and provide water purification systems in difficult to reach areas.',
            fr: `Dans le cadre du projet MATW, nous sommes axés sur les solutions et nous nous assurons que nos solutions répondent aux besoins de chaque communauté. Nous construisons des puits creusés à la main, des puits forés et nous fournissons des systèmes de purification de l'eau dans les zones difficiles d'accès. `,
            ar: 'في مشروع MATW، نحن نسعى لتقديم حلول ونضمن أن حلولنا تلبي احتياجات كل مجتمع. نقوم ببناء آبار يدوية الحفر وآبار مثقبة، ونوفر أنظمة تنقية المياه في المناطق الصعبة الوصول.'
        },
    },
    {
        id: 9,
        question: {
            en: 'What do I get when I donate a water well?',
            fr: `Qu'est-ce que je reçois lorsque je fais don pour construire un puits d'eau ?`,
            ar: 'ماذا أحصل عليه عندما أتبرع ببئر مياه؟'
        },
        answer: {
            en: 'At MATW Project, we’re 100% committed to delivering a transparent experience. When a donor invests in a Sadaqah Jariyah project, we provide updates to keep our donors informed of the progress at different stages. The final report includes images of the project throughout it’s stages and in some cases, a video.',
            fr: `Au projet MATW, nous nous engageons à 100 % à offrir une expérience transparente. Lorsqu'un donateur investit dans un projet Sadaqah Jariyah, nous lui fournissons des mises à jour afin de le tenir informé des progrès réalisés à différentes étapes. Le rapport final comprend des images du projet à tous les stades et, dans certains cas, une vidéo.`,
            ar: 'في مشروع MATW، نلتزم بنسبة 100٪ بتقديم تجربة شفافة. عندما يستثمر المتبرع في مشروع صدقة جارية، نقدم تحديثات للحفاظ على إبقاء المتبرعين على علم بالتقدم في مراحله المختلفة. يتضمن التقرير النهائي صورًا للمشروع خلال مراحله، وفي بعض الحالات، فيديو.'
        },
    },
    {
        id: 10,
        question: {
            en: 'How long does it take to build a water well?',
            fr: 'Combien de temps faut-il pour construire un puits ?',
            ar: 'كم يستغرق بناء بئر مياه؟'
        },
        answer: {
            en: 'Depending on the <a class="text-[#00a3da]" href="/sadaqah-jariyah/build-a-water-well">water well</a> and the country in which it is being built, your well could take up to one year from donation to delivery.',
            fr: `Selon le type de puits et le pays dans lequel il est construit, il peut s'écouler jusqu'à un an entre le don et la livraison.`,
            ar: 'يعتمد ذلك على نوع البئر والبلد الذي يتم فيه البناء، فقد يستغرق بناء بئرك ما يصل إلى سنة واحدة من التبرع إلى التسليم.'
        },
    },
];

export { foodFaqs, waterFaqs };
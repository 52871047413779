import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import SelectOneProductsBox from '../../../components/general/selectOneProductsBox';
import ProductBox from '../../../components/general/productBox';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';

const AqiqahTabContent = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { items, hasCustomPrice, showArrows = true } = props;
  const selectedCurrency = useSelector(selectSelectedCurrency);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="hidden md:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4">
        {items?.map(
          (item, index) =>
            item.products?.length !== 0 && (
              <div key={`products__${index}`} className="w-[390px] lg:w-[450px]">
                {item.products.length > 1 ? (
                  <SelectOneProductsBox
                    hasCustomPrice={hasCustomPrice}
                    title={item.title}
                    description={item.description}
                    image={item.image}
                    products={item.products}
                  />
                ) : (
                  <ProductBox product={item.products[0]} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                )}
              </div>
            ),
        )}
      </div>
      <div className="w-full md:hidden flex-wrap justify-center items-center relative">
        {showArrows && items.length > 1 && (
          <>
            <button
              className="absolute translate-y-1/2 h-full- h-[28px] top-[47%] -left-[5px] z-10"
              onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
            >
              <LiaAngleLeftSolid color="white" size={25} />
            </button>
            <button
              className="absolute translate-y-1/2 h-full- h-[28px] top-[47%] -right-[5px] z-10"
              onClick={e => e.stopPropagation() || instanceRef.current?.next()}
            >
              <LiaAngleRightSolid color="white" size={25} />
            </button>
          </>
        )}
        <div ref={sliderRef} className="keen-slider">
          {items.map((item, index) => {
            return (
              <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center">
                <div className="w-full max-w-[390px]">
                  {item.products.length > 1 ? (
                    <SelectOneProductsBox
                      hasCustomPrice={hasCustomPrice}
                      title={item.title}
                      description={item.description}
                      image={item.image}
                      products={item.products}
                    />
                  ) : (
                    <ProductBox product={item.products[0]} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default AqiqahTabContent;
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { palestineFaqs } from './components/faqs';
import { useKeenSlider } from 'keen-slider/react';
import CrisisWidget from './components/crisisWidget';
import VideosSection from '../../components/general/videosSection';
import MaxWidthContainer from '../../components/general/maxWidthContainer';

import { selectProducts } from '../../store/products';
import Palestinepreramadan1 from '../../images/landings/palestine/pre-ramadan-palestine1.jpeg';
import Palestinepreramadan2 from '../../images/landings/palestine/pre-ramadan-palestine2.jpeg';
import Palestinepreramadan3 from '../../images/landings/palestine/pre-ramadan-palestine3.jpeg';
import Palestinepreramadan4 from '../../images/landings/palestine/pre-ramadan-palestine5.jpeg';
import Palestinepreramadan5 from '../../images/landings/palestine/pre-ramadan-palestine5.jpeg';

// import banner from '../../images/landings/palestine/Palestine-mercy-wide-hero-banner.jpg';
// import bannerMobile from '../../images/landings/palestine/Palestine-mercy-mobile-hero-banner.jpg';
import banner from '../../images/landings/palestine/Palestine-Emergency-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/landings/palestine/Palestine-Emergency-Hero-Banner-Mobile.jpg';
import matwImpactPhoto from '../../images/landings/palestine/matw-impact.png';
import impactSliderRightIcon from '../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../images/landings/palestine/slider-icon-left.png';
import imageJHCO from '../../images/landings/palestine/image-jhco.png';
import imageAlHilal from '../../images/landings/palestine/image-alhilal.png';
import imageEyc from '../../images/landings/palestine/image-eyc.png';
import sponsorFamilyImg from '../../images/landings/palestine/crisis-and-emergencies-palestine-palestinian-refugee-egypt-settlement.jpg';
import palestineRefugeeImg from '../../images/landings/palestine/crisis-and-emergencies-palestine-palestinian-refugee-settlement-support.jpg';
import shelterOrphanImg from '../../images/landings/palestine/shelter-for-orphan.jpg';
import feedOrphanImg from '../../images/landings/palestine/feed-a-palestine-orphan.jpg';
import supportFamilyImg from '../../images/landings/palestine/support-a-family-with-orphans.jpg';
import babyMilkImg from '../../images/landings/palestine/food-and-water-aid-food-aid-baby-milk-for-52-weeks.jpg';
import donateMealImg from '../../images/landings/palestine/donate-a-meal.jpg';
import donateCleanWaterImg from '../../images/landings/palestine/donate-clean-water.jpg';
// import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import { OurPromiseSection } from '../../components/general';
import PalestineTabs from './components/palestineTabs';
import { impactInfo, impactSliderData, videos } from './fixtures';
import CustomAccordion from '../../components/general/customAccordion';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const images = [
  { src: Palestinepreramadan1, alt: 'Donate to palestine, they need you' },
  { src: Palestinepreramadan2, alt: 'Palestine Charity to help the palestinian people' },
  { src: Palestinepreramadan3, alt: 'Palestine donation to make an impact' },
  { src: Palestinepreramadan4, alt: 'Palestine appeal, help them today' },
  { src: Palestinepreramadan5, alt: 'donate for palestine emergency appeal' },
];

const DonateToGaza = () => {
  /* ----------------------------- Start variables ---------------------------- */
  // const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [loaded, setLoaded] = useState(false);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0, 0]);
  const [saveOrphansProducts, setSaveOrphansProducts] = useState([0, 0, 0, 0]);
  const [palestineRefugeeSettlementProducts, setPalestineRefugeeSettlementProducts] = useState([0, 0]);
  const [palestineHungerProducts, setPalestineHungerProducts] = useState([0, 0]);
  const [sponsorFamily] = useState({
    title: 'Sponsor a Family',
    description: 'Help provide ongoing support to Palestinian refugee families residing in Egypt. Offer monthly allowances to cover their essential needs, including food, medical expenses, rent and education costs.',
    img: sponsorFamilyImg,
    products: [],
  });
  const [palestineRefugee] = useState({
    title: 'Palestine Refugee Settlement',
    description: 'Help provide all the essentials required to set up a Palestinian refugee family as they resettle in Egypt, in their new home.',
    img: palestineRefugeeImg,
    products: [],
  });
  const [feedAPalestineOrphan] = useState({
    title: 'Feed a Palestinian Orphan',
    description: 'Deliver a hot meal to orphaned children in Gaza and protect them from sleeping hungry during a looming famine.',
    img: feedOrphanImg,
    products: [],
  });
  const [supportFamilyOrphan] = useState({
    title: 'Support A Family With Orphans',
    description: 'Your one-off donation can sustain a widow and families with orphaned children, who have lost their breadwinner and ability to earn a living for an entire month!  This donation will help deliver daily meals and provide access to clean water, helping them survive the famine and alleviate the suffering brought on by conflict.',
    img: supportFamilyImg,
    products: [],
  });
  const [shelterOrphan] = useState({
    title: 'Shelter for Orphans',
    description: `Protect orphans by sheltering them with your Sadaqah! This fund will be used to help protect and provide shelter to Palestinian orphaned children. Through this fund, we're able to deliver essentials such as tents, hygiene kits, blankets, pillows, mattresses and more basic necessities required for temporary settlement.`,
    img: shelterOrphanImg,
    products: [],
  });
  const [donateMeal] = useState({
    title: 'Donate a Meal',
    description: `Stand with Families in Palestine Facing Hunger. Donate a Meal Today. Your donation provides essential nutrients for a family in need.`,
    img: donateMealImg,
    products: [],
  });
  const [donateCleanWater] = useState({
    title: 'Donate Clean Water',
    description: `Donate Clean Water Today: Every dollar provides essential hydration for a family in need.`,
    img: donateCleanWaterImg,
    products: [],
  });
  const [babyMilk] = useState({
    title: 'Baby milk',
    description: `Provide a delivery of an urgent baby milk supply to families who cannot afford to provide this staple to their infant children.`,
    img: babyMilkImg,
    products: [],
  });
  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });

  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempPalestineEmergencyProducts = [];
    let tempSaveOrphansProducts = [];
    let tempPalestineRefugeeSettlementProducts = [];
    let tempPalestineHungerProducts = [];
    let tempFeedOrphanProducts = [];
    let tempSupportFamilyProducts = [];
    let tempShelterOrphanProducts = [];
    // let tempPalestineRefugeeProducts = [];
    let tempSponsorFamilyProducts = [];
    let tempSupportRefugeeSettlementProducts = [];
    let tempDonateMealProducts = [];
    let tempDonateWaterProducts = [];
    let tempBabyMilkProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // palestineEmergencyProducts
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            // inner_item_product = Object.assign({}, inner_item_product, {
            //   name: 'PALESTINE APPEAL',
            // });
            tempPalestineEmergencyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') tempPalestineEmergencyProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') tempPalestineEmergencyProducts[2] = inner_item_product;

          // Feed orphans
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-315') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed An Orphan',
            });
            tempFeedOrphanProducts[0] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed 10 Orphans',
            });
            tempFeedOrphanProducts[1] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed 20 Orphans',
            });
            tempFeedOrphanProducts[2] = inner_item_product
          };

          // Support family
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-311') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Family With Orphans For 1 Month',
            });
            tempSupportFamilyProducts[0] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-312') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: '5 Families With Orphans for 1 month',
            });
            tempSupportFamilyProducts[1] = inner_item_product
          };

          // Shelter orphans
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-313') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempShelterOrphanProducts[0] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-314') tempShelterOrphanProducts[1] = inner_item_product;

          // sponsorFamilyProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-12-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'Family of 12 for 1 Month',
            });
            tempSponsorFamilyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-8-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'Family of 8 for 1 Month',
            });
            tempSponsorFamilyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-4-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'Family of 4 for 1 Month',
            });
            tempSponsorFamilyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
              alternativeName: 'Any Amount',
            });
            tempSponsorFamilyProducts[3] = inner_item_product;
          }

          // SupportRefugeeSettlementProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'Palestinian Refugee Egypt Settlement',
            });
            tempSupportRefugeeSettlementProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempSupportRefugeeSettlementProducts[1] = inner_item_product;
          }

          // Donate meal
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-401') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate a meal - 20 people',
            });
            tempDonateMealProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-402') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate a meal - 50 people',
            });
            tempDonateMealProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-400') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
              alternativeName: 'Any Amount',
            });
            tempDonateMealProducts[2] = inner_item_product;
          }

          // Donate water
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-404') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate Clean Water - 500 people',
            });
            tempDonateWaterProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-405') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate Clean Water - 1500 people',
            });
            tempDonateWaterProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-406') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate Clean Water - 2500 people',
            });
            tempDonateWaterProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-403') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
              alternativeName: 'Any Amount',
            });
            tempDonateWaterProducts[3] = inner_item_product;
          }

          // Baby milk
          if (inner_item_product.creator === 'FWD-DIS-BMW-2023-01-048') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Baby Milk for 1 Week',
            });
            tempBabyMilkProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMM-2023-01-049') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Baby Milk for 4 Weeks',
            });
            tempBabyMilkProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Baby Milk for 52 Weeks',
            });
            tempBabyMilkProducts[2] = inner_item_product;
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempSaveOrphansProducts[0] = feedAPalestineOrphan;
    tempSaveOrphansProducts[0].products = tempFeedOrphanProducts;
    tempSaveOrphansProducts[1] = supportFamilyOrphan;
    tempSaveOrphansProducts[1].products = tempSupportFamilyProducts;
    tempSaveOrphansProducts[2] = shelterOrphan;
    tempSaveOrphansProducts[2].products = tempShelterOrphanProducts;

    tempPalestineRefugeeSettlementProducts[0] = sponsorFamily;
    tempPalestineRefugeeSettlementProducts[0].products = tempSponsorFamilyProducts;
    tempPalestineRefugeeSettlementProducts[1] = palestineRefugee;
    tempPalestineRefugeeSettlementProducts[1].products = tempSupportRefugeeSettlementProducts;

    tempPalestineHungerProducts[0] = donateMeal;
    tempPalestineHungerProducts[0].products = tempDonateMealProducts;
    tempPalestineHungerProducts[1] = donateCleanWater;
    tempPalestineHungerProducts[1].products = tempDonateWaterProducts;
    tempPalestineHungerProducts[2] = babyMilk;
    tempPalestineHungerProducts[2].products = tempBabyMilkProducts;
    console.log("humger=", tempDonateMealProducts)
    console.log("humger=", tempDonateWaterProducts)
    tempPalestineEmergencyProducts = tempPalestineEmergencyProducts.filter(n => n)
    tempSaveOrphansProducts = tempSaveOrphansProducts.filter(n => n)
    tempPalestineRefugeeSettlementProducts = tempPalestineRefugeeSettlementProducts.filter(n => n)
    tempPalestineHungerProducts = tempPalestineHungerProducts.filter(n => n)
    setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
    setSaveOrphansProducts(tempSaveOrphansProducts);
    setPalestineRefugeeSettlementProducts(tempPalestineRefugeeSettlementProducts);
    setPalestineHungerProducts(tempPalestineHungerProducts);
    // setRefugeeSettlementProducts(tempSupportRefugeeSettlementProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  // useEffect(() => {
  //   selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  // }, [selectedCurrencyStore]);
  useEffect(() => {
    // getProductsByName('palestine');
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <NewHelmetLayout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['donateToGaza']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['donateToGaza']['description']}`}/>
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'EMR-DIS-PSE-2023-01',
            label: 'Save Lives In Palestine',
            value: 255,
          }}
        />
      </section>

      <section className="flex justify-center">
        <img src={banner} alt="Donate to Gaza with MATW Projects" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="Donate to Gaza with MATW Projects" className="min-[501px]:hidden w-full" />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: 'EMR-DIS-PSE-2023-01',
              label: 'Save Lives In Palestine',
              value: 255,
            }}
          />
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <PalestineTabs
        palestineEmergencyProducts={palestineEmergencyProducts}
        saveOrphansProducts={saveOrphansProducts}
        palestineRefugeeSettlementProducts={palestineRefugeeSettlementProducts}
        palestineHungerProducts={palestineHungerProducts}
      />
      {/* ------------------------ Total Impact Section */}
      <section className="bg-[#E1EFF2] py-6 md:py-8">
        <div className="flex items-center justify-center mb-4 md:mb-5">
          <img src={matwImpactPhoto} alt="Donate to Gaza with MATW Projects" />
        </div>
        <div className="flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
          <h2 className="text-[18px] md:text-4xl font-montserratBold text-[#253B7E] text-center font-black ">
            <span className="font-normal text-[18px] md:text-4xl text-[#78716C] uppercase mr-1">Total Impact:</span>
            &nbsp; {impactInfo.total}
          </h2>
        </div>
        <MaxWidthContainer className="!max-w-[1440px]">
          <div className="hidden sm:grid sm:grid-cols-4 xl:grid-cols-8 gap-16 justify-between items-center">
            {impactSliderData.map(({ img, value, title }, index) => (
              <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                <img src={img.src} alt={img.alt} />
                <div className="flex flex-col items-center text-center">
                  <h4 className="md:text-2xl font-montserratBold text-[#253B7E]">{value}</h4>
                  <small className="text-xs md:text-base font-montserratMedium uppercase text-[#78716C]">{title}</small>
                </div>
              </div>
            ))}
          </div>
        </MaxWidthContainer>
        <MaxWidthContainer className="sm:hidden !max-w-[1440px]">
          <div className="relative">
            <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
              {impactSliderData.map(({ img, value, title }, index) => (
                <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                  <img src={img.src} alt={img.alt} />
                  <div className="flex flex-col items-center text-center">
                    <h4 className="md:text-2xl font-montserratBold text-[#253B7E]">{value}</h4>
                    <small className="text-xs md:text-base font-montserratMedium uppercase text-[#78716C]">{title}</small>
                  </div>
                </div>
              ))}
            </div>
            {loadedImpact && instanceRefImpactSlider.current && (
              <>
                <Arrow
                  left
                  onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
                  className="bg-transparent"
                  disabled={currentImpactSlide === 0}
                  icon={impactSliderLeftIcon}
                />

                <Arrow
                  onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
                  disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
                  className="bg-transparent"
                  icon={impactSliderRightIcon}
                  rightMargin
                />
              </>
            )}
          </div>
        </MaxWidthContainer>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      <OurPromiseSection alt="Donate to Gaza with our 100% Donation policy" />

      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12">
        <h1 className="text-2xl md:text-[40px] text-[#253b7e] font-montserratBold mb-5 md:mb-8 md:text-center">
          Donate to <span className="text-[#f60362]">Gaza with MATW Projects</span>
        </h1>
        <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-montserratMedium space-y-4">
          <p>
            Since the start of the conflict on October 7, more than 30,000 Palestinians have lost their lives, including more than 12,000 children.
            Over 63,000 Palestinians have been injured, and more than 1.9 million people have become internally displaced. Families have fled south,
            where conditions are barely liveable. Urgent food, water, and medical supplies are required to ensure their survival.
          </p>
          <p>
            MATW is on the ground providing life-saving assistance for displaced families in Palestine and, more recently, for Palestinian families
            seeking refuge in Egypt. We urgently need your support to help the people of Palestine survive and to help refugee families start over.
          </p>
          <p>
            You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and
            family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100%
            Zakat-applicable.
          </p>
          {/* <p>This Ramadan let’s perform these good deeds and feel Allah’s Blessings and Mercy together.</p> */}
        </div>
      </div>
      <div className="max-w-[1440px] mx-auto">
        <section className="xl:px-20 mb-8 sm:mb-16">
          <div className="relative overflow-hidden flex flex-wrap">
            {/* <div
              className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[390px] md:top-1/2 md:-translate-y-1/2
                            right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute px-9 py-5 md:px-[48px] md:py-[38px]"
            >
              <div className="leading-[25px] w-full text-[25px] md:text-[30px] md:leading-[30px] text-white text-center font-montserratBold">
                Palestine is on the brink of famine
              </div>
              <p className="md:text-[18px] mt-3 md:mt-5 text-white text-center font-montserratMedium">
                Let your Zakat or Sadaqah be their lifeline and your entry to Jannah
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" /> */}
            {/* <YoutubeVideoHero src={`https://www.youtube.com/embed/8fCYKifjeg4?controls=0&autoplay=1&loop=1&mute=1&playlist=8fCYKifjeg4`} /> */}
          </div>
        </section>
      </div>

      {/* /* --------------------------------- Muslim Arounds ------------------------- */}
      <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16">
        <div className="flex flex-col justify-center items-center gap-5 md:gap-8">
          <p className="text-2xl md:text-[40px] max-w[884px] text-[#253B7E] text-center font-montserratBold">
            Muslims Around The World (MATW) on the ground in Palestine:
          </p>
          <h2 className="text-[#78716C] text-center text-sm md:text-[18px] font-montserratMedium">In Partnership with</h2>
        </div>
        <div className="flex justify-center items-center flex-col gap-3 md:gap-6">
          <img src={imageJHCO} alt="jhco logo" className="w-[301px] md:w-[585px]" />
          <div className="flex gap-5 items-center">
            <img src={imageAlHilal} alt="alhilal logo" className="w-[120px] md:w-[201px]" />
            <img src={imageEyc} alt="eyc logo" className="w-[71px] md:w-[100px]" />
          </div>
        </div>
        <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-montserratMedium space-y-4">
          <p>
            With a long-standing presence in Palestine and supplies on standby for an immediate response, our MATW team has been quick to deliver
            urgent essentials to those directly affected by the conflict in Palestine. Our direct response efforts are focused on those who are most
            vulnerable including orphans, widows, the elderly and families.
          </p>
          <p>
            Our operations team is delivering emergency medical kits, urgent food packs, fuel, hot meals and more to those who have been displaced.
          </p>
          <p>
            Our medical response within hospitals proved to be a critical lifeline. Thanks to donors, our team was able to deliver stretchers, beds,
            mattresses, blankets, medical kits and supplies. We also were able to provide staff support and fuel to ensure medical make-shift camps
            and operational hospitals continue to run.
          </p>
          <p className="text-2xl text-[#253B7E] font-montserratBold">
            Our team is primarily distributing with the Jordanian Hashemite Charity Organisation (JHCO). We’re also partnered with the Egyptian Youth
            Council, the Egyptian Red Crescent to ensure relief is reaching those in need in Palestine.
          </p>
          <p>
            We are also working to provide urgent essentials and family relocation support to Palestinian families seeking refuge in Egypt. Through
            these projects, donors will be able to help us provide family sponsorship and settlement support to ensure a new start for families with
            all that they need.
          </p>
          <p>We are determined as ever to never give up. Every life is valuable, and your support allows us to continue doing the work that we do.</p>
          <p>
            With full transparency to our donors, every single donation to our Palestine Emergency Appeal will go directly to the field, with a 100%
            donation policy.
          </p>
          <p>Donations to our Palestine Emergency Appeal are also 100% Zakat applicable.</p>
        </div>
      </div>

      {/* /* -------------------------------------------------------------------------- */}
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* ----------------- videos section ---------------- */}
        <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
        {/* ----------------- videos section --END-------------- */}

        {/* <section className="md:mt-16 px-4 md:px-0">
          <div className="md:container md:mx-auto">
            <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-montserratMedium space-y-4">
              <p>
                Since the conflict began on October 7, the toll has been devastating. More than{' '}
                <span className="text-[#253B7E] font-montserratBold">30,000</span> Palestinians, including over{' '}
                <span className="text-[#253B7E] font-montserratBold">12,000</span> children, have lost their lives. The situation is dire, with an
                increasing number of children in Gaza facing death from acute hunger, and newborns succumbing to low birth weight. Over{' '}
                <span className="text-[#253B7E] font-montserratBold">63,000 Palestinians</span> have been injured, and a staggering{' '}
                <span className="text-[#253B7E] font-montserratBold">1.9 million</span> are now internally displaced. Families have been forced to
                flee to the south, where conditions are barely survivable. Urgent action is needed to provide food, water, and medical supplies to
                ensure their survival.
              </p>
              <div className="flex flex-col gap-2">
                <h3 className="text-[#253B7E] font-montserratBold">Donate for Palestine - Your Contributions Can Save Lives</h3>
                <p>
                  You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter,
                  and family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is
                  100% Zakat-applicable.
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <h3 className="text-[#253B7E] font-montserratBold">Charity for Palestine to Empower, Rebuild and Restore Hope</h3>
                <p>
                  Your charitable contributions can bring hope and relief to the people of Palestine. Whether you donate through your Sadaqah or
                  Zakat, your support will provide crucial assistance for urgent needs. Join us in extending a helping hand to those facing
                  unimaginable adversity in Palestine.
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <h3 className="text-[#253B7E] font-montserratBold">Palestine Donation: Join Us in Providing Critical Relief</h3>
                <p>
                  Your donation can change lives in Palestine. Every contribution, whether big or small, can make a significant impact on the lives of
                  those in need. Your support will provide vital resources. Please consider acting now to stand with Palestine today through your
                  generous donations.
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <h3 className="text-[#253B7E] font-montserratBold">Palestine Appeal – Stand in Solidarity, Act Now</h3>
                <p>
                  The situation in Palestine demands immediate action and your support can be life-saving for those enduring hardship. Your donation
                  of Sadaqah or Zakat can play a crucial role in delivering essential services including aid, food and assistance with family
                  relocation. Act now to make a tangible difference in the lives of those impacted by the crisis in Palestine. Your contribution can
                  provide hope and vital assistance to those in need.
                </p>
              </div>
              <div className="flex flex-col gap-2 mt-8">
                <h3 className="text-[#253B7E] font-montserratBold">
                  Time is of the essence dear readers. This Ramadan, don't miss this opportunity to make a meaningful impact for Palestine. Every
                  donation counts. Act now.Your compassionate actions and empathetic heart will not go unaccounted for.May Allah have Mercy on the
                  People of Palestine. Ameen.
                </h3>
              </div>
            </div>
          </div>
        </section> */}

        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="mt-4 md:mt-12">
          <div className="md:container md:mx-auto flex justify-center flex-wrap ">
            <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
              <div ref={sliderRefImages} className="keen-slider">
                {images.map((item, index) => {
                  return (
                    <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                      {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                      <img src={item.src} alt={item.alt} className=" bg-top object-cover w-full object-top" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full  flex justify-center">
              {loaded && instanceRefImages.current && (
                <div className="dots flex pt-3 ">
                  {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRefImages.current?.moveToIdx(idx);
                        }}
                        className={
                          'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                          (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h2 className="text-[#253B7E] text-left  sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] font-brandingBold">
          Gaza Charity Donations <span className="text-[#F60362]"> FAQ’s</span>
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{palestineFaqs[0].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{palestineFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{palestineFaqs[1].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{palestineFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{palestineFaqs[2].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{palestineFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          <CustomAccordion>
            <CustomAccordion.Title tag="h2">
              Gaza Charity Donations <span className="text-[#F60362]"> FAQ’s</span>: Discover How Your Support Fuels Our Palestine charity Initiatives
            </CustomAccordion.Title>
            <CustomAccordion.Header>
              {palestineFaqs &&
                palestineFaqs.map(
                  ({ question, answer }, index) =>
                    index < 3 && (
                      <CustomAccordion.HeaderContent key={index}>
                        <CustomAccordion.HeaderQuestion>{question}</CustomAccordion.HeaderQuestion>
                        <CustomAccordion.HeaderAnswer>{answer}</CustomAccordion.HeaderAnswer>
                      </CustomAccordion.HeaderContent>
                    ),
                )}
            </CustomAccordion.Header>
            <div className="mt-6">
              <CustomAccordion.Accordion items={palestineFaqs.filter((_, index) => index > 2)} />
            </div>
          </CustomAccordion>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};
export default DonateToGaza;

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`${
        props.bg ? props.bg : 'bg-[#F60362]'
      } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${
        props.className ? props.className : ''
      } ${props.left ? 'arrow--left left-[0px]' : 'arrow--right left-auto right-[0px]'}`}
      onClick={props.onClick}
    >
      {props.icon ? (
        <img alt="arrow-img" src={props.icon} className={`left-0 relative top-10 ${props.rightMargin ? 'ml-3' : '-ml-3'} `} />
      ) : (
        <svg
          className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
          {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
        </svg>
      )}
    </div>
  );
}
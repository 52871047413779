import useGetIndexData from '../hooks/useGetIndexDataHook';

const Slider = ({ images }) => {
  const { loaded, sliderRefImages, instanceRefImages, currentSlideImages } = useGetIndexData();
  return (
    <section className="bg-[#F8F8F8] xs:mt-5 lg:mt-0 pb-5 sm:py-8">
      {/* /* --------------------------------- Images --------------------------------- */}
      <section className="md:mt-16">
        <div className="md:container md:mx-auto flex justify-center flex-wrap ">
          <div className="w-full flex-wrap justify-center items-center px-6 sm:px-0 relative">
            <div ref={sliderRefImages} className="keen-slider">
              {images &&
                images.map((item, index) => {
                  return (
                    <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                      {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                      <img src={item.src} alt={item.alt} className=" bg-top object-cover w-full object-top" />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="w-full  flex justify-center">
            {loaded && instanceRefImages.current && (
              <div className="dots flex pt-3 ">
                {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRefImages.current?.moveToIdx(idx);
                      }}
                      className={
                        'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                        (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                      }
                    ></button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </section>
  );
};

export default Slider;

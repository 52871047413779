export const volunteerFaqs = [
    {
      id: 0,
      question: 'How often can I / am I expected to volunteer?',
      answer: `At MATW, we depend on and greatly appreciate the invaluable time, skills and experience
      of our dedicated volunteers every day. Volunteers can participate as often as they like, if
      you are able to dedicate a minimum of 2 hours a week, 4 hours a fortnight, 8 hours a
      month, we welcome your valuable help!
      <br/>
      <br/>
      We have volunteer activities and shifts throughout the week, including weekends and
      especially during Ramadan and the weeks leading up to Eid Al Adha. Shift length can
      vary from a couple of hours to a full day, depending on the role. We do have limited
      volunteer roles in some locations, and more info on the timing of shifts in your location will
      be provided when a need for volunteers arises in your location.
      <br/>
      <br/>
      Many of our roles offer flexible commitment, for example, they do not need to be
      undertaken on the same day every week. However, some roles will ask volunteers to
      commit to a regular and set schedule, and this will be made clear during the volunteer
      engagement process.
      `,
    },
    {
      id: 1,
      question: 'When is the next Information/Induction Session?',
      answer: `The Information session is your opportunity to learn in more detail about the work we do,
      the various volunteer roles available in your location, and get details on our volunteer
      registration requirements. We would like to provide you with as much information
      as possible to enable you to make an informed decision about joining our volunteer team.
      This is also a great opportunity for you to get to know us and ask questions.
      <br/>
      Once you’ve registered your details on the website here, your local Volunteer Coordinator
      will be in touch <span class='text-[#ff1e05]'>ahead of the next Information/Induction Session in your locality</span> – sessions
      are held quarterly or bi-annually, depending on your location.
      `,
    },
    {
      id: 2,
      question: 'Apart from attending the Information & Induction Session, what else will I need to do or provide before I can start volunteering?',
      answer: `Each MATW location has a unique engagement process along with important compliance
      requirements for volunteers. These will be discussed at either the volunteer interview
      stage or at the Information Session. However, volunteers in all locations must complete
      and return a signed copy of the Volunteer Agreement and other engagement forms and
      will need to provide a valid copy of their driver’s license (both sides) or passport (the main
      page) before they start volunteering activities. Most locations also require a Police Check
      (issued within the last 12 months).
      <br/>
      Depending on the nature of the volunteer activity, some roles may have further
      requirements – for example, Working with Children Checks and or attending a
      Safeguarding training session online (provided at no cost).
      <br/>
      If you have any questions regarding the above, kindly contact us via
      email <a class='text-[#00a3da]' href='mailto:volunteer@matwproject.org'>volunteer@matwproject.org</a>.
      <br/>
      Please note: a record on a police check does not automatically disqualify someone from
      volunteering. The result may mean volunteers are more suited to some roles over others,
      and this will be discussed in confidence should the need arise.
      `,
    },
    {
      id: 3,
      question: "Is there a cost involved to volunteer?",
      answer: `There is sometimes a small cost associated with volunteer registration, which can include:
      <br/>
      -     Volunteers to provide a police check issued within the last 12 months, at their own
      cost. Volunteers are welcome to obtain their check through any means they prefer.
      We will give you more info on the discounted rate in our information session.
      <br/>
      <br/>
      -     Volunteers who already hold existing police checks issued within the past 12
      months can submit this and may not be required to obtain a new check.
      <br/>
      <br/>
      If this cost would present a financial challenge, please get in touch with your local MATW
      Volunteer Coordinator, or email <a class='text-[#00a3da]' href='mailto:volunteer@matwproject.org'>volunteer@matwproject.org</a>.
      <br/>
      Please note: a record on a police check does not automatically disqualify someone from
      volunteering. The result may mean volunteers are more suited to some roles over others,
      and this will be discussed in confidence should the need arise.
      `,
    },
    {
      id: 4,
      question: 'Do I need to wear a uniform?',
      answer: `All volunteers are required to wear a blue MATW T-shirt over black, navy or white
      clothes and closed toed shoes. This may change from time to time to suit special
      activities; however, you will be kept informed and up to date of any changes.`,
    },
    {
      id: 5,
      question: 'Do you require reference checks for volunteers?',
      answer: `Depending on the role, yes. MATW offers a variety of volunteer opportunities, so
      volunteers are asked to supply a reference in the volunteer application form. The
      Volunteer Coordinator will conduct and oversee the reference process for any role that
      requires it (you’ll be informed if a role you’d like to help with does).`,
    },
    {
      id: 6,
      question: 'Do any volunteer roles require further training?',
      answer: `Yes, all MATW volunteer roles require volunteers to undergo role-specific training, which
      is conducted as needed, either as part of a group or individually, depending on the role.
      Additionally, some volunteer programs may require you to shadow an existing volunteer or
      complete a trial shift before you can participate in those activities.
      <br/>
      As a minimum, all volunteers will receive basic training when they participate in a role for
      the first time.
      `,
    },
    {
      id: 7,
      question: 'Can people under the age of 18 volunteer with MATW?',
      answer: `We love to see young people looking to get involved and make a positive impact in their
      community. For those under the age of 18, please ensure a legal guardian registers their
      details either as part of your application or as a separate application. Should you have any
      questions about this, please email us on <a class='text-[#00a3da]' href='mailto:volunteer@matwproject.org'>volunteer@matwproject.org</a>.
      `,
    },
    {
      id: 8,
      question: 'I’ve registered an application online to become a volunteer, why haven’t I received a reply?',
      answer: `Thank you for registering your interest in volunteering with MATW and for taking steps towards
      helping people in need and supporting this incredible cause. We will respond to your application
      when there is a need for volunteers in your location, be sure to check your spam/junk folder in
      case you’ve missed correspondence from us.`,
    },
    {
      id: 9,
      question: 'I can’t find the answer to my question in the FAQs above, where can I get some more information about volunteering?',
      answer: `If we haven’t been able to answer your question in our FAQs, please send us an email
      to <a class='text-[#00a3da]' href='mailto:volunteer@matwproject.org'>volunteer@matwproject.org</a> with your question and details of your locality, and we can
      help you with your specific query`,
    },
];
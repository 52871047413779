import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-new';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import ProductBox from '../../components/general/productBox';
import WidgetHome from '../../components/widgets/widgetHome';
import Skeleton from 'react-loading-skeleton';
import Banner from "../../images/banners/large-earthquake-emergency-hero-banner-desktop.jpg";
import SeoData from '../../utils/seo.json';
import { getProducts } from '../../store/products';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
const EarthquakeAppealPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [earthQuakeProducts, setEarthQuakeProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const products = useSelector((state) => getProducts(state, "Crisis and Emergencies", "Earthquake Appeal"));
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getProductsByName = () => {
        let tempCritical = [''];
        products.subCategory.products.map(item => {
            if (item.creator === 'EM-SYR-EQ-02-2023') { // Caravan
                item = Object.assign({}, item, {
                    description: `MATW's Caravan: A safe, long-term shelter for families with a 5x3m living space, kitchen sink,
                    and proper toilet, offering protection from the wilderness. Support now to make a lasting impact. Donate today.`
                })
            }
            if (item.creator === 'EMR-MR-WMN-001-2023') { // Morocco Appeal
                tempCritical[0] = item;
            } else {
                tempCritical.push(item);
            }
            return item;
        });
        setIsLoaded(true);
        setEarthQuakeProducts(tempCritical);
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        if (products && !isLoaded) {
            getProductsByName();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    return (
        <NewHelmetLayout>
            {seo &&
                <Helmet>
                    <title>{`${seo['crisisAndEmergencies']['earthquakeAppeal']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo['crisisAndEmergencies']['earthquakeAppeal']['description']}`} />
                </Helmet>
            }
            {/* <div className="flex md:w-full md:h-[120px] lg:w-full lg:h-[160px] xl:w-full xl:h-[200px] 2xl:w-full 2xl:h-[250px]"> */}
                {isLoaded && <img src={Banner} alt={products.name} className='w-full' onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/general/logo-replace.png";
                    currentTarget.className = 'max-h-[150px] mx-auto'
                }} />}
            {/* </div> */}
            <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
            <div className="md:container md:mx-auto mt-5">
                <div className="grid grid-cols-12 px-3- md:px-4-">
                    <div className="col-span-12 text-center text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
                        {isLoaded ? products.name : <Skeleton height={80} />}
                    </div>
                    <div className="col-start-1 col-span-12 rounded-md">
                        <div className="flex flex-row justify-center flex-wrap">
                            {earthQuakeProducts.map((item, index) => {
                                return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                    <ProductBox product={item} currency={selectedCurrency} />
                                </div>
                            })
                            }
                        </div>
                        <div className={`col-span-12 rounded-xl mt-6 lg:min-h-[500px]- bg-white p-6 lg:p-12 text-center shadow-lg`}>
                            <h1 className='text-[#00a3da] text-4xl mb-4'>
                                {isLoaded
                                    ? 'The earthquake in Morocco has resulted in loss of life and property, leaving thousands of people dead, homeless and critically injured.'
                                    : <Skeleton />}
                            </h1>
                            <p className="text-[#777] text-xl mb-4">
                                {isLoaded
                                    ? 'A disaster leaves behind it a trail of grief, trauma, tragedy and loss for those who survive. The ones affected need our support at the earliest so they may get the chance to restart'
                                    : <Skeleton />}
                            </p>
                            <div className="text-[#999] text-md">
                                {isLoaded
                                    ? <p>
                                        No matter how little or big, every bit of help from you will make a huge impact in restoring the lives of those displaced. With your donations, we can provide them with essential needs such as food and water, shelter and healthcare.
                                        Let’s be their ray of hope and the answer to their duas by coming together in these hard times.
                                        <br />
                                        Your support is their strength.
                                        <br />
                                        Donate now!
                                    </p>
                                    : <Skeleton />}
                            </div>
                        </div>
                        <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
                            <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                                <iframe className='video'
                                    width={'100%'}
                                    height={'100%'}
                                    title={'youtube'}
                                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                    src={`https://youtube.com/embed/XRziciz2sZA?autoplay=0`}
                                >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NewHelmetLayout>
    );
}

export default EarthquakeAppealPage;

import { useEffect, useState } from 'react';
import CustomModal from '../../components/modal';
import Layout from '../../../../components/general/layout';
import ProductBox from '../../../../components/general/productBox';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../../store/user';
import { selectProducts } from '../../../../store/products';
import AppealCarouselSection from '../../../appeal/carouselSection';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import { useKeenSlider } from 'keen-slider/react';
import Arrow from '../../../home/components/tab-arrow';
// import { Loader } from '../../../../components/general';

const PreviewPageModal = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { show, onClose, banners, products, content, title } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const allProducts = useSelector(state => selectProducts(state));
    const [pageProducts, setPageProducts] = useState([0, 0, 0, 0])
    const [imageHasError, setImageHasError] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        mode: "snap",
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,
        },
    })
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const closeModal = () => {
        onClose();
    }
    const getAllProducts = (_prds) => {
        let tempProductsList = [];
        allProducts.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    _prds.map(prd_item => {
                        if (prd_item.creator === inner_item_product.creator) tempProductsList.push(inner_item_product)
                        return prd_item;
                    })
                    return inner_item_product;
                });
                return inner_item;
            });
            return item;
        });
        console.log("tempProductsList=",tempProductsList)
        setPageProducts(tempProductsList)
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        console.log("products=",products)
        show && products && getAllProducts(products);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);
    useEffect(() => {
        show && console.log("show modal", props);
        show && products && getAllProducts(products);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    /* -------------------------------------------------------------------------- */
    return (
        <CustomModal
            title={'Preview Page'}
            show={show}
            className='pb-0 !w-2/3'
            onClose={() => closeModal()}
        >
            <div className="h-[500px] overflow-y-scroll">
                <Layout className='bg-white scale-90 h-[500px]- overflow-y-scroll-' hideFooter showRegularImg={false} fixedNavbar={false}>
                    <section className="flex justify-center" >
                        {banners.length > 1
                            ? <AppealCarouselSection banners={banners} />
                            : banners.length === 0
                                ? <></>
                                : <img className={`${imageHasError ? 'w-auto max-h-[300px]' : 'w-full'}`} src={banners[0] ? banners[0].image : '/images/general/logo-replace.png'}
                                    onError={({ currentTarget }) => {
                                        setImageHasError(true)
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/images/general/logo-replace.png";
                                    }}
                                    alt="" />}
                    </section>
                    <section className="bg-[#253B7E]">
                        <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
                            <div className='hidden sm:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4'>
                                {pageProducts.map((item, index) => {
                                    return <div key={`pageProducts${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 lg:basis-1/4 max-w-[300px]">
                                        <ProductBox product={item} currency={selectedCurrencyStore ? selectedCurrencyStore : 'AUD'} />
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
                            {pageProducts[0] && products.length > 1 && <>
                                <div className='absolute translate-y-1/2 h-full- h-[28px] top-[47%] -left-[5px] z-10'
                                    onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                                >
                                    <LiaAngleLeftSolid color='white' size={25} />
                                </div>
                                <div className='absolute translate-y-1/2 h-full- h-[28px] top-[47%] -right-[5px] z-10'
                                    onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                                >
                                    <LiaAngleRightSolid color='white' size={25} />
                                </div>
                            </>}
                            <div ref={sliderRef} className="keen-slider">
                                {pageProducts.map((item, index) => {
                                    return <div key={`pageProducts${index}`} className="keen-slider__slide basis-full flex justify-center">
                                        <div className="w-full max-w-[300px]">
                                            <ProductBox product={item} currency={selectedCurrencyStore ? selectedCurrencyStore : 'AUD'} />
                                        </div>
                                    </div>
                                })}
                            </div>
                            {pageProducts[0] && pageProducts.length > 1 && <div className="flex bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
                                {loaded && instanceRef.current && (
                                    <div className="flex justify-center items-center relative w-full">
                                        <Arrow
                                            left
                                            onClick={(e) =>
                                                e.stopPropagation() || instanceRef.current?.prev()
                                            }
                                            disabled={currentSlide === 0}
                                        />
                                        {/* {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => { */}
                                        {/* {pageProducts.map(idx => { */}
                                        {[...Array(pageProducts.length).keys()].map(idx => {
                                            return (
                                                <button
                                                    key={`q${idx}`}
                                                    onClick={() => {
                                                        instanceRef.current?.moveToIdx(idx);
                                                    }}
                                                    className={
                                                        'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                                                        (currentSlide === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362')
                                                    }
                                                ></button>
                                            );
                                        })}
                                        <Arrow
                                            onClick={(e) =>
                                                e.stopPropagation() || instanceRef.current?.next()
                                            }
                                            disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                                        />
                                    </div>
                                )}
                            </div>}
                        </div>
                    </section>
                    <section className='px-4 md:px-0 py-4 md:container md:mx-auto'>
                        <div className='flex justify-center flex-col items-center'>
                            <h1 className='sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl px-4 w-full sm:w-10/12'>
                                {title || 'Products'}
                            </h1>
                            <p className='text-[#78716C] px-4 lg:px-20 mt-3 leading-6 text-[18px] sm:text-center font-montserratMedium w-full'>
                                {content || ''}
                            </p>
                        </div>
                    </section>
                    {/* /* -------------------------------------------------------------------------- */}
                </Layout>
            </div>
        </CustomModal>
    );
};
export default PreviewPageModal;
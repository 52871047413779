import clickerImg from '../../images/general/clickhereimpact.png';
import CountUp from 'react-countup';
import impactHeaderImg from '../../images/general/MATW-Home-Banner-TheMATWImpact-Heading.png';
import { Link } from 'react-router-dom';

const CountUpBannerUSA = () => {
    return (
        <div className="flex flex-col justify-between flex-wrap col-span-12 bg-[url('../src/images/general/impactbg.jpg')] bg-cover py-6 items-center md:h-[380px] lg:h-[400px]">
            <img src={impactHeaderImg} alt="Muslim Charity - MATW Project" className="mt-16- md:w-[439px] md:h-[74px]" />
            <div className="flex flex-wrap w-full md:container md:mx-auto px-3 py-10 justify-center">
                <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
                    <CountUp start={4400} end={4513} duration={5} separator="" decimals={0} decimal="," prefix="" suffix="" delay={0}>
                        {({ countUpRef }) => (
                            <div>
                                <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                            </div>
                        )}
                    </CountUp>
                    <span className="text-md md:text-lg text-center">Water Wells Built</span>
                </div>
                <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
                    <CountUp start={5900} end={6000} duration={5} separator="" decimals={0} decimal="," prefix="" suffix="" delay={0}>
                        {({ countUpRef }) => (
                            <div>
                                <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                            </div>
                        )}
                    </CountUp>
                    <span className="text-md md:text-lg text-center">Orphans Sponsored</span>
                </div>
                <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
                    <CountUp start={0} end={34} duration={5} separator="" decimals={0} decimal="," prefix="" suffix="M+" delay={0}>
                        {({ countUpRef }) => (
                            <div>
                                <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                            </div>
                        )}
                    </CountUp>
                    <span className="text-md md:text-lg text-center">Impact Made</span>
                </div>
            </div>
            <Link className="" to={'/annual-reports'}>
                <img src={clickerImg} alt="" className="mt-16- md:w-[606px] md:h-[110px]" />
            </Link>
        </div>
    );
}

export default CountUpBannerUSA;

import { useEffect, useState } from 'react';
import services from '../../services';
import { toast } from 'react-toastify';

const useDashboardSubscriptionsHook = () => {
  /* ----------------------------- Start variables ---------------------------- */
  // const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchedItems, setSearchedItems] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [totalTrxs] = useState({ count: 0, amount: [], totalAmountAud: 0 });
  const [lastPage] = useState();
  const [subscriptions, setSubscriptions] = useState([]);
  const columnsDataTrxs = [
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Currency',
      accessor: 'currency',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Recurring',
      accessor: 'recurring',
    },
    {
      Header: 'subscription Id',
      accessor: 'sub_id',
    },
    {
      Header: 'Created Date',
      accessor: 'created_date',
    },
    {
      Header: 'account_type',
      accessor: 'account_type',
    },
    {
      Header: 'Actions',
      accessor: 'id',
    },
  ];
  var tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  const cancelSubscription = async (account_type, subscription_id) => {
    setIsLoading(true);
    const cancelSubscriptionResponse = await services.cancelDashboardSubscription(account_type, subscription_id);
    if ('errors' in cancelSubscriptionResponse) toast.error(cancelSubscriptionResponse.message);
    else {
      toast.success(cancelSubscriptionResponse.message);
      const subs = subscriptions.filter(i => i.id !== subscription_id);
      setSubscriptions(subs);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const subs = await services.getDashboardSubscriptions();
      const filterSubs = subs.map(i => ({ ...i, sub_id: i.id }));
      setSubscriptions(filterSubs);
      setIsLoading(false);
    })();
  }, []);

  return {
    columnsDataTrxs,
    cancelSubscription,
    subscriptions,
    lastPage,
    searchedItems,
    pageSize,
    isLoading,
    totalTrxs,
    setSearchedItems,
  };
};
export default useDashboardSubscriptionsHook;

import React from 'react';
import SubscriptionsList from './list';
import useDashboardSubscriptionsHook from '../../../../hooks/dashboard/useDashboardSubscriptionsHook';
// import HandleProduct from "./handleProduct";

const SubscriptionsPage = () => {
  const { columnsDataTrxs, lastPage, subscriptions, cancelSubscription, searchedItems, pageSize, isLoading, totalTrxs, setSearchedItems } =
    useDashboardSubscriptionsHook();
  return (
    <>
      <SubscriptionsList
        columnsData={columnsDataTrxs}
        tableData={subscriptions}
        lastPage={lastPage}
        currentPage={searchedItems ? searchedItems.page : 1}
        pageSize={pageSize}
        isLoading={isLoading}
        totalTrxs={totalTrxs}
        cancelSubscription={cancelSubscription}
        onSearchItems={e => setSearchedItems(e)}
      />
    </>
    // <div className="flex- flex-wrap-">
    //     <div className="w-full-">
    //     </div>
    //     <div className="w-full mt-4">
    //         <SubscriptionChart />
    //     </div>
    // </div>
  );
};
export default SubscriptionsPage;

import { palestineFaqs } from '../data/faqs';

const Faq = () => {
  return (
    <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
      <h1 className="text-[#253B7E] md:text-center mb-5 md:mb-6 lg:mb-8 text-[30px] sm:text-[48px] font-montserratBold">
        Eid al Fitr 2024 - Donation <span className="text-[#F60362]">FAQ’s</span>
      </h1>
      <div className="grid grid-cols-3 gap-x-5 md:gap-x-16 gap-y-5 md:gap-y-8">
        <div className="col-span-3 sm:col-span-1">
          <p className="text-[#253B7E] font-montserratBold text-[18px] md:text-2xl">{palestineFaqs[1].question}</p>
          <p className="text-base font-['MontserratMedium'] mt-3 text-[#78716C]">{palestineFaqs[0].answer}</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="text-[#253B7E] font-montserratBold text-[18px] md:text-2xl">{palestineFaqs[1].question}</p>
          <p className="text-base font-['MontserratMedium'] mt-3 text-[#78716C]">{palestineFaqs[1].answer}</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="text-[#253B7E] font-montserratBold text-[18px] md:text-2xl">{palestineFaqs[2].question}</p>
          <p className="text-base font-['MontserratMedium'] mt-3 text-[#78716C]">{palestineFaqs[2].answer}</p>
        </div>
      </div>
    </section>
  );
};

export default Faq;

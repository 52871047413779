import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react';
// import CrisisWidget from '../../crisis-and-emergencies/components/crisisWidget';
import MaxWidthContainer from '../../../components/general/maxWidthContainer';

import { selectProducts } from '../../../store/products';
import carouselImag1 from '../../../images/others/carousel-img1.jpg';
import carouselImag2 from '../../../images/others/carousel-img2.jpg';
import carouselImag3 from '../../../images/others/carousel-img3.jpg';
import carouselImag4 from '../../../images/others/carousel-img4.jpg';
import carouselImag5 from '../../../images/others/carousel-img5.jpg';
import carouselImag6 from '../../../images/others/carousel-img6.jpg';
import carouselImag7 from '../../../images/others/carousel-img7.jpg';
import carouselImag8 from '../../../images/others/carousel-img8.jpg';
import imgPaperPlane from '../../../images/others/paper-plane.png';
import imgPaperPlaneMobile from '../../../images/others/paper-plane-mob.png';
import imgPaperPlaneTab from '../../../images/others/paper-plane-tab.png';
import imgWhatsappIcon from '../../../images/others/logos_whatsapp-icon.png';
import imgInstaIcon from '../../../images/others/skill-icons_instagram.png';

import YoutubeVideoHero from '../../../components/general/youtubeVideoHero';
import { Link } from 'react-router-dom';
import { OurPromiseSection } from '../../../components/general';
import CustomButton from '../../../components/general/button';
import HeroTab from '../../../components/general/heroTab';
import NewHelmetLayout from '../../../components/general/newHelmetLayout';

const images = [
  { src: carouselImag1, alt: 'Donate to palestine, they need you' },
  { src: carouselImag2, alt: 'Donate to palestine, they need you' },
  { src: carouselImag3, alt: 'Donate to palestine, they need you' },
  { src: carouselImag4, alt: 'Donate to palestine, they need you' },
  { src: carouselImag5, alt: 'Donate to palestine, they need you' },
  { src: carouselImag6, alt: 'Donate to palestine, they need you' },
  { src: carouselImag7, alt: 'Donate to palestine, they need you' },
  { src: carouselImag8, alt: 'Donate to palestine, they need you' },
];

const videoStyleIftar = ['md:h-[534px] lg:h-[675px] rounded-[16px] overflow-hidden'];
const videoStyle = ['md:h-[534px] lg:h-[534px] rounded-[16px] overflow-hidden'];

const LiveMeals = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [loaded, setLoaded] = useState(false);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const [mealsProducts, setMealsProducts] = useState([0, 0, 0]);

  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempMealsProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // if (inner_item_product.creator === 'EMR-DIS-PSE-2023-02') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: 'Feed 10 Hungry People',
          //   });
          //   tempMealsProducts[0] = inner_item_product;
          // }
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-002') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Feed 10 Hungry People',
            });
            console.log('image_link', inner_item_product);
            tempMealsProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-003') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // image_link: imgFeed20People,
              // title: 'Feed 20 Hungry People',
              // description: 'You have the power to nourish the lives of up to 20 hungry people, providing sustenance and support.',
            });
            tempMealsProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-004') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // image_link: imgFeed50People,
              // title: 'Feed 50 Hungry People',
              // description: 'Feed 50 hungry people and be a catalyst for change, one meal at a time.',
            });
            tempMealsProducts[2] = inner_item_product;
          }

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    tempMealsProducts = tempMealsProducts.filter(n => n)
    setMealsProducts(tempMealsProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  // useEffect(() => {
  //   selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  // }, [selectedCurrencyStore]);
  useEffect(() => {
    // getProductsByName('palestine');
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <NewHelmetLayout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
          {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
        </Helmet>
      )}
      {/*
      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={true}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: 'Where Most Needed',
            value: 255,
          }}
        />
      </section> */}
      {/* /* --------------------------------- Widget --------------------------------- */}
      {/* <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={true}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: 'Where Most Needed',
              value: 255,
            }}
          />
        </div>
      </section> */}
      {/* Live Section */}
      {/* Section Feel Live Iftar */}
      <section>
        <MaxWidthContainer>
          <div className="mt-5 md:mt-12">
            <p className="text-[25px] md:text-[40px] lg:text-[60px] font-gotcha text-center text-[#253B7E] mb-4 md:mb-5">
              Feel A Live <span className="text-[#F60362]">Iftar Feast</span>
            </p>
            <div className="flex flex-col justify-center items-center gap-6 max-w-[1040px] mx-auto">
              <p className="text-[25px] md:text-[40px] font-montserratBold text-center text-[#253B7E] leading-7 md:leading-[42px]">
                Support our <span className="text-[#F60362]">Live kitchen</span> in Türkiye and feed our Syrian brothers and sisters
              </p>
              <p className="text-[#78716C] md:text-lg text-center font-montserratMedium">
                Watch the preparation of wholesome meals in Türkiye LIVE. Experience the true spirit of giving by witnessing the direct impact of your
                gift.
              </p>
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      <section className="py-8 md:py-12">
        <MaxWidthContainer>
          <div className="mb-5 md:mb-[30px] flex flex-col justify-center items-center gap-[10px] md:gap-4">
            <p className="flex items-center gap-[5px] py-2 px-[10px] md:px-5 rounded-full bg-[#F60362] montserratMedium text-sm md:text-base text-white uppercase">
              <span className="h-[20px] w-[20px] flex justify-center items-center">
                <span className="bg-white h-1 w-1 rounded-full"></span>
              </span>
              LIVE FROM TÜRKIYE
            </p>
            {/* <div className="flex items-center gap-1">
              <img src={userIcon} alt="users" className="w-[14px] h-[14px] md:w-[24px] md:h-[24px]" />
              <span className="text-[#78716C] text-sm md:text-base montserratMedium">576 views</span>
            </div> */}
            <div className="flex flex-col justify-center gap-1 items-center text-[#78716C] md:text-lg text-center font-montserratMedium">
              <p>Cooking time 9:30 to 3:00 (Turkiye time)</p>
              <p>Iftar Meal Distribution time 4:00 to 5:00 (Turkiye time)</p>
            </div>
          </div>
          <div className="relative overflow-hidden flex flex-wrap">
            <YoutubeVideoHero
              src={`https://www.youtube.com/embed/00OmB7w0hLM?controls=0&autoplay=1&loop=1&mute=1&playlist=00OmB7w0hLM`}
              videoStyle={videoStyleIftar}
            />
          </div>
        </MaxWidthContainer>
      </section>
      {/* <section className="bg-[#EAEAEA] py-[30px] md:py-[50px]">
        <MaxWidthContainer>
          <div className="flex justify-center items-center">
            <div className="flex flex-col lg:flex-row items-start justify-center gap-3">
              <Link
                to="/"
                className="flex items-center gap-[5px] py-2 px-[10px] md:px-5 rounded-lg bg-[#F60362] montserratMedium text-sm md:text-base text-white uppercase"
              >
                <span className="h-[20px] w-[20px] flex justify-center items-center">
                  <span className="bg-white h-1 w-1 rounded-full"></span>
                </span>
                FAMILIES FED
              </Link>
              <div className="flex gap-3 items-end">
                <p className="text-[80px] leading-[1] md:text-[200px] font-montserratBold text-[#253B7E]">576</p>
                <p className="text-[#253B7E] text-lg md:text-[28px] uppercase">meals provided</p>
              </div>
            </div>
          </div>
        </MaxWidthContainer>
      </section> */}
      {/* Products */}
      <section>
        <HeroTab
          products={[mealsProducts]}
          title="Support our Syrian brothers and sisters in Şanlıurfa, Türkiye, with a gift of chicken and rice meals"
        />
      </section>
      {/* Our Promise */}
      <OurPromiseSection />
      {/* /* --------------------------------- Section Images Slider --------------------------------- */}
      <section className="bg-[#F1F1F1] py-[30px] md:py-[50px]">
        <MaxWidthContainer>
          <div ref={sliderRefImages} className="keen-slider">
            {images.map((item, index) => {
              return (
                <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-[10px] overflow-hidden`}>
                  {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                  <img src={item.src} alt={item.alt} className="rounded-[10px] bg-top object-cover w-full object-top" />
                </div>
              );
            })}
          </div>
          <div className="w-full flex justify-center">
            {loaded && instanceRefImages.current && (
              <div className="dots flex mt-[10px] md:[mt-20px]">
                {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRefImages.current?.moveToIdx(idx);
                      }}
                      className={
                        'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                        (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                      }
                    ></button>
                  );
                })}
              </div>
            )}
          </div>
        </MaxWidthContainer>
      </section>
      {/* Section Invite*/}
      <section className="lg:py-12 bg-[#DFEEF2]">
        <MaxWidthContainer>
          <div className="flex flex-col lg:flex-row gap-4 md:gap-8 items-center justfy-between">
            <div className="lg:basis-[600px] flex flex-col gap-4 md:gap-5 py-5 md:py-12 lg:py-0">
              <p className="text-[25px] leading-7 md:text-[40px] md:leading-[42px]  text-[#093484] font-montserratBold">
                <span className="text-[#F60362]">Invite your friends and family</span> to feed a family in Şanlıurfa, Türkiye
              </p>
              <p className="text-[#78716C] font-montserratMedium md:text-lg">
                Share this with your loved ones to magnify your blessings. Encourage them to experience the profound joy and rewards of helping
                others, and together.
              </p>
            </div>
            <div className="hidden flex-col gap-8 items-center flex-auto">
              <img src={imgPaperPlane} alt="share" className="md:w-[300px]" />
              <div className="flex flex-col gap-3 items-center">
                <Link
                  to="/"
                  className="flex gap-3 items-center text-sm md:text-base font-montserratMedium uppercase py-2 px-6 md:py-[14px] md:px-8 bg-[#00A3DA] text-white rounded-[6px] md:w-[320px]"
                >
                  Share on Whatsapp
                  <img src={imgWhatsappIcon} alt="whatsapp" />
                </Link>
                <Link
                  to="/"
                  className="flex gap-3 items-center text-sm md:text-base font-montserratMedium uppercase py-2 px-6 md:py-[14px] md:px-8 bg-[#00A3DA] text-white rounded-[6px] md:w-[320px]"
                >
                  Share on your Stories
                  <img src={imgInstaIcon} alt="instagram" />
                </Link>
              </div>
            </div>
          </div>
        </MaxWidthContainer>
        <div className="hidden flex- items-start mt-9 gap-0 md:gap-6">
          <img src={imgPaperPlaneMobile} alt="share" className="md:hidden" />
          <img src={imgPaperPlaneTab} alt="share" className="hidden md:block md:w-[300px]" />
          <div className="flex flex-col gap-3 pb-9 md:pb-0 pr-3">
            <Link
              to="/"
              className="flex gap-3 items-center text-[13px] md:text-base font-montserratMedium uppercase py-2 px-6 md:py-[14px] md:px-8 bg-[#00A3DA] text-white rounded-[6px] md:w-[320px]"
            >
              Share on Whatsapp
              <img src={imgWhatsappIcon} alt="whatsapp" />
            </Link>
            <Link
              to="/"
              className="flex gap-3 items-center text-[13px] md:text-base font-montserratMedium uppercase py-2 px-6 md:py-[14px] md:px-8 bg-[#00A3DA] text-white rounded-[6px] md:w-[320px]"
            >
              Share on your Stories
              <img src={imgInstaIcon} alt="instagram" />
            </Link>
          </div>
        </div>
      </section>
      {/* Section Ali Video */}
      <section className="py-[30px] md:py-50px]">
        <MaxWidthContainer>
          <div className="relative overflow-hidden flex flex-wrap">
            <YoutubeVideoHero src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0?feature=oembed`} videoStyle={videoStyle} />
          </div>
          <div className="shadow-[0px_8px_24px_0px_#252A3414] px-5 md:px-[30px] py-5 rounded-[10px] md:rounded-[32px]">
            <div className="flex flex-col items-center gap-3 md:gap-[10px] mt-5">
              <p className="text-[22px] md:text-[40px] leading-8 md:leading-[42px] font-gotcha text-[#1E3271]">
                Ali Banat <span className="text-[#F60362] font-['montserrat'] font-semibold">MATW’s Founder</span>
              </p>
              <p className="font-montserratMedium text-[#78716C]">
                Ali Banat, a self-made entrepreneur from Sydney, Australia, had achieved remarkable success by the age of 29, indulging in a life of
                luxury and sharing his success generously with those around him. However, his life took a transformative turn upon receiving a
                terminal diagnosis. Viewing this moment not as an end but as an opportunity for profound impact, Ali made the bold decision to sell
                all his possessions. He embarked on a journey to Togo, where he founded the Muslims Around The World Project in 2016. What began as
                Ali's personal mission to make a difference has since evolved into a global legacy, reaching across more than 20 countries and
                profoundly touching millions of lives. And now we continue his legacy as we build our own.
              </p>
            </div>
            <div className="flex justify-center items-center mt-5 md:mt-[30px]">
              <CustomButton link="/ali-banat" title="Learn more about Ali Banat" />
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};

export default LiveMeals;

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFound from './pages/other/notFound';
import CheckoutPage from './pages/checkout';
import SuccessPage from './pages/other/success';
import ErrorPage from './pages/other/error';
import ReceiptPage from './pages/receipt/receipt';
import AllAppeals from './pages/other/allAppeals';
// import HomePage from './pages/home';
import SubCategory from './pages/products/subCategory';
import Products from './pages/products/products';
import RouteContext from './context/route';
// import ZakatCalculator from './pages/zakat/zakatCalculator';
import AliBanatPage from './pages/other/aliBanat';
import FAQsPage from './pages/other/faq';
import PoliciesPage from './pages/other/policies';
import SocialMedia from './pages/other/socialMedia';
import GDPR from './pages/other/gdpr';
import PrivacyPolicyPage from './pages/other/privacyPolicy';
import AnnualReports from './pages/other/annualReports';
import ScrollToTop from './utils/ScrollToTop';
import Contact from './pages/contact';
import Live from './pages/other/Live';
import AllProducts from './pages/allProducts';
import AppPage from './pages/other/app';
import PurposePage from './pages/other/purpose';
import CancelPage from './pages/other/cancel';
import GetReceiptPage from './pages/receipt/getReceipt';
import OptOutPage from './pages/other/opt-out';
import ParticipantPage from './pages/other/participantRegistration';
// import FundraisingPage from './pages/fundraising';
import QurbanMostPopularPage from './pages/qurban/qurban-mostPopular';
import SadaqahMostPopularPage from './pages/qurban/sadaqah-mostPopular';
import SheepsPage from './pages/qurban/sheeps';
import CowsPage from './pages/qurban/cows';
import ZakatLandingPage from './pages/zakat/zakat-landing';
import DashboardPage from './pages/dashboard';
import QuickDonatePage from './pages/other/quick-donate';
import FoodAndWaterLandingPage from './pages/foodAndWater/index';
// import WaterAidLandingPage from './pages/foodAndWater/water-aid';
// import FoodAidLandingPage from './pages/foodAndWater/food-aid';
import LoginPage from './pages/dashboard/pages/login';
import OneBillionLegacyPage from './pages/one-billion';
import MorningTeaPage from './pages/events/morning-tea';
import LiveFundraiserPage from './pages/events/live-fundraiser';
import YasminMogahedIndonesiaPage from './pages/events/yasmin-indonesia';
import YasminMogahedMalaysiaPage from './pages/events/yasmin-malaysia';
import YasminMogahedSingaporePage from './pages/events/yasmin-singapore';
import AsmaaAndBelalPage from './pages/events/asmaa-and-belal';
import VIPPage from './pages/vip';
import NewVolunteerPage from './pages/volunteer';
// import OrphanFeastLandingPage from './pages/orphans/orphan-feast';
import OrphanEducationLandingPage from './pages/orphans/orphan-education';
import OrphanSupportLandingPage from './pages/orphans/orphan-support';
import MoroccoEmergencyLandingPage from './pages/crisis-and-emergencies/morocco-emergency';
import EarthquakeAppealPage from './pages/crisis-and-emergencies/earthquake-appeal';
// import LibyaEmergencyLandingPage from './pages/crisis-and-emergencies/libya-emergency';
import ZakatWidgetEmbedded from './pages/zakat/zakatWidgetEmbedded';
import OrphanHomesLandingPage from './pages/sadaqah-jariyah/orphan-homes';
// import OrphansLandingPage from './pages/orphans';
import YasirandnoumanPage from './pages/events/yasir';
// import BuildWaterWellLandingPage from './pages/sadaqah-jariyah/build-water-well';
import SustainableLandingPage from './pages/sadaqah-jariyah/sustainable';
import OrphanagesLandingPage from './pages/sadaqah-jariyah/orphanages';
// import MasjidsLandingPage from './pages/sadaqah-jariyah/masjids';
import EmergencyLandingPage from './pages/sadaqah-jariyah/emergency';
// import SadaqahJariyahLandingPage from './pages/sadaqah-jariyah';
import YasirandnoumanMalaysiaPage from './pages/events/yasir-malaysia';
import NoumanalikhanLandingPage from './pages/events/noumanalikhan';
import MakeADonationLandingPage from './pages/donate-now/make-a-donation';
// import UKHome from './pages/ukHome';
// import PalestineNewLandingPage from './pages/crisis-and-emergencies/palestineNew';
import PalestineCampaignLandingPage from './pages/crisis-and-emergencies/palestine-campaign';
import NoumanalikhanMalaysiaLandingPage from './pages/events/noumanalikhan-malaysia';
import LoveOnesLandingPage from './pages/loved-ones-legacy';
import UkModal from './components/general/UkModal';
import WinterLandingPage from './pages/winter';
import SpecialCasesLandingPage from './pages/special-cases';
import CallCenter from './pages/call-center';
import PalestineResponseLandingPage from './pages/crisis-and-emergencies/palestine-response';
import EmergencyResponseLandingPage from './pages/crisis-and-emergencies/emergency-response';
import MuftiMenkLandingPage from './pages/events/muftimenk';
// import ProjectNoorLandingPage from './pages/sadaqah-jariyah/project-noor-new';
import DonorReceipts from './pages/receipt/donorReceipts';
import USAPage from './pages/ukHome/usa-home';
// import Events from './pages/events';
import EventDetails from './pages/events/eventDetails';
import YasminmogahedLandingPage from './pages/events/yasminmogahed';
import GlobalPage from './pages/global';
import USAGeneralDonation from './pages/usa/usaGeneralDonation';
import SubscriptionLandingPage from './pages/subscription/index';
import PalestineArabic from './pages/crisis-and-emergencies/palestineArabic';
import { useSelector } from 'react-redux';
import { selectSelectedCountry } from './store/user';
import { useEffect } from 'react';
import DetailsProduct from './pages/products/details';
// import ZakatPreRamadan from './pages/zakat/zakatPreRamadan';
import FidyaKaffarah from './pages/fidya-kaffarah/fidyaKaffarah';
import PalestineWeekly from './pages/crisis-and-emergencies/palestine-weekly';
import YasminmogahedAustraliaPage from './pages/events/yasminmogahed-australia';
import UpTimeStatus from './pages/other/upTimeStatus';
import ZakatAlFitr from './pages/zakat/zakatAlFitr';
import MasjidsNewLandingPage from './pages/sadaqah-jariyah/masjidsNew';
import ProjectNoorUAT from './pages/sadaqah-jariyah/projectNoorUAT';
import FidyaKaffarahUat from './pages/fidya-kaffarah/fidyaKaffarahUat';
import FoodAidUATLandingPage from './pages/foodAndWater/food-aid-UAT';
import FundraisingLandingPageUAT from './pages/fundraising/index-uat';
import HealthLandingPage from './pages/sadaqah-jariyah/health';
import OurPromise from './pages/other/ourPromise';
import ZakatCalculatorUATLandingPage from './pages/zakat/zakatCalculatorUAT';
import SadaqahjariahUat from './pages/sadaqah-jariyah/sadaqahJariahUat';
import OrphansUat from './pages/orphans/orphansUat';
import BuildWaterWellUat from './pages/sadaqah-jariyah/buildWaterWellUat';
import OrphansFeastUat from './pages/orphans/orphansFeastUat';
import WaterAidUATLandingPage from './pages/foodAndWater/water-aid-uat';
import VolunteerUATPage from './pages/volunteer/volunteer-uat';
import ZakatUat from './pages/zakat/zakatUat';
import KaffarahLandingPage from './pages/fidya-kaffarah/kaffarah';
import FidyaLandingPage from './pages/fidya-kaffarah/fidya';
import HundredDonationsPolicy from './pages/other/hundredDonationsPolicy';
import Fitrana from './pages/fitrana';
import SentCryptoPage from './pages/crypto/sentCrypto';
import CancelCryptoPage from './pages/crypto/cancelCrypto';
import DonateToPalestine from './pages/crisis-and-emergencies/donateToPalestine';
import RamadanDua from './pages/ramadan/ramadanDua';
import ZakatOnGold from './pages/zakat/zakatOnGold';
import DuaForBreakingFast from './pages/ramadan/duaForBreakingFast';
import SponsorAnOrphan from './pages/orphans/sponsorAnOrphan';
import WhenIsEid from './pages/ramadan/whenIsEid';
import Aqiqah from './pages/sadaqah-jariyah/aqiqah';
import OliveTreeSadaqah from './pages/sadaqah-jariyah/ oliveTreeSadaqah';
import SadaqahjariahForDeceased from './pages/sadaqah-jariyah/SadaqahjariahForDeceased';
import GiftGiving from './pages/giftGiving';
// import PalestineUat from './pages/crisis-and-emergencies/palestineUat';
import SadaqahAndLillah from './pages/sadaqah-jariyah/sadaqahAndLillah';
import DuaForPalestine from './pages/crisis-and-emergencies/duaForPalestine';
import DuaForParents from './pages/sadaqah-jariyah/duaForParents';
import NewsPage from './pages/newsPage';
import Nisab from './pages/zakat/nisab';
// import LaylatulQadr from './pages/ramadan/laylatulQadr';
import CampaignTiktok from './pages/other/campaignTiktok';
import RamadanLandingPage from './pages/ramadan';
import HomePageUAT from './pages/home/index-UAT';
// import PalestineUatu from './pages/crisis-and-emergencies/palestine';
import PalestineFamineLandingPage from './pages/crisis-and-emergencies/palestineFamine';
// import Last10NightsLandingPage from './pages/ramadan/last10Nights';
import GiftOfSoundLandingPage from './pages/sadaqah-jariyah/giftOfSound';
import PalestineLandingPage from './pages/crisis-and-emergencies/palestine';
import LaylaTulQadrLandingPage from './pages/ramadan/laylatulQadar';
// import LastTenNightsLandingPage from './pages/ramadan/lastTenNights/lastTenNights';
import PhishingLandingPage from './pages/other/phishing';
import LaylatulQadr2024LandingPage from './pages/ramadan/laylatulQadr2024';
import AdminFeesPage from './pages/other/adminFees';
import SubscriptionABLandingPage from './pages/subscription-ab';
import LiveMeals from './pages/other/live-meals';
import PalestineRefugeeLandingPage from './pages/crisis-and-emergencies/palestineRefugeeSettlement';
import EidAlFitr2024 from './pages/eidAlFitr2024';
import EidMubarak2024 from './pages/eidAlFitr2024/eidMubarak2024';
import SaveOrphansLandingPage from './pages/crisis-and-emergencies/save-orphans';
import ChildrenInGazaLandingPage from './pages/crisis-and-emergencies/childrenInGaza';

import TopAppeals from './pages/topAppeals';
import DonorLogin from './pages/donor/pages/login';
import DonorPage from './pages/donor';
import VerifyOTP from './pages/donor/pages/verifyOTP';

import BelalAssadApril from './pages/events/belalassad-april';
import Sadaqah from './pages/sadaqah-jariyah/sadaqah';
// import QurbanLandingPage from './pages/qurban';
import QurbaniLandingPage from './pages/qurban/qurbani';
import QurbanMainLandingPage from './pages/qurban/qurban-main';
import Qurbani2024LandingPage from './pages/qurban/qurbani-2024';
import DonateToGaza from './pages/crisis-and-emergencies/donateToGaza';
import DetailsPalestineProduct from './pages/products/detailsPalestine';
import DhulHijjah2024LandingPage from './pages/qurban/dhul-hijjah-2024';
import EidAlAdha2024LandingPage from './pages/qurban/eid-al-adha-2024';
import KurbanBayramiLandingPage from './pages/qurban/kurban-bayrami-2024';
import AfghanistanLandingPageUAT from './pages/crisis-and-emergencies/afghanistan-uat';
import CheapestQurbani2024LandingPage from './pages/qurban/cheapest-qurbani-2024';
import WidgetEmbedded from './pages/other/embedWidget/widgetEmbedded';
import PalestineAiaPartnershipLandingPage from './pages/crisis-and-emergencies/palestine-aia-partnership';
import MajedMahmoudLandingPage from './pages/events/majedMahmoud';
import QurbanPalestineLandingPage from './pages/qurban/qurban-palestine';
import PalestineArabicLandingPage from './pages/crisis-and-emergencies/palestine-ar';
import UltimateQurbanLandingPage from './pages/qurban/qurban-ultimate';
import OrphansAiaSponsorshipLandingPage from './pages/crisis-and-emergencies/orphans-aia-sponsorshop';
import QurbanDhulHijjahLandingPage from './pages/qurban/qurban-dhul-hijjah';
import QurbanDayOfArafatLandingPage from './pages/qurban/qurban-day-of-arafat';
import DoubleTheDonationPage from './pages/double-the-donation';
import QurbaniDonationLandingPage from './pages/qurban/qurbani-donation';
import PalastineMedicalRelief from './pages/medicalforPalestine/palestine-medical';
import Top10Products from './pages/other/top10Products';
// import USACanadaLandingPage from './pages/events/aliBanatUsa';
import QurbanEidAlAdhaLandingPage from './pages/qurban/qurban-eid-al-adha';
import AppealLandingPage from './pages/appeal';
import AliBanatUSALandingPage from './pages/events/aliBanatUsa';
import AliBanatCADLandingPage from './pages/events/aliBanatCad';
import MuharramLandingPage from './pages/muharram';
import MATWAppealDetailsLandingPage from './pages/MATWAppeal/details';
import MATWAppealLandingPage from './pages/MATWAppeal';
import EventLandingPage from './pages/events/eventPage';
import ChildrenOfGaza from './pages/childrenOfGaza';
import BangladeshAppeal from './pages/crisis-and-emergencies/bangladesh-appeal';
import CountriesInCrisisLandingPage from './pages/crisis-and-emergencies/countriesInCrisis';
import LimbsOfHopeLandingPage from './pages/other/limbsOfHope';
import GazaAppealLandingPage from './pages/crisis-and-emergencies/gaza-appeal';
import LebanonAppealLandingPage from './pages/crisis-and-emergencies/lebanon-appeal';
import PalestineImpact from './pages/crisis-and-emergencies/palestine-impact';
import MatwAppeals from './pages/other/matw-appeals';
import PalestineEmergencyAppealLandingPage from './pages/crisis-and-emergencies/palestine-emergency-appeal';
// import FoodAndWaterUATLandingPage from './pages/foodAndWater';

let startTime = null; // Variable to store the start time

const AllRoutes = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [routeState, setRouteState] = useState([{ name: 'MATW Home', id: '/' }]);
  const [showModal, setShowModal] = useState(false);
  const isAlreadyShown = JSON.parse(localStorage.getItem('isModalShown')) || false;
  const country = useSelector(selectSelectedCountry);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem('isModalShown', 'true');
  };
  const checkDayAndShowDialog = () => {
    if (window.location.hostname === 'matwproject.org.uk') {
      return true;
    } else {
      return false;
    }
  };
  // Function to track scrolling and calculate scroll distance
  const trackScroll = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    if (dayOfWeek !== 5) localStorage.setItem('isModalShown', 'false');
    if (dayOfWeek === 5 && checkDayAndShowDialog() && country === 'uk') {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const mints = new Date(elapsedTime).getMinutes();
      const secs = new Date(elapsedTime).getSeconds();
      if (secs > 20 || mints > 0) {
        setShowModal(true);
        window.removeEventListener('scroll', trackScroll);
      }
    } else {
      window.removeEventListener('scroll', trackScroll);
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    startTime = Date.now();
    checkDayAndShowDialog(); //only for Uk domain
    window.addEventListener('scroll', trackScroll);
    return () => {
      window.removeEventListener('scroll', trackScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
  /* -------------------------------------------------------------------------- */

  const getHomePage = () => {
    let component = '';
    switch (window.location.hostname) {
      case 'matwproject.org.uk':
        // component = <UKHome />;
        component = <HomePageUAT />;
        break;
      case 'matwprojectusa.org':
        // component = <USAPage country={"usa"}/>;
        component = <HomePageUAT />;
        break;
      case 'localhost':
        // component = <UKHome />;
        // component =<USAPage country={"usa"}/>;
        component = <HomePageUAT />;
        break;
      default:
        component = <HomePageUAT />;
        break;
    }
    return component;
  };

  return (
    <RouteContext.Provider value={{ routeState, setRouteState }}>
      {showModal && !isAlreadyShown && <UkModal closeModal={closeModal} />}
      <Routes>
        <Route path="/" element={<HomePageUAT />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/donor/login" element={<DonorLogin />} />
        <Route path="/donor/verify-otp" element={<VerifyOTP />} />
        <Route path="/usa-general-donation" element={<USAGeneralDonation />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/all-products" element={<AllProducts />} />
        <Route path="/all-appeals" element={<AllAppeals />} />
        <Route path="/participant-registration" element={<ParticipantPage />} />
        <Route path="/fundraising" element={<FundraisingLandingPageUAT />} />
        {/* <Route path="/sub-categories" element={<SubCategory />} /> */}
        {/* <Route path="/products" element={<Products />} /> */}
        <Route path="/qurban/sheep" element={<SheepsPage />} />
        <Route path="/qurban/cow" element={<CowsPage />} />
        <Route path="/qurban-most-popular" element={<QurbanMostPopularPage />} />
        <Route path="/sadaqah-most-popular" element={<SadaqahMostPopularPage />} />
        <Route path="/zakat-calculator" element={<ZakatCalculatorUATLandingPage />} />
        <Route path="/nisab" element={<Nisab />} />
        <Route path="/zakat-landing" element={<ZakatLandingPage />} />
        <Route path="/zakat" element={<ZakatUat />} />
        <Route path="/zakat-on-gold" element={<ZakatOnGold />} />
        <Route path="/zakat/zakat-al-fitr" element={<ZakatAlFitr />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/ali-banat" element={<AliBanatPage />} />
        <Route path="/purpose" element={<PurposePage />} />
        <Route path="/gdpr" element={<GDPR />} />
        <Route path="/faq" element={<FAQsPage />} />
        <Route path="/opt-out" element={<OptOutPage />} />
        <Route path="/cancel" element={<CancelPage />} />
        <Route path="/get-receipt" element={<GetReceiptPage />} />
        <Route path="/social-media" element={<SocialMedia />} />
        <Route path="/annual-reports" element={<AnnualReports />} />
        <Route path="/policies" element={<PoliciesPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
        <Route path="/volunteer" element={<NewVolunteerPage />} />
        <Route path="/receipt" element={<ReceiptPage />} />
        <Route path="/app" element={<AppPage />} />
        <Route path="/global" element={<GlobalPage />} />
        <Route path="/quick-donate" element={<QuickDonatePage />} />
        <Route path="/food-and-water-aid" element={<FoodAndWaterLandingPage />} />
        <Route path="/food-and-water-aid/water-aid" element={<WaterAidUATLandingPage />} />
        <Route path="/food-and-water-aid/food-aid" element={<FoodAidUATLandingPage />} />
        <Route path="/the-1-billion-legacy" element={<OneBillionLegacyPage />} />
        <Route path="/vip" element={<VIPPage />} />
        <Route path="/call-center" element={<CallCenter />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/donate-to-palestine" exact element={<DonateToPalestine />} />
        <Route path="/food-and-water-aid-fr" element={<FoodAndWaterLandingPage lang={'fr'} />} />
        <Route path="/food-and-water-aid-ar" element={<FoodAndWaterLandingPage lang={'ar'} />} />
        <Route path="/donate-to-gaza" exact element={<DonateToGaza />} />
        <Route path="/donate-now/make-a-donation" element={<MakeADonationLandingPage />} />
        <Route path="/loved-ones-legacy" element={<LoveOnesLandingPage />} />
        <Route path="/winter" element={<WinterLandingPage />} />
        <Route path="/live" element={<Live />} />
        <Route path="/donor-receipts" element={<DonorReceipts />} />
        <Route path="/subscription" element={<SubscriptionLandingPage />} />
        <Route path="/subscription-regular-giving" element={<SubscriptionABLandingPage />} />
        <Route path="/ramadan" element={<RamadanLandingPage />} />
        <Route path="/ramadan/laylatul-qadr" element={<LaylaTulQadrLandingPage />} />
        <Route path="/orphans-fr" element={<OrphansUat lang={'fr'} />} />
        <Route path="/orphans-ar" element={<OrphansUat lang={'ar'} />} />
        <Route path="/palestine-campaign-3-ar" element={<PalestineArabic />} />
        <Route path="/ramadan/fidya-kaffarah" element={<FidyaKaffarah />} />
        <Route path="/kaffarah" element={<KaffarahLandingPage />} />
        <Route path="/fidya" element={<FidyaLandingPage />} />
        <Route path="/uptime-status" element={<UpTimeStatus />} />
        <Route path="/singapore" element={<USAPage country={'singapore'} />} />
        <Route path="/our-promise" element={<OurPromise />} />
        <Route path="/processing-crypto" element={<SentCryptoPage />} />
        <Route path="/cancel-crypto" element={<CancelCryptoPage />} />
        <Route path="/aqiqah" element={<Aqiqah />} />
        <Route path="/our-promise" element={<OurPromise />} />
        <Route path="/100-donation-policy" element={<HundredDonationsPolicy />} />
        <Route path="/dua-for-breaking-fast" element={<DuaForBreakingFast />} />
        <Route path="/difference-between-sadaqah-and-lillah" element={<SadaqahAndLillah />} />
        <Route path="/dua-for-palestine" element={<DuaForPalestine />} />
        <Route path="/dua-for-parents" element={<DuaForParents />} />
        <Route path="/when-is-eid-2024" element={<WhenIsEid />} />
        <Route path="/ramadan-dua" element={<RamadanDua />} />
        <Route path="/gift-giving" element={<GiftGiving />} />
        <Route path="/fitrana" element={<Fitrana />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/muharram" element={<MuharramLandingPage type={'main'} />} />
        <Route path="/muharram-2024" element={<MuharramLandingPage type={'duplicated'} />} />
        <Route path="/events/live-fundraiser" element={<LiveFundraiserPage />} />
        <Route path="/events/morningtea" element={<MorningTeaPage />} />
        <Route path="/events/yasminmogahed" element={<YasminmogahedLandingPage />} exact />
        <Route path="/events/yasminmogahed-australia-feb" element={<YasminmogahedAustraliaPage />} exact />
        <Route path="/events/qasimrafique-australia-feb" element={<YasminmogahedAustraliaPage />} exact />
        <Route path="/events/alihammuda-au-tour" element={<EventLandingPage type="alihamud" />} exact />
        <Route path="/events/iftardinner-march" element={<EventLandingPage type="iftar" />} exact />
        <Route path="/events/belal-assaad-south-africa" element={<EventLandingPage type="belal" />} exact />
        <Route path="/events/shaun-king-malaysia" element={<EventLandingPage type="shaun" />} exact />
        <Route path="/events/yasmin-mogahed-malaysia-november" element={<EventLandingPage type="yasmin" />} />
        <Route path="/events/doctor-rania-malaysia-november" element={<EventLandingPage type="rania" />} />
        <Route path="/events/mustafa-briggs-november" element={<EventLandingPage type="mustafa" />} />
        <Route path="/events/muslim-family-fest-november" element={<EventLandingPage type="muslim" />} />
        <Route path="/events/abdul-wahab" element={<EventLandingPage type="abdulwahab" />} exact />
        <Route path="/events/majedmahmoud-qurban" element={<MajedMahmoudLandingPage />} exact />
        <Route path="/events/yasminmogahedindonesia" element={<YasminMogahedIndonesiaPage />} exact />
        <Route path="/events/yasminmogahedmalaysia" element={<YasminMogahedMalaysiaPage />} exact />
        <Route path="/events/yasminmogahedsingapore" element={<YasminMogahedSingaporePage />} exact />
        <Route path="/events/asmaaandbelal" element={<AsmaaAndBelalPage />} exact />
        <Route path="/events/yasirandnouman" element={<YasirandnoumanPage />} exact />
        <Route path="/events/yasirandnoumanmalaysia" element={<YasirandnoumanMalaysiaPage />} exact />
        <Route path="/events/noumanalikhan" element={<NoumanalikhanLandingPage />} exact />
        <Route path="/events/muftimenk" element={<MuftiMenkLandingPage />} exact />
        <Route path="/events/noumanalikhan/malaysia" element={<NoumanalikhanMalaysiaLandingPage />} exact />
        <Route path="/events/belalassad-april2024" element={<BelalAssadApril />} exact />
        <Route path="/events/alibanatlegacy" element={<AliBanatUSALandingPage />} exact />
        <Route path="/events/alibanatlegacyca" element={<AliBanatCADLandingPage />} exact />
        <Route path="/sadaqah-jariyah" element={<SadaqahjariahUat />} />
        <Route path="/sadaqah" element={<Sadaqah />} />
        <Route path="/sadaqah-jariyah-for-deceased" element={<SadaqahjariahForDeceased />} />
        <Route path="/sadaqah-jariyah/project-noor" element={<ProjectNoorUAT />} />
        <Route path="/sadaqah-jariyah/orphan-homes" element={<OrphanHomesLandingPage />} />
        <Route path="/sadaqah-jariyah/build-a-water-well" element={<BuildWaterWellUat />} />
        <Route path="/olive-tree-sadaqah" element={<OliveTreeSadaqah />} />
        <Route path="/sadaqah-jariyah/sustainable" element={<SustainableLandingPage />} />
        <Route path="/sadaqah-jariyah/orphans" element={<OrphanagesLandingPage />} />
        <Route path="/sadaqah-jariyah/build-a-masjid" element={<MasjidsNewLandingPage type="buildAMasjid" />} />
        <Route path="/donation-for-masjid" element={<MasjidsNewLandingPage type="donationForMasjid" />} />
        <Route path="/sadaqah-jariyah/emergency" element={<EmergencyLandingPage />} />
        <Route path="/sadaqah-jariyah/health" element={<HealthLandingPage />} />
        <Route path="/orphans" element={<OrphansUat />} />
        <Route path="/orphans-fr" element={<OrphansUat lang={'fr'} />} />
        <Route path="/orphans-ar" element={<OrphansUat lang={'ar'} />} />
        <Route path="/orphans/orphan-feast" element={<OrphansFeastUat />} />
        <Route path="/orphans/education" element={<OrphanEducationLandingPage />} />
        <Route path="/orphans/support-an-orphan" element={<OrphanSupportLandingPage />} />
        <Route path="/sponsor-an-orphan" element={<SponsorAnOrphan />} />
        <Route path="/orphans/orphan-widget" element={<ZakatWidgetEmbedded />} />
        <Route path="/crisis-and-emergencies/palestine" exact element={<PalestineLandingPage />} />
        <Route path="/palestine-emergency-appeal" exact element={<PalestineEmergencyAppealLandingPage />} />
        <Route path="/crisis-and-emergencies/palestine-famine" exact element={<PalestineFamineLandingPage lang={'en'} />} />
        <Route path="/palestine-famine-ar" exact element={<PalestineFamineLandingPage lang={'ar'} />} />
        <Route path="/palestine-famine-fr" exact element={<PalestineFamineLandingPage lang={'fr'} />} />
        <Route path="/crisis-and-emergencies/palestine-subscription" exact element={<SubscriptionLandingPage />} />
        <Route path="/crisis-and-emergencies/palestine-campaign" exact element={<PalestineCampaignLandingPage />} />
        <Route path="/crisis-and-emergencies/palestine-response" exact element={<PalestineResponseLandingPage />} />
        <Route path="/crisis-and-emergencies/emergency-response" exact element={<EmergencyResponseLandingPage />} />
        <Route path="/crisis-and-emergencies/morocco" exact element={<MoroccoEmergencyLandingPage />} />
        <Route path="/crisis-and-emergencies/libya-floods" exact element={<Navigate to="/crisis-and-emergencies" replace />} />
        <Route path="/crisis-and-emergencies/earthquake-appeal" exact element={<EarthquakeAppealPage />} />
        <Route path="/crisis-and-emergencies/palestine-weekly-au" exact element={<PalestineWeekly country={'au'} />} />
        <Route path="/crisis-and-emergencies/palestine-weekly-usa" exact element={<PalestineWeekly country={'usa'} />} />
        <Route path="/crisis-and-emergencies/palestine-weekly-uk" exact element={<PalestineWeekly country={'uk'} />} />
        <Route path="/crisis-and-emergencies/palestine-weekly-global" exact element={<PalestineWeekly country={'global'} />} />
        <Route path="/crisis-and-emergencies/save-orphans" exact element={<SaveOrphansLandingPage lang={'en'} />} />
        <Route path="/crisis-and-emergencies/palestine-refugee-settlement" element={<PalestineRefugeeLandingPage lang={'en'} type="RefusseP" />} />
        <Route path="/crisis-and-emergencies/bangladesh-appeal" element={<BangladeshAppeal />} />
        <Route path="/crisis-and-emergencies/countries-in-crisis" element={<CountriesInCrisisLandingPage />} />
        <Route path="/medical-aid-for-palestinians" element={<PalastineMedicalRelief />} />
        <Route path="/palestinian-refugees-egypt" element={<PalestineRefugeeLandingPage lang={'en'} />} />
        <Route path="/palestine-refugee-settlement-ar" element={<PalestineRefugeeLandingPage lang={'ar'} />} />
        <Route path="/palestine-refugee-settlement-fr" element={<PalestineRefugeeLandingPage lang={'fr'} />} />
        <Route path="/save-palestine-orphans-ar" exact element={<SaveOrphansLandingPage lang={'ar'} />} />
        <Route path="/save-palestine-orphans-fr" exact element={<SaveOrphansLandingPage lang={'fr'} />} />
        <Route path="/palestine-fr" exact element={<PalestineLandingPage _lang={'fr'} />} />
        <Route path="/palestine-ar" exact element={<PalestineArabicLandingPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/donor" element={<DonorPage />} />
        <Route path="/dashboard/*" element={<DashboardPage />} />
        <Route path="/donor/*" element={<DonorPage />} />
        <Route path="/undefined/*" element={<HomePageUAT />} />
        <Route path="/events/:id" element={<EventDetails />} />
        <Route path="/appeals/:id" element={<DetailsProduct />} />
        <Route path="/appeals/palestine/:id" element={<DetailsPalestineProduct />} />
        {/* <Route path="/:name" element={<SubCategory />} /> */}
        <Route path="/:name" element={<AppealLandingPage />} />
        <Route path="/:name/:product" element={<Products />} />
        <Route path="/ramadan/fidya-kaffarah-uat" element={<FidyaKaffarahUat />} />
        <Route path="/ramadan/last-10-nights" element={<LaylaTulQadrLandingPage />} />
        <Route path="/laylatul-qadr-2024" element={<LaylatulQadr2024LandingPage />} />
        <Route path="/volunteer-uat" element={<VolunteerUATPage />} />
        <Route path="/sadaqah-jariyah-uat" element={<SadaqahjariahUat />} />
        <Route path="/sadaqah-jariyah/build-a-water-well-uat" element={<BuildWaterWellUat />} />
        <Route path="/sadaqah-jariyah/gift-of-sound" element={<GiftOfSoundLandingPage />} />
        <Route path="/campaign-tt" element={<CampaignTiktok />} />
        <Route path="/phishing-scams" element={<PhishingLandingPage />} />
        <Route path="/admin-fee" element={<AdminFeesPage />} />
        <Route path="/live-meals" element={<LiveMeals />} />
        <Route path="/eid-al-fitr-2024" element={<EidAlFitr2024 />} />
        <Route path="/eid-mubarak-2024" element={<EidMubarak2024 />} />
        <Route path="/top-appeals" element={<TopAppeals />} />
        <Route path="/qurbani-2024" element={<Qurbani2024LandingPage />} />
        <Route path="/qurbani-2023" element={<Navigate to="/qurbani-2024" replace />} />
        <Route path="/qurbani-palestine" element={<Qurbani2024LandingPage country={'Palestine'} />} />
        <Route path="/qurban" element={<QurbanMainLandingPage lang={'en'} title={'Qurban'} />} />
        <Route path="/qurban-ar" element={<QurbanMainLandingPage lang={'ar'} />} />
        <Route path="/qurban-fr" element={<QurbanMainLandingPage lang={'fr'} />} />
        <Route path="/cheapest-qurbani-2024" element={<CheapestQurbani2024LandingPage />} />
        <Route path="/qurbani" element={<QurbanMainLandingPage lang={'en'} title={'Qurbani'} />} />
        <Route path="/qurban/palestine" element={<QurbanPalestineLandingPage title="Qurban" lang={'en'} />} />
        <Route path="/qurbani/palestine" element={<QurbanPalestineLandingPage title="Qurbani" />} />
        <Route path="/qurban/palestine-fr" element={<QurbanPalestineLandingPage title="Qurban" lang={'fr'} />} />
        <Route path="/qurban/palestine-ar" element={<QurbanPalestineLandingPage title="Qurban" lang={'ar'} />} />
        <Route path="/qurbani-donation" element={<QurbaniDonationLandingPage />} />
        <Route path="/qurbani-yemen" element={<Qurbani2024LandingPage country={'Yemen'} />} />
        <Route path="/qurbani-egypt" element={<Qurbani2024LandingPage country={'Egypt'} />} />
        <Route path="/qurbani-africa" element={<Qurbani2024LandingPage country={'Africa'} />} />
        <Route path="/qurbani-syria" element={<Navigate to="/qurbani" replace />} />
        <Route path="/qurbani-afghanistan" element={<Qurbani2024LandingPage country={'Afghanistan'} />} />
        <Route path="/qurbani-jordan" element={<Qurbani2024LandingPage country={'Jordan'} />} />
        <Route path="/qurbani-lebanon" element={<Qurbani2024LandingPage country={'Lebanon'} />} />
        <Route path="/qurbani-bangladesh" element={<Qurbani2024LandingPage country={'Bangladesh'} />} />
        <Route path="/eid-qurban-2024" element={<Qurbani2024LandingPage title={'Qurban'} />} />
        <Route path="/qurban/dhul-hijjah" element={<QurbanDhulHijjahLandingPage />} />
        <Route path="/qurban/ultimate-qurbani" element={<UltimateQurbanLandingPage title="Qurbani" />} />
        <Route path="/qurban/ultimate-qurban" element={<UltimateQurbanLandingPage title="Qurban" lang={'en'} />} />
        <Route path="/qurban/ultimate-qurban-ar" element={<UltimateQurbanLandingPage title="Qurban" lang={'ar'} />} />
        <Route path="/qurban/ultimate-qurban-fr" element={<UltimateQurbanLandingPage title="Qurban" lang={'fr'} />} />
        <Route path="/qurban/day-of-arafat" element={<QurbanDayOfArafatLandingPage title="Qurbani" />} />
        <Route path="/qurban-campaign-1" element={<QurbanMainLandingPage isCampaign title={'Qurban'} />} />
        <Route path="/qurbani-campaign-1" element={<QurbaniLandingPage isCampaign />} />
        <Route path="/kurban-bayrami-2024" element={<KurbanBayramiLandingPage />} />
        <Route path="/eid-al-adha-2024" element={<EidAlAdha2024LandingPage />} />
        <Route path="/dhul-hijjah-2024" element={<DhulHijjah2024LandingPage />} />
        <Route path="/pakistan-qurbani" element={<Qurbani2024LandingPage country={'Pakistan'} />} />
        <Route path="/qurban/eid-al-adha" element={<QurbanEidAlAdhaLandingPage title="Qurban" />} />
        <Route path="/crisis-and-emergencies/afghanistan" element={<AfghanistanLandingPageUAT />} />
        <Route path="/widget" element={<WidgetEmbedded />} />
        <Route path="/palestine-aia-partnership" element={<PalestineAiaPartnershipLandingPage />} />
        <Route path="/orphans-aia-sponsorship" element={<OrphansAiaSponsorshipLandingPage />} />
        <Route path="/doublethedonation" element={<DoubleTheDonationPage />} />
        <Route path="/top-10" element={<Top10Products page="general" />} />
        <Route path="/top-10-palestine" element={<Top10Products page="palestine" />} />
        <Route path="/appeal/:id" element={<AppealLandingPage />} />
        <Route path="/appeals/palestine-emergency" element={<MATWAppealDetailsLandingPage campaign="palestine" />} />
        <Route path="/appeals/gaza-camp" element={<MATWAppealDetailsLandingPage campaign="gaza-camp" />} />
        <Route path="/appeals/palestine-refugee-resettlement" element={<MATWAppealDetailsLandingPage campaign="egyptian" />} />
        <Route path="/appeals/limbs-of-hope" element={<MATWAppealDetailsLandingPage campaign="limbs-of-hope" />} />
        <Route path="/appeals/appeal/limbs-of-hope" element={<MATWAppealLandingPage campaign="limbs-of-hope" />} />
        <Route path="/appeals/appeal/palestine-emergency" element={<MATWAppealLandingPage campaign="palestine" />} />
        <Route path="/appeals/appeal/palestine-refugee-resettlement" element={<MATWAppealLandingPage campaign="egyptian" />} />
        <Route path="/children-of-gaza" element={<ChildrenOfGaza />} />
        <Route path="/limbs-of-hope" element={<LimbsOfHopeLandingPage />} />
        <Route path="/lebanon-appeal" element={<LebanonAppealLandingPage />} />
        <Route path="/crisis-and-emergencies/palestine-impact" element={<PalestineImpact />} />
        <Route path="/crisis-and-emergencies/children-in-gaza" exact element={<ChildrenInGazaLandingPage lang={'en'} />} />
        <Route path="/special-cases" exact element={<SpecialCasesLandingPage />} />
        <Route path="/gaza-appeal" exact element={<GazaAppealLandingPage />} />
        <Route path="/matw-appeals" exact element={<MatwAppeals />} />

        <Route path="/fr/" element={getHomePage()} />
        <Route path="/fr/login" element={<LoginPage />} />
        <Route path="/fr/donor/login" element={<DonorLogin />} />
        <Route path="/fr/donor/verify-otp" element={<VerifyOTP />} />
        <Route path="/fr/usa-general-donation" element={<USAGeneralDonation />} />
        <Route path="/fr/checkout" element={<CheckoutPage />} />
        <Route path="/fr/all-products" element={<AllProducts />} />
        <Route path="/fr/all-appeals" element={<AllAppeals />} />
        <Route path="/fr/participant-registration" element={<ParticipantPage />} />
        <Route path="/fr/fundraising" element={<FundraisingLandingPageUAT />} />
        {/* <Route path="/fr/sub-categories" element={<SubCategory />} /> */}
        {/* <Route path="/fr/products" element={<Products />} /> */}
        <Route path="/fr/qurban/sheep" element={<SheepsPage />} />
        <Route path="/fr/qurban/cow" element={<CowsPage />} />
        <Route path="/fr/qurban-most-popular" element={<QurbanMostPopularPage />} />
        <Route path="/fr/sadaqah-most-popular" element={<SadaqahMostPopularPage />} />
        <Route path="/fr/zakat-calculator" element={<ZakatCalculatorUATLandingPage />} />
        <Route path="/fr/nisab" element={<Nisab />} />
        <Route path="/fr/zakat-landing" element={<ZakatLandingPage />} />
        <Route path="/fr/zakat" element={<ZakatUat />} />
        <Route path="/fr/zakat-on-gold" element={<ZakatOnGold />} />
        <Route path="/fr/zakat/zakat-al-fitr" element={<ZakatAlFitr />} />
        <Route path="/fr/success" element={<SuccessPage />} />
        <Route path="/fr/ali-banat" element={<AliBanatPage />} />
        <Route path="/fr/purpose" element={<PurposePage />} />
        <Route path="/fr/gdpr" element={<GDPR />} />
        <Route path="/fr/faq" element={<FAQsPage />} />
        <Route path="/fr/opt-out" element={<OptOutPage />} />
        <Route path="/fr/cancel" element={<CancelPage />} />
        <Route path="/fr/get-receipt" element={<GetReceiptPage />} />
        <Route path="/fr/social-media" element={<SocialMedia />} />
        <Route path="/fr/annual-reports" element={<AnnualReports />} />
        <Route path="/fr/policies" element={<PoliciesPage />} />
        <Route path="/fr/privacypolicy" element={<PrivacyPolicyPage />} />
        <Route path="/fr/volunteer" element={<NewVolunteerPage />} />
        <Route path="/fr/receipt" element={<ReceiptPage />} />
        <Route path="/fr/app" element={<AppPage />} />
        <Route path="/fr/global" element={<GlobalPage />} />
        <Route path="/fr/quick-donate" element={<QuickDonatePage />} />
        <Route path="/fr/food-and-water-aid" element={<FoodAndWaterLandingPage />} />
        <Route path="/fr/food-and-water-aid/water-aid" element={<WaterAidUATLandingPage />} />
        <Route path="/fr/food-and-water-aid/food-aid" element={<FoodAidUATLandingPage />} />
        <Route path="/fr/the-1-billion-legacy" element={<OneBillionLegacyPage />} />
        <Route path="/fr/vip" element={<VIPPage />} />
        <Route path="/fr/call-center" element={<CallCenter />} />
        <Route path="/fr/contact" element={<Contact />} />
        <Route path="/fr/error" element={<ErrorPage />} />
        <Route path="/fr/donate-to-palestine" exact element={<DonateToPalestine />} />
        <Route path="/fr/food-and-water-aid-fr" element={<FoodAndWaterLandingPage lang={'fr'} />} />
        <Route path="/fr/food-and-water-aid-ar" element={<FoodAndWaterLandingPage lang={'ar'} />} />
        <Route path="/fr/donate-to-gaza" exact element={<DonateToGaza />} />
        <Route path="/fr/donate-now/make-a-donation" element={<MakeADonationLandingPage />} />
        <Route path="/fr/loved-ones-legacy" element={<LoveOnesLandingPage />} />
        <Route path="/fr/winter" element={<WinterLandingPage />} />
        <Route path="/fr/live" element={<Live />} />
        <Route path="/fr/donor-receipts" element={<DonorReceipts />} />
        <Route path="/fr/subscription" element={<SubscriptionLandingPage />} />
        <Route path="/fr/subscription-regular-giving" element={<SubscriptionABLandingPage />} />
        <Route path="/fr/ramadan" element={<RamadanLandingPage />} />
        <Route path="/fr/ramadan/laylatul-qadr" element={<LaylaTulQadrLandingPage />} />
        <Route path="/fr/orphans-fr" element={<OrphansUat lang={'fr'} />} />
        <Route path="/fr/orphans-ar" element={<OrphansUat lang={'ar'} />} />
        <Route path="/fr/palestine-campaign-3-ar" element={<PalestineArabic />} />
        <Route path="/fr/ramadan/fidya-kaffarah" element={<FidyaKaffarah />} />
        <Route path="/fr/kaffarah" element={<KaffarahLandingPage />} />
        <Route path="/fr/fidya" element={<FidyaLandingPage />} />
        <Route path="/fr/uptime-status" element={<UpTimeStatus />} />
        <Route path="/fr/singapore" element={<USAPage country={'singapore'} />} />
        <Route path="/fr/our-promise" element={<OurPromise />} />
        <Route path="/fr/processing-crypto" element={<SentCryptoPage />} />
        <Route path="/fr/cancel-crypto" element={<CancelCryptoPage />} />
        <Route path="/fr/aqiqah" element={<Aqiqah />} />
        <Route path="/fr/our-promise" element={<OurPromise />} />
        <Route path="/fr/100-donation-policy" element={<HundredDonationsPolicy />} />
        <Route path="/fr/dua-for-breaking-fast" element={<DuaForBreakingFast />} />
        <Route path="/fr/difference-between-sadaqah-and-lillah" element={<SadaqahAndLillah />} />
        <Route path="/fr/dua-for-palestine" element={<DuaForPalestine />} />
        <Route path="/fr/dua-for-parents" element={<DuaForParents />} />
        <Route path="/fr/when-is-eid-2024" element={<WhenIsEid />} />
        <Route path="/fr/ramadan-dua" element={<RamadanDua />} />
        <Route path="/fr/gift-giving" element={<GiftGiving />} />
        <Route path="/fr/fitrana" element={<Fitrana />} />
        <Route path="/fr/news" element={<NewsPage />} />
        <Route path="/fr/muharram" element={<MuharramLandingPage type={'main'} />} />
        <Route path="/fr/muharram-2024" element={<MuharramLandingPage type={'duplicated'} />} />
        <Route path="/fr/events/live-fundraiser" element={<LiveFundraiserPage />} />
        <Route path="/fr/events/morningtea" element={<MorningTeaPage />} />
        <Route path="/fr/events/yasminmogahed" element={<YasminmogahedLandingPage />} exact />
        <Route path="/fr/events/yasminmogahed-australia-feb" element={<YasminmogahedAustraliaPage />} exact />
        <Route path="/fr/events/qasimrafique-australia-feb" element={<YasminmogahedAustraliaPage />} exact />
        <Route path="/fr/events/alihammuda-au-tour" element={<EventLandingPage type="alihamud" />} exact />
        <Route path="/fr/events/iftardinner-march" element={<EventLandingPage type="iftar" />} exact />
        <Route path="/fr/events/belal-assaad-south-africa" element={<EventLandingPage type="belal" />} exact />
        <Route path="/fr/events/shaun-king-malaysia" element={<EventLandingPage type="shaun" />} exact />
        <Route path="/fr/events/yasmin-mogahed-malaysia-november" element={<EventLandingPage type="yasmin" />} />
        <Route path="/fr/events/doctor-rania-malaysia-november" element={<EventLandingPage type="rania" />} />
        <Route path="/fr/events/mustafa-briggs-november" element={<EventLandingPage type="mustafa" />} />
        <Route path="/fr/events/muslim-family-fest-november" element={<EventLandingPage type="muslim" />} />
        <Route path="/fr/events/abdul-wahab" element={<EventLandingPage type="abdulwahab" />} exact />
        <Route path="/fr/events/majedmahmoud-qurban" element={<MajedMahmoudLandingPage />} exact />
        <Route path="/fr/events/yasminmogahedindonesia" element={<YasminMogahedIndonesiaPage />} exact />
        <Route path="/fr/events/yasminmogahedmalaysia" element={<YasminMogahedMalaysiaPage />} exact />
        <Route path="/fr/events/yasminmogahedsingapore" element={<YasminMogahedSingaporePage />} exact />
        <Route path="/fr/events/asmaaandbelal" element={<AsmaaAndBelalPage />} exact />
        <Route path="/fr/events/yasirandnouman" element={<YasirandnoumanPage />} exact />
        <Route path="/fr/events/yasirandnoumanmalaysia" element={<YasirandnoumanMalaysiaPage />} exact />
        <Route path="/fr/events/noumanalikhan" element={<NoumanalikhanLandingPage />} exact />
        <Route path="/fr/events/muftimenk" element={<MuftiMenkLandingPage />} exact />
        <Route path="/fr/events/noumanalikhan/malaysia" element={<NoumanalikhanMalaysiaLandingPage />} exact />
        <Route path="/fr/events/belalassad-april2024" element={<BelalAssadApril />} exact />
        <Route path="/fr/events/alibanatlegacy" element={<AliBanatUSALandingPage />} exact />
        <Route path="/fr/events/alibanatlegacyca" element={<AliBanatCADLandingPage />} exact />
        <Route path="/fr/sadaqah-jariyah" element={<SadaqahjariahUat />} />
        <Route path="/fr/sadaqah" element={<Sadaqah />} />
        <Route path="/fr/sadaqah-jariyah-for-deceased" element={<SadaqahjariahForDeceased />} />
        <Route path="/fr/sadaqah-jariyah/project-noor" element={<ProjectNoorUAT />} />
        <Route path="/fr/sadaqah-jariyah/orphan-homes" element={<OrphanHomesLandingPage />} />
        <Route path="/fr/sadaqah-jariyah/build-a-water-well" element={<BuildWaterWellUat />} />
        <Route path="/fr/olive-tree-sadaqah" element={<OliveTreeSadaqah />} />
        <Route path="/fr/sadaqah-jariyah/sustainable" element={<SustainableLandingPage />} />
        <Route path="/fr/sadaqah-jariyah/orphans" element={<OrphanagesLandingPage />} />
        <Route path="/fr/sadaqah-jariyah/build-a-masjid" element={<MasjidsNewLandingPage type="buildAMasjid" />} />
        <Route path="/fr/donation-for-masjid" element={<MasjidsNewLandingPage type="donationForMasjid" />} />
        <Route path="/fr/sadaqah-jariyah/emergency" element={<EmergencyLandingPage />} />
        <Route path="/fr/sadaqah-jariyah/health" element={<HealthLandingPage />} />
        <Route path="/fr/orphans" element={<OrphansUat />} />
        <Route path="/fr/orphans-fr" element={<OrphansUat lang={'fr'} />} />
        <Route path="/fr/orphans-ar" element={<OrphansUat lang={'ar'} />} />
        <Route path="/fr/orphans/orphan-feast" element={<OrphansFeastUat />} />
        <Route path="/fr/orphans/education" element={<OrphanEducationLandingPage />} />
        <Route path="/fr/orphans/support-an-orphan" element={<OrphanSupportLandingPage />} />
        <Route path="/fr/sponsor-an-orphan" element={<SponsorAnOrphan />} />
        <Route path="/fr/orphans/orphan-widget" element={<ZakatWidgetEmbedded />} />
        <Route path="/fr/crisis-and-emergencies/palestine" exact element={<PalestineLandingPage />} />
        <Route path="/fr/crisis-and-emergencies/palestine-famine" exact element={<PalestineFamineLandingPage lang={'en'} />} />
        <Route path="/fr/palestine-famine-ar" exact element={<PalestineFamineLandingPage lang={'ar'} />} />
        <Route path="/fr/palestine-famine-fr" exact element={<PalestineFamineLandingPage lang={'fr'} />} />
        <Route path="/fr/crisis-and-emergencies/palestine-subscription" exact element={<SubscriptionLandingPage />} />
        <Route path="/fr/crisis-and-emergencies/palestine-campaign" exact element={<PalestineCampaignLandingPage />} />
        <Route path="/fr/crisis-and-emergencies/palestine-response" exact element={<PalestineResponseLandingPage />} />
        <Route path="/fr/crisis-and-emergencies/emergency-response" exact element={<EmergencyResponseLandingPage />} />
        <Route path="/fr/crisis-and-emergencies/morocco" exact element={<MoroccoEmergencyLandingPage />} />
        <Route path="/fr/crisis-and-emergencies/libya-floods" exact element={<Navigate to="/crisis-and-emergencies" replace />} />
        <Route path="/fr/crisis-and-emergencies/earthquake-appeal" exact element={<EarthquakeAppealPage />} />
        <Route path="/fr/crisis-and-emergencies/palestine-weekly-au" exact element={<PalestineWeekly country={'au'} />} />
        <Route path="/fr/crisis-and-emergencies/palestine-weekly-usa" exact element={<PalestineWeekly country={'usa'} />} />
        <Route path="/fr/crisis-and-emergencies/palestine-weekly-uk" exact element={<PalestineWeekly country={'uk'} />} />
        <Route path="/fr/crisis-and-emergencies/palestine-weekly-global" exact element={<PalestineWeekly country={'global'} />} />
        <Route path="/fr/crisis-and-emergencies/save-orphans" exact element={<SaveOrphansLandingPage lang={'en'} />} />
        <Route path="/fr/crisis-and-emergencies/palestine-refugee-settlement" element={<PalestineRefugeeLandingPage lang={'en'} type="RefusseP" />} />
        <Route path="/fr/crisis-and-emergencies/bangladesh-appeal" element={<BangladeshAppeal />} />
        <Route path="/fr/crisis-and-emergencies/countries-in-crisis" element={<CountriesInCrisisLandingPage />} />
        <Route path="/fr/medical-aid-for-palestinians" element={<PalastineMedicalRelief />} />
        <Route path="/fr/palestinian-refugees-egypt" element={<PalestineRefugeeLandingPage lang={'en'} />} />
        <Route path="/fr/palestine-refugee-settlement-ar" element={<PalestineRefugeeLandingPage lang={'ar'} />} />
        <Route path="/fr/palestine-refugee-settlement-fr" element={<PalestineRefugeeLandingPage lang={'fr'} />} />
        <Route path="/fr/save-palestine-orphans-ar" exact element={<SaveOrphansLandingPage lang={'ar'} />} />
        <Route path="/fr/save-palestine-orphans-fr" exact element={<SaveOrphansLandingPage lang={'fr'} />} />
        <Route path="/fr/palestine-fr" exact element={<PalestineLandingPage _lang={'fr'} />} />
        <Route path="/fr/palestine-ar" exact element={<PalestineArabicLandingPage />} />
        <Route path="/fr/dashboard" element={<DashboardPage />} />
        <Route path="/fr/donor" element={<DonorPage />} />
        <Route path="/fr/dashboard/*" element={<DashboardPage />} />
        <Route path="/fr/donor/*" element={<DonorPage />} />
        <Route path="/fr/undefined/*" element={<HomePageUAT />} />
        <Route path="/fr/events/:id" element={<EventDetails />} />
        <Route path="/fr/appeals/:id" element={<DetailsProduct />} />
        <Route path="/fr/appeals/palestine/:id" element={<DetailsPalestineProduct />} />
        <Route path="/fr/:name" element={<AppealLandingPage />} />
        <Route path="/fr/:name/:product" element={<Products />} />
        <Route path="/fr/ramadan/fidya-kaffarah-uat" element={<FidyaKaffarahUat />} />
        <Route path="/fr/ramadan/last-10-nights" element={<LaylaTulQadrLandingPage />} />
        <Route path="/fr/laylatul-qadr-2024" element={<LaylatulQadr2024LandingPage />} />
        <Route path="/fr/volunteer-uat" element={<VolunteerUATPage />} />
        <Route path="/fr/sadaqah-jariyah-uat" element={<SadaqahjariahUat />} />
        <Route path="/fr/sadaqah-jariyah/build-a-water-well-uat" element={<BuildWaterWellUat />} />
        <Route path="/fr/sadaqah-jariyah/gift-of-sound" element={<GiftOfSoundLandingPage />} />
        <Route path="/fr/campaign-tt" element={<CampaignTiktok />} />
        <Route path="/fr/phishing-scams" element={<PhishingLandingPage />} />
        <Route path="/fr/admin-fee" element={<AdminFeesPage />} />
        <Route path="/fr/live-meals" element={<LiveMeals />} />
        <Route path="/fr/eid-al-fitr-2024" element={<EidAlFitr2024 />} />
        <Route path="/fr/eid-mubarak-2024" element={<EidMubarak2024 />} />
        <Route path="/fr/top-appeals" element={<TopAppeals />} />
        <Route path="/fr/qurbani-2024" element={<Qurbani2024LandingPage />} />
        <Route path="/fr/qurbani-2023" element={<Navigate to="/qurbani-2024" replace />} />
        <Route path="/fr/qurbani-palestine" element={<Qurbani2024LandingPage country={'Palestine'} />} />
        <Route path="/fr/qurban" element={<QurbanMainLandingPage lang={'en'} title={'Qurban'} />} />
        <Route path="/fr/qurban-ar" element={<QurbanMainLandingPage lang={'ar'} />} />
        <Route path="/fr/qurban-fr" element={<QurbanMainLandingPage lang={'fr'} />} />
        <Route path="/fr/cheapest-qurbani-2024" element={<CheapestQurbani2024LandingPage />} />
        <Route path="/fr/qurbani" element={<QurbanMainLandingPage lang={'en'} title={'Qurbani'} />} />
        <Route path="/fr/qurban/palestine" element={<QurbanPalestineLandingPage title="Qurban" lang={'en'} />} />
        <Route path="/fr/qurbani/palestine" element={<QurbanPalestineLandingPage title="Qurbani" />} />
        <Route path="/fr/qurban/palestine-fr" element={<QurbanPalestineLandingPage title="Qurban" lang={'fr'} />} />
        <Route path="/fr/qurban/palestine-ar" element={<QurbanPalestineLandingPage title="Qurban" lang={'ar'} />} />
        <Route path="/fr/qurbani-donation" element={<QurbaniDonationLandingPage />} />
        <Route path="/fr/qurbani-yemen" element={<Qurbani2024LandingPage country={'Yemen'} />} />
        <Route path="/fr/qurbani-egypt" element={<Qurbani2024LandingPage country={'Egypt'} />} />
        <Route path="/fr/qurbani-africa" element={<Qurbani2024LandingPage country={'Africa'} />} />
        <Route path="/fr/qurbani-syria" element={<Navigate to="/qurbani" replace />} />
        <Route path="/fr/qurbani-afghanistan" element={<Qurbani2024LandingPage country={'Afghanistan'} />} />
        <Route path="/fr/qurbani-jordan" element={<Qurbani2024LandingPage country={'Jordan'} />} />
        <Route path="/fr/qurbani-lebanon" element={<Qurbani2024LandingPage country={'Lebanon'} />} />
        <Route path="/fr/qurbani-bangladesh" element={<Qurbani2024LandingPage country={'Bangladesh'} />} />
        <Route path="/fr/eid-qurban-2024" element={<Qurbani2024LandingPage title={'Qurban'} />} />
        <Route path="/fr/qurban/dhul-hijjah" element={<QurbanDhulHijjahLandingPage />} />
        <Route path="/fr/qurban/ultimate-qurbani" element={<UltimateQurbanLandingPage title="Qurbani" />} />
        <Route path="/fr/qurban/ultimate-qurban" element={<UltimateQurbanLandingPage title="Qurban" lang={'en'} />} />
        <Route path="/fr/qurban/ultimate-qurban-ar" element={<UltimateQurbanLandingPage title="Qurban" lang={'ar'} />} />
        <Route path="/fr/qurban/ultimate-qurban-fr" element={<UltimateQurbanLandingPage title="Qurban" lang={'fr'} />} />
        <Route path="/fr/qurban/day-of-arafat" element={<QurbanDayOfArafatLandingPage title="Qurbani" />} />
        <Route path="/fr/qurban-campaign-1" element={<QurbanMainLandingPage isCampaign title={'Qurban'} />} />
        <Route path="/fr/qurbani-campaign-1" element={<QurbaniLandingPage isCampaign />} />
        <Route path="/fr/kurban-bayrami-2024" element={<KurbanBayramiLandingPage />} />
        <Route path="/fr/eid-al-adha-2024" element={<EidAlAdha2024LandingPage />} />
        <Route path="/fr/dhul-hijjah-2024" element={<DhulHijjah2024LandingPage />} />
        <Route path="/fr/pakistan-qurbani" element={<Qurbani2024LandingPage country={'Pakistan'} />} />
        <Route path="/fr/qurban/eid-al-adha" element={<QurbanEidAlAdhaLandingPage title="Qurban" />} />
        <Route path="/fr/crisis-and-emergencies/afghanistan" element={<AfghanistanLandingPageUAT />} />
        <Route path="/fr/widget" element={<WidgetEmbedded />} />
        <Route path="/fr/palestine-aia-partnership" element={<PalestineAiaPartnershipLandingPage />} />
        <Route path="/fr/orphans-aia-sponsorship" element={<OrphansAiaSponsorshipLandingPage />} />
        <Route path="/fr/doublethedonation" element={<DoubleTheDonationPage />} />
        <Route path="/fr/top-10" element={<Top10Products page="general" />} />
        <Route path="/fr/top-10-palestine" element={<Top10Products page="palestine" />} />
        <Route path="/fr/appeal/:id" element={<AppealLandingPage />} />
        <Route path="/fr/appeals/palestine-emergency" element={<MATWAppealDetailsLandingPage campaign="palestine" />} />
        <Route path="/fr/appeals/gaza-camp" element={<MATWAppealDetailsLandingPage campaign="gaza-camp" />} />
        <Route path="/fr/appeals/palestine-refugee-resettlement" element={<MATWAppealDetailsLandingPage campaign="egyptian" />} />
        <Route path="/fr/appeals/limbs-of-hope" element={<MATWAppealDetailsLandingPage campaign="limbs-of-hope" />} />
        <Route path="/fr/appeals/appeal/limbs-of-hope" element={<MATWAppealLandingPage campaign="limbs-of-hope" />} />
        <Route path="/fr/appeals/appeal/palestine-emergency" element={<MATWAppealLandingPage campaign="palestine" />} />
        <Route path="/fr/appeals/appeal/palestine-refugee-resettlement" element={<MATWAppealLandingPage campaign="egyptian" />} />
        <Route path="/fr/children-of-gaza" element={<ChildrenOfGaza />} />
        <Route path="/fr/limbs-of-hope" element={<LimbsOfHopeLandingPage />} />
        <Route path="/fr/lebanon-appeal" element={<LebanonAppealLandingPage />} />
        <Route path="/fr/crisis-and-emergencies/palestine-impact" element={<PalestineImpact />} />
        <Route path="/fr/crisis-and-emergencies/children-in-gaza" exact element={<ChildrenInGazaLandingPage lang={'en'} />} />
        <Route path="/fr/special-cases" exact element={<SpecialCasesLandingPage />} />
        <Route path="/fr/gaza-appeal" exact element={<GazaAppealLandingPage />} />
        <Route path="/fr/matw-appeals" exact element={<MatwAppeals />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </RouteContext.Provider>
  );
};

export default AllRoutes;

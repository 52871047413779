import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { selectSelectedCurrency } from '../../store/user';
import ShowCurrencies from '../../utils/showCurrencies';
import useHandleAddProduct from '../../utils/handleAddProduct';
import ReactSelect from 'react-select';
import SelectCurrency from '../../components/general/selectCurrency';
import creditImg from "../../images/payment/credit-cards.png";
import ConvertAUD from '../../utils/convertAUD';
const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}
const TabContent = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const { contributes, product } = props;
    const [currentCurrency, setCurrentCurrency] = useState();
    const [productAmount, setProductAmount] = useState('');
    const [addProduct] = useHandleAddProduct();
    const refLazyImg = useRef(null);
    const suggestedRecurringModes = [
        { value: 'month', label: 'Monthly' },
        { value: 'week', label: 'Weekly' },
    ]
    const [selectedRecurring, setSelectedRecurring] = useState(suggestedRecurringModes[0]);
    const suggestedPrices = [
        {
            aud: 60,
            usd: 40,
            cad: 50,
            sgd: 50,
            gbp: 30,
            eur: 20,
            myr: 50,
            aed: 140,
            idr: 29000,
        },
        {
            aud: 80,
            usd: 50,
            cad: 70,
            sgd: 80,
            gbp: 40,
            eur: 35,
            myr: 100,
            aed: 180,
            idr: 39000,
        },
        {
            aud: 95,
            usd: 60,
            cad: 85,
            sgd: 100,
            gbp: 50,
            eur: 60,
            myr: 150,
            aed: 230,
            idr: 69000,
        },
    ];
    const [selectedPrice, setSelectedPrice] = useState({
        value: suggestedPrices[0][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
        index: 0
    });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleAddNewProduct = () => {
        let tempProduct = product;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedRecurring.value
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }
    const handleSelectedItem = (currency, index) => {
        let tempSelectedPrice = {};
        tempSelectedPrice.index = index;
        tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
        setSelectedPrice(tempSelectedPrice)
    }
    const handleConvert = (currency, value) => {
        return Math.round(ConvertAUD(currency, value, false))
    }
    const handleProductAmount = (currency, value) => {
        setProductAmount(handleConvert(currency, value))
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (selectedCurrencyStore) {
            setCurrentCurrency(selectedCurrencyStore);
            selectedPrice
                ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index)
                : handleProductAmount(selectedCurrencyStore, productAmount)
        } else {
            setCurrentCurrency('AUD')
            handleSelectedItem('AUD', selectedPrice.index)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])

    /* -------------------------------------------------------------------------- */

    return (
        <div className="bg-white w-full rounded-md py-4 px-2 sm:px-4 lg:py-8 lg:px-6">
            <div className="w-full flex flex-col- md:flex-row flex-wrap bg-white overflow-hidden-">
                <div className="pl-0 lg:pl-24 order-2 basis-full lg:basis-1/2">
                    <div className="overflow-hidden rounded-lg flex items-center basis-full h-auto lg:h-[280px] xl:h-[400px] px-2 md:px-0">
                        {product ? product.image_link
                            ? <LazyLoadImage
                                ref={refLazyImg}
                                alt={product.name}
                                effect="blur"
                                width={`100%`}
                                delayTime={500}
                                className='rounded-lg'
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/general/logo-replace.png";
                                }}
                                src={product.image_link} // use normal <img> attributes as props
                            />
                            : <img className='w-full h-full' src="/images/general/logo-replace.png" alt="" />
                            : <Skeleton height={344} />
                        }
                    </div>
                </div>
                <div className="basis-full order-1 md:order-1 lg:basis-1/2 py-2 px-2 md:py-4 md:px-8">
                    <h3 className="text-[#093c8a] text-3xl md:text-4xl text-center md:text-left font-medium mb-4 leading-6 md:leading-none">
                        {product ? product.name : <Skeleton height={48} />}
                    </h3>
                    <div className="relative">
                        {product ? <>
                            <div className={`text-center md:text-left my-2 relative overflow-hidden h-auto ${(product.short_description || (product.description && product.description)).split(' ').length > 28 ? 'mb-4' : 'mb-4'}`}>
                                <p className={`text-[#78716C] text-sm md:text-lg overflow-hidden transition-all duration-200 min-h-[79px]-`}>
                                    {product.short_description || product.description}
                                </p>
                            </div>
                        </> : <Skeleton count={4} />}
                    </div>
                    <h4 className="text-[#093c8a] text-lg md:text-2xl text-center md:text-left font-medium my-2 leading-6 md:leading-none">
                        Contribute monthly or weekly to:
                    </h4>
                    <ul className="list-disc my-2 flex justify-between pl-4">
                        {contributes ? contributes.map((contri, index) => {
                            return <li key={`contributes${index}`} className={`text-[#78716C] text-xs md:text-lg`}>
                                {contri}
                            </li>
                        })
                            : <Skeleton count={4} />}
                    </ul>
                </div>
                <div className="basis-full order-3 flex-col flex justify-center pt-2 px-2 md:px-0 md:pt-4">
                    <div className="col-span-12 grid grid-cols-12 gap-2 bg-[#E2EFF3] md:bg-transparent rounded-lg md:rounded-none">
                        <div className="col-span-12 md:hidden grid grid-cols-4 mb-1">
                            {suggestedRecurringModes.map((mode, index) => {
                                return <div key={`modes${index}`} className="col-span-2" style={styles.shadow}>
                                    <div
                                        className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${index === 0 ? 'rounded-tl-lg' : 'rounded-tr-lg'} ${selectedRecurring.value === mode.value ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                        onClick={() => {
                                            setSelectedRecurring(mode);
                                            setProductAmount('');
                                        }}
                                    >
                                        {mode.label}
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="col-span-12 grid grid-cols-12 gap-2 px-2 pb-2 md:p-0">
                            <div className="hidden md:flex col-span-6 sm:col-span-6 lg:col-span-6 xl:col-span-2 lg:mb-2 items-center justify-center" style={styles.shadow}>
                                <ReactSelect
                                    options={suggestedRecurringModes}
                                    className='[&>*]:!cursor-pointer w-full'
                                    value={selectedRecurring}
                                    isSearchable={false}
                                    styles={styles}
                                    onChange={(e) => {
                                        setSelectedRecurring(e);
                                    }}
                                />
                            </div>
                            {suggestedPrices.map((price, index) => {
                                return <div key={`prices${index}`} className="col-span-4 md:col-span-2 xl:col-span-1 h-12" style={styles.shadow}>
                                    <div
                                        className={`h-12 rounded-md text-base md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-1 md:px-4 min-w-[80px] ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud'] ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                        onClick={() => {
                                            handleSelectedItem(currentCurrency, index);
                                            setProductAmount('');
                                        }}
                                    >
                                         {ShowCurrencies(currentCurrency, price[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], true)}
                                    </div>
                                </div>
                            })}
                            <div className="col-span-12 lg:col-span-6 xl:col-span-3 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                                <div className="flex w-full md:w-1/2">
                                    <SelectCurrency onChangeCurrency={(e) => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
                                </div>
                                <input
                                    value={productAmount}
                                    className="w-full md:w-1/2 h-12 p-2 rounded-md text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                                    type="number"
                                    onChange={(e) => {
                                        setProductAmount(e.target.value);
                                        if (e.target.value === '') {
                                            setSelectedPrice(100);
                                        } else {
                                            setSelectedPrice(null);
                                        }
                                        console.log("amount", e.target.value)
                                    }}
                                    placeholder="Enter amount" />
                            </div>
                            <div className="col-span-6 sm:col-span-6 lg:col-span-3 xl:col-span-2 rounded-md bg-white px-3 h-12 hidden md:flex items-center justify-center" style={styles.shadow}>
                                <img src={creditImg} alt="MATW" className="w-[260px] h-[20px] sm:w-[254px] sm:h-[28px] md:w-[221px] md:h-[25px] lg:w-[125px] lg:h-[14px] xl:w-[133px] xl:h-[15px] 2xl:w-[218px] 2xl:h-[24px]" />
                            </div>
                            <div className="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2 lg:mb-2 flex flex-col justify-center items-center-">
                                <button
                                    className={`text-white w-full text-lg md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${product ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                                    onClick={handleAddNewProduct}
                                >Set up donation</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabContent;

import React, { useEffect, useRef, useState } from 'react';
import WidgetHome from '../../components/widgets/widgetHome';
// import Accordionn from '../../components/Accordion';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import Card from '../dashboard/components/card';
import {
  Formik,
  Field,
  useFormikContext,
  useField
} from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { AsYouType } from 'libphonenumber-js';
import ReactCountryFlag from "react-country-flag";
import Select from "react-select";
import countryList from "react-select-country-list";
import services from '../../services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
import { volunteerFaqs } from './faq';
import Accordion from '../../components/general/accordion';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
const NewVolunteerPage = () => {
  /* ----------------------------- Start variables ---------------------------- */

  const navigate = useNavigate();
  const resetRef = useRef();
  const [isLoading, setIsLoading] = useState(false)
  const [countryCode, setCountryCode] = useState('au');
  const [isCheckedIp, setIsCheckedIp] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let isPhoneValid = false;
  const countriesList = countryList().getData();
  const userInfo = {
    first_name: '',
    last_name: '',
    address_line1: '',
    address_line2: '',
    address_line3: '',
    city: '',
    state: '',
    country: '',
    email: '',
    phone: '',
    birthdate: '',
    experiences: [
      { label: 'Admin Support', name: 'admin_support', value: false },
      { label: 'Donor Care', name: 'donor_care', value: false },
      { label: 'Remote Activities', name: 'remote_activities', value: false },
      { label: 'Fund Raising', name: 'fund_raising', value: false },
      { label: 'Event Planning', name: 'event_planning', value: false },
      { label: 'Social Media & Marketing', name: 'social_media', value: false },
      { label: 'Driving', name: 'driving', value: false }
    ],
    current_occupation: [
      { label: 'Unemployed', name: 'unemployed', value: 'unemployed' },
      { label: 'Student', name: 'student', value: 'student' },
      { label: 'Working Part-time', name: 'part_time', value: 'part_time' },
      { label: 'Working Full-time', name: 'full_time', value: 'full_time' }
    ],
    travel_work: false,
    availability_hours: false,
    availability_times: [
      { label: 'Monday day time', name: 'monday_day_time', value: false },
      { label: 'Monday evening', name: 'monday_evening', value: false },
      { label: 'Tuesday day time', name: 'tuesday_day_time', value: false },
      { label: 'Tuesday evening', name: 'tuesday_evening', value: false },
      { label: 'Wednesday day time', name: 'wednesday_day_time', value: false },
      { label: 'Wednesday evening', name: 'wednesday_evening', value: false },
      { label: 'Thursday day time', name: 'thursday_day_time', value: false },
      { label: 'Thursday evening', name: 'thursday_evening', value: false },
      { label: 'Friday day time', name: 'friday_day_time', value: false },
      { label: 'Friday evening', name: 'friday_evening', value: false },
      { label: 'Saturday day time', name: 'saturday_day_time', value: false },
      { label: 'Saturday evening', name: 'saturday_evening', value: false },
      { label: 'Sunday day time', name: 'sunday_day_time', value: false },
      { label: 'Sunday evening', name: 'sunday_evening', value: false },
    ],
    volunteering_elsewhere: '',
    volunteering_elsewhere_details: '',
    description: '',
    confirmation: false,
  };
  const refPhone = useRef(null);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // eslint-disable-next-line no-useless-escape
  const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const VolunteerSchema = Yup.object().shape({
    first_name: Yup.string().required('Enter first name'),
    last_name: Yup.string().required('Enter last name'),
    address_line1: Yup.string().required('Enter address'),
    address_line2: Yup.string(),
    address_line3: Yup.string(),
    city: Yup.string().required('Enter city'),
    state: Yup.string().required('Enter state'),
    email: Yup.string().matches(emailRegExp, 'Invalid email address').required('Enter email address'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Enter your phone number').min(10, 'Should be 10 digits'),
    birthdate: Yup.date().required('Enter birthdate'),
    experiences: Yup.array().required('Select experiences').min(1, 'Should be selected at least one item'),
    current_occupation: Yup.string().required('Select current occupation'),
    travel_work: Yup.boolean().required('Select travel work'),
    availability_hours: Yup.boolean().required('Select availability hours'),
    availability_times: Yup.array().required('Select availability times').min(1, 'Should be selected at least one item'),
    volunteering_elsewhere: Yup.boolean().required('Select volunteering elsewhere'),
    volunteering_elsewhere_details: Yup.string(),
    description: Yup.string().required('Enter description'),
    confirmation: Yup.boolean().oneOf([true], 'Field must be checked'),
  });
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handlePhoneValidation = (value, country) => {
    let temp = new AsYouType(country.countryCode).input(`+${value}`)
    if (value.length < 3) {
      // console.log("country code changed", country.countryCode, country.dialCode, value.length);
      refPhone.current.value = `+${country.dialCode}`;
    } else {
      if (Number(value) === 0 || Number(value.charAt(0)) === 0) {
        let tempPhone = country.dialCode + value.substr(value.indexOf(country.dialCode) + country.dialCode.length + 1, value.length);
        refPhone.current.value = tempPhone;
      } else {
        if (temp.split(' ')[1] && Number(temp.split(' ')[1].charAt(0)) === 0) {
          isPhoneValid = false;
          let tempPhone = country.dialCode + value.substr(value.indexOf(country.dialCode) + country.dialCode.length + 1, value.length);
          console.log("tempPhone==", tempPhone);
          refPhone.current.value = tempPhone;
        } else {
        }
      }
    }
  }
  const handleIsPhoneValid = (value, country) => {
    if (value.match(/12345/)) {
      isPhoneValid = false;
      return 'Invalid value: ' + value + ', ' + country.name;
    } else if (value.match(/1234/)) {
      return false;
    } else {
      isPhoneValid = true;
      return true;
    }
  }
  const submitForm = async (info, resetForm) => {
    setIsLoading(true);
    let request = {
      first_name: info.first_name,
      last_name: info.last_name,
      address_line1: info.address_line1,
      address_line2: info.address_line2,
      address_line3: info.address_line3,
      city: info.city,
      state: info.state,
      country: Array.isArray(info.country) ? info.country[0] : info.country,
      email: info.email,
      phone: info.phone,
      birthdate: info.birthdate,
      experiences: info.experiences,
      current_occupation: info.current_occupation,
      travel_work: `${info.travel_work}` === 'false' ? false : true,
      availability_hours: `${info.availability_hours}` === 'false' ? false : true,
      // availability_times: info.availability_times[0],
      availability_times: info.availability_times,
      volunteering_elsewhere: `${info.volunteering_elsewhere}` === 'false' ? false : true,
      volunteering_elsewhere_details: info.volunteering_elsewhere_details,
      description: info.description
    }
    console.log("before sending", request)
    try {
      const response = await services.postVolunteer(request);
      console.log('response getStripeReceipt', response);
      setIsLoading(false);
      toast.success('Thank you for registering your interest in volunteering with MATW Project. We value your support and will be in touch when opportunities arise in your location.');
      navigate(generateLinkWithLang(`/`, lang))
      return true
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
      setIsLoading(false)
      console.error('error========', error);
      return false
    }
  }
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // window.scroll(0, 0);
    if (!userInfo.phone) {
      fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
        .then(res => res.json())
        .then(response => {
          setIsCheckedIp(true)
          setCountryCode(response.countryCode.toLowerCase())
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout>
      {seo &&
        <Helmet>
          <title>{`${seo['aboutUs']['volunteer']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo['aboutUs']['volunteer']['description']}`} />
        </Helmet>
      }
      <WidgetHome selectedCurrency={e => { }} selectedProduct={e => console.log('product')} />
      <div
        className={`w-full bg-[url('../src/images/landings/volunteer/MATW-Volunteer-Banner.jpg')] bg-cover text-white h-auto md:h-[340px] flex justify-center items-center`}
      >
        <div className="w-full h-full py-4">
          <div className="md:container md:mx-auto px-2 sm:px-3 h-full flex items-center">
            <h1 className="w-full text-white text-lg sm:text-2xl md:text-5xl text-center">Volunteer</h1>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center mt-8 md:mt-16">
        <div className="basis-full md:basis-10/12 px-4 md:px-0">
          <Card title='Registration Form'>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                address_line1: '',
                address_line2: '',
                address_line3: '',
                city: '',
                state: '',
                country: [countryCode.toUpperCase()],
                // country: countryCode,
                email: '',
                phone: '',
                birthdate: '',
                experiences: [],
                current_occupation: '',
                travel_work: true,
                availability_hours: true,
                availability_times: [],
                volunteering_elsewhere: 'false',
                volunteering_elsewhere_details: '',
                description: '',
                confirmation: false
              }}
              enableReinitialize
              validationSchema={VolunteerSchema}
              onSubmit={(values, { resetForm }) => {
                submitForm(values, resetForm) && resetForm({ values: {} });
                // console
                // resetForm({values: ''})
                // handleSubmit(values);
              }}
            >
              {({ errors, values, touched, setFieldValue, handleBlur, handleChange, handleSubmit, resetForm }) => (
                <form onSubmit={handleSubmit} className="flex flex-wrap justify-center-">
                  {/* /* -------------------------------- About you ------------------------------- */}
                  <div className="basis-full px-4 my-2 md:my-4">
                    <h3 className='text-[#00a3da]'>About you</h3>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>First Name<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      <input
                        type="text"
                        name='first_name'
                        id='first_name'
                        placeholder='Enter first name'
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {(errors.first_name && touched.first_name) &&
                        <small id='first_name-error' className="text-[#f60362] text-sm">
                          {errors.first_name}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>Last Name<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      <input
                        type="text"
                        name='last_name'
                        id='last_name'
                        placeholder='Enter last name'
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {(errors.last_name && touched.last_name) &&
                        <small id='last_name-error' className="text-[#f60362] text-sm">
                          {errors.last_name}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>Address Line 1<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      <input
                        type="text"
                        name='address_line1'
                        id='address_line1'
                        placeholder='Enter address line 1'
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {(errors.address_line1 && touched.address_line1) &&
                        <small id='address_line1-error' className="text-[#f60362] text-sm">
                          {errors.address_line1}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>Address Line 2
                      <input
                        type="text"
                        name='address_line2'
                        id='address_line2'
                        placeholder='Enter address line 2'
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {(errors.address_line2 && touched.address_line2) &&
                        <small id='address_line2-error' className="text-[#f60362] text-sm">
                          {errors.address_line2}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>Address Line 3
                      <input
                        type="text"
                        name='address_line3'
                        id='address_line3'
                        placeholder='Enter address line 3'
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {(errors.address_line3 && touched.address_line3) &&
                        <small id='address_line3-error' className="text-[#f60362] text-sm">
                          {errors.address_line3}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>City<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      <input
                        type="text"
                        name='city'
                        id='city'
                        placeholder='Enter city'
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {(errors.city && touched.city) &&
                        <small id='city-error' className="text-[#f60362] text-sm">
                          {errors.city}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>State/Region<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      <input
                        type="text"
                        name='state'
                        id='state'
                        placeholder='Enter state'
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {(errors.state && touched.state) &&
                        <small id='state-error' className="text-[#f60362] text-sm">
                          {errors.state}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>Country<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      <div className='flex items-center min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent pl-3 outline-none transition-all duration-200 ease-linear'>
                        <ReactCountryFlag
                          countryCode={Array.isArray(values.country) ? values.country[0].toLowerCase() : values.country}
                          // countryCode={countryCode}
                          svg
                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                          cdnSuffix="svg"
                          title={values.country}
                        />
                        <div className='ml-2 w-full'>
                          {isCheckedIp &&
                            <FormikReactSelect
                              name='country'
                              id='country'
                              // defaultValue={countryCode}
                              // value3={values.country}
                              // isMulti={false}
                              onChange={e => {
                                console.log("new select", e);
                                // handleChange(e)
                              }}
                              options={countriesList}
                            />
                          }
                        </div>
                      </div>
                      {(errors.country && touched.country) &&
                        <small id='country-error' className="text-[#f60362] text-sm">
                          {errors.country}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>Email<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      <input
                        type="text"
                        name='email'
                        id='email'
                        placeholder='Enter email'
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {(errors.email && touched.email) &&
                        <small id='eamil-error' className="text-[#f60362] text-sm">
                          {errors.email}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777] w-full'>Phone<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      <PhoneInput
                        name="phone"
                        ref={refPhone}
                        className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md even:min-h-[48px]"
                        id="phone"
                        placeholder='Enter your phone number'
                        onBlur={(val) => {
                          let e = { target: { value: val, name: 'phone', id: 'phone' } }
                          handleBlur(e)
                        }}
                        isValid={(value, country) => handleIsPhoneValid(value, country)}
                        country={countryCode}
                        inputClass={'!w-full'}
                        onChange={(val, country) => {
                          let e = { target: { value: val, name: 'phone', id: 'phone' } };
                          handlePhoneValidation(val, country)
                          handleChange(e);
                        }}
                      />
                      {(errors.phone && touched.phone) &&
                        <small id='phone-error' className="text-[#f60362] text-sm">
                          {errors.phone}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full sm:basis-1/2 px-4 py-2">
                    <label className='text-sm md:text-md text-[#777]'>Date of birth<small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      <input
                        name="birthdate"
                        id="birthdate"
                        type="date"
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        placeholder="mm/dd/yyyy"
                        max={new Date().toISOString().split('T')[0]}
                        onChange={handleChange}
                      />
                      {(errors.birthdate && touched.birthdate) &&
                        <small id='birthdate-error' className="text-[#f60362] text-sm">
                          {errors.birthdate}
                        </small>}
                    </label>
                  </div>
                  {/* /* -------------------------------------------------------------------------- */}

                  {/* /* ------------------------------ Volunteering ------------------------------ */}
                  <div className="basis-full px-4 mt-6 md:mt-12 mb-2 md:mb-4">
                    <h3 className='text-[#00a3da]'>Volunteering</h3>
                  </div>
                  <div className="basis-full flex flex-wrap px-4 py-2">
                    <label className='text-base md:text-lg text-[#777] basis-full mb-3'>
                      <strong>Areas of interest/experience</strong> - Which areas do you have experience and would like to volunteer for?
                      <small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      {(errors.experiences && touched.experiences) &&
                        <small id='experiences-error' className="text-[#f60362] text-sm">
                          {errors.experiences}
                        </small>}
                    </label>
                    {userInfo.experiences.map((item, index) => {
                      return <div key={`experiences${index}`} className='basis-1/2 flex sm:basis-1/3 md:basis-1/4 mb-2'>
                        <label className='text-[#777] w-[calc(100%_-_1.125rem)]- text-xs sm:text-base flex items-center flex-col- flex-row'>
                          <Field
                            type="checkbox"
                            name="experiences"
                            value={item.name}
                            className="relative float-left mr-1 h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-[#00a3da] checked:bg-[#00a3da] checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-[#00a3da] checked:focus:bg-[#00a3da] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                          />
                          {item.label}
                        </label>
                      </div>
                    })}
                  </div>
                  <div className="basis-full flex flex-wrap px-4 py-3">
                    <label className='text-base md:text-lg text-[#777] basis-full mb-3'>
                      <strong>Current Occupation</strong>
                      <small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      {(errors.current_occupation && touched.current_occupation) &&
                        <small id='current_occupation-error' className="text-[#f60362] text-sm">
                          {errors.current_occupation}
                        </small>}
                    </label>
                    {userInfo.current_occupation.map((item, index) => {
                      return <div key={`current_occupation${index}`} className='basis-1/2 sm:basis-1/3 md:basis-1/4 mb-2'>
                        <div className="flex items-center">
                          <input
                            className="w-4 h-4 border-[0.125rem] mr-1 border-solid border-[rgba(0,0,0,0.25)]- cursor-pointer text-[#00a3da] bg-gray-100 border-gray-300 focus:ring-[#00a3da] focus:ring-2 checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                            id={item.name}
                            type="radio"
                            name='current_occupation'
                            onChange={e => handleChange(e)}
                            value={item.value}
                            defaultChecked={values.current_occupation === item.value}
                          />
                          <label className="text-[#777] w-[calc(100%_-_1.125rem)] text-xs sm:text-base flex items-start flex-col sm:flex-row" htmlFor={item.name}>
                            <span className='font-medium-'>{item.label}</span>
                          </label></div>
                      </div>
                    })}
                  </div>
                  <div className="basis-full flex flex-wrap px-4 py-3">
                    <label className='text-base md:text-lg text-[#777] basis-full mb-3'>
                      <strong>Willing to travel for voluntary work?</strong>
                      <small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      {(errors.travel_work && touched.travel_work) &&
                        <small id='travel_work-error' className="text-[#f60362] text-sm">
                          {errors.travel_work}
                        </small>}
                    </label>
                    <div className='basis-1/2 sm:basis-1/4 md:basis-1/6 mb-2'>
                      <label className='text-[#777] text-xs sm:text-base flex items-center'>
                        <input
                          className="w-4 h-4 border-[0.125rem] mr-1 border-solid border-[rgba(0,0,0,0.25)]- cursor-pointer text-[#00a3da] bg-gray-100 border-gray-300 focus:ring-[#00a3da] focus:ring-2 checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                          id={'travel_work'}
                          type="radio"
                          name="travel_work"
                          onChange={e => handleChange(e)}
                          value={true}
                          defaultChecked
                        />
                        <span className='ml-1'>Yes</span>
                      </label>
                    </div>
                    <div className='basis-1/2 sm:basis-1/4 md:basis-1/6 mb-2'>
                      <label className='text-[#777] text-xs sm:text-base flex items-center'>
                        <input
                          className="w-4 h-4 border-[0.125rem] mr-1 border-solid border-[rgba(0,0,0,0.25)]- cursor-pointer text-[#00a3da] bg-gray-100 border-gray-300 focus:ring-[#00a3da] focus:ring-2 checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                          id={'travel_work'}
                          type="radio"
                          name="travel_work"
                          onChange={e => handleChange(e)}
                          value={false}
                          defaultChecked={values.travel_work === false}
                        />
                        <span className='ml-1'>No</span>
                      </label>
                    </div>
                  </div>
                  <div className="basis-full flex flex-wrap px-4 py-3">
                    <label className='text-base md:text-lg text-[#777] basis-full mb-3'><strong>Availability?</strong></label>
                    <label className='text-base md:text-lg text-[#777] basis-full mb-3'>
                      Can you commit to volunteering
                      <strong> 2-4 hours per week?</strong>
                      <small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      {(errors.availability_hours && touched.availability_hours) &&
                        <small id='availability_hours-error' className="text-[#f60362] text-sm">
                          {errors.availability_hours}
                        </small>}
                    </label>
                    <div className='basis-1/2 sm:basis-1/4 md:basis-1/6 mb-2'>
                      <label className='text-[#777] text-xs sm:text-base flex items-center'>
                        <input
                          className="w-4 h-4 border-[0.125rem] mr-1 border-solid border-[rgba(0,0,0,0.25)]- cursor-pointer text-[#00a3da] bg-gray-100 border-gray-300 focus:ring-[#00a3da] focus:ring-2 checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                          id={'availability_hours'}
                          type="radio"
                          name="availability_hours"
                          onChange={e => handleChange(e)}
                          value={true}
                          defaultChecked
                        />
                        <span className='ml-1'>Yes</span>
                      </label>
                    </div>
                    <div className='basis-1/2 sm:basis-1/4 md:basis-1/6 mb-2'>
                      <label className='text-[#777] text-xs sm:text-base flex items-center'>
                        {/* <Field
                          type="radio"
                          name="availability_hours"
                          value={"No"}
                          className="cursor-pointer"
                          onChange={(e) => {
                            handleChange(e)
                          }} /> */}
                        <input
                          className="w-4 h-4 border-[0.125rem] mr-1 border-solid border-[rgba(0,0,0,0.25)]- cursor-pointer text-[#00a3da] bg-gray-100 border-gray-300 focus:ring-[#00a3da] focus:ring-2 checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                          id={'availability_hours'}
                          type="radio"
                          name="availability_hours"
                          onChange={e => handleChange(e)}
                          value={false}
                          defaultChecked={values.availability_hours === false}
                        />
                        <span className='ml-1'>No</span>
                      </label>
                    </div>
                  </div>
                  <div className="basis-full flex flex-wrap px-4 py-3">
                    <label className='text-base md:text-lg text-[#777] basis-full mb-3'>
                      <strong>What times would you be available to volunteer?</strong>
                      <small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      {(errors.availability_times && touched.availability_times) &&
                        <small id='availability_times-error' className="text-[#f60362] text-sm">
                          {errors.availability_times}
                        </small>}
                    </label>
                    {userInfo.availability_times.map((item, index) => {
                      return <div key={`availability_times${index}`} className='basis-1/2 sm:basis-1/3 lg:basis-1/4 mb-2'>
                        <label className='text-[#777] w-[calc(100%_-_1.125rem)]- text-xs sm:text-base flex items-center flex-col- flex-row'>
                          <Field
                            type="checkbox"
                            name="availability_times"
                            value={item.name}
                            className="relative float-left mr-1 h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-[#00a3da] checked:bg-[#00a3da] checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-[#00a3da] checked:focus:bg-[#00a3da] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                          />
                          {item.label}
                        </label>
                        {/* <label className='text-[#777] text-xs sm:text-base flex items-center'>
                          <Field
                            type="radio"
                            name="availability_times"
                            value={item.name}
                            className="cursor-pointer"
                            onChange={(e) => {
                              handleChange(e)
                              // handleAvailableTimes(e, index)
                            }} />
                          <span className='ml-1'>{item.label}</span>
                        </label> */}
                      </div>
                    })}
                  </div>
                  <div className="basis-full flex flex-wrap px-4 py-3">
                    <label className='text-base md:text-lg text-[#777] basis-full mb-3'>
                      <strong>Are you volunteering elsewhere?</strong>
                      <small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                    </label>
                    <div className='basis-1/2 sm:basis-2/12 md:basis-1/12 mb-2 flex items-center'>
                      <label className='text-[#777] text-xs sm:text-base flex items-center'>
                        <input
                          className="w-4 h-4 border-[0.125rem] mr-1 border-solid border-[rgba(0,0,0,0.25)]- cursor-pointer text-[#00a3da] bg-gray-100 border-gray-300 focus:ring-[#00a3da] focus:ring-2 checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                          id={'volunteering_elsewhere'}
                          type="radio"
                          name="volunteering_elsewhere"
                          onChange={e => handleChange(e)}
                          value={'true'}
                        // defaultChecked
                        />
                        <span className='ml-1'>Yes</span>
                      </label>
                    </div>
                    <div className='basis-1/2 sm:basis-2/12 md:basis-1/12 mb-2 flex items-center'>
                      <label className='text-[#777] text-xs sm:text-base flex items-center'>
                        <input
                          className="w-4 h-4 border-[0.125rem] mr-1 border-solid border-[rgba(0,0,0,0.25)]- cursor-pointer text-[#00a3da] bg-gray-100 border-gray-300 focus:ring-[#00a3da] focus:ring-2 checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                          id={'volunteering_elsewhere'}
                          type="radio"
                          name="volunteering_elsewhere"
                          onChange={e => handleChange(e)}
                          value={'false'}
                          // defaultChecked={values.volunteering_elsewhere === 'false'}
                          defaultChecked
                        />
                        <span className='ml-1'>No</span>
                      </label>
                    </div>
                    <div className='basis-full sm:basis-8/12 md:basis-10/12 flex mb-2'>
                      <label className='text-sm md:text-md text-[#777] w-full flex items-center'>Details
                        <input
                          type="text"
                          name='volunteering_elsewhere_details'
                          id='volunteering_elsewhere_details'
                          placeholder='Enter details of your role and which organisation'
                          className={` disabled:bg-gray-100 disabled:cursor-not-allowed ml-2 min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear`}
                          onBlur={handleBlur}
                          disabled={`${values.volunteering_elsewhere}` === 'false'}
                          onChange={(e) => { handleChange(e) }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="basis-full flex flex-wrap px-4 py-2">
                    <label className='text-base md:text-lg text-[#777] basis-full mb-3'><strong>Why would you like to volunteer for us?</strong><small className='text-[#f60362] text-lg leading-4 ml-1'>*</small></label>
                    <label className='text-sm md:text-md text-[#777] basis-full'>Please provide a <strong>detailed response</strong> for your drive behind wanting to volunteer with MATW Project.
                      <textarea
                        type="text"
                        name='description'
                        id='description'
                        rows={10}
                        placeholder=''
                        className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {(errors.description && touched.description) &&
                        <small id='description-error' className="text-[#f60362] text-sm">
                          {errors.description}
                        </small>}
                    </label>
                  </div>
                  <div className="basis-full flex flex-wrap px-4 py-2">
                    <label className='text-base md:text-lg text-[#00a3da] basis-full mb-3'>
                      Confirmation statement
                      <small className='text-[#f60362] text-lg leading-4 ml-1'>*</small>
                      {(errors.confirmation && touched.confirmation) &&
                        <small id='confirmation-error' className="text-[#f60362] text-sm">
                          {errors.confirmation}
                        </small>}
                    </label>
                    <div className='basis-full mb-2 flex items-center'>
                      <Field
                        type="checkbox"
                        name="confirmation"
                        className="relative float-left mr-1 h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-[#00a3da] checked:bg-[#00a3da] checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-[#00a3da] checked:focus:bg-[#00a3da] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                      />
                      <label className='text-[#777] w-[calc(100%_-_1.125rem)] ml-1 text-xs sm:text-base flex items-center'>
                        I agree to provide a valid police check/certificate upon request, and understand that failing to do so, may result in my registration being cancelled.
                        <span className='ml-1 text-red-600'>{values.confirmation}</span>
                      </label>
                    </div>
                  </div>
                  {/* /* -------------------------------------------------------------------------- */}
                  <div className="basis-full flex flex-wrap justify-center px-4 py-4">
                    {/* <Button isLoading={isLoading} title={'Submit application'} className={'!w-auto'}/> */}
                    <div className="col-start-1 col-span-2 md:col-span-1">
                    </div>
                    <div className="col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]`}
                      >
                        {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        </>) : <><span>Submit application</span>
                        </>}
                      </button>
                      <button
                        type="reset"
                        ref={resetRef}
                        className='opacity-0'
                        onClick={resetForm}
                      >
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Card>
        </div>
      </div>

      <div className="flex flex-wrap justify-center py-10 mt-6">
        <div className="basis-full md:basis-10/12 px-4 md:px-0 flex justify-center flex-wrap relative">
          <div className="grid grid-cols-12 px-4 md:px-0">
            <div className="col-start-1 col-span-12 md:col-start-2- md:col-span-10- rounded-md bg-white- p-6- mt-4">
              <h2 className="text-[#253B7E] text-left  sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] font-brandingBold">
                Frequently Asked Questions
              </h2>
              {volunteerFaqs.map((item, index) => {
                return (
                  <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                    <Accordion
                      key={`keyallfaq${index}`}
                      shadow
                      title={item.question}
                      index={index}
                      level={1}
                      noBorder={true}
                      className="!text-[#253B7E] text-lg font-brandingBold"
                    >
                      {item.answer}
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-10 bg-[#00a3da] flex-col items-center justify-center px-4 hidden flex-">
        <div className="md:container md:mx-auto flex justify-center flex-wrap">
          <div className="basis-full md:basis-1/2 lg:basis-3/6 flex-col mb-4 md:mb-0">
            <PaperAirplaneIcon className="w-8 h-8 text-[#23408f] mb-4 transform:rotate -rotate-45" />
            <p className="text-white text-xl md:text-3xl">
              Sign up and see how Ali Banat’s <br />
              Legacy (and yours) is growing <br />
              and making an impact on the world.
            </p>
          </div>
          <div className="basis-full md:basis-1/2 lg:basis-1/6 flex-col">
            <h2 className="text-white text-xl mb-2">Stay in touch</h2>
            <input
              type="text"
              name="firstname"
              id="firstname"
              placeholder="First Name"
              className="min-h-[40px] md:min-h-[48px] w-full mb-4 text-base md:text-md rounded bg-white py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
            />
            <input
              type="text"
              name="firstname"
              id="firstname"
              placeholder="Email Address"
              className="min-h-[40px] md:min-h-[48px] w-full mb-4 text-base md:text-md rounded bg-white py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
            />
            <button className="bg-[#f60362] h-[48px] px-8 py-2 rounded text-white">Subscribe</button>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

const FormikReactSelect = (props) => {
  const { name, defaultValue, onChange, ...restProps } = props;
  const [field] = useField(name);
  // let [field] = useField(name);
  // const [field] = {value:[defaultValue.toUpperCase()], name: 'country'};
  // field.value = [defaultValue.toUpperCase()];
  const { setFieldValue } = useFormikContext();

  //flatten the options so that it will be easier to find the value
  const flattenedOptions = props.options?.flatMap((o) => {
    const isNotGrouped = "value" in o;
    if (isNotGrouped) {
      return o;
    } else {
      return o.options;
    }
  });

  //get the value using flattenedOptions and field.value
  const value = flattenedOptions?.filter((o) => {
    const isArrayValue = Array.isArray(field.value);
    if (isArrayValue) {
      const values = field.value;
      return values.includes(o.value);
    } else {
      return field.value === o.value;
    }
  });

  // console.log("change country first=", defaultValue, value3, value, field)
  // console.log("change country first=", value, field)
  return (
    <Select
      {...restProps}
      value={value}
      className='h-[46px] border-0 [&>*]:!border-0 [&>*]:!min-h-[46px]'
      // defaultValue={[defaultValue.toUpperCase()]}
      // defaultValue={value[3]}
      // onChange implementation
      onChange={(val) => {
        //here I used explicit typing but there maybe a better way to type the value.
        // console.log("change country", val, value2)
        onChange(val)
        const _val = val;
        const isArray = Array.isArray(_val);
        if (isArray) {
          const values = _val.map((o) => o.value);
          setFieldValue(name, values);
        } else {
          setFieldValue(name, _val.value);
        }
      }}
    />
  );
};

export default NewVolunteerPage;

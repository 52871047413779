import { createSlice } from '@reduxjs/toolkit';

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    navbarMenu: [],
    highlightMenu: [],
    footerMenu: [],
  },
  reducers: {
    setNavbarMenu(state, action) {
      state.navbarMenu = action.payload;
    },
    setHighlightMenu(state, action) {
      state.highlightMenu = action.payload;
    },
    setFooterMenu(state, action) {
      state.footerMenu = action.payload;
    },
  },
});

export const { setNavbarMenu, setFooterMenu, setHighlightMenu } = menuSlice.actions;

export const selectNavbarMenu = state => state.menu.navbarMenu;
export const selectHighlightMenu = state => state.menu.highlightMenu;
export const selectFooterMenu = state => state.menu.footerMenu;

export default menuSlice.reducer;

// import stripeServices from './stripe';
import userServices from './user';
import paypalServices from './paypal';
import productsServices from './products';
import dashboardServices from './dashboard';
import reportsServices from './reports';
import cryptoServices from './crypto';
import menuServices from './menu';
import categoriesServices from './categories';
// import dashboardServices from './dashboard';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...userServices,
    ...paypalServices,
    ...productsServices,
    ...dashboardServices,
    ...reportsServices,
    ...cryptoServices,
    ...menuServices,
    ...categoriesServices,
};

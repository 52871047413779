import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import TabContent from '../home/components/tab-content';
import { useRef } from 'react';

const WinterTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { winterEmergencyProducts, pakistanWinterEmergencyProducts, arabic = false, lang = 'en' } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  let href = window.location.href;
  let addPakistanTab = href.includes('matwproject.org.uk') || href.includes('matwprojectusa.org') || href.includes('localhost');
  const tabButtonsProducts = [
    {
      title: 'Winter Emergency',
      description: '',
      products: winterEmergencyProducts,
    },
    {
      title: 'Pakistan Winter Emergency',
      description: '',
      products: pakistanWinterEmergencyProducts,
    },
  ];
  // const checkingDomain = () => {
  //   let href = window.location.href;
  //   console.log("checking=", href.includes('localhost'))
  //   let temp = tabButtonsProducts;
  //   if (href.includes('matwproject.org.uk') || href.includes('matwproject.org.uk') || href.includes('localhost')) {
  //     console.log("checked=", href.includes('localhost'))
  //     temp.push({
  //       title: 'Pakistan Winter Emergency',
  //       description: '',
  //       products: pakistanWinterEmergencyProducts,
  //     })
  //   }
  //   setTabButtonsProducts(temp)
  // };
  /* -------------------------------------------------------------------------- */

  const handleTabChange = index => {
    window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
    setActiveTabProducts(index);
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {tabButtonsProducts.map(({ title }, index) => {
            if (title === 'Pakistan Winter Emergency') {
              if (addPakistanTab) {
                return (
                  <button
                    key={`btn_${index}`}
                    onClick={handleTabChange.bind(this, index)}
                    className={`${
                      index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
                    } text-[14px] rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${
                      arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'
                    }`}
                  >
                    {title}
                  </button>
                );
              }
              return null;
            } else {
              return (
                <button
                  key={`btn_${index}`}
                  onClick={handleTabChange.bind(this, index)}
                  className={`${
                    index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
                  } text-[14px] rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${
                    arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'
                  }`}
                >
                  {title}
                </button>
              );
            }
          })}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {tabButtonsProducts.map(
              (item, index) =>
                index === activeTabProducts && (
                  <React.Fragment key={`description_${index}`}>
                    <div ref={contentRef}>
                      <TabContent
                        arabic={arabic}
                        lang={lang}
                        products={item.products}
                        currency={selectedCurrency}
                        showDots={true}
                        showArrows={false}
                        showMultiProductsOnRow={false}
                        viewAppealsLink={item.viewAppealsLink || null}
                      />
                    </div>
                  </React.Fragment>
                ),
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default WinterTabs;

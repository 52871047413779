import slider1 from '../../../images/eidFitr2024/slider1.jpg';
import slider2 from '../../../images/eidFitr2024/slider2.jpg';
import slider3 from '../../../images/eidFitr2024/slider3.jpg';
import slider4 from '../../../images/eidFitr2024/slider4.jpg';
import slider5 from '../../../images/eidFitr2024/slider5.jpg';
import slider6 from '../../../images/eidFitr2024/slider6.jpg';
import slider7 from '../../../images/eidFitr2024/slider7.jpg';
import slider8 from '../../../images/eidFitr2024/slider8.jpg';
import slider9 from '../../../images/eidFitr2024/slider9.jpg';
import slider10 from '../../../images/eidFitr2024/slider10.jpg';
import slider11 from '../../../images/eidFitr2024/slider11.jpg';
import slider12 from '../../../images/eidFitr2024/slider12.jpg';
import slider13 from '../../../images/eidFitr2024/slider13.jpg';
import slider14 from '../../../images/eidFitr2024/slider14.jpg';
import slider15 from '../../../images/eidFitr2024/slider15.jpg';
import slider16 from '../../../images/eidFitr2024/slider16.jpg';

export const images = [
  { src: slider1, alt: 'Eid Mubarak 2024: Happy Eid from MATW' },
  { src: slider2, alt: 'Eid Mubarak Wishes from MATW' },
  { src: slider3, alt: 'Happy Eid Mubarak from MATW' },
  { src: slider4, alt: 'Eid Mubarak Fitr 2024: Marking the end of Ramadan' },
  { src: slider5, alt: '' },
  { src: slider6, alt: '' },
  { src: slider7, alt: '' },
  { src: slider8, alt: '' },
  { src: slider9, alt: '' },
  { src: slider10, alt: '' },
  { src: slider11, alt: '' },
  { src: slider12, alt: '' },
  { src: slider13, alt: '' },
  { src: slider14, alt: '' },
  { src: slider15, alt: '' },
  { src: slider16, alt: '' },
];

const AddUniversalUSAScript = () => {
  const addScriptOnHead = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'facebook_script';
    script.innerHTML = `var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://t.matwprojectusa.org/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL);head.appendChild(script);`;
    document.getElementsByTagName('head')[0].appendChild(script);
  };
  var href = window.location.href;
  var scripts = document.getElementsByTagName('script');
  // console.log('AddUniversalUSAScript=', href, href.includes('localhost'));
  var is_exist = false;
  // if (href.includes('localhost')) {
  if (href.includes('matwprojectusa.org')) {
    for (var i = scripts.length; i--; ) {
      if (scripts[i].id === 'facebook_script') is_exist = true;
    }
    !is_exist && addScriptOnHead();
  }
};

export default AddUniversalUSAScript;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import TabContent from '../../home/components/tab-content';

const GiftSoundProducts = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const { giftSoundsProducts} = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */
  const tabButtonsProducts = [
    {
      products: giftSoundsProducts,
      description: `Give the gift of sound with your Sadaqah Jariyah and transform the lives of those in need.`,
    }
  ];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {tabButtonsProducts.map((item, index) =>
              <React.Fragment key={`description_${index}`}>
                <div className="flex w-full justify-center mb-6">
                  <p className='text-center text-white text-[18px] md:text-3xl md:w-2/3'>
                    {item.description}
                  </p>
                </div>
                <TabContent
                  products={item.products}
                  showArrows={false}
                  currency={selectedCurrency} />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>

  );
};
export default GiftSoundProducts;
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import CustomButton from "../../../../components/general/button";


const HighlightMenu = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const {
        columnsData,
        tableData,
        isLoading,
        onDeleteMenu,
        onUpdateMenu,
        onAddMenu
    } = props;
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    // const handleSearch = (name, value) => {
    //     let tempSearchItems = searchItems;
    //     tempSearchItems[name] = value;
    //     // setSearchItems({ ...searchItems, [name]: value });
    //     // console.log("on handle search=",name,value)
    //     onSearchItems({ [name]: value })
    // }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */

    useEffect(() => {
        // currentSearches && setSearchItems(currentSearches)
        console.log("is loading=", isLoading, window)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])
    useEffect(() => {
        // currentSearches && setSearchItems(currentSearches)
        console.log("tableData=", tableData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData])
    /* -------------------------------------------------------------------------- */
    return (
        <div className="sm:overflow-auto w-full pt-6">
            <div className='basis-full flex justify-between border-b border-stone-300 pb-2'>
                <h2 className='text-lg md:text-2xl text-stone-700'>Highlight Items</h2>
                <CustomButton
                    type='button'
                    onClick={() => onAddMenu(0)}
                    title={'Add Menu'}
                    className='!h-[42px] !bg-[#00A3DA]'
                />
            </div>
            <div className="mt-8 overflow-hidden min-h-[400px]">
                {isLoading
                    ? <div role="status" className='w-full col-span-12 flex justify-center items-center min-h-[500px]'>
                        <svg aria-hidden="true" className="w-20 h-20 mr-2 text-gray-200 animate-spin fill-[#49acea]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                    : tableData.length ? <div className="w-full overflow-x-scroll lg:overflow-x-auto">
                        <table
                            className="w-full min-w-[1500px] 2xl:table-fixed"
                            variant="simple"
                            color="gray-500"
                            mb="24px"
                        >
                            <thead>
                                <tr>
                                    {columnsData.map((headerGroup, index) => (
                                        <th className="border-b border-gray-200 pr-16 pb-[10px] text-start uppercase font-['Montserrat']" key={index}>
                                            <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs whitespace-nowrap">
                                                {headerGroup.Header}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.length && tableData.map((row, rowIndex) => {
                                    return (
                                        <tr key={rowIndex} className={`${rowIndex % 2 === 0 ? 'bg-gray-50' : ''} transition-all duration-200 hover:bg-gray-100`}>
                                            {columnsData.map((cell, index) => {
                                                let data = "";
                                                if (cell.Header === "Image") {
                                                    data = (
                                                        <p className="text-xs text-left font-medium text-navy-700 pr-1 lowercase overflow-hidden">
                                                            <img src={row[cell.accessor] ? row[cell.accessor] : '/images/general/logo-replace.png'} alt="slider" className={`${row[cell.accessor] ? 'w-full h-auto rounded max-w-[80px]' : 'w-auto mx-auto'}`} onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src = "/images/general/logo-replace.png";
                                                                currentTarget.className = 'max-h-[64px] mx-auto'
                                                            }} />
                                                        </p>
                                                    );
                                                } else if (cell.Header === "Title") {
                                                    data = (
                                                        <p className="text-sm text-left font-medium text-navy-700 pr-1 overflow-hidden">
                                                            {row[cell.accessor] ? row[cell.accessor] : <span className="text-xs font-normal text-stone-400">NULL</span>}
                                                        </p>
                                                    );
                                                } else if (cell.Header === "Link") {
                                                    data = (
                                                        <p className="text-sm text-left font-medium text-navy-700 pr-1 2xl:px-1 max-h-12 overflow-hidden">
                                                            {row[cell.accessor] ? row[cell.accessor] : <span className="text-xs font-normal text-stone-400">NULL</span>}
                                                        </p>
                                                    );
                                                } else if (cell.Header === "Order") {
                                                    data = (
                                                        <p className="text-sm text-left font-medium text-navy-700 pr-1 lowercase overflow-hidden">
                                                            {row[cell.accessor] ? row[cell.accessor] : <span className="text-xs font-normal text-stone-400">NULL</span>}
                                                        </p>
                                                    );
                                                } else if (cell.Header === "Created Date") {
                                                    data = (
                                                        <p className="text-xs text-left font-medium text-navy-700">
                                                            {moment(row[cell.accessor], 'YYYY-MM-DD').format('MM-DD-YYYY')}
                                                        </p>
                                                    );
                                                } else if (cell.Header === "Updated Date") {
                                                    data = (
                                                        <p className="text-xs text-left font-medium text-navy-700">
                                                            {moment(row[cell.accessor], 'YYYY-MM-DD').format('MM-DD-YYYY')}
                                                        </p>
                                                    );
                                                } else if (cell.Header === "Status") {
                                                    data = (
                                                        <p className="text-sm text-left font-medium text-navy-700">
                                                            <span className={`flex px-2 py-1 rounded-full w-fit ${row[cell.accessor] ? 'border border-green-700 bg-green-50 text-green-700' : 'border border-red-700 bg-red-50 text-red-700'}`}>
                                                                {row[cell.accessor] ? 'Active' : 'Inactive'}
                                                            </span>
                                                        </p>
                                                    );
                                                } else if (cell.Header === "Actions") {
                                                    data = (
                                                        <div className="flex text-white">
                                                            <button className='bg-orange-500 text-white hover:bg-orange-700 h-10 w-10 min-w-[40px] flex items-center justify-center rounded mr-2 transition-all duration-200' onClick={() => {
                                                                // onUpdateMenu(row[cell.accessor])
                                                                onUpdateMenu(row)
                                                            }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                </svg>
                                                            </button>
                                                            <button
                                                                type='button'
                                                                onClick={async () => {
                                                                    await Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: "You will not be able to recover this MENU!",
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        cancelButtonText: 'No, cancel it!',
                                                                        confirmButtonText: 'Yes, I am sure!',
                                                                        dangerMode: true,
                                                                    }).then(function (isConfirm) {
                                                                        if (isConfirm.isConfirmed) {
                                                                            onDeleteMenu(row[cell.accessor])
                                                                        } else {
                                                                            console.log("cansel", isConfirm)
                                                                            new Swal("Cancelled", "This menu wasn’t removed", "error");
                                                                        }
                                                                    })
                                                                }}
                                                                className={`text-[#F60362] text-white- w-10 h-[42px] min-w-[40px] rounded hidden md:flex justify-center items-center`}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <td
                                                        key={index}
                                                        className="pt-[14px] pb-[16px] sm:text-[14px] text-stone-500 font-normal max-w-max- w-[150px]- max-w-[120px]-"
                                                    >
                                                        {data}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div> : <div className="w-full flex items-center justify-center">No Data</div>
                }
            </div>
        </div>
    );
};

export default HighlightMenu;
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';

const SocialBox = props => {
  return (
    <Popover className="relative col-span-12 sm:col-span-6">
      {({ open }) => (
        <>
          <PopoverButton
            className={`
                ${open ? '' : 'text-opacity-90'}
                text-white group w-full inline-flex items-center  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            {props.children || (
              <div className="w-full flex flex-col justify-center items-center p-2 md:p-4 relative rounded-lg border-2 border-[#14A2DC] hover:opacity-80 duration-100">
                <h3 className=" text-[#14A2DC] text-lg md:text-xl text-center font-medium h-16 flex items-center">{props.title}</h3>
                <div className="flex justify-center items-center h-24 text-[#14A2DC]">{props.svg}</div>
                <p className=" text-[#78716C] mt-4 md:mt-8 text-center">{props.description}</p>
              </div>
            )}
          </PopoverButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className={`absolute z-10  px-10 transform -translate-x-1/2 left-1/2 ${props.top ? '-top-[60px] left-[60px] mt-0' : 'mt-3'}`}>
              <div className="overflow-hidden min-w-max md:min-w-[260px] rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative bg-white p-2 md:p-4 flex items-center justify-center gap-2 md:gap-3">
                  <FacebookShareButton url={window.location.href}>
                    <img src="/images/social-media/facebook.png" alt="facebook" className="w-3 md:w-4 h-8-" />
                  </FacebookShareButton>
                  <WhatsappShareButton url={window.location.href}>
                    <img src="/images/social-media/whatsapp.png" alt="whatsapp" className="w-5 md:w-8 h-8-" />
                  </WhatsappShareButton>
                  <TwitterShareButton url={window.location.href}>
                    <img src="/images/social-media/twitter.png" alt="twitter" className="w-5 md:w-8 h-8-" />
                  </TwitterShareButton>
                  <LinkedinShareButton url={window.location.href}>
                    <img src="/images/social-media/linkedin.png" alt="linkedin" className="w-5 md:w-8 h-8-" />
                  </LinkedinShareButton>
                  <EmailShareButton url={window.location.href}>
                    <img src="/images/social-media/email.png" alt="email" className="w-5 md:w-8" />
                  </EmailShareButton>
                </div>
              </div>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default SocialBox;

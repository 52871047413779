import React from 'react'
import imageJHCO from '../../../../images/landings/palestine/image-jhco.png';
import imageAlHilal from '../../../../images/landings/palestine/image-alhilal.png';
import imageEyc from '../../../../images/landings/palestine/image-eyc.png';

const MuslimsAroundSection = ({ arabic, lang = 'en' }) => {
  return (
    <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="flex flex-col justify-center items-center gap-5 md:gap-8">
        <p className={`text-2xl md:text-[40px] max-w[884px] text-[#253B7E] text-center ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
          {arabic || lang === 'ar'
            ? 'مسلمون حول العالم (MATW) على الأرض في فلسطين:'
            : lang === 'fr'
              ? `Muslims Around The World (MATW) sur le terrain en Palestine`
              : 'Muslims Around The World (MATW) on the ground in Palestine:'
          }
        </p>
        <h2 className={`text-[#78716C] text-center text-sm md:text-[18px] ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
          {arabic || lang === 'ar'
            ? 'بالشراكة مع'
            : lang === 'fr'
              ? `En partenariat avec`
              : 'In Partnership with'
          }
        </h2>
      </div>
      <div className="flex justify-center items-center flex-col gap-3 md:gap-6">
        <img src={imageJHCO} alt="jhco logo" className="w-[301px] md:w-[585px]" />
        <div className="flex gap-5 items-center">
          <img src={imageAlHilal} alt="alhilal logo" className="w-[120px] md:w-[201px]" />
          <img src={imageEyc} alt="eyc logo" className="w-[71px] md:w-[100px]" />
        </div>
      </div>
      <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
        <p>
          {arabic || lang === 'ar'
            ? 'مع وجود طويل الأمد في فلسطين والإمدادات على أهبة الاستعداد للاستجابة الفورية ، كان فريق MATW سريعا في تقديم الضروريات العاجلة لأولئك المتضررين بشكل مباشر من النزاع في فلسطين. وتركز جهودنا للاستجابة المباشرة على الفئات الأكثر ضعفا بما في ذلك الأيتام والأرامل والمسنين والأسر.'
            : lang === 'fr'
              ? `Avec une présence de longue date en Palestine et des fournitures en attente d’une réponse immédiate, notre équipe MATW a été rapide pour livrer des produits de première nécessité aux personnes directement touchées par le conflit en Palestine. Nos efforts d’intervention directe se concentrent sur les personnes les plus vulnérables, notamment les orphelins, les veuves, les personnes âgées et les familles.`
              : `With a long-standing presence in Palestine and supplies on standby for an immediate response, our MATW team has been quick to deliver
              urgent essentials to those directly affected by the conflict in Palestine. Our direct response efforts are focused on those who are most
              vulnerable including orphans, widows, the elderly and families.`
          }
        </p>
        <p>
          {arabic || lang === 'ar'
            ? 'يقوم فريق العمليات لدينا بتسليم مجموعات الطوارئ الطبية والطرود الغذائية العاجلة والوقود والوجبات الساخنة والمزيد للنازحين.'
            : lang === 'fr'
              ? `Notre équipe des opérations distribue des trousses médicales d’urgence, des colis alimentaires d’urgence, du carburant, des repas chauds et bien plus encore aux personnes déplacées.`
              : `Our operations team is delivering emergency medical kits, urgent food packs, fuel, hot meals and more to those who have been displaced.`
          }
        </p>
        <p>
          {arabic || lang === 'ar'
            ? 'أثبتت استجابتنا الطبية داخل المستشفيات أنها شريان حياة بالغ الأهمية. وبفضل المتبرعين، تمكن فريقنا من تقديم نقالات وأسرة وفرش وبطانيات ومستلزمات طبية. كما تمكنا من توفير الدعم والوقود للموظفين لضمان استمرار تشغيل المخيمات الطبية المؤقتة والمستشفيات العاملة.'
            : lang === 'fr'
              ? `Notre intervention médicale au sein des hôpitaux s’est avérée être une bouée de sauvetage essentielle. Grâce aux donateurs, notre équipe a pu livrer des civières, des lits, des matelas, des couvertures, des trousses médicales et des fournitures. Nous avons également été en mesure de fournir du personnel et du carburant pour assurer le bon fonctionnement des camps médicaux de fortune et des hôpitaux opérationnels.`
              : `Our medical response within hospitals proved to be a critical lifeline. Thanks to donors, our team was able to deliver stretchers, beds,
          mattresses, blankets, medical kits and supplies. We also were able to provide staff support and fuel to ensure medical make-shift camps
          and operational hospitals continue to run.`
          }
        </p>
        <p className={`text-2xl text-[#253B7E] ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
          {arabic || lang === 'ar'
            ? 'يقوم فريقنا بالتوزيع بشكل أساسي مع الهيئة الخيرية الأردنية الهاشمية (JHCO). كما أننا نتشارك مع مجلس الشباب المصري والهلال الأحمر المصري لضمان وصول الإغاثة إلى المحتاجين في فلسطين.'
            : lang === 'fr'
              ? `Notre équipe distribue l’aide principalement avec l’Organisation caritative hachémite jordanienne (JHCO). Nous travaillons également en partenariat avec le Conseil égyptien de la jeunesse et le Croissant-Rouge égyptien pour veiller à ce que l’aide parvienne aux personnes dans le besoin en Palestine.`
              : `Our team is primarily distributing with the Jordanian Hashemite Charity Organisation (JHCO). We’re also partnered with the Egyptian Youth
              Council, the Egyptian Red Crescent to ensure relief is reaching those in need in Palestine.`
          }
        </p>
        <p>
          {arabic || lang === 'ar'
            ? 'كما نعمل على توفير الضروريات العاجلة ودعم نقل الأسر للعائلات الفلسطينية التي تسعى للجوء في مصر. من خلال هذه المشاريع، سيتمكن المانحون من مساعدتنا في توفير رعاية الأسرة ودعم الاستقرار لضمان بداية جديدة للعائلات بكل ما تحتاجه.'
            : lang === 'fr'
              ? `Nous nous efforçons également de fournir des produits de première nécessité et une aide à la réinstallation des familles palestiniennes qui cherchent refuge en Égypte. Grâce à ces projets, les donateurs pourront nous aider à parrainer des familles et à les aider à s’établir afin d’assurer un nouveau départ aux familles avec tout ce dont elles ont besoin.`
              : `We are also working to provide urgent essentials and family relocation support to Palestinian families seeking refuge in Egypt. Through
          these projects, donors will be able to help us provide family sponsorship and settlement support to ensure a new start for families with
          all that they need.`
          }
        </p>
        <p>
          {arabic || lang === 'ar'
            ? 'ونحن مصممون أكثر من أي وقت مضى على ألا نستسلم أبدا. كل حياة لها قيمة ، ودعمك يسمح لنا بمواصلة القيام بالعمل الذي نقوم به.'
            : lang === 'fr'
              ? `Nous sommes plus déterminés que jamais à ne jamais abandonner. Chaque vie a de la valeur, et votre soutien nous permet de continuer à faire le travail que nous faisons.`
              : `We are determined as ever to never give up. Every life is valuable, and your support allows us to continue doing the work that we do.`
          }
        </p>
        <p>
          {arabic || lang === 'ar'
            ? 'وبشفافية تامة للمانحين، فإن كل تبرع لنداء فلسطين الطارئ سيذهب مباشرة إلى الميدان، مع سياسة تبرع بنسبة 100٪.'
            : lang === 'fr'
              ? `Avec une transparence totale vis-à-vis de nos donateurs, chaque don à notre Appel d’urgence pour la Palestine ira directement sur le terrain, avec une politique de don à 100%.`
              : `With full transparency to our donors, every single donation to our Palestine Emergency Appeal will go directly to the field, with a 100%
            donation policy.`
          }
        </p>
        <p>
          {arabic || lang === 'ar'
            ? 'كما أن التبرعات لنداء فلسطين الطارئ هي أيضا زكاة مطبقة بنسبة 100٪ .'
            : lang === 'fr'
              ? `Les dons à notre Appel d’urgence pour la Palestine sont également applicables à 100% à la Zakat.`
              : `Donations to our Palestine Emergency Appeal are also 100% Zakat applicable.`
          }
        </p>
      </div>
    </div>
  )
}

export default MuslimsAroundSection
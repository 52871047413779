import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-new';
import { useSelector } from 'react-redux';
import SeoData from '../../../utils/seo.json';
import { selectProducts } from '../../../store/products';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import WidgetHome from '../../../components/widgets/widgetHome';
import Top10ProductBox from './top10ProductBox';
import services from '../../../services';
import moment from 'moment';
import NewHelmetLayout from '../../../components/general/newHelmetLayout';


const Top10Products = ({page = 'general'}) => {
  // const [products, setProducts] = useState();
  const products = useSelector(state => selectProducts(state));
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [topProducts, setTopProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [view, setView] = useState('grid');
  const palestineProductID = [74,136,250,251,252,253,254,255,335,336,337,338,339,352,353,354,355,356,357,358,371,372,373,374,375,376,399,400,401,402,403,404,405,406,409,480,481,482,546,547,548,549,550,551,552,611,624,592,593,594,595,596,597,598,599,600,601,602,604,605,606,607,623,642,643,644,646,647,648,649];
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    getTop10Products()
  };
  const getTop10Products = async () => {
    // setIsLoading(true);
    let tempTopProducts = [];
    let tempGeneralSearch = {
      from: new Date().toISOString().split('T')[0],
      to: new Date().toISOString().split('T')[0],
      host: window.location.hostname,
      limit: 11
    };
    let tempPalestineSearch = {
      from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      limit: 11,
      products: palestineProductID
    };
    let tempSearch = page === 'general' ? tempGeneralSearch : tempPalestineSearch
    try {
      const response = await services.getTop20Products(tempSearch);
      // console.log("ProductsTransactionTotalReport", response);
      let temp = [];
      response.data.map(item => {
        item.product_name !== 'Admin Contribution' && temp.push(item)
        return item;
      })
      let compare = (a, b) => {
        if (a.total_sales < b.total_sales) {
          return -1;
        }
        if (a.total_sales > b.total_sales) {
          return 1;
        }
        return 0;
      }
      let sortable = temp.sort(compare).reverse();
      // let sortable = topProducts.sort(compare).reverse();
      let _top10 = sortable.slice(0, 11);
      // console.log("sortable", sortable);
      // console.log("sortable", _top10);
      products.map(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            _top10.map((_item,index) => {
              if (inner_item_product.name === _item.product_name) {
                tempTopProducts[index] = inner_item_product
              }
              return null;
            })
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
      // setProductsCount(tempProductsCount);
      // setProductsName(tempProductsName);
      // setTopProducts(_top10)
      function uniqBy(a) {
        var seen = {};
        return a.filter(function (item) {
          var k = item.creator;
          return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        })
      }
      let tempNewProducts = uniqBy(tempTopProducts)
      tempNewProducts = tempNewProducts.slice(0, 10);
      tempNewProducts = tempNewProducts.filter(n => n)
      // console.log("tempNewProducts=", tempNewProducts)
      setTopProducts(tempNewProducts)
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.error('error========', error);
    }
  }
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore])
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout showRegularImg={false}>
      {seo &&
        <Helmet>
          <title>{`${seo['appeals']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['appeals']['description']}`} />
        </Helmet>
      }
      <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="w-full px-4 md:px-2 lg:px-0">
          <h1 className="w-full text-center text-4xl md:text-5xl lg:text-7xl text-[#253b7e] py-2 font-['Gotcha'] mt-6">
            {page === 'general'
            ? 'Top 10 Most Popular'
            : 'Most Popular Palestine Appeals'
            }
            </h1>
          <div className="flex md:hidden pt-4 mb-4 justify-start items-center border-b border-stone-300">
            <div className={`flex rounded-t-md ${view === 'list' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('list')}>
              List
            </div>
            <div className={`flex rounded-t-md ${view === 'tile' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('tile')}>
              Tile
            </div>
            <div className={`flex rounded-t-md ${view === 'grid' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('grid')}>
              Grid
            </div>
          </div>
          <div className="grid grid-cols-1 ">
            <div className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4">
              <div className="flex justify-center flex-wrap">
                {topProducts.map((prod,index) => {
                  return prod.name !== 'Pakistan Solar Powered Well' && (
                    <div className={`${view === 'list' ? 'basis-full' : view === 'tile' ? 'basis-1/2 px-1' : 'basis-1/3 px-1'} sm:basis-1/3 md:basis-1/3 xl:basis-1/5 py-1 md:p-2`} key={`top10${index}`}>
                      <Top10ProductBox product={prod} currency={selectedCurrency} view={view} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

export default Top10Products;
